import AuthorizeApi from "../AuthorizeApi";
import {NeedResponse} from "../entity/response/NeedResponse";
import {ExecutionResponse} from "../entity/response/ExecutionResponse";

export default class SyncApi {

  private base: string = '/absence-sync';

  private authApi: AuthorizeApi = new AuthorizeApi();

  public async getStatusSync() {
    return await this.authApi.get<ExecutionResponse>(this.base);
  }

  public async sync() {
    return await this.authApi.post<void, ExecutionResponse>(this.base, null);
  }
}
