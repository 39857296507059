import {VehicleDailyPlanningResponseNt} from "./nt/VehicleDailyPlanningResponseNt";
import {VehicleDailyPlanningDto} from "../VehicleDailyPlanningDto";

export class VehicleDailyPlanningResponse {

  status: string;
  errors: string[];
  content: VehicleDailyPlanningDto[];

  constructor(vehicleDailyResp: VehicleDailyPlanningResponseNt) {
    this.status = vehicleDailyResp.status;
    this.errors = vehicleDailyResp.errors;
    this.content = vehicleDailyResp.content.map(v=> new VehicleDailyPlanningDto(v));
  }
}
