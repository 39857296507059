import React, {Component} from "react";
import TimeLineNavigation from "../../../components/TimeLine/TimeLineNavigation/TimeLineNavigation";
import {DateTime} from "luxon";
import DailyPlanningNavigationLabel from "./DailyPlanningNavigationLabel";
import "./DailyNavigationBar.scss"
import Button from "../../../components/Button/Button";
import {SelectedView} from "../DailyPlanning";
import IconChevronCircleLeft from "../../../icons/IconChevronCircleLeft";
import I18n from "../../../lib/I18n";

interface Props {
  selectedService:string
  selectedView:SelectedView
  currentDate:DateTime
  onDayChanged: (date: DateTime) => void
  onViewSelected:(selectedView:SelectedView)=>void
  deselectService:()=>void
}

interface State {
  isCalendarOpen:boolean
}

export default class DailyNavigationBar extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      isCalendarOpen:false
    }
  }
  render() {
    const {currentDate, selectedView, onDayChanged, selectedService} = this.props;
    return <div className="DailyNavigationBar">
      {selectedService && <div className="DailyNavigationBar-goBack">
        <Button flat onClick={()=>this.props.deselectService()}>
        <IconChevronCircleLeft/>{I18n.get().MonthlyTimeLine.exit}
        </Button>
      </div>}


      {!selectedService && <div className="DailyNavigationBar-actions">
        <Button
          onClick={()=>this.props.onViewSelected(SelectedView.SERVICES)}
          secondary={selectedView != SelectedView.SERVICES}>
          Ser.
        </Button>
        <Button
          onClick={()=>this.props.onViewSelected(SelectedView.WORKERS)}
          secondary={selectedView != SelectedView.WORKERS}>
          Dip.
        </Button>
        <Button
          onClick={()=>this.props.onViewSelected(SelectedView.VEHICLES)}
          secondary={selectedView != SelectedView.VEHICLES}>
          Mez.
        </Button>
      </div>}

      <TimeLineNavigation
       navigationLabel={<DailyPlanningNavigationLabel
       currentDate={currentDate} />}
       selectedDate={currentDate}
       onDateChange={date => onDayChanged(date)}
       prevPage={()=>this.previousMonth()}
       nextPage={()=>this.nextMonth()}
       granularity="day"
    /></div>
  }
  public nextMonth() {
    const {currentDate} = this.props;
    this.props.onDayChanged(currentDate.plus({day:1}))
  }

  public previousMonth() {
    const {currentDate} = this.props;
    this.props.onDayChanged(currentDate.minus({day:1}))
  }
}

