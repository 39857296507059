import React, {Component} from "react";
import "./MonthlyPlanningManualSettingsHeader.scss"
import Time from "../../../../../lib/utils/Time";

interface Props{
  day:Date
  totalF:number
  totalAR:number
  totalGR:number
}

interface State{

}

export default class MonthlyPlanningManualSettingsHeader extends Component<Props,State>{
  render() {
    const {day,totalF,totalAR,totalGR} = this.props
    return <div className="MonthlyPlanningManualSettingsHeader">
      <div className="MonthlyPlanningManualSettingsHeader-subtext">
        {Time.getStringDay(day)} {Time.toDoubleDigit(day.getDate())}
      </div>
      <div className="MonthlyPlanningManualSettingsHeader-container">
      {/*{totalF != 0 && */}<div className="MonthlyPlanningManualSettingsHeader-f">{totalF}</div>
      {/*{totalAR != 0 && */}<div className="MonthlyPlanningManualSettingsHeader-ar">{totalAR}</div>
      {/*{totalGR != 0 && */}<div className="MonthlyPlanningManualSettingsHeader-gr">{totalGR}</div>
      </div>
    </div>
  }

}
