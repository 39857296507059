import React, {BaseSyntheticEvent, Component} from "react";
import "./VehicleUnavailabilityModal.scss";
import Button from "../../Button/Button";
import I18n from "../../../lib/I18n";
import {ModalType} from "../ModalType";
import Select from "../../Select/Select";
import TimeInputPicker from "../../TimeInputPicker/TimeInputPicker";
import ShiftUtils from "../../../utils/ShiftUtils";
import {VehicleUnavailabilityDto} from "../../../api/entity/VehicleUnavailabilityDto";
import {GfDatePicker} from "../../DatePicker/GfDatePicker";
import {DateTime} from "luxon";
import {VehicleDto} from "../../../api/entity/VehicleDto";
import GfDateUtils from "../../DatePicker/GfDateUtils";
import TextInput from "../../TextInput/TextInput";

export interface Props {
  title: string,
  modalType: ModalType,
  /** This defines additional classes for the Button */
  className: string,
  /** Disables onclick
   *  @default false
   **/
  month: DateTime;
  vehicle: VehicleDto,
  vehicleUnDto: VehicleUnavailabilityDto;
  onSubmit: (vehicle: VehicleUnavailabilityDto) => void;
  onCancel: () => void;
  disabled: boolean;
}

interface State {
  vehicleUnDto: VehicleUnavailabilityDto;
  modalErrors: VehicleUnavailabilityErrors
}

export interface VehicleUnavailabilityErrors {
  fromDate: string;
  toDate: string;
  fromTime: string;
  toTime: string;
}

export class VehicleUnavailabilityModal extends Component<Props, State> {

  private static LAV_1 = "LAV_1";
  private static LAV_2 = "LAV_2";
  public static MOTIVATION_DTO = ["manutenzione", VehicleUnavailabilityModal.LAV_1, VehicleUnavailabilityModal.LAV_2];
  private static MOTIVATION = [I18n.get().VehicleUnavailabilityModal.reasons.maintenance,
    I18n.get().VehicleUnavailabilityModal.reasons.washing, I18n.get().VehicleUnavailabilityModal.reasons.washing2];

  private shiftUtils = new ShiftUtils();
  /************************************************
   * PROPS
   ************************************************/
  static defaultProps: Partial<Props> = {
    title: "",
    className: "",
    vehicle: new ShiftUtils().getEmptyVehicleDto(),
    onCancel: () => {
    },
    disabled: false,
  };

  /************************************************
   * CONSTRUCTOR
   ************************************************/
  constructor(props: Props) {
    super(props);
    this.state = {
      vehicleUnDto: props.vehicleUnDto,
      modalErrors: {fromDate: "", toDate: "", fromTime: "", toTime: ""}
    };
  }

  componentDidMount() {
    this.setState({
      vehicleUnDto: this.props.vehicleUnDto,
      modalErrors: {fromDate: "", toDate: "", fromTime: "", toTime: ""}
    });
  }

  onCancel() {
    this.props.onCancel();
  }

  private readonly LOWER_BOUND = 30;

  private onSubmit(event: BaseSyntheticEvent) {
    event.preventDefault();

    this.setState({modalErrors: {fromTime: "", fromDate: "", toDate: "", toTime: ""}}, () => {
      this.validate();
    });
  }

  private static isAnyErrorPresent(errors: VehicleUnavailabilityErrors) {
    let values = Object.values(errors);
    return values.filter(a => a.length != "").length > 0;
  }

  private validate() {
    const vehicleUnDto = this.state.vehicleUnDto;
    let {modalErrors} = this.state;

    if (vehicleUnDto.fromDate == vehicleUnDto.toDate &&
      GfDateUtils.convertHHMMToNumber(vehicleUnDto.fromTime) >= GfDateUtils.convertHHMMToNumber(vehicleUnDto.toTime)) {
      modalErrors.fromTime = I18n.get().VehicleUnavailabilityModal.errors.timeAfterTimeOnSameDay;
      modalErrors.toTime = I18n.get().VehicleUnavailabilityModal.errors.timeAfterTimeOnSameDay;
    }

    if (vehicleUnDto.fromDate == vehicleUnDto.toDate &&
      GfDateUtils.convertHHMMToNumber(vehicleUnDto.toTime) - GfDateUtils.convertHHMMToNumber(vehicleUnDto.fromTime) < this.LOWER_BOUND) {
      modalErrors.fromTime = I18n.get().VehicleUnavailabilityModal.errors.timeShouldBe30MinAtLeast;
      modalErrors.toTime = I18n.get().VehicleUnavailabilityModal.errors.timeShouldBe30MinAtLeast;
    }

    if (vehicleUnDto.fromDate.toMillis() > vehicleUnDto.toDate.toMillis()) {
      modalErrors.fromDate = I18n.get().VehicleUnavailabilityModal.errors.dateAfterDate;
    }
    if (vehicleUnDto.fromTime == "") {
      modalErrors.fromTime = I18n.get().VehicleRegistry.modal.emptyField;
    }
    if (vehicleUnDto.toTime == "") {
      modalErrors.toTime = I18n.get().VehicleRegistry.modal.emptyField;
    }

    this.setState({modalErrors}, () => {
      if (VehicleUnavailabilityModal.isAnyErrorPresent(this.state.modalErrors)) {
        return;
      }
      this.props.onSubmit(this.state.vehicleUnDto);
    });
  }

  private selectMotivation(index: number) {
    this.setState({
      vehicleUnDto: {
        ...this.state.vehicleUnDto,
        motivation: VehicleUnavailabilityModal.MOTIVATION_DTO[index],
        fromTime: this.getFromTime(VehicleUnavailabilityModal.MOTIVATION_DTO[index]),
        toDate: this.state.vehicleUnDto.fromDate,
        toTime: this.getToTime(VehicleUnavailabilityModal.MOTIVATION_DTO[index]),
      }
    });
  }

  private getFromTime(string: string) {
    switch (string) {
      case VehicleUnavailabilityModal.LAV_1:
        return "08:00"
      case VehicleUnavailabilityModal.LAV_2:
        return "17:00"
      default:
        return ""
    }
  }

  private getToTime(string: string) {
    switch (string) {
      case VehicleUnavailabilityModal.LAV_1:
        return "11:00"
      case VehicleUnavailabilityModal.LAV_2:
        return "20:00"
      default:
        return ""
    }
  }

  private selectStartDate(fromDate: DateTime) {
    let {vehicleUnDto, modalErrors} = this.state;
    vehicleUnDto.fromDate = fromDate;
    modalErrors.fromDate = "";

    if(this.isAnyWashingSelected()){
      vehicleUnDto.toDate = fromDate;
      modalErrors.toDate = "";
    }

    this.setState({vehicleUnDto, modalErrors});
  }

  private isAnyWashingSelected() {
    return this.state.vehicleUnDto.motivation == VehicleUnavailabilityModal.LAV_1 ||
      this.state.vehicleUnDto.motivation == VehicleUnavailabilityModal.LAV_2;
  }

  /************************************************
   * LIFECYCLE
   ************************************************/
  render() {
    return (
      <div className={"Modal VehicleUnavailabilityModal " + this.props.className}>
        <form onSubmit={(event) => this.onSubmit(event)}>
          <div>
            <h1>
              {I18n.get().VehicleUnavailabilityModal.title + " " + this.props.vehicle.name}
            </h1>
          </div>
          <div className={"form"}>
            <div className={"row row-3"}>
              <div className={"row-w"}>
                <Select
                  defaultValue={VehicleUnavailabilityModal.MOTIVATION.indexOf(this.state.vehicleUnDto.motivation)}
                  items={VehicleUnavailabilityModal.MOTIVATION} label={I18n.get().VehicleUnavailabilityModal.reason}
                  className={"row-w height"}
                  onChange={(index) =>
                    this.selectMotivation(index)}
                />
              </div>
            </div>
            <div className={"row row-1"}>
              <div className={"row-w"}>
                <GfDatePicker
                  label={I18n.get().VehicleUnavailabilityModal.startDate}
                  onChange={(fromDate) => {
                    this.selectStartDate(fromDate);
                  }}
                  minDate={this.props.month}
                  date={this.state.vehicleUnDto.fromDate}
                  errorMessage={this.state.modalErrors.fromDate}/>
              </div>
              <div className={"row-w"}>
                <TimeInputPicker
                  disabled={this.isAnyWashingSelected()}
                  onCorrectChange={(time) => {
                    let {vehicleUnDto, modalErrors} = this.state;
                    vehicleUnDto.fromTime = time;
                    modalErrors.fromTime = "";
                    this.setState({vehicleUnDto, modalErrors});
                  }}
                  onChange={(e) => {
                    this.setState({modalErrors: {fromTime: "", ...this.state.modalErrors}})
                  }}
                  label={I18n.get().VehicleUnavailabilityModal.from}
                  placeholder={"00:00"}
                  className={"row-w"}
                  defaultValue={this.state.vehicleUnDto.fromTime}
                  error={this.state.modalErrors.fromTime != ""}
                />
              </div>

            </div>
            <div className={"row row-2"}>
              <div className={"row-w"}>
                <GfDatePicker
                  disabled={this.isAnyWashingSelected()}
                  label={I18n.get().VehicleUnavailabilityModal.endDate}
                  onChange={(toDate) => {
                    let {vehicleUnDto, modalErrors} = this.state;
                    vehicleUnDto.toDate = toDate;
                    modalErrors.toDate = "";
                    this.setState({vehicleUnDto, modalErrors});
                  }}
                  minDate={this.props.month}
                  date={this.state.vehicleUnDto.toDate}
                  errorMessage={this.state.modalErrors.toDate}/>
              </div>
              <div className={"row-w"}>
                <TimeInputPicker
                  disabled={this.isAnyWashingSelected()}
                  onCorrectChange={(time) => {
                    let {vehicleUnDto, modalErrors} = this.state;
                    vehicleUnDto.toTime = time;
                    modalErrors.toTime = "";
                    this.setState({vehicleUnDto, modalErrors});

                  }}
                  label={I18n.get().VehicleUnavailabilityModal.to}
                  placeholder={"00:00"}
                  className={"row-w"}
                  defaultValue={this.state.vehicleUnDto.toTime}
                  error={this.state.modalErrors.toTime != ""}
                />
              </div>

            </div>
            <div className={"row row-2"}>
              <div className={"row-fw"}>
                <TextInput
                  label={I18n.get().VehicleUnavailabilityModal.notes}
                  onChange={(desc) => {
                    let {vehicleUnDto} = this.state;
                    vehicleUnDto.description = desc;
                    this.setState({vehicleUnDto});
                  }}
                  placeholder={""}
                  errorMessage={""}/>
              </div>
            </div>
            <p className={"error"}>{this.state.modalErrors.fromTime}</p>
          </div>
          <div>
            <div className={"buttons"}>
              <Button onClick={(event) => this.onSubmit(event)}
                      className={"add-btn"}>
                {this.props.modalType == ModalType.CREATE ? I18n.get().WorkersRegistry.confirmAddWorker :
                  I18n.get().WorkersRegistry.confirmModifyWorker}
              </Button>
              <Button secondary={true}
                      onClick={() => this.onCancel()}
                      className={"cancel-btn"}>
                {I18n.get().WorkersRegistry.cancelAddWorker}</Button>
            </div>
          </div>
        </form>
      </div>
    )
  }
}
