import React, {Component, PureComponent} from "react";
import "./UnavailabilityModal.scss";
import {ModalType} from "../ModalType";
import I18n from "../../../lib/I18n";
import GfDateUtils from "../../DatePicker/GfDateUtils";
import {WeekToggleGroup} from "../../WeekToggleGroup/WeekToggleGroup";
import Button from "../../Button/Button";
import WorkerWeeklyUnDto from "../../../api/entity/WorkerWeeklyUnDto";


export interface Props {
  /** This defines additional classes for the Button */
  className?: string,
  label?: string,
  /** This dictates what the button will do on click */
  onOpen?: () => void;
  onClose?: () => void;
  onSubmit?: (modifiedUnavailabilityData: WorkerWeeklyUnDto[]) => void;
  isOpen?: boolean;
  type: ModalType;
  unavailabilityData: WorkerWeeklyUnDto[];
}

interface State {
  isOpen: boolean;
  selection: string;
  isRowError: boolean[];
  modifiedUnavailabilityData: WorkerWeeklyUnDto[];
}

export class UnavailabilityModal extends Component<Props, State> {


  /************************************************
   * PROPS
   ************************************************/
  static defaultProps: Partial<Props> = {
    className: "",
    label: "",
    isOpen: true,
    onOpen: () => {
    },
    onClose: () => {
    },
    onSubmit: (event) => {
    },
  };

  /************************************************
   * CONSTRUCTOR
   ************************************************/
  constructor(props: Props) {
    super(props);
    this.state = this.getCleanState();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (prevProps.unavailabilityData != this.props.unavailabilityData) {
      this.setState(this.getCleanState());
    }
  }

  private onSubmit(unAvailabilityData) {
    if (this.isErrorForm()) {
      return;
    }
    this.props.onSubmit(this.state.modifiedUnavailabilityData);
  }

  private onCancel() {
    this.setState(this.getCleanState());
    this.props.onClose();
  }

  private isErrorForm() {
    return this.state.isRowError.filter(a => a).length > 0;
  }

  private onClose() {
    this.props.onClose();
  }

  private getCleanState() {
    return {
      selection: "", isOpen: false,
      isRowError: this.props.unavailabilityData.map(value => false),
      modifiedUnavailabilityData: this.props.unavailabilityData
    };
  }

  private onChange(index: number, fromTime: string, toTime: string, selectedIndexes: number[], error: boolean) {
    let previousUnavailabilityDto = this.state.modifiedUnavailabilityData[index];
    this.state.isRowError[index] = error;

    let electedFromTime = fromTime == "24:00" ? "00:00" : fromTime
    let electedToTime = toTime == "24:00" ? "00:00" : toTime

    let {modifiedUnavailabilityData} = this.state;

    modifiedUnavailabilityData[index] = {
      id: previousUnavailabilityDto.id,
      fromTime: electedFromTime,
      toTime: electedToTime,
      weekRow: previousUnavailabilityDto.weekRow,
      weekdays: selectedIndexes,
      workerId: previousUnavailabilityDto.workerId
    };

    this.setState({modifiedUnavailabilityData});
  }

  /************************************************
   * LIFECYCLE
   ************************************************/
  render() {
    return <div className={this.props.className + " Modal UnavailableModal " + (this.props.isOpen ? "open" : "")}>
      <div>
        <h1>
          {I18n.get().WorkersRegistry.modifyUnavailability}
        </h1>
      </div>
      <div onClick={() => this.onClose()} className={"close"}>&times;</div>

      <form onSubmit={(event) => this.onSubmit(event)}>
        <div className={"side"}>

          <div className={"row week"}>

            <div className={"head weekdays"}>
              <label>{I18n.get().WorkersRegistry.weeklyTimeSpan}</label>
              <label>{I18n.get().WorkersRegistry.fromTime}</label>
              <label>{I18n.get().WorkersRegistry.toTime}</label>
            </div>
            {
              this.props.unavailabilityData.slice(0, GfDateUtils.WEEK_ENTRIES)
                .map((weeklyRecord) => {
                    return <WeekToggleGroup elements={GfDateUtils.getWeekDaysShort()}
                                            index={weeklyRecord.weekRow}
                                            key={weeklyRecord.weekRow}
                                            selectedIndexes={weeklyRecord.weekdays}
                                            fromTime={weeklyRecord.fromTime}
                                            toTime={weeklyRecord.toTime}
                                            onBlur={(index, fromTime, toTime, selectedIndexes, error) =>
                                              this.onChange(index, fromTime, toTime, selectedIndexes, error)}
                    />
                  }
                )
            }
            <div className={"head weekend"}>
              <label>{I18n.get().WorkersRegistry.weekendTimeSpan}</label>
              <label>{I18n.get().WorkersRegistry.fromTime}</label>
              <label>{I18n.get().WorkersRegistry.toTime}</label>
            </div>
            {
              this.props.unavailabilityData
                .slice(GfDateUtils.WEEK_ENTRIES, GfDateUtils.WEEK_ENTRIES + GfDateUtils.WEEKEND_ENTRIES)
                .map((weeklyRecord) => {
                    return < WeekToggleGroup
                      elements={GfDateUtils.getWeekendDaysShort()}
                      key={weeklyRecord.weekRow}
                      index={weeklyRecord.weekRow}
                      indexesOffset={GfDateUtils.WEEK_ENTRIES + GfDateUtils.WEEKEND_ENTRIES}
                      selectedIndexes={weeklyRecord.weekdays}
                      fromTime={weeklyRecord.fromTime}
                      toTime={weeklyRecord.toTime}
                      onBlur={(index, fromTime, toTime, selectedIndexes, error) =>
                        this.onChange(index, fromTime, toTime, selectedIndexes, error)}
                    />
                  }
                )
            }
          </div>
        </div>
      </form>
      <div className={"buttons"}>
        <Button onClick={(event) => this.onSubmit(event)}
                className={"add-btn"}>
          {this.props.type == ModalType.CREATE ? I18n.get().WorkersRegistry.confirmAddWorker :
            I18n.get().WorkersRegistry.confirmModifyWorker}
        </Button>
        <Button secondary={true}
                onClick={() => this.onCancel()}
                className={"cancel-btn"}>
          {I18n.get().WorkersRegistry.cancelAddWorker}</Button>
      </div>
    </div>
  }
}
