import React, {BaseSyntheticEvent, Component} from "react";
import "./FixedShiftModal.scss";
import Button from "../../Button/Button";
import I18n from "../../../lib/I18n";
import ShiftUtils from "../../../utils/ShiftUtils";
import {FixedShiftDto} from "../../../api/entity/FixedShiftDto";
import {ShiftDto} from "../../../api/entity/ShiftDto";
import {FixedShiftEntry} from "./Entry/FixedShiftEntry";

export interface Props {
  title: string,
  /** This defines additional classes for the Button */
  className: string,
  /** Disables onclick
   *  @default false
   **/
  shiftsDto: FixedShiftDto[];
  shifts: ShiftDto[];
  onSubmit: (fixedShifts: FixedShiftDto[]) => void;
  onCancel: () => void;
  disabled: boolean;
}

interface State {
  fixedShiftsDto: FixedShiftDto[],
  availableShifts: ShiftDto[],
  notAvailableShifts: ShiftDto[],
  options: any[]
  selectedDays: number[],
}

export class FixedShiftModal extends Component<Props, State> {


  private readonly IS_SELECTED = 1;

  /************************************************
   * PROPS
   ************************************************/
  static defaultProps: Partial<Props> = {
    title: "",
    className: "",
    onCancel: () => {
    },
    disabled: false,
  };

  /************************************************
   * CONSTRUCTOR
   ************************************************/
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedDays: this.getAllSelectedDaysIndexes(props.shiftsDto),
      fixedShiftsDto: props.shiftsDto,
      // shift that are not in use, therefore available
      availableShifts: props.shifts.filter(shift => props.shiftsDto
        .map(s => s.shiftCode).indexOf(shift.shiftCode) == -1),
      // shift that are in use, therefore unavailable
      notAvailableShifts: props.shifts.filter(shift => props.shiftsDto
        .map(s => s.shiftCode).indexOf(shift.shiftCode) != -1),
      options: props.shifts.map(a => {
        return {value: a.id, label: a.shiftCode}
      })
    };
  }

  onRemoveElements(fixedShift: FixedShiftDto) {
    let {fixedShiftsDto} = this.state;
    let indexOfRemovedEntry = fixedShiftsDto.indexOf(fixedShift);
    const removed = fixedShiftsDto[indexOfRemovedEntry];

    this.reAddShiftToList(removed.shiftCode);

    fixedShiftsDto.splice(indexOfRemovedEntry, 1);
    this.setState({
      fixedShiftsDto,
      selectedDays: this.getAllSelectedDaysIndexes(fixedShiftsDto)
    });
  }

  private onSubmit(event: BaseSyntheticEvent) {
    event.preventDefault();
    this.props.onSubmit(this.state.fixedShiftsDto);
  }

  private addFixedShift() {
    let {availableShifts, notAvailableShifts, fixedShiftsDto} = this.state;
    let shiftDto = availableShifts[0];
    this.removeShiftFromPossibleSelection(shiftDto.shiftCode);
    fixedShiftsDto.push({
      days: ShiftUtils.getEmptyWeek(), userId: null,
      shiftCode: shiftDto.shiftCode, id: 0, shiftId: shiftDto.id
    });
    this.setState({availableShifts, fixedShiftsDto, notAvailableShifts})
  }

  private onChange(index: number,
                   newShift: FixedShiftDto,
                   previousShift: FixedShiftDto) {
    let {fixedShiftsDto, notAvailableShifts} = this.state;
    // If the shift code has changed
    this.reAddShiftToList(previousShift.shiftCode);
    this.removeShiftFromPossibleSelection(newShift.shiftCode);
    fixedShiftsDto[index] = newShift;
    let selectedDays = this.getAllSelectedDaysIndexes(this.state.fixedShiftsDto);
    this.setState({fixedShiftsDto, selectedDays, notAvailableShifts});
  }

  private removeShiftFromPossibleSelection(shiftCode: string) {
    let {availableShifts, notAvailableShifts} = this.state;
    let shift = availableShifts.filter(f => f.shiftCode == shiftCode)
    if (shift.length == 0) {
      console.error("Shift not found: " + shift);
    }
    availableShifts.splice(availableShifts.indexOf(shift[0]), 1);
    notAvailableShifts.push(shift[0])
    this.setState({availableShifts, notAvailableShifts});
  }

  private reAddShiftToList(shiftCode: string) {
    let {availableShifts, notAvailableShifts} = this.state;
    let reinsertedElement = notAvailableShifts.find(e => e.shiftCode == shiftCode);
    availableShifts.push(reinsertedElement)
    notAvailableShifts.splice(notAvailableShifts.indexOf(reinsertedElement), 1);
    this.setState({availableShifts, notAvailableShifts});
  }

  private getAllSelectedDaysIndexes(fixedShiftDto: FixedShiftDto[]): number[] {
    let map: number[][] = fixedShiftDto.map(f => f.days);
    let allSelected: number[] = [];
    map.forEach(a => {
        a.forEach((b, i) => {
          if (b == this.IS_SELECTED) {
            allSelected.push(i)
          }
        })
      }
    )
    return allSelected;
  }

  private onClose() {
    this.props.onCancel();
  }

  /************************************************
   * LIFECYCLE
   ************************************************/
  render() {
    return (
      <div className={"Modal FixedShiftModal " + this.props.className}>
        <form>
          <div>
            <h1>
              {I18n.get().ShiftRegistry.addNewShift}
            </h1>
          </div>
          <div className={"form"}>
            <div className={"row row-1"}>
              {this.state.fixedShiftsDto.map((fixedShift, entryIndex) =>
                <FixedShiftEntry
                  key={entryIndex + "_" + fixedShift.shiftId}
                  index={entryIndex}
                  fixedShift={fixedShift}
                  onShiftChange={
                    (index, fixedShift, previousShift) =>
                      this.onChange(index, fixedShift, previousShift)}
                  onCancel={(fixedShift) => this.onRemoveElements(fixedShift)}
                  availableShifts={this.state.availableShifts}
                  daysAlreadySelected={this.state.selectedDays}/>
              )}
            </div>
            <div className={"row row-1 bottom-row"}>
              {this.state.availableShifts.length > 0 ? (
                <div onClick={() => this.addFixedShift()} className={"addEmptyContract"}>
                  <span className={"icon"}>&#43;</span>
                  <span className={"label"}>{I18n.get().ShiftRegistry.shiftModal.addFixedShift}</span>
                </div>
              ) : <div>{I18n.get().ShiftRegistry.shiftModal.emptyShiftModal}</div>
              }
            </div>
          </div>
          <div>
            <div className={"buttons"}>
              <Button onClick={(event) => this.onSubmit(event)}
                      className={"add-btn"}>
                {I18n.get().WorkersRegistry.confirmModifyWorker}
              </Button>
              <Button secondary={true}
                      onClick={() => this.onClose()}
                      className={"cancel-btn"}>
                {I18n.get().WorkersRegistry.cancelAddWorker}</Button>
            </div>
          </div>
        </form>
      </div>
    )
  }
}
