import React from 'react';
import './ShiftRegistry.scss';
import Page from "../Page";
import I18n from "../../lib/I18n";
import Table, {CellVisibilityRule, Field, Header, ValueOf} from "../../components/Table/Table";
import Toast from "../../components/Toast/Toast";
import Button from "../../components/Button/Button";
import ShiftRegistryApi from "../../api/shift/ShiftRegistryApi";
import {ShiftResponse} from "../../api/entity/response/ShiftResponse";
import Ellipsis from "../../components/Ellipsis/Ellipsis";
import {FocusPanel} from "../../components/FocusPanel/FocusPanel";
import {ShiftModal} from "../../components/Modal/ShiftModal/ShiftModal";
import {ShiftDto} from "../../api/entity/ShiftDto";
import {ModalType} from "../../components/Modal/ModalType";
import {OperationResult} from "../../api/entity/OperationResult";
import SearchInput from "../../components/SearchInput/SearchInput";
import {Pagination} from "../../components/Table/Pagination";
import IconPencil from "../../icons/IconPencil";
import ShiftUtils from "../../utils/ShiftUtils";
import IconCheck from "../../icons/IconCheck";
import {PartialRecord} from "../../lib/TypeUtils";
import Spinner from "../../components/Spinner/Spinner";

interface State {
  table: {
    headers: Header<TableEntry>[];
    shift: ShiftDto[];
    items: TableEntry[];
    currentSlice: number;
  };
  modal: {
    selectedShiftDto: ShiftDto;
    createModify: ModalType;
  }

  isToastOpen: boolean;
  registryCount: number;
  isCreateModifyOpen: boolean;
  toast: {
    isError: boolean;
    errorList: string[];
    message: string;
  }
  loading: boolean
}

interface TableEntry {
  id: number,
  shiftCode: string,
  timeSlot: string,
  typology: string,
  shiftType: string,
  duration: string,
  pause: string,
  description: string,
  action: number,
  autoGenerated: boolean
}

export default class ShiftRegistry extends Page<{}, State> {
  private readonly elementsPerPage = 50;

  private shiftApi = new ShiftRegistryApi();
  private shiftUtils = new ShiftUtils();


  private itemsRender: PartialRecord<Field<TableEntry>, (value: ValueOf<TableEntry>, item: TableEntry) => React.ReactNode> = {
    typology: (value: string) =>
      <div className={"TypologyContainer"}>
        {value == ShiftUtils.NOTHING_VALUE ? null :
          value
            .split(';').map(v => <div className={"Typology"}>{this.shiftUtils.resolveLabelForShiftTypology(v)}</div>)
        }
      </div>,
    description: (value) =>
      <div className={"DescriptionContainer"}>
        <div className={"desc-w"}>
          <Ellipsis>{value}</Ellipsis>
        </div>
      </div>,
    pause: (value) =>
      <div className={"PauseContainer"}>
        {(!value || value == "00:00") ? null : <IconCheck/>}
      </div>,
    action: (id, item) => {
      return !item.autoGenerated ? <div className={"actionButton"}
                                        onClick={(e) => this.onModifySelection(id)}>
        <IconPencil width={24} height={24}/>
      </div> : null
    },
  };

  public constructor(props) {
    super(props);

    this.state = {
      isToastOpen: false,
      registryCount: 0,
      modal: {
        selectedShiftDto: null,
        createModify: ModalType.CREATE
      },
      table: {
        shift: [],
        headers: [],
        items: [],
        currentSlice: 0
      },
      toast: {
        isError: false,
        message: "",
        errorList: [],
      },
      isCreateModifyOpen: false,
      loading: true
    }
  }

  render() {
    if (this.state.loading)
      return <Spinner/>;
    return (
      <div>
        <FocusPanel show={this.state.isCreateModifyOpen}/>
        {this.state.isCreateModifyOpen ?
          <ShiftModal modalType={this.state.modal.createModify}
                      shiftDto={this.state.modal.selectedShiftDto}
                      onSubmit={(e) => this.upsertShift(e)}
                      onModify={(e) => this.modifyShift(e)}
                      onCancel={() => this.toggleCreateModifyModal()}/> : null}

        <div className={"inner-content"}>
          <Toast isError={this.state.toast.isError}
                 message={this.state.toast.message}
                 errors={this.state.toast.errorList}
                 visible={this.state.isToastOpen}
                 trigger={() => this.closeToast()}/>

          <div className={"top"}>
            <div className={"up-title"}>
              <h1>{I18n.get().Menu.shiftRegistry}</h1>
            </div>
            <div className={"up-controls"}>
              <Button onClick={() => {
                this.setState({modal: {...this.state.modal, createModify: ModalType.CREATE}})
                this.toggleCreateModifyModal();
              }}>
                {I18n.get().ShiftRegistry.addNewShift}</Button>
            </div>
          </div>

          <div className={"search-location"}>
            <SearchInput className="search-input" label="" defaultValue=""
                         placeholder={I18n.get().ShiftRegistry.findShift} onChange={(value) => this.onSearch(value)}
                         readonly={false} disabled={false} password={false} errorMessage={""}/>
          </div>
          <div className={"search-result"}>
            <Table<TableEntry> headers={this.state.table.headers}
                               items={this.state.table.items}
                               itemsRender={this.itemsRender}
                               onClick={(i) => {
                               }}/>
            <Pagination
              page={this.state.table.currentSlice}
              onPageChange={(page) => this.onPaginationChange(page)}
              totalPages={this.state.registryCount}
              elementsPerPage={this.elementsPerPage}/>
          </div>
        </div>
      </div>
    );
  }


  private toggleCreateModifyModal() {
    this.setState({isCreateModifyOpen: !this.state.isCreateModifyOpen})
  }


  async componentDidMount() {
    try {
      await this.fetchShifts();
      this.setState({loading: false})
    } catch {
      this.setState({loading: false})
    }
  }

  private async modifyShift(shiftDto: ShiftDto) {
    const preparedShiftDto = this.prepareEntityForSaving(shiftDto);

    let shiftResponsePromise = await this.shiftApi.modify(preparedShiftDto);
    if (shiftResponsePromise.status == OperationResult.OK) {
      this.toggleSuccessMessage(I18n.get().ShiftRegistry.shiftModal.shiftModifiedSuccessMessage)
      this.toggleCreateModifyModal();
      await this.fetchShifts();
    }
  }

  private async upsertShift(shiftDto: ShiftDto) {
    const preparedShiftDto = this.prepareEntityForSaving(shiftDto);
    let shiftResponsePromise = await this.shiftApi.create(preparedShiftDto);
    if (shiftResponsePromise.status == OperationResult.OK) {
      this.toggleSuccessMessage(I18n.get().ShiftRegistry.shiftModal.shiftCreatedSuccessMessage)
      this.toggleCreateModifyModal();
      await this.fetchShifts();
    }
  }

  private prepareEntityForSaving(shiftDto: ShiftDto): ShiftDto {
    let electedFromTime = shiftDto.fromTime == "24:00" ? "00:00" : shiftDto.fromTime;
    let electedToTime = shiftDto.toTime == "24:00" ? "00:00" : shiftDto.toTime;
    return {
      id: shiftDto.id, fromTime: electedFromTime, toTime: electedToTime,
      typology: shiftDto.typology, description: shiftDto.description, shiftCode: shiftDto.shiftCode,
      time: shiftDto.time, shiftType: shiftDto.shiftType, pause: shiftDto.pause, autoGenerated: shiftDto.autoGenerated,
      smontoH: shiftDto.smontoH, archived: shiftDto.archived
    };
  }

  private async fetchShifts() {
    let shiftResponse = await this.shiftApi.get("", this.elementsPerPage,
      this.elementsPerPage * (this.state.table.currentSlice));
    this.setState({registryCount: shiftResponse.total});
    this.updateShiftTable(shiftResponse);
  }

  private closeToast() {
    this.setState({isToastOpen: false});
  }

  private toggleErrorMessage(errors: string[]) {
    this.setState({
      toast: {
        isError: true,
        message: I18n.get().WorkersRegistry.errorMessage,
        errorList: errors,
      },
      isToastOpen: true
    });
  }

  private toggleSuccessMessage(message: string) {
    this.setState({
      toast: {
        isError: false,
        message: message,
        errorList: [],
      },
      isToastOpen: true
    })
  }

  private onError(errors: string[]) {
    this.toggleErrorMessage(errors);
  }

  private async onSearch(value: string) {
    if (value) {
      let response = await this.shiftApi.get(value, this.elementsPerPage,
        this.elementsPerPage * (this.state.table.currentSlice));
      this.setState({registryCount: response.content.length});
      this.resetTable();
      this.updateShiftTable(response);
    } else {
      this.resetTable();
      await this.fetchShifts();
    }
  }

  private async onPaginationChange(page) {
    this.state.table.currentSlice = page;
    await this.fetchShifts();
  }

  private resetTable() {
    this.setState({ table: { ...this.state.table, currentSlice: 0}})
  }

  private updateShiftTable(shiftResponse: ShiftResponse) {
    let shiftUtils = this.shiftUtils;
    this.setState({
        table: {
          ...this.state.table,
          headers: [
            {
              name: I18n.get().ShiftRegistry.table.shiftCode,
              field: "shiftCode"
            },
            {
              name: I18n.get().ShiftRegistry.table.timeSlot,
              field: "timeSlot"
            },
            {
              name: I18n.get().ShiftRegistry.table.duration,
              field: "duration",
              visibility: CellVisibilityRule.FOR_TABLET_LANDSCAPE_UP
            },
            {
              name: I18n.get().ShiftRegistry.table.pause,
              field: "pause",
              visibility: CellVisibilityRule.FOR_TABLET_LANDSCAPE_UP
            },
            {
              name: I18n.get().ShiftRegistry.table.typology,
              field: "typology",
              visibility: CellVisibilityRule.FOR_TABLET_PORTRAIT_UP
            },
            {
              name: I18n.get().ShiftRegistry.table.shiftType,
              field: "shiftType"
            },
            {
              name: I18n.get().ShiftRegistry.table.description,
              field: "description",
              visibility: CellVisibilityRule.FOR_DESKTOP_UP
            },
            {
              name: "",
              field: "action"
            }
          ],
          shift: shiftResponse.content,
          items:
            shiftResponse.content.map(function (e) {
              return {
                id: e.id,
                shiftCode: e.shiftCode,
                timeSlot: e.fromTime + " - " + (e.toTime == "00:00" ? "24:00" : e.toTime),
                typology: e.typology.join(ShiftDto.separatorTypology),
                shiftType: shiftUtils.resolveLabelForShiftTime(e.shiftType),
                duration: e.time,
                pause: e.pause,
                description: e.description,
                action: e.id,
                autoGenerated: e.autoGenerated
              };
            })
        }
      }
    );
  }

  private onModifySelection(i) {
    const subject = this.state.table.shift.filter(a => a.id == i);
    this.setState({
      modal: {
        selectedShiftDto: subject[0],
        createModify: ModalType.MODIFY
      }
    })
    this.toggleCreateModifyModal();
  }
}
