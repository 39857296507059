import React, {Component} from "react";
import Button from "../../../components/Button/Button";
import I18n from "../../../lib/I18n";
import {DateTime} from "luxon";
import "./MonthlyTimeLineButtonBar.scss"
import {StatusEnum} from "../../../api/engine/EngineApi";
import {FocusPanel} from "../../../components/FocusPanel/FocusPanel";
import {ConfirmModal} from "../../../components/Modal/ConfirmModal/ConfirmModal";
import MonthlyPlanningViewSelector from "../MonthlyPlanningViewSelector/MonthlyPlanningViewSelector";
import {MonthlyCompletedView} from "../MonthlyPlanning";
import MonthlyDownloadButtonBar from "./MonthlyDownloadButtonBar/MonthlyDownloadButtonBar";
import {CircuitSelect} from "../../../components/CircuitSelect/CircuitSelect";

interface Props {
  status: StatusEnum;
  editMode: boolean;
  month: DateTime;
  monthHasData: boolean;
  monthHasElapsed: boolean;
  monthHasOverloaded: boolean;
  resetPlanning:()=>void
  startDailyPlanning: () => void
  verifyConstraints: () => void
  onToggleEditMode: () => void
  onChangeView: (MonthlyCompletedView) => void
  onCircuitFilteringChange: (circuit) => void
  view: MonthlyCompletedView
}

interface State {
  isPlanningDisabled: boolean
  isConfirmationVisible: boolean
  showMonthlyPlanningResetConfirmModal:boolean
}

export default class MonthlyTimeLineButtonBar extends Component<Props, State> {
  /**************************************************
   * CONSTRUCTOR
   **************************************************/
  toggleConfirmation() {
    let {isConfirmationVisible} = this.state;
    isConfirmationVisible = !isConfirmationVisible;
    this.setState({isConfirmationVisible});
  }

  runPlanning() {
    this.setState({isPlanningDisabled: true})
    this.props.startDailyPlanning();
    this.toggleConfirmation();
    setTimeout(() => this.setState({isPlanningDisabled: false}), 10 * 1000);
  }


  constructor(props: Props) {
    super(props);

    this.state = {
      isPlanningDisabled: false,
      isConfirmationVisible: false,
      showMonthlyPlanningResetConfirmModal:false
    }
  }

  /**************************************************
   * LIFECYCLE
   **************************************************/
  render() {
    let label = I18n.get().MonthlyTimeLine;
    const {status, editMode} = this.props;

    if (this.isNextMonth() && status == StatusEnum.MONTHLY_PLANNING_COMPLETED)
      return <div className="MonthlyTimeLineButtonBar">

        <span className={this.state.isConfirmationVisible ? "" : "hide"}>
          <ConfirmModal body={I18n.get().MonthlyPlanning.confirmModalBody}
                        title={I18n.get().MonthlyPlanning.confirmModalTitle}
                        onClick={() => this.runPlanning()} onCancel={() => this.toggleConfirmation()}/>
        </span>

        <FocusPanel show={this.state.isConfirmationVisible}/>
        <div className={"MonthlyTimeLineButtonBar-row"}>

          {/*<Button*/}
          {/*  onClick={() => this.props.onReset()}>*/}
          {/*  RESET*/}
          {/*</Button>*/}
          <div className={"MonthlyTimeLineButtonBar-leftSection"}>
            <MonthlyPlanningViewSelector
              isDataAvailable={this.props.monthHasData}
              isElapsedAvailable={this.props.monthHasElapsed}
              isOverloadedAvailable={this.props.monthHasOverloaded}
              onSelect={(type) => {
                this.props.onChangeView(type)
              }}
              state={this.props.view}
            />
            <CircuitSelect className={"circuit-select"} onChange={(circuitDto) => {
              this.props.onCircuitFilteringChange(circuitDto)
            }}/>
          </div>

          <div className={"MonthlyTimeLineButtonBar-rightSection"}>

            <Button className="modify-btn"
                    flat onClick={() => this.props.onToggleEditMode()}>
              {editMode ? label.disableModify : label.enableModify}
            </Button>

            <Button className="reset-btn" secondary disabled={this.state.isPlanningDisabled} onClick={() => {
              this.setState({showMonthlyPlanningResetConfirmModal:true})
            }}>{label.reset_planning}</Button>

            <Button
              secondary onClick={() => {
              this.setState({isPlanningDisabled: true})
              this.props.verifyConstraints()
              setTimeout(() => this.setState({isPlanningDisabled: false}), 10 * 1000)
            }}>
              {label.verify_boundaries}
            </Button>

            <Button className={"confirm-btn"}
                    onClick={() => {
                      this.toggleConfirmation();
                    }}>
              {label.confirm_planning}
            </Button>

            <MonthlyDownloadButtonBar month={this.props.month}
                                      monthHasData={this.props.monthHasData}
                                      monthHasElapsed={this.props.monthHasElapsed}
                                      monthHasOverloaded={this.props.monthHasOverloaded}
                                      view={this.props.view}/>



          </div>
        </div>
        {this.state.showMonthlyPlanningResetConfirmModal && <ConfirmModal
          title={label.reset_planning_title}
          body={label.reset_planning_body}
          onClick={()=>{this.props.resetPlanning(); this.setState({showMonthlyPlanningResetConfirmModal:false})}}
          onCancel={()=>this.setState({showMonthlyPlanningResetConfirmModal:false})}/>}
      </div>
    else if (status == StatusEnum.DAILY_PLANNING_COMPLETED)
      return <div className="MonthlyTimeLineButtonBar">

        <div className={"MonthlyTimeLineButtonBar-row"}>
          <div className={"MonthlyTimeLineButtonBar-leftSection"}>
            <MonthlyPlanningViewSelector
              isDataAvailable={true}
              isElapsedAvailable={this.props.monthHasElapsed}
              isOverloadedAvailable={this.props.monthHasOverloaded}
              onSelect={(type) => {
                this.props.onChangeView(type)
              }}
              state={this.props.view}
            />
            <CircuitSelect className={"circuit-select"} onChange={(circuitDto) => {
              this.props.onCircuitFilteringChange(circuitDto)
            }}/>
          </div>
          <div className={"MonthlyTimeLineButtonBar-rightSection"}>
            <MonthlyDownloadButtonBar month={this.props.month}
                                      monthHasData={this.props.monthHasData}
                                      monthHasElapsed={this.props.monthHasElapsed}
                                      monthHasOverloaded={this.props.monthHasOverloaded}
                                      view={this.props.view}/>
          </div>

        </div>

      </div>
    else
      return <div className={"MonthlyTimeLineButtonBar"}>
        <div className={"MonthlyTimeLineButtonBar-row"}>
          <div className={"MonthlyTimeLineButtonBar-leftSection"}>
            <MonthlyPlanningViewSelector
              isElapsedAvailable={this.props.monthHasElapsed}
              isOverloadedAvailable={this.props.monthHasOverloaded}
              isDataAvailable={false}
              onSelect={(type) => {
                this.props.onChangeView(type)
              }}
              state={this.props.view}
            />
            <CircuitSelect className={"circuit-select"} onChange={(circuitDto) => {
              this.props.onCircuitFilteringChange(circuitDto)
            }}/>
          </div>
          <div className={"MonthlyTimeLineButtonBar-rightSection"}>
            {status == StatusEnum.MONTHLY_PLANNING_COMPLETED ?
              <MonthlyDownloadButtonBar month={this.props.month}
                                        monthHasData={this.props.monthHasData}
                                        monthHasElapsed={this.props.monthHasElapsed}
                                        monthHasOverloaded={this.props.monthHasOverloaded}
                                        view={this.props.view}/> : null
            }
          </div>

        </div>
      </div>

  }


  /**************************************************
   * PRIVATE FUNCTIONS
   **************************************************/
  private isNextMonth(): boolean {
    return this.props.month.equals(DateTime.local().startOf('month').plus({months: 1}))
  }

}
