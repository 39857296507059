import {DateTime} from "luxon";
import {MonthlyPlanningDtoNt} from "./nt/MonthlyPlanningDtoNt";
import GfDateUtils from "../../components/DatePicker/GfDateUtils";
import {StatusEnum} from "../engine/EngineApi";

export class MonthlyPlanningDto {
  id: number;
  month: DateTime
  data : string
  lastupdate : DateTime
  status : StatusEnum
  errors : string
  infeasible: string
  laststartedplanning: DateTime
  lastverifieddata: DateTime
  completedon: DateTime

  constructor(mpd: MonthlyPlanningDtoNt) {
    this.id = mpd.id;
    this.month = DateTime.fromFormat(mpd.month, GfDateUtils.STORED_DATE_FORMAT);
    this.data = mpd.data;
    this.lastupdate = DateTime.fromFormat(mpd.lastupdate, GfDateUtils.STORED_DATE_TIME_FORMAT);
    this.status = StatusEnum[mpd.status];
    this.errors = mpd.errors;
    this.infeasible = mpd.infeasible
    this.laststartedplanning = DateTime.fromFormat(mpd.laststartedplanning, GfDateUtils.STORED_DATE_TIME_FORMAT)
    this.lastverifieddata = DateTime.fromISO(mpd.lastverifieddata)
    this.completedon = mpd.completedon == null ? null : DateTime.fromFormat(mpd.completedon, GfDateUtils.STORED_DATE_FORMAT)
  }
}
