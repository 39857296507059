import {VehicleUnavailabilityDto} from "../VehicleUnavailabilityDto";
import GfDateUtils from "../../../components/DatePicker/GfDateUtils";

export class VehicleUnavailabilityNt {
  id: number;
  vehicleId: number;
  fromDate: string;
  toDate: string;
  fromTime: string;
  toTime: string;
  motivation: string;
  description: string;

  constructor(vehicle: VehicleUnavailabilityDto) {
    this.id = vehicle.id;
    this.vehicleId = vehicle.vehicleId;
    this.fromDate = vehicle.fromDate.toFormat(GfDateUtils.STORED_DATE_FORMAT);
    this.toDate = vehicle.toDate.toFormat(GfDateUtils.STORED_DATE_FORMAT);
    this.fromTime = vehicle.fromTime;
    this.toTime = vehicle.toTime;
    this.motivation = vehicle.motivation;
    this.description = vehicle.description;
  }
}
