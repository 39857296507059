import React, {Component, ReactNode} from "react";
import "./TimeLine.scss"
import {TimeLineYAxis} from "./TimeLineYAxis/TimeLineYAxis";
import TimeLineNavigation from "./TimeLineNavigation/TimeLineNavigation";
import TimeLineXAxis from "./TimeLineXAxis/TimeLineXAxis";
import TimeLineSeparatorsHorizontal from "./TimeLineSeparatorsHorizontal/TimeLineSeparatorsHorizontal";
import TimeLineDataRow from "./TimeLineDataRow/TimeLineDataRow";
import TimeLineData from "./TimeLineData";
import TimeLineMatrix = TimeLineData.TimeLineMatrix;
import {DateTime} from "luxon";

interface Props {
  // title: string,
  headers: ReactNode[]
  timeLines: TimeLineMatrix[]
  spacing: boolean
  columnWidth: number
  rowHeight: number
  headerHeight: number
  bufferCell:ReactNode
  // navigationLabel: ReactNode,
  // nextDisabled: boolean,
  // nextPage: () => void
  // prevDisabled: boolean,
  // prevPage: () => void,
  // onMonthChanged: (date : DateTime) => void,
  // selectedDate: Date
}

interface State {
}

export default class TimeLine extends Component<Props, State> {
  /**************************************************
   * PRIVATE FUNCTIONS
   **************************************************/
  static defaultProps:Partial<Props> = {
    // title: "",
    // navigationLabel: null,
    spacing: false,
    columnWidth: 80,
    headerHeight: 60,
    rowHeight: 60,
    bufferCell:null,
    timeLines: [],
    // nextDisabled: false,
    // nextPage: () => {
    // },
    // prevDisabled: false,
    // prevPage: () => {
    // },
    // selectedDate: null
  }

  /**************************************************
   * LIFECYCLE
   **************************************************/
  render() {
    const {
      // title,
      headers,
      timeLines,
      spacing,
      columnWidth,
      rowHeight,
      headerHeight,
      bufferCell,
      // navigationLabel,
      // prevDisabled,
      // prevPage,
      // nextDisabled,
      // nextPage,
      // onMonthChanged,
      // selectedDate
    } = this.props;
    return <div className="TimeLine">
      {/*{title && <div className="TimeLine-title">{title}</div>}*/}
      {/*{navigationLabel && <TimeLineNavigation navigationLabel={navigationLabel}*/}
      {/*                                        selectedDate={selectedDate}*/}
      {/*                                        prevDisabled={prevDisabled}*/}
      {/*                                        onDateChange={date=>onMonthChanged(date)}*/}
      {/*                                        prevPage={prevPage}*/}
      {/*                                        nextDisabled={nextDisabled} nextPage={nextPage}/>}*/}
      <div className="TimeLine-content">
        <div className="TimeLine-table-wrapper">
          <TimeLineYAxis
            timeLines={timeLines}
            rowHeight={rowHeight}
            height={((timeLines.length) * rowHeight)+headerHeight}
            headerHeight={headerHeight}
            bufferCell={bufferCell}/>
          <div className="TimeLine-table" style={{minWidth: (headers.length * columnWidth), minHeight: ((timeLines.length) * rowHeight)+headerHeight}}>
            <TimeLineXAxis
              headers={headers}
              headerHeight={headerHeight}
            />
            <TimeLineSeparatorsHorizontal totalHeaders={headers.length}/>{
              timeLines.map((timeLine, i) => {
              return <TimeLineDataRow key={i} intervals={timeLine.intervals}
                               segmentsNum={headers.length} spacing={spacing}
                               style={{backgroundColor: timeLine.backgroundColor ? timeLine.backgroundColor : "transparent",
                                 height:rowHeight}}/>})}
          </div>
        </div>
      </div>
    </div>
  }
}
