import {VehicleNt} from "./nt/VehicleNt";

export class VehicleDto {
  id: number;
  name: string;
  category: string;
  plate: string;
  circuit: string;
  validitystart: string;
  validityend: string;
  telephone: string;

  air: boolean;
  obese: boolean;
  telepass: boolean;
  pedimate: boolean;
  pccar: boolean;
  babycradle: boolean;

  constructor(vehicle: VehicleNt) {
    this.id = vehicle.id;
    this.air = vehicle.air;
    this.name = vehicle.name;
    this.circuit = vehicle.circuit;
    this.babycradle = vehicle.babycradle;
    this.category = vehicle.category;
    this.telepass = vehicle.telepass;
    this.telephone = vehicle.telephone;
    this.obese = vehicle.obese;
    this.pedimate = vehicle.pedimate;
    this.pccar = vehicle.pccar;
    this.validitystart = vehicle.validitystart;
    this.validityend = vehicle.validityend;
    this.plate = vehicle.plate;
  }
}
