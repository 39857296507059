import React, {useState} from "react";
import Button from "../../../../components/Button/Button";
import IconChevronCircleLeft from "../../../../icons/IconChevronCircleLeft";
import I18n from "../../../../lib/I18n";
import "./WizardStepThree.scss"

interface Props {
  onConvergenceCheck: () => void
  goBack: () => void
}


export default function WizardStepThree(props: Props) {
  let labels = I18n.get().MonthlyTimeLine;
  const [disabled, toggleDisabled] = useState(false)

  return <>
    <div className="TimeLineNavigation">
      <div className="TimeLineNavigation-goBack">
        <Button flat onClick={() => props.goBack()}>
          <IconChevronCircleLeft/>{labels.exit}
        </Button>
      </div>
    </div>
    <div className="WizardStepThree">
      <div className="WizardStepThree-wrapper">
        <Button disabled={disabled} onClick={() => {
          toggleDisabled(true);
          props.onConvergenceCheck()
          setTimeout(() => toggleDisabled(false), 10 * 1000)
        }}>{labels.verifyEngineConvergence}</Button>
      </div>
    </div>
  </>

}
