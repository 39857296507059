import {WorkerContractDto} from "../WorkerContractDto";
import {WorkerContractResponseNt} from "./nt/WorkerContractResponseNt";

export class WorkerContractResponse {
  status: string;
  errors: string[];
  contracts: WorkerContractDto[];

  constructor(workerContractResponseNt: WorkerContractResponseNt) {
    this.status = workerContractResponseNt.status;
    this.errors = workerContractResponseNt.errors;
    this.contracts = workerContractResponseNt.contracts.map((workerContractDtoNt) => new WorkerContractDto(workerContractDtoNt))
  }

}
