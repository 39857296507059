import AuthorizeApi from "../AuthorizeApi";
import {WorkerContractResponse} from "../entity/response/WorkerContractResponse";
import {WorkerContractResponseNt} from "../entity/response/nt/WorkerContractResponseNt";
import {WorkerContractDto} from "../entity/WorkerContractDto";

export default class WorkerContractApi {

  private base: string = '/contract';

  private authApi: AuthorizeApi = new AuthorizeApi();

  public async modify(workerContractDto: WorkerContractDto[]) {
    let workerContractResponse: WorkerContractResponse;
    await this.authApi.post<WorkerContractDto[], WorkerContractResponseNt>(this.base + "/worker/", workerContractDto)
      .then((w) => {
        workerContractResponse = WorkerContractApi.mapToWorkerResponse(w)
      });
    return workerContractResponse;
  }

  public async delete(id: number) {
    let workerContractResponse: WorkerContractResponse;
    await this.authApi.delete<WorkerContractDto[], WorkerContractResponseNt>(this.base + "/" + id)
      .then((w) => {
        workerContractResponse = WorkerContractApi.mapToWorkerResponse(w)
      });
    return workerContractResponse;
  }


  private static mapToWorkerResponse(workerContractResponseNt: WorkerContractResponseNt) {
    return new WorkerContractResponse(workerContractResponseNt);
  }
}
