import {NeedDto} from "../NeedDto";
import {NeedResponseNt} from "./nt/NeedResponseNt";

export class NeedResponse {
  status: string;
  errors: string[];
  content: NeedDto[];
  totalPages: number;
  currentPages: number;
  size: number;
  total: number;

  constructor(needResponseNt: NeedResponseNt) {
    this.status = needResponseNt.status;
    this.errors = needResponseNt.errors;
    this.content = needResponseNt.content.map((needDtoNT) => new NeedDto(needDtoNT))
    this.totalPages = needResponseNt.totalPages;
    this.currentPages = needResponseNt.currentPages;
    this.size = needResponseNt.size;
    this.total = needResponseNt.total;
  }

}
