import React from "react";
import Icon, {Props} from "./Icon";

export default class IconChevronLeft extends Icon {
  static defaultProps: Partial<Props> = {
    height: 24,
    width: 24
  };

  render() {
    return (
      <svg className={"IconChevronLeft "+this.props.className}
           width={this.props.width}
           height={this.props.height}
           viewBox="0 0 24 24">
          <g fill="none" fillRule="evenodd">
            <g>
              <g>
                <g transform="translate(-651 -201) translate(651 200) matrix(-1 0 0 1 24 1)">

                  <path fill="#2E3A59" fillRule="nonzero"
                        d="M15.535 3.515L7.05 12l8.485 8.485 1.415-1.414L9.879 12l7.07-7.071-1.414-1.414z"
                        transform="rotate(-180 12 12)"/>
                </g>
              </g>
            </g>
          </g>
        </svg>


    );
  }
}
