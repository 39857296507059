import React, {Component} from "react";
import {WorkerDto} from "../../../api/entity/WorkerDto";
import Button from "../../../components/Button/Button";
import "./MonthlyPlanningConvergenceCompleted.scss"
import I18n from "../../../lib/I18n";

interface Props {
  infeasible: number[]
  workers: WorkerDto[]
  onGoBack: () => void
  onConfirm: () => void
}

interface State {
  buttonsDisabled: boolean
}

interface InfeasibleWorker {
  name: string,
  lastname: string
}

export default class MonthlyPlanningConvergenceCompleted extends Component<Props, State> {
  state: State = {
    buttonsDisabled: false
  }

  render() {

    const workers: InfeasibleWorker[] = this.props.infeasible.map((workerId, i) => this.getWorkerNameFromId(workerId));

    let labels = I18n.get().MonthlyPlanning;
    return <div className="MonthlyPlanningConvergenceCompleted">
      <div className="MonthlyPlanningConvergenceCompleted-wrapper">

        <div className="MonthlyPlanningConvergenceCompleted-title">
          {labels.convergenceCheckCompleted}
        </div>
        {(this.props.infeasible && this.props.infeasible.length > 0)
          ? <>
            <div className="MonthlyPlanningConvergenceCompleted-subtext">
              {labels.someWorkersNotConverging}
            </div>

            <ul className="MonthlyPlanningConvergenceCompleted-workers">
              {workers.map((worker,i)=><li key={i}>{worker.lastname} {worker.name}</li>)}
            </ul>
          </>
          : <div className="MonthlyPlanningConvergenceCompleted-subtext">
            {labels.allWorkersConverging}
          </div>}
        <div className="MonthlyPlanningConvergenceCompleted-separator"/>
        <div className="MonthlyPlanningConvergenceCompleted-actionBar">
          <Button secondary disabled={this.state.buttonsDisabled} onClick={() => {
            this.disableButtons();
            this.props.onGoBack()
          }}>{labels.backButton}</Button>

          <Button disabled={this.state.buttonsDisabled} onClick={() => {
            this.disableButtons();
            this.props.onConfirm()
          }}>{labels.proceedWithMonthlyPlanning}
          </Button>
        </div>
      </div>
    </div>
  }

  private disableButtons() {
    this.setState({buttonsDisabled: true});
    setTimeout(() => this.setState({buttonsDisabled: false}), 10 * 1000)
  }

  private getWorkerNameFromId(workerId: number):InfeasibleWorker {
    if (!this.props.workers) return {name:String(workerId), lastname:String(workerId)};
    for (const {id, name, lastname} of this.props.workers)
      if (id == workerId)
        return {name, lastname}
    return  {name:String(workerId), lastname:String(workerId)}
  }
}
