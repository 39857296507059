import React from 'react';
import './TotemManagers.scss';
import Page from "../Page";
import I18n from "../../lib/I18n";
import {CircuitSelect} from "../../components/CircuitSelect/CircuitSelect";
import ViewSelector from "./ViewSelector/ViewSelector";
import MonthlyNavigationBar from "../MonthlyPlanning/MonthlyNavigationBar/MonthlyNavigationBar";
import {DateTime} from "luxon";
import {WizardStep} from "../MonthlyPlanning/MonthlyPlanningWizard/MonthlyPlanningWizard";
import ManagementTable from "./ManagementTable/ManagementTable";
import GfDateUtils from '../../components/DatePicker/GfDateUtils';
import HrTable from "./HrTable/HrTable";
import NursingTable from "./NursingTable/NursingTable";
import {
  TotemHrEntry,
  TotemManagementEntry,
  TotemManagementEntryDto,
  TotemNursingEntry
} from "../../api/entity/TotemDto";
import TotemApi from "../../api/totem/TotemApi";
import {DayValuesDto} from "../../api/entity/DayValuesDto";
import {FocusPanel} from "../../components/FocusPanel/FocusPanel";
import {ModifyEntryModal} from "./ModifyEntryModal/ModifyEntryModal";
import ShiftUtils from "../../utils/ShiftUtils";

interface State {
  viewSelection: ViewSelection,
  circuitFilter: string,
  date: DateTime
  datesInMonth: DateTime[],
  isModalOpen: boolean,
  elementBeingChanged: DayValuesDto,
  entries: {
    nursing: TotemNursingEntry[],
    hr: TotemHrEntry[],
    management: TotemManagementEntry[],
  }

  mappedEntries: {
    dayValuesDto: DayValuesDto[]
  }
}

export enum ViewSelection {
  NURSING = "Infermieri", HR = "Ufficio Personale", MANAGEMENT = "Coordinamento"
}

export default class TotemManagers extends Page<{}, State> {

  private totemApi: TotemApi = new TotemApi();
  private shiftUtils = new ShiftUtils();

  public constructor(props) {
    super(props);
    this.state = {
      date: DateTime.local().startOf('month'),
      elementBeingChanged: null,
      mappedEntries: {
        dayValuesDto: []
      },
      isModalOpen: false,
      viewSelection: ViewSelection.MANAGEMENT,
      circuitFilter: this.shiftUtils.CIRCUIT_DTO[0],
      datesInMonth: [],
      entries: {
        hr: [],
        management: [],
        nursing: [],
      }
    }
  }

  componentDidMount() {
    this.loadPlanningInfoFor(this.state.date);
  }

  private onToggleModify() {
    this.setState({isModalOpen: !this.state.isModalOpen})
  }

  private onChangeView(viewSelection) {
    this.setState({viewSelection: viewSelection}, () => {
      this.loadPlanningInfoFor(this.state.date);
    });
  }

  private onCircuitFilteringChange(circuitDto) {
    this.setState({circuitFilter: circuitDto}, () => {
      this.loadPlanningInfoFor(this.state.date);
    })
  }

  private loadPlanningInfoFor(date: DateTime) {
    const circuit = this.state.circuitFilter;
    if (this.state.viewSelection == ViewSelection.HR) {
      this.totemApi.getHr(date, circuit).then((resp) => {
        this.setState({entries: {...this.state.entries, hr: resp.entries}}, () => {
          const datesForMonth = this.getDatesForMonth(date);
          const entries = this.getResultingMapping(datesForMonth, resp.entries);
          this.setState({mappedEntries: {dayValuesDto: entries}});
        })
      })
    }
    if (this.state.viewSelection == ViewSelection.NURSING) {
      this.totemApi.getNursing(date, circuit).then((resp) => {
        this.setState({entries: {...this.state.entries, nursing: resp.entries}}, () => {
          const datesForMonth = this.getDatesForMonth(date);
          const entries = this.getResultingMapping(datesForMonth, resp.entries);
          this.setState({mappedEntries: {dayValuesDto: entries}});
        })
      })
    }
    if (this.state.viewSelection == ViewSelection.MANAGEMENT) {
      this.totemApi.getManagement(date, circuit).then((resp) => {
        this.setState({entries: {...this.state.entries, management: resp.entries}}, () => {
          const datesForMonth = this.getDatesForMonth(date);
          const entries = this.getResultingMapping(datesForMonth, resp.entries);
          this.setState({mappedEntries: {dayValuesDto: entries}});
        })
      })
    }
  }

  private getResultingMapping(datesForMonth: DateTime[], entries: any[]) {
    const resulting: DayValuesDto[] = datesForMonth.map(it => {
      const totemHrEntries = entries.filter(entry => it.toMillis() == entry.date.toMillis());
      if (totemHrEntries.length > 0) {
        const elected = totemHrEntries[0];
        return {
          id: elected.id, central: elected.time2, night:
          elected.time3, morning: elected.time, date: elected.date.toFormat(GfDateUtils.STORED_DATE_FORMAT)
        }
      }
      return TotemManagers.getEmptyValue(it)
    });
    return resulting;
  }

  private getDatesForMonth(date: DateTime): DateTime[] {
    let datesInMonth = [];
    for (let i = 1; i < date.daysInMonth + 1; i++) {
      datesInMonth.push(DateTime.fromObject({year: date.year, month: date.month, day: i}));
    }
    this.setState({datesInMonth: datesInMonth});
    this.setState({date: date});
    return datesInMonth;
  }

  private onChange(id: number, date: DateTime) {
    const d: DayValuesDto = this.getById(id, date);
    this.setState({
      elementBeingChanged: d
    })
    this.onToggleModify();

  }

  private getModalBody() {
    return I18n.get().TotemManagers.modal.bodyFirstPart + DateTime.fromFormat(this.state.elementBeingChanged.date, GfDateUtils.STORED_DATE_FORMAT)
      .setLocale("it").toFormat(GfDateUtils.DAY_NR_WITH_MONTH_YEAR) + I18n.get().TotemManagers.modal.bodySecondPart + this.state.circuitFilter;
  }

  private getModalTitle() {
    return I18n.get().TotemManagers.modal.modify + this.state.viewSelection;
  }

  private getModalLabels() {
    const viewSelection = this.state.viewSelection;
    if (viewSelection == ViewSelection.MANAGEMENT) {
      return [ManagementTable.FIRST_TIME, ManagementTable.SECOND_TIME, ManagementTable.THIRD_TIME];
    } else if (viewSelection == ViewSelection.NURSING) {
      return [NursingTable.FIRST_TIME, NursingTable.SECOND_TIME, NursingTable.THIRD_TIME];
    } else {
      return [HrTable.FIRST_TIME, HrTable.SECOND_TIME, HrTable.THIRD_TIME];
    }
  }

  private getById(id, date): DayValuesDto {
    const viewSelection = this.state.viewSelection;
    let {entries} = this.state;
    if (id == 0) {
      return TotemManagers.getEmptyValue(date);
    }
    if (viewSelection == ViewSelection.MANAGEMENT) {
      return TotemManagers.mapEntryToValue(entries.management.filter(e => e.id == id)[0])
    } else if (viewSelection == ViewSelection.NURSING) {
      return TotemManagers.mapEntryToValue(entries.nursing.filter(e => e.id == id)[0])
    } else {
      return TotemManagers.mapEntryToValue(entries.hr.filter(e => e.id == id)[0])
    }
  }

  private upsertValue(changedValue: DayValuesDto) {
    const viewSelection = this.state.viewSelection;
    const entry: TotemManagementEntryDto =
      TotemManagers.getValueFromMapped(changedValue,
      this.state.circuitFilter);
    this.onToggleModify();

    if (viewSelection == ViewSelection.MANAGEMENT) {
      this.totemApi.upsertManagementValue(entry).then(() => this.loadPlanningInfoFor(this.state.date));
    } else if (viewSelection == ViewSelection.NURSING) {
      this.totemApi.upsertNursingValue(entry).then(() => this.loadPlanningInfoFor(this.state.date));
    } else {
      this.totemApi.upsertHrValue(entry).then(() => this.loadPlanningInfoFor(this.state.date));
    }
  }

  render() {
    let {isModalOpen, elementBeingChanged} = this.state;
    return (
      <div className={"TotemManagers"}>
        <div className={"inner-content"}>

          {isModalOpen ?
            <ModifyEntryModal title={this.getModalTitle()} body={this.getModalBody()}
                              entriesLabels={this.getModalLabels()}
                              dayValueDto={elementBeingChanged} onClick={(changedValue) => {
              this.upsertValue(changedValue);
            }} onCancel={() => {
              this.onToggleModify()
            }}/>
            : null}

          <FocusPanel show={isModalOpen}/>

          <div className={"top"}>
            <div className={"up-title"}>
              <h1>{I18n.get().Menu.totemManagers}</h1>

              <div className={"row"}>
                <div className={"left-side"}>
                  <div className={"sections-btn"}>
                    <ViewSelector state={this.state.viewSelection}
                                  onSelect={(viewSelection) => {
                                    this.onChangeView(viewSelection)
                                  }}/>
                  </div>
                </div>
                <div className={"right-side"}>
                  <CircuitSelect className={"circuit-select"}
                                 excludedValue={CircuitSelect.NO_FILTER}
                                 onChange={(circuitDto) => {
                                   this.onCircuitFilteringChange(circuitDto)
                                 }}/>
                </div>
              </div>
            </div>
          </div>
          <div className={"contents"}>
            <MonthlyNavigationBar
              isFutureEnabled={true}
              currentDate={this.state.date} step={WizardStep.ZERO}
              onMonthChanged={date => this.loadPlanningInfoFor(date)}
            />
          </div>
          <div className={"table"}>
            {this.state.viewSelection == ViewSelection.MANAGEMENT ?
              <ManagementTable entries={this.state.mappedEntries.dayValuesDto}
                               onChange={(id, date) => this.onChange(id, date)}/> : null}
            {this.state.viewSelection == ViewSelection.HR ?
              <HrTable entries={this.state.mappedEntries.dayValuesDto}
                       onChange={(id, date) => this.onChange(id, date)}/> : null}
            {this.state.viewSelection == ViewSelection.NURSING ?
              <NursingTable entries={this.state.mappedEntries.dayValuesDto}
                            onChange={(id, date) => this.onChange(id, date)}/> : null}
          </div>
        </div>
      </div>
    );
  }

  private static getValueFromMapped(changedValue: DayValuesDto, circuit: string) {
    return {
      id: changedValue.id, date: changedValue.date, time: changedValue.morning,
      time2: changedValue.central, time3: changedValue.night, circuit: circuit
    };
  }

  static mapEntryToValue(entry: any): DayValuesDto {
    return {
      id: entry.id, date: entry.date.toFormat(GfDateUtils.STORED_DATE_FORMAT),
      morning: entry.time, central: entry.time2, night: entry.time3
    }
  }

  private static getEmptyValue(it: DateTime) {
    return {id: 0, central: "", night: "", morning: "", date: it.toFormat(GfDateUtils.STORED_DATE_FORMAT)};
  }
}
