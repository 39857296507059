import React from "react";
import I18n from "../../../lib/I18n";
import "./MonthlyPlanningNoData.scss"

export default function MonthlyPlanningNoData() {
  let labels = I18n.get().MonthlyTimeLine;
  return <div className="MonthlyPlanningNoData">
    <div className="MonthlyPlanningNoData-wrapper">
      <p className="MonthlyPlanningNoData-title">{labels.noDataAvailable}</p>
      <p>{labels.noDataAvailableMessage}</p>
    </div>
  </div>
}

