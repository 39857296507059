import I18n from "../lib/I18n";
import {VehicleDto} from "../api/entity/VehicleDto";
import {DateTime} from "luxon";

export default class ShiftUtils {

  public static getEmptyWeek(): number[] {
    return [0, 0, 0, 0, 0, 0, 0]
  };

  public SHIFT_TOPOLOGY_LIST: string[] = [
    I18n.get().ShiftRegistry.shiftTypology.nothing,
    I18n.get().ShiftRegistry.shiftTypology.ambulance,
    I18n.get().ShiftRegistry.shiftTypology.moss,
    (I18n.get().ShiftRegistry.shiftTypology.ambulanceAndMoss)
  ].map(a => {try{return a.toUpperCase()} catch {return ""}})

  public static MOSS: string = "MOSS";

  public static NOTHING_VALUE = "NOTHING";

  public SHIFT_TYPOLOGY_DTO: string[] = [
    ShiftUtils.NOTHING_VALUE, "AMBULANCE", "MOSS", "AMBULANCE;MOSS"
  ];

  public SHIFT_TOPOLOGY: string[] = [
    I18n.get().ShiftRegistry.shiftTypology.ambulance,
    I18n.get().ShiftRegistry.shiftTypology.moss,
  ].map(a => {try{return a.toUpperCase()} catch {return ""}})

  public SHIFT_TYPOLOGY_UNIQUE = [
    "AMBULANCE", "MOSS", ShiftUtils.NOTHING_VALUE
  ];

  public resolveLabelForShiftTypology(value: string) {
    let index = this.SHIFT_TYPOLOGY_UNIQUE.indexOf(value);
    return this.SHIFT_TOPOLOGY[index];
  }

  public SHIFT_TIME_LABELS: string[] = [
    I18n.get().ShiftRegistry.shiftType.morning,
    I18n.get().ShiftRegistry.shiftType.afternoon,
    I18n.get().ShiftRegistry.shiftType.evening,
    I18n.get().ShiftRegistry.shiftType.long,
    I18n.get().ShiftRegistry.shiftType.others,
    I18n.get().ShiftRegistry.shiftType.availability,
  ].map(a => {try{return a.toUpperCase()} catch {return ""}})

  public SHIFT_TIME_DTO = [
    "MORNING", "AFTERNOON", "EVENING", "LONG", "OTHERS", "AVAILABILITY"
  ];

  public resolveLabelForShiftTime(value: string) {
    let index = this.SHIFT_TIME_DTO.indexOf(value);
    return this.SHIFT_TIME_LABELS[index];
  }

  public CIRCUIT: string[] = [
    I18n.get().NeedManagement.addNewNeedManagement.circuitTypes.general,
    I18n.get().NeedManagement.addNewNeedManagement.circuitTypes.loiano,
    I18n.get().NeedManagement.addNewNeedManagement.circuitTypes.central,
  ].map(a => {try{return a.toUpperCase()} catch {return ""}})

  public CIRCUIT_DTO: string[] = [
    "CATIS", "ISMOBO", "OP-CENTRALE", "LOIANO"
  ];

  public ROLE_DTO: string[] = [
    "NURSE", "DRIVER"
  ];

  public resolveLabelForRole(value: string) {
    let index = this.ROLE_DTO.indexOf(value);
    return this.ROLE_LABEL[index];
  }

  public ROLE_LABEL: string[] = [
    I18n.get().NeedManagement.addNewNeedManagement.human.category.nurse,
    I18n.get().NeedManagement.addNewNeedManagement.human.category.driver,
  ].map(a => {try{return a.toUpperCase()} catch {return ""}})

  public ENABLEMENT_DTO = [
    "-", "INTRAH", "INTERH", "EM", "EM+"
  ]

  public VEHICLE_ENABLEMENT_DTO = [
    "INTRAH", "INTERH", "EM", "EM+", "MOSS", "EM/INTERH"
  ]

  public SERVICES_DTO = [
    "EM+", "EM", "INTERH", "INTRAH"
  ]

  public TUTORING_DTO = [
    "", "EM", "INTERH", "INTRAH"
  ]

  public PLACE_DTO: string[] = [
    "BLU1", "BLU2", "BLU3", "BLU4", "INTERH",
    "POST. S. ORSOLA",
    "POST. LOIANO", "COA5", "ZOLA"
  ];

  public PUBLISHING_LABELS: string[] = [
    I18n.get().NeedManagement.addNewNeedManagement.vehicle.publishingOptions.engineering,
    I18n.get().NeedManagement.addNewNeedManagement.vehicle.publishingOptions.unpublished,
  ].map(a => {try{return a.toUpperCase()} catch {return ""}})

  public PUBLISHING_DTO: string[] = [
    "engineering",
    "unpublished",
  ].map(a => {try{return a.toUpperCase()} catch {return ""}})

  public NEED_TOPOLOGY_LIST: string[] = [
    I18n.get().NeedManagement.typology.intrah,
    I18n.get().NeedManagement.typology.interh,
    I18n.get().NeedManagement.typology.emergency,
    I18n.get().NeedManagement.typology.emergencyPlus,
    I18n.get().NeedManagement.typology.moss
  ].map(a => {try{return a.toUpperCase()} catch {return ""}})

  public NEED_TOPOLOGY_DTO: string[] = [
    "INTRAH", "INTERH", "EM", "EM+", "MOSS"
  ];

  public NEED_CONVENTION_DTO: string[] = [
    "TEMPORARY", "PRIMARY", "SECONDARY"
  ];

  public NEED_CONVENTION_LIST: string[] = [
    I18n.get().NeedManagement.convention.moment,
    I18n.get().NeedManagement.convention.primary,
    I18n.get().NeedManagement.convention.secondary,
  ].map(a => {try{return a.toUpperCase()} catch {return ""}})


  public getEmptyVehicleDto(): VehicleDto {
    return {
      id: null,
      category: this.VEHICLE_ENABLEMENT_DTO[0],
      circuit: this.CIRCUIT_DTO[0],
      name: "",
      plate: "",
      telephone: "",
      validitystart: DateTime.now().toFormat("yyyy-MM-dd"),
      validityend: DateTime.local(2099,12,31).toFormat("yyyy-MM-dd"),
      pccar: false,
      pedimate: false,
      telepass: false,
      babycradle: false,
      air: false,
      obese: false,
    }
  }


}
