import React, {PureComponent} from "react";

export interface Props{
    className?: string
    width:number;
    height:number;
    color: string;
}

export default class Icon<P extends Props = Props> extends PureComponent<P, {}> {
}

