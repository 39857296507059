import React, {Component, ReactNode} from "react";
import "./TimeLineNavigation.scss"
import IconChevronRight from "../../../icons/IconChevronRight";
import IconChevronLeft from "../../../icons/IconChevronLeft";
import DatePicker from "react-date-picker";
import {DateTime} from "luxon";
import IconCalendar from "../../../icons/IconCalendar";

interface Props {
  navigationLabel: ReactNode,
  nextDisabled: boolean,
  selectedDate: DateTime,
  nextPage: () => void
  prevDisabled: boolean,
  prevPage: () => void
  onDateChange: (date: DateTime) => void
  granularity:"month"|"day"
  maxDate: Date
}

interface State{
  isCalendarOpen: boolean
}

export default class TimeLineNavigation extends Component<Props,State> {
  /**************************************************
   * PROPS
   **************************************************/
  static defaultProps:Partial<Props> = {
    nextDisabled:false,
    prevDisabled:false,
    maxDate: DateTime.now().plus({month: 1}).toJSDate()
  }
  /**************************************************
   * CONSTRUCTOR
   **************************************************/
  constructor(props) {
    super(props);
    this.state = {
      isCalendarOpen: false
    }
  }
  /**************************************************
   * LIFECYCLE
   **************************************************/
  render() {
    const {navigationLabel, prevDisabled, prevPage, granularity,nextDisabled, nextPage} = this.props;
    return <div className="TimeLineNavigation">
      <div className="TimeLineNavigation-buttons">
        <div onClick={() => !prevDisabled ? prevPage() : null}>
          <IconChevronLeft className={prevDisabled ? "disabled" : ""}/>
        </div>
        <div className="navigation-label" onClick={() => this.toggleCalendar()}>{navigationLabel}</div>
        <label>
          <IconCalendar className={"icon"} width={25} height={25}/>
          {granularity == "month"
            ? <DatePicker
              isOpen={this.state.isCalendarOpen}
              minDetail={"year"} maxDetail={"year"} value={this.props.selectedDate.toJSDate()}
              maxDate={this.props.maxDate}
              clearIcon={null} calendarIcon={null}
              onChange={(val: Date) => {
                this.props.onDateChange(DateTime.fromJSDate(val))
                this.setState({isCalendarOpen: false})
              }}
            />
            : <DatePicker
              isOpen={this.state.isCalendarOpen}
              value={this.props.selectedDate.toJSDate()}
              clearIcon={null} calendarIcon={null}
              onChange={(val: Date) => {
                this.props.onDateChange(DateTime.fromJSDate(val));
                this.setState({isCalendarOpen: false});
              }}
            />}
            </label>
        <div onClick={() => !nextDisabled ? nextPage() : null}>
          <IconChevronRight className={nextDisabled ? "disabled" : ""}/>
        </div>
      </div>
    </div>
  }

  private toggleCalendar() {
    this.setState({isCalendarOpen: !this.state.isCalendarOpen});
  }
}
