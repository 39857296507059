import React, {Component} from "react";
import "./SkillsSection.scss";
import I18n from "../../../lib/I18n";
import {SkillDto} from "../../../api/entity/SkillDto";
import ShiftUtils from "../../../utils/ShiftUtils";

export interface Props {
  onToggleModify: () => void;
  certificates: SkillDto[]
  service: string
  tutoring: string
  moss: boolean,
  flagNottiConsecutive: number,
  numNottiConsecutive: number
}

interface State {
  services: string[]
  indexOfService: number
}

export default class SkillsSection extends Component<Props, State> {

  private shiftUtils = new ShiftUtils()

  static defaultProps: Partial<Props> = {
    onToggleModify: () => {
    },
    certificates: [],
    service: ""
  };

  public constructor(props) {
    super(props);
    this.state = {
      services: [this.shiftUtils.SERVICES_DTO[3]],
      indexOfService: 0
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (prevProps.service != this.props.service) {
      if (this.props.service == null) return;
      this.updateServices();
    }
  }

  componentDidMount() {
    this.updateServices();

  }

  private updateServices() {
    let indexOfService = this.shiftUtils.SERVICES_DTO.indexOf(this.props.service);
    this.setState({
      indexOfService: indexOfService,
      services: this.isAtLeastOneServiceAssigned(indexOfService) ?
        this.shiftUtils.SERVICES_DTO.map((v, i) => {
          if (i >= indexOfService) return v;
        }) : []
    });
  }

  private isAtLeastOneServiceAssigned(indexOfService: number) {
    return indexOfService > -1;
  }

  render() {
    return (
      <div className={"SkillsSection"}>
        <div className={"details"}>
          <div className={"registrySubtitle"}>
            <span className={"subtitle"}>{I18n.get().WorkersRegistry.sidebar.skills} </span>
            <span className={"actionButton"} onClick={() => this.props.onToggleModify()}>
                {I18n.get().ActionButton.modify}
              </span>
          </div>
          <div className={"registryDetails"}>
            <div className={"row"}>
              <div className={"column-50"}>
                <div className={"lbl"}>
                  <label>{I18n.get().WorkersRegistry.sidebar.certificates}</label>
                  <div className={"value"}>
                    {this.props.certificates.map(certificate => {
                      return <label key={certificate.name} className={"certificate-label"}>
                        {certificate.name.toUpperCase()}
                      </label>;
                    })
                    }
                  </div>
                </div>
              </div>
              <div className={"column-50"}>
                <div className={"lbl"}>
                  <label>{I18n.get().WorkersRegistry.sidebar.services}</label>
                  <div className={"value"}>
                    {this.state.services.map((skill) =>
                      skill != null ?
                        <label key={skill} className={(skill == "EM+" ? "EM_PLUS" : skill) + " skill"}>
                          {skill.toUpperCase()}
                        </label> : null
                    )
                    }
                    {this.props.moss && <label key={"MOSS"} className={"MOSS skill"}>MOSS</label>}
                  </div>
                </div>
              </div>
            </div>

            <div className={"row"}>
              <div className={"column-50"}>
                <div className={"lbl"}>
                  <label>{I18n.get().WorkersRegistry.sidebar.tutoring}</label>
                  <div className={"value"}>
                    {this.props.tutoring != "" ?
                      <label className={"certificate-label"}>
                        {this.props.tutoring.toUpperCase()}
                      </label>
                      : null}
                  </div>
                </div>
              </div>

              <div className={"column-50"}>
                <div className={"lbl"}>
                  <label>{I18n.get().WorkersRegistry.consecutiveNights}</label>
                  <div className={"value"}>
                    {this.props.flagNottiConsecutive > 0 ?
                      <label>
                        {this.props.numNottiConsecutive.toString()}
                      </label>
                      : <label >
                      {I18n.get().WorkersRegistry.flagConsecutiveNightsDisabled}
                    </label>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
