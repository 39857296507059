import React, {PureComponent} from "react";
import "./InformationModal.scss";
import Button from "../../Button/Button";
import InformationModalController, {InformationModalProps} from "./InformationModalController";

export class InformationModal extends PureComponent<InformationModalProps> {

  /************************************************
   * PROPS
   ************************************************/
  static defaultProps: Partial<InformationModalProps> = {
    title:"Title",
    okButtonLabel:"Ok",
    cancelButtonLabel:"",
    onOk: () => InformationModalController.getInstance().hide(),
    onCancel: () => InformationModalController.getInstance().hide(),
  };

  /************************************************
   * LIFECYCLE
   ************************************************/
  render() {
    return (
      <div className="InformationModal-Overlay">
        <div className="InformationModal">
              <h1>{this.props.title}</h1>
            <div className="InformationModal-Body">
              {this.props.body}
            </div>
            <div className="InformationModal-Buttons">

              {this.props.cancelButtonLabel && <Button onClick={() => this.props.onCancel()}
                      className="InformationModal-CancelButton" secondary>
                {this.props.cancelButtonLabel}

              </Button>}
              <Button onClick={() => this.props.onOk()}
                      className="InformationModal-OkButton">
                {this.props.okButtonLabel}
              </Button>
            </div>
        </div>
      </div>
    )
  }

  componentDidMount() {
    document.body.style.overflow = "hidden"
  }

  componentWillUnmount() {
    document.body.style.overflow = "auto"
  }
}
