import React from 'react';
import './TotemWorkers.scss';
import Page from "../Page";
import I18n from "../../lib/I18n";
import WorkersTable, {WTEntry} from "./WorkersTable/WorkersTable";
import {DateTime} from "luxon";
import GfDateUtils from "../../components/DatePicker/GfDateUtils";
import UserDailyShiftApi from "../../api/user/UserDailyShiftApi";
import {UserDailyShiftDto} from "../../api/entity/UserDailyShiftDto";
import {EntryMapper} from "./EntryMapper";
import SearchInput from "../../components/SearchInput/SearchInput";
import {CircuitSelect} from "../../components/CircuitSelect/CircuitSelect";
import DailyPlanningApi, { coursesMatches } from '../../api/planning/DailyPlanningApi';
import { DailyPlanningWorkerDtoNt, DailyPlanningWorkerResponse } from '../../api/entity/response/nt/DailyPlanningWorkerResponseNt';
import WorkerRegistryApi from '../../api/worker/WorkerRegistryApi';
import { WorkerDto } from '../../api/entity/WorkerDto';
import Button from '../../components/Button/Button';
import LoginApi from '../../api/login/LoginApi';
import { Roles } from '../../api/Roles';
import TotemApi from '../../api/totem/TotemApi';

interface State {
  date: DateTime,
  dateIndex: number,
  searchFilter: string,
  circuitFilter: string,
  dailyContent: UserDailyShiftDto[],
  reperibleWorkerMap: Map<string, DailyPlanningWorkerDtoNt[]>,
  reperibleWorkers: DailyPlanningWorkerDtoNt[],
  coursesWorkerMap: Map<string, DailyPlanningWorkerDtoNt[]>,
  coursesWorkers: DailyPlanningWorkerDtoNt[],
  tableContentMapping: WTEntry[],
  searchValue?: string,
  cancelSearchSignal?: number,
  pendingDownload?: boolean,
  workers: WorkerDto[]
}

export default class TotemWorkers extends Page<{}, State> {

  private refreshIntervalTimer: any;

  private userDailyShiftApi: UserDailyShiftApi = new UserDailyShiftApi();
  private dailyPlanningApi: DailyPlanningApi = new DailyPlanningApi();
  private workerRegistryApi: WorkerRegistryApi = new WorkerRegistryApi();

  private readonly msInFiveMinutes = 1000 * 5 * 60;

  public constructor(props) {
    super(props);
    this.state = {
      date: DateTime.now(), //DateTime.fromFormat("2022-01-28", GfDateUtils.STORED_DATE_FORMAT), //DateTime.now(), //
      dailyContent: [],
      reperibleWorkerMap: new Map<string, DailyPlanningWorkerDtoNt[]>(),
      reperibleWorkers: [],
      coursesWorkerMap: new Map<string, DailyPlanningWorkerDtoNt[]>(),
      coursesWorkers: [],
      searchFilter: "",
      circuitFilter: CircuitSelect.NO_FILTER,
      tableContentMapping: [],
      dateIndex: 0,
      workers: [],
      pendingDownload: false
    }
  }

  private getAdminMenuBtnClass() {
    if(LoginApi.getRole() != Roles.admin) {
      return "hidden";
    }
    return "adminbar";
  }


  componentDidMount() {
    this.loadEntriesForDay();
    this.refreshIntervalTimer = setInterval(() => { this.onRefresh() }, this.msInFiveMinutes)
  }

  private resetRefreshTimer() {
    clearInterval(this.refreshIntervalTimer);
    this.refreshIntervalTimer = setInterval(() => this.onRefresh(), this.msInFiveMinutes)
  }

  private onRefresh() {
    //reset the index to 0
    this.setState({
      dailyContent: [],
      searchFilter: "",
      circuitFilter: CircuitSelect.NO_FILTER,
      tableContentMapping: [],
      dateIndex: 0,
      searchValue: "",
      cancelSearchSignal: DateTime.now().valueOf()
    }, () => this.reloadData());
  }

  private reloadData(force: boolean = false) {

    if (force || this.state.searchFilter.length <= 0) {
      this.loadEntriesForDay();
    }

    if (this.state.searchFilter.length > 0) {
      this.onSearchAndFilter(this.state.searchFilter);
    }
  }

  componentWillUnmount() {
    clearInterval(this.refreshIntervalTimer);
  }

  private async loadEntriesForDay()  {
    var dateToSelect = this.state.date.plus({days: this.state.dateIndex});
    var resp = await this.userDailyShiftApi.getByDate(dateToSelect);
    var [respRep, respCourses] = await this.dailyPlanningApi.getReperibilityAndCoursesWorkersByDate(dateToSelect);
    var workers = await this.workerRegistryApi.getAllActiveInDay(dateToSelect);

    respRep = respRep.map((e) => {
      let worker = workers.content.find(w => w.id == e.workerid);
      if(worker){
        e.workername = worker.lastname + " " + worker.name;
      }
      return e;
    });

    respCourses = respCourses.map((e) => {
      let worker = workers.content.find(w => w.id == e.workerid);
      if(worker){
        e.workername = worker.lastname + " " + worker.name;
      }
      return e;
    });

    this.setState({
      workers: workers.content,
      dailyContent: resp.userDailyShifts,
      tableContentMapping: resp.userDailyShifts
        .map((e) => EntryMapper.mapDateToTableEntry(e)),
      reperibleWorkers: respRep,
      reperibleWorkerMap: this.mapWorkers(respRep),
      coursesWorkers: respCourses,
      coursesWorkerMap: this.mapCourses(respCourses)
    }, () => this.onSearchAndFilter(this.state.searchFilter));

  }
  private mapWorkers(workers: DailyPlanningWorkerDtoNt[]): Map<string, DailyPlanningWorkerDtoNt[]> {
    return workers.reduce((acc, it) => {
      acc.set(it.turn, [...(acc.get(it.turn) || []), it]);
      return acc;
    }, new Map<string, DailyPlanningWorkerDtoNt[]>());
  }

  private mapCourses(workers: DailyPlanningWorkerDtoNt[]): Map<string, DailyPlanningWorkerDtoNt[]> {
    const catRegex = coursesMatches;

    return workers.reduce((acc, it) => {
      if (it.turn.match(catRegex.morning)) {
        acc.set("morning", [...(acc.get("morning") || []), it]);
      } else if (it.turn.match(catRegex.afternoon)) {
        acc.set("afternoon", [...(acc.get("afternoon") || []), it]);
      } else if (it.turn.match(catRegex.allDay)) {
        acc.set("allDay", [...(acc.get("allDay") || []), it]);
      }
      return acc;
    }, new Map<string, DailyPlanningWorkerDtoNt[]>());
  }

  private filterMapWorkers(workers: DailyPlanningWorkerDtoNt[], searchFilter: string, circuitFilter: string): Map<string, DailyPlanningWorkerDtoNt[]> {
    var filteredWorkers = workers;

    if (searchFilter.length > 0) {
      filteredWorkers = filteredWorkers.filter(w => {
        return this.isNameOrSurnameInOptions(searchFilter, [w.workername]);
      });
    }

    if (circuitFilter != CircuitSelect.NO_FILTER) {
      let workersForCircuit = this.state.workers.filter(w => w.circuit == circuitFilter).map(w => w.id);
      filteredWorkers = filteredWorkers.filter(w => workersForCircuit.includes(w.workerid));
    }

    return this.mapWorkers(filteredWorkers);
  }

  private filterCoursesWorkers(workers: DailyPlanningWorkerDtoNt[], searchFilter: string, circuitFilter: string): Map<string, DailyPlanningWorkerDtoNt[]> {
    var filteredWorkers = workers;

    if (searchFilter.length > 0) {
      filteredWorkers = filteredWorkers.filter(w => {
        return this.isNameOrSurnameInOptions(searchFilter, [w.workername]);
      });
    }

    if (circuitFilter != CircuitSelect.NO_FILTER) {
      let workersForCircuit = this.state.workers.filter(w => w.circuit == circuitFilter).map(w => w.id);
      filteredWorkers = filteredWorkers.filter(w => workersForCircuit.includes(w.workerid));
    }

    return this.mapCourses(filteredWorkers);
  }

  private onCircuitFilter(value: string) {
    this.resetRefreshTimer();
    this.setState({
      reperibleWorkerMap: this.filterMapWorkers(this.state.reperibleWorkers, this.state.searchFilter, value),
      coursesWorkerMap: this.filterCoursesWorkers(this.state.coursesWorkers, this.state.searchFilter, value),
      circuitFilter: value, tableContentMapping: this.filterEntries(
        this.state.searchFilter, value,
        this.state.dailyContent)
    })
  }

  private onChangeDate(index: number) {
    this.resetRefreshTimer();
    this.setState({dateIndex: index}, () => this.reloadData(true));
  }

  private onSearchAndFilter(value: string) {
    this.resetRefreshTimer();
    let selectedValueForSearch = value.trim();

    this.setState({
      reperibleWorkerMap: this.filterMapWorkers(this.state.reperibleWorkers, selectedValueForSearch, this.state.circuitFilter),
      coursesWorkerMap: this.filterCoursesWorkers(this.state.coursesWorkers, selectedValueForSearch, this.state.circuitFilter),
      searchFilter: selectedValueForSearch, tableContentMapping:
        this.filterEntries(selectedValueForSearch, this.state.circuitFilter, this.state.dailyContent)
    });
  }

  private filterEntries(filter: string, circuit: string, dailyContent: UserDailyShiftDto[]): WTEntry[] {
    return dailyContent
      .filter(d => this.isNameOrSurnameInOptions(filter, [d.operator, d.operator2, d.operator3]))
      .filter(d => circuit == CircuitSelect.NO_FILTER || d.circuit.toLowerCase() == circuit.toLowerCase())
      .map((it) => EntryMapper.mapDateToTableEntry(it))
  }

  private isNameOrSurnameInOptions(filter: string, names: string[]) {
    return names.map(n => n.toLowerCase().indexOf(filter.toLowerCase()))
      .filter(r => r > -1).length > 0
  }

  private getTurnDescription(turn: string): string {
    switch (turn) {
      case "R/P16":
        return I18n.get().TotemWorkers.reperibilityTurns.RP16
      case "Rep":
        return I18n.get().TotemWorkers.reperibilityTurns.Rep;
      case "M1/R":
        return I18n.get().TotemWorkers.reperibilityTurns.M1R;
      case "P16/R":
        return I18n.get().TotemWorkers.reperibilityTurns.P16R;
      default:
        return turn;
    }
  }

  private getCourseDescription(turn: string): string {
    switch (turn) {
      case "morning":
        return I18n.get().TotemWorkers.coursesTurns.morning;
      case "afternoon":
        return I18n.get().TotemWorkers.coursesTurns.afternoon;
      case "allDay":
        return I18n.get().TotemWorkers.coursesTurns.allDay;
      default:
        return turn;
    }
  }

  private async onTotemExcelExport() {
    this.setState({pendingDownload: true});
    var date = this.state.date.plus({days: this.state.dateIndex});
    await this.userDailyShiftApi
      .export(date)
      .then(() => this.setState({pendingDownload: false}));
  }



  render() {
    let {date, tableContentMapping} = this.state;
    let formatteDatePrev = date.minus({days: 1}).setLocale("it").toFormat(GfDateUtils.FULL_READABLE_DATE);
    let formatteDateNext = date.plus({days: 1}).setLocale("it").toFormat(GfDateUtils.FULL_READABLE_DATE);
    let formattedDate = date.setLocale("it").toFormat(GfDateUtils.FULL_READABLE_DATE);

    return (
      <div className={"TotemWorkers"}>
        <div className={"inner-content"}>
          <div className={"top"}>
            <div className={"up-title"}>
              <h1>{I18n.get().Menu.totemWorkers}</h1>
            </div>
          </div>

      <div className={this.getAdminMenuBtnClass()}>
      <Button
        className={"downloadExcel-btn"}
        secondary disabled={this.state.pendingDownload}
        onClick={() => this.onTotemExcelExport()}>
        {I18n.get().TotemWorkers.download_excel}
      </Button>
      </div>
          <div className={"contents"}>
            <div className={"top"}>
              <h2 className={this.state.dateIndex == -1 ? "selectedDate"  : "date"} onClick={() => this.onChangeDate(-1)}>{formatteDatePrev}</h2>
              <h2 className={this.state.dateIndex == 0 ? "selectedDate"  : "date"} onClick={() => this.onChangeDate(0)}>{formattedDate}</h2>
              <h2 className={this.state.dateIndex == 1 ? "selectedDate"  : "date"} onClick={() => this.onChangeDate(1)}>{formatteDateNext}</h2>
            </div>
            <div className={"controls"}>
              <SearchInput className={"search-bar"} label={""}
                           defaultValue={""}
                           placeholder={I18n.get().TotemWorkers.search.placeholder}
                           onChange={(value) => {
                             this.onSearchAndFilter(value);
                           }} readonly={false}
                           disabled={false} password={false}
                           errorMessage={""}
                           cancelSignal={this.state.cancelSearchSignal}
                           />

              <CircuitSelect disabled={false}
                             onChange={(value) => this.onCircuitFilter(value)}
                             value={this.state.circuitFilter}
              />
            </div>
            <div className={"page"}>
            <span className={"table"}>
                         <WorkersTable
                           entries={tableContentMapping}/>
            </span>


            <div className={"footer"}>

              <div className={"reperibility"}>
                <div className={"title"}>{I18n.get().TotemWorkers.reperibility}</div>
                <table>
                  <thead>
                  <tr>
                    <th className={"turn_head"}> {I18n.get().TotemWorkers.table.shift}</th>
                    <th className={"operators_head"}>{I18n.get().TotemWorkers.operators}</th>
                  </tr>
                  </thead>
                  <tbody>
                  {[...this.state.reperibleWorkerMap.keys()].map((item, i) =>
                    <tr key={`${"r_"+i}`}>
                      <td>{ this.getTurnDescription(item) }</td>
                      <td className={"operators"}>
                        <ul>
                        {this.state.reperibleWorkerMap.get(item).map((it, iw) =>
                          <li key={`${"r_" + i.toString() +  "_"+ iw.toString() }`}>{it.workername}</li>
                        )}
                        </ul>
                      </td>
                    </tr>
                  )}
                  </tbody>
                </table>
              </div>


              <div className={"courses"}>
                <div className={"title"}>{I18n.get().TotemWorkers.courses}</div>
                <table>
                  <thead>
                  <tr>
                    <th className={"turn_head"}>{I18n.get().TotemWorkers.table.shift}</th>
                    <th className={"operators_head"}>{I18n.get().TotemWorkers.operators}</th>
                  </tr>
                  </thead>
                  <tbody>
                  {[...this.state.coursesWorkerMap.keys()].map((item, i) =>
                    <tr key={`${"r_"+i}`}>
                      <td>{ this.getCourseDescription(item) }</td>
                      <td className={"operators"}>
                        <ul>
                        {this.state.coursesWorkerMap.get(item).map((it, iw) =>
                          <li key={`${"r_" + i.toString() +  "_"+ iw.toString() }`}>{it.workername}</li>
                        )}
                        </ul>
                      </td>
                    </tr>
                  )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    );
  }
}
