import React, {Component} from 'react';
import "./TopMenu.scss";
import LoginApi from "../../api/login/LoginApi";
import {Pages} from "../../router/Pages";
import I18n from "../../lib/I18n";
import withStatusPolling, {WithStatusPollingProps} from "../../utils/WithStatusPolling";
import EngineApi, {StatusEnum} from "../../api/engine/EngineApi";
import * as H from "history";
import IconStopProcess from '../../icons/IconStopProcess';
import IconLoading from "../../loader/LoaderDots";
import {Roles} from "../../api/Roles";

interface State {
  isSubmenuToggle: boolean
  isTotemSubmenuToggle: boolean
  isPlanningCancellationSubmitted: boolean
}

interface Props {
  history: H.History<H.LocationState>;
  location: H.Location;
}

class TopMenu extends Component<Props & WithStatusPollingProps, State> {

  private readonly TIMEOUT = 3000;
  private engineApi = new EngineApi();

  public constructor(props) {
    super(props);
    this.state = {
      isTotemSubmenuToggle: false,
      isSubmenuToggle: false,
      isPlanningCancellationSubmitted: false
    };
  }

  private isActive(page) {
    return this.props.location.pathname == page ||
    this.props.location.pathname.startsWith(page) ? "active" : ""
  }

  private toggleSubmenu() {
    this.setState({isSubmenuToggle: !this.state.isSubmenuToggle});
  }

  private toggleTotemSubmenu() {
    this.setState({isTotemSubmenuToggle: !this.state.isTotemSubmenuToggle});
  }

  private getAdminMenuBtnClass() {
    if(LoginApi.getRole() != Roles.admin) {
      return "hidden"
    }
    return ""
  }

  private goTo(location: string) {
    if (this.state.isSubmenuToggle) this.toggleSubmenu();
    if (this.state.isTotemSubmenuToggle) this.toggleTotemSubmenu();
    this.props.history.push(location);
  }

  /************************************************
   * LIFECYCLE
   ************************************************/
  render() {
    return <nav className={"TopMenu"}>
      <div className={"blue-btn"}/>
      <ul>
        <li
          className={this.getAdminMenuBtnClass() + " toggle-menu" + " " + this.isActive(Pages.PLANNING) + " " + (this.state.isSubmenuToggle ? "active" : "")}>
          <a onClick={() => this.toggleSubmenu()}>
            {I18n.get().Menu.planning} {this.state.isSubmenuToggle ? <span>&#9652;</span> : <span>&#9662;</span>}
          </a>
          {this.state.isSubmenuToggle ?
            <div className={"submenu"}>
              <ul className={!this.state.isSubmenuToggle ? "hidden" : ""}>
                <li className={this.isActive(Pages.DAILY_PLANNING)}>
                  <a onClick={() => this.goTo(Pages.DAILY_PLANNING)}>{I18n.get().Menu.dailyPlanning}</a>
                </li>
                <li className={this.isActive(Pages.MONTHLY_PLANNING)}>
                  <a
                    onClick={() => this.goTo(Pages.MONTHLY_PLANNING)}>{I18n.get().Menu.monthlyPlanning}</a>
                </li>
              </ul>
            </div>
            : null}
        </li>
        <li className={this.getAdminMenuBtnClass() + " " + this.isActive(Pages.WORKERS_REGISTRY)}>
          <a onClick={() => this.goTo(Pages.WORKERS_REGISTRY)}>
            {I18n.get().Menu.workersRegistry}
          </a>
        </li>
        <li className={this.getAdminMenuBtnClass() + " " + this.isActive(Pages.VEHICLE_REGISTRY)}>
          <a onClick={() => this.goTo(Pages.VEHICLE_REGISTRY)}>
            {I18n.get().Menu.vehicleRegistry}
          </a>
        </li>
        <li className={this.getAdminMenuBtnClass() + " " + this.isActive(Pages.SHIFT_REGISTRY)}>
          <a onClick={() => this.goTo(Pages.SHIFT_REGISTRY)}>
            {I18n.get().Menu.shiftRegistry}
          </a>
        </li>
        <li className={this.getAdminMenuBtnClass() + " " + this.isActive(Pages.NEED_MANAGEMENT)}>
          <a onClick={() => this.goTo(Pages.NEED_MANAGEMENT)}>
            {I18n.get().Menu.needManagement}
          </a>
        </li>
        <li className={this.getAdminMenuBtnClass() + " " + this.isActive(Pages.VEHICLE_MANAGEMENT)}>
          <a onClick={() => this.goTo(Pages.VEHICLE_MANAGEMENT)}>
            {I18n.get().Menu.vehicleManagement}
          </a>
        </li>
        <li className={this.getAdminMenuBtnClass() + " " + this.isActive(Pages.RADIO_CODE)}>
          <a onClick={() => this.goTo(Pages.RADIO_CODE)}>
            {I18n.get().RadioCodeRegistry.title}
          </a>
        </li>
        <li className={this.getAdminMenuBtnClass() + " " + this.isActive(Pages.TOOLS)}>
          <a onClick={() => this.goTo(Pages.TOOLS)}>
            {I18n.get().Menu.tools}
          </a>
        </li>
        <li
          className={"toggle-menu" + " " + this.isActive(Pages.TOTEM) + " " + (this.state.isTotemSubmenuToggle ? "active" : "")}>
          <a onClick={() => this.toggleTotemSubmenu()}>
            {I18n.get().Menu.totem} {this.state.isTotemSubmenuToggle ? <span>&#9652;</span> : <span>&#9662;</span>}
          </a>
          {this.state.isTotemSubmenuToggle ?
            <div className={"submenu"}>
              <ul className={!this.state.isTotemSubmenuToggle ? "hidden" : ""}>
                <li className={this.isActive(Pages.TOTEM_WORKERS)}>
                  <a onClick={() => this.goTo(Pages.TOTEM_WORKERS)}>{I18n.get().Menu.totemWorkers}</a>
                </li>
                <li className={this.isActive(Pages.TOTEM_MANAGERS)}>
                  <a
                    onClick={() => this.goTo(Pages.TOTEM_MANAGERS)}>{I18n.get().Menu.totemManagers}</a>
                </li>
              </ul>
            </div>
            : null}
        </li>
        <li className="logout">
          <a onClick={() => {
            LoginApi.logout();
            this.goTo("/");
          }}>
            Log-out
          </a>
        </li>
      </ul>
      {this.isPlanningInProgress() &&
      <div className="TopMenu-StatusIndicator">
          <span className={"Icon-close " + (this.state.isPlanningCancellationSubmitted ? "submitted" : "")}
                onClick={() => !this.state.isPlanningCancellationSubmitted ? this.stopProcess() : null}>
            <IconStopProcess width={15} color={"#ff0000"}/>
          </span>
        <span className="TopMenu-StatusLabel" onClick={() => this.goTo(Pages.MONTHLY_PLANNING)}>
          <span>{this.getPlanningLabel()}</span>
          <span className={"icon-loading"}><IconLoading size={10}/></span>
        </span>
      </div>}
    </nav>
  }

  private stopProcess() {
    this.setState({isPlanningCancellationSubmitted: true});
    this.engineApi.stopAnyPlanning().then(() => {
      setTimeout(() => {
        this.setState({isPlanningCancellationSubmitted: false})
      }, this.TIMEOUT);
    });
  }

  /**************************************************
   * VARIABLES
   **************************************************/
  isPlanningInProgress(): boolean {
    switch (this.props?.nextMonthPlanning?.status) {
      case StatusEnum.CONVERGENCE_CHECK_IN_PROGRESS:
      case StatusEnum.MONTHLY_PLANNING_IN_PROGRESS:
      case StatusEnum.CONSTRAINT_CHECK_IN_PROGRESS:
      case StatusEnum.DAILY_PLANNING_IN_PROGRESS:
        return true
      default:
        return false
    }
  }

  getPlanningLabel(): string {
    let labels = I18n.get().MonthlyPlanning;

    switch (this.props?.nextMonthPlanning?.status) {
      case StatusEnum.CONVERGENCE_CHECK_IN_PROGRESS:
        return labels.convergenceCheckInProgress
      case StatusEnum.MONTHLY_PLANNING_IN_PROGRESS:
        return labels.monthlyPlanningInProgress
      case StatusEnum.CONSTRAINT_CHECK_IN_PROGRESS:
        return labels.constraintCheckInProgress
      case StatusEnum.DAILY_PLANNING_IN_PROGRESS:
        return labels.dailyPlanningInProgress
      default:
        return null
    }
  }
}


const TopMenuWithStatusPolling = withStatusPolling<Props>(TopMenu);

export default TopMenuWithStatusPolling
