import React from "react";
import "./FixedShiftSection.scss";
import I18n from "../../../lib/I18n";
import {FixedShiftDto} from "../../../api/entity/FixedShiftDto";
import GfDateUtils from "../../../components/DatePicker/GfDateUtils";
import IconCalendar from "../../../icons/IconCalendar";

export interface Props {
  onToggleModify: () => void;
  fixedShifts: FixedShiftDto[]
}

interface State {
}

export default class FixedShiftSection extends React.PureComponent<Props, State> {

  private readonly ACTIVE_CONSTANT = 1;

  static defaultProps: Partial<Props> = {
    onToggleModify: () => {
    },
    fixedShifts: []
  };

  render() {
    const days = GfDateUtils.getWeekdays();
    // let isSectionToShow = (this.props.fixedShifts.map(a => a.days.filter(day => day == 1).filter(anyMatch => anyMatch)).length) > 0;
    return (
      <div className={"FixedShiftSection"}>
        <div className={"details"}>
          <div className={"registrySubtitle"}>
            <span className={"subtitle"}>{I18n.get().WorkersRegistry.sidebar.fixedShift} </span>
            <span className={"actionButton"} onClick={() => this.props.onToggleModify()}>
    {I18n.get().ActionButton.modify}
    </span>
            <div className={"registryDetails"}>
              <div className={"row"}>

                {this.props.fixedShifts.map((shift, index) => {

                  const isToShow = shift.days.filter(day => day == 1).length > 0;

                  let mappedDays = shift.days.map((day, i) =>
                    day == this.ACTIVE_CONSTANT ? days[i] : null)
                    .filter(day => day != null);

                  return isToShow ? (
                    <div className={"entry"} key={shift.shiftId + "_" + index}>
                      <div className={"lbl"}>
                        <IconCalendar />
                        <div>
                          <label className={"shift-name"}>{shift.shiftCode}</label>
                          <div key={shift.shiftCode}/>
                          {
                            <label key={shift.shiftId + "_" + index} className={"weekday-entry"}>
                              {mappedDays.join(", ")}
                            </label>
                          }
                        </div>
                      </div>
                    </div>) : null
                })}
              </div>
            </div>
          </div>
        </div>

      </div>
    )
  }
}
