import AuthorizeApi from "../AuthorizeApi";
import {ShiftResponse} from "../entity/response/ShiftResponse";
import {ShiftDto} from "../entity/ShiftDto";
import {ShiftResponseNt} from "../entity/response/nt/ShiftResponseNt";
import {ReadRequest, ReadRequestExtended} from "../entity/request/ReadRequest";

export default class ShiftRegistryApi {

  private base: string = '/shift';

  private authApi: AuthorizeApi = new AuthorizeApi();

  public async create(shiftDto: ShiftDto) {
    return await this.authApi.put<ShiftDto, ShiftResponse>(this.base, shiftDto);
  }

  public async modify(shiftDto: ShiftDto) {
    let shiftResponse: ShiftResponse;
    await this.authApi.post<ShiftDto, ShiftResponseNt>(this.base + "/update", shiftDto)
      .then((w) => {
        shiftResponse = ShiftRegistryApi.mapToShiftResponse(w)
      });
    return shiftResponse;
  }

  public async get(value: string, count: number, skip: number, exact: boolean = false) {
    let shiftResponse: ShiftResponse;
    await this.authApi.post<ReadRequestExtended, ShiftResponseNt>(this.base, {count: count, skip: skip, searchKey: value, exact: exact})
      .then((w) => {
        shiftResponse = ShiftRegistryApi.mapToShiftResponse(w)
      });
    return shiftResponse;
  }

  public async getAll() {
    /** TODO: we should change the API to just return all employees
     *        added this wrapper to distinguish calls that are supposed to be paginated or not */
    return this.get("", 5000, 0)
  }

  public async getByCode(key: string) {
    let shiftResponse: ShiftResponse;
    const value : ReadRequestExtended = {searchKey: key, skip: 0, count: 1, exact: true};
    await this.authApi.post<ReadRequest, ShiftResponseNt>(`${this.base}`, value).then((w) => {
      shiftResponse = ShiftRegistryApi.mapToShiftResponse(w)
    });
    return shiftResponse;
  }

  public async getDetails(id: number) {
    let shiftResponse: ShiftResponse;
    await this.authApi.get<ShiftResponseNt>(`${this.base}/${id}`).then((w) => {
      shiftResponse = ShiftRegistryApi.mapToShiftResponse(w)
    });
    return shiftResponse;
  }

  private static mapToShiftResponse(shiftResponseNt: ShiftResponseNt) {
    return new ShiftResponse(shiftResponseNt);
  }
}
