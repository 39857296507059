import AuthorizeApi from "../AuthorizeApi";
import {MonthlyData, MonthlyEmployee} from "../../pages/Dashboard/MonthlyPlanning/MonthlyDTO";

export interface TimeOffTypeDto {
  value: string,
  label: string
}

export enum TimeOffTypeEnum {
  F = "F",
  AR = "AR",
  GR = "GR"
}

export enum NonWorkingDaysTypeEnum {
  R = "R",
  RN = "RN",
  S = "S",
  F = "F",
  FT = "FT"
}

export class TimeOffUtils {

  public static getTimeOffTotalForDay(dayIndex: number, monthlyEmployees: MonthlyEmployee[]): number {
    let total = 0;
    for (const e of monthlyEmployees)
      if (e.turns[dayIndex] && this.isTimeOff(e.turns[dayIndex]))
        ++total;
    return total
  }

  public static getTotalFForDay(dayIndex: number, monthlyEmployees: MonthlyEmployee[]): number {
    let total = 0;
    for (const e of monthlyEmployees)
      if (e.turns[dayIndex] && this.isF(e.turns[dayIndex]))
        ++total;
    return total
  }

  public static getTotalARForDay(dayIndex: number, monthlyEmployees: MonthlyEmployee[]): number {
    let total = 0;
    for (const e of monthlyEmployees)
      if (e.turns[dayIndex] && this.isAR(e.turns[dayIndex]))
        ++total;
    return total
  }

  public static getTotalGRForDay(dayIndex: number,  monthlyEmployees : MonthlyEmployee[]): number {
    let total = 0;
    for (const e of monthlyEmployees)
      if (e.turns[dayIndex] && this.isGR(e.turns[dayIndex]))
        ++total;
    return total
  }

  public static isNonWorkingDayOrTimeOff(turnName: string) {
    return this.isNonWorkingDay(turnName) || this.isTimeOff(turnName);
  }

  public static isTimeOff(turnName: string) {
    return this.isF(turnName) || this.isAR(turnName) || this.isGR(turnName)
  }

  public static isNonWorkingDay(turnName: string) {
    return this.isR(turnName) || this.isRN(turnName) || this.isS(turnName)
      || this.isF(turnName) || this.isFT(turnName)
  }

  public static isF(turnName: string) {
    return turnName == TimeOffTypeEnum.F
  }

  public static isAR(turnName: string) {
    return turnName == TimeOffTypeEnum.AR
  }

  public static isGR(turnName: string) {
    return turnName == TimeOffTypeEnum.GR
  }

  public static isR(turnName: string) {
    return turnName == NonWorkingDaysTypeEnum.R
  }

  public static isRN(turnName: string) {
    return turnName == NonWorkingDaysTypeEnum.RN
  }

  public static isS(turnName: string) {
    return turnName == NonWorkingDaysTypeEnum.S
  }

  public static isFT(turnName: string) {
    return turnName == NonWorkingDaysTypeEnum.FT
  }
}

export default class TimeOffApi {
  private base: string = '/time-off';
  private authApi: AuthorizeApi = new AuthorizeApi();

  public async getTypes(): Promise<TimeOffTypeDto[]> {
    return Promise.resolve([
        {value: TimeOffTypeEnum.F, label: "F"},
        {value: TimeOffTypeEnum.AR, label: "AR"},
        {value: TimeOffTypeEnum.GR, label: "GR"}
      ]
    )
  }
}
