import React from "react";
import Icon from "./Icon";

interface Props {
  className: string;
  width: number;
  height: number;
  color: string;
}

export default class IconCheck extends Icon<Props> {
  /************************************************
   * PROPS
   ************************************************/
  static defaultProps = {
    className: "",
    width: 24,
    height: 24,
    color: "#2E3A59"
  };

  /************************************************
   * LIFECYCLE
   ************************************************/
  render() {
    return (
      <svg className={"IconCheck " + this.props.className}
           width={this.props.width}
           height={this.props.height}
           viewBox="0 0 24 24">
        <path d="m11.939 0.90625a11.094 11.094 0 0 0-11.033 11.094 11.094 11.094 0 0 0 11.094 11.094 11.094 11.094 0 0 0 11.094-11.094 11.094 11.094 0 0 0-11.094-11.094 11.094 11.094 0 0 0-0.060547 0zm4.6504 6.6738 1.4648 1.4648-7.8926 7.8926-0.009765-0.011719-0.11524 0.11524-4.1016-4.0996 1.4219-1.4199 2.6465 2.6465 6.5859-6.5879z" fill="#d1f081" stroke-linecap="round" stroke-width="26.453"/>
      </svg>);
  }
}
