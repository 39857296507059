import React, {Component} from "react";
import "./CircuitSelect.scss";
import Select from "../Select/Select";
import ShiftUtils from "../../utils/ShiftUtils";

interface Props {
  /** This defines additional classes for the Button */
  className: string,
  onChange: (circuitDto: string) => void;
  excludedValue?: string;
  value? : string
  disabled? : boolean
}

interface State {
  valueIndex: number
  circuitDtoChoices: string[]
}

export class CircuitSelect extends Component<Props, State> {

  private shiftUtils = new ShiftUtils();
  private selected: number = 0;

  public static NO_FILTER = "TUTTI I CIRCUITI";

  /************************************************
   * PROPS
   ************************************************/
  static defaultProps: Partial<Props> = {
    className: "",
    onChange: () => {},
    value: CircuitSelect.NO_FILTER,
    disabled: false,
  };

  /************************************************
   * CONSTRUCTOR
   ************************************************/
  constructor(props: Props) {
    super(props);
    this.state = {valueIndex: 0,
     circuitDtoChoices: [CircuitSelect.NO_FILTER]
        .concat(this.shiftUtils.CIRCUIT_DTO)
    }
  }

  componentDidMount() {
    if(this.props.excludedValue) {
      let {circuitDtoChoices} = this.state;
      circuitDtoChoices.splice(
        circuitDtoChoices
          .indexOf(this.props.excludedValue),
        1);
      this.setState({circuitDtoChoices});
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if(this.props.value != prevProps.value) {
      this.assignNewValueIfFoundInChoices();
    }
  }

  private assignNewValueIfFoundInChoices() {
    const valueIndex = this.state.circuitDtoChoices.indexOf(this.props.value);
    if (valueIndex > -1) {
      this.setState({valueIndex: valueIndex})
    }
  }

  /************************************************
   * LIFECYCLE
   ************************************************/
  render() {
    let {circuitDtoChoices} = this.state;
    return (
      <div className={"CircuitSelect " + this.props.className}>
        <Select items={circuitDtoChoices}
                defaultValue={this.state.valueIndex}
                disabled={this.props.disabled}
                onChange={(index) => this.onChange(index)}/>
      </div>
    )
  }

  private onChange(index: number) {
    this.selected = index;
    this.setState({ valueIndex: index });
    this.props.onChange(this.state.circuitDtoChoices[index]);
  }
}
