import React, {Component, ReactNode} from "react";
import "./TimeLineXAxis.scss"
import TimeLineSeparatorVertical from "../TimeLineSeparatorVertical/TimeLineSeparatorVertical";

export default class TimeLineXAxis extends Component<{ headers: ReactNode[], headerHeight:number }> {
  render() {
    return <div className="TimeLineXAxis" style={{height:this.props.headerHeight}}>
      {this.props.headers.map((header, i) =>
        <div className="TimeLineXAxis-cell" key={i}>
          <TimeLineSeparatorVertical />
          {header}
        </div>)}
    </div>
  }
}
