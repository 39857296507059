import React, {Component} from "react";
import "./WorkerRegistryDetails.scss";
import {WorkerDto} from "../../../api/entity/WorkerDto";
import I18n from "../../../lib/I18n";
import {HeightOptionUtils} from "../../../api/entity/HeightOptionsUtils";
import HeaderSection from "../HeaderSection/HeaderSection";
import GfDateUtils from "../../../components/DatePicker/GfDateUtils";

export interface Props {
  onToggleModify: (WorkerDto) => void;
  onToggleModifyRelated: (WorkerDto) => void
  onToggleTutor: (WorkerDto) => void
  sections: {
    registryDetails: {
      workerDto: WorkerDto,
      calculatedAge: number
    }
  }
}

interface State {

}

export class WorkerRegistryDetails extends Component<Props, State> {

  static defaultProps: Partial<Props> = {
    onToggleModify: () => {
    },
  };

  render() {
    const workerDto = this.props.sections.registryDetails.workerDto;
    return (
      <div className={"WorkerRegistryDetails"}>
        <div className={"title"}>
          {`${workerDto.lastname} ${workerDto.name}`}
        </div>
        <HeaderSection user={workerDto} onToggleModify={() =>
          this.props.onToggleModifyRelated(this.props.sections.registryDetails.workerDto)}
                       onToggleAddTutor={() =>
                         this.props.onToggleTutor(this.props.sections.registryDetails.workerDto)}/>
        <div className={"details"}>
          <div className={"registrySubtitle"}>
            <span className={"subtitle"}>{I18n.get().WorkersRegistry.sidebar.details} </span>
            <span className={"actionButton"}
                  onClick={() => this.props.onToggleModify(this.props.sections.registryDetails)}>
                {I18n.get().ActionButton.modify}
              </span>
          </div>
          <div className={"registryDetails"}>
            <div className={"row"}>
              <div className={"lbl"}>
                <label>{I18n.get().WorkersRegistry.sidebar.gender}</label>
                <div className={"value"}>{this.props.sections.registryDetails.workerDto.gender}</div>
              </div>
              <div className={"lbl"}>
                <label>{I18n.get().WorkersRegistry.sidebar.age}</label>
                <div className={"value"}>{this.props.sections.registryDetails.calculatedAge}</div>
              </div>
              <div className={"lbl"}>
                <label>{I18n.get().WorkersRegistry.height}</label>
                <div className={"value"}>{HeightOptionUtils.getHeightInternationalized(workerDto.height)}</div>
              </div>
            </div>
            <div className={"row second-row"}>
              <div className={"lbl"}>
                <label>{I18n.get().WorkersRegistry.cf}</label>
                <div className={"value"}>{workerDto.cf}</div>
              </div>
              {workerDto.firstContract != null ?
                <div className={"lbl"}>
                  <label>{I18n.get().WorkersRegistry.firstContract}</label>
                  <div
                    className={"value"}>{WorkerRegistryDetails.resolveStartContractLabel(workerDto)}</div>
                </div> : null
              }
            </div>
          </div>
        </div>
      </div>
    )
  }

  private static resolveStartContractLabel(e: WorkerDto) {
    let startContract = I18n.printDate(e.firstContract, GfDateUtils.DATE_FORMAT);
    let creationContract = I18n.printDate(e.creationDate, GfDateUtils.DATE_FORMAT);
    let endContract = I18n.printDate(e.endContract, GfDateUtils.DATE_FORMAT);
    if (creationContract == startContract && creationContract == endContract) {
      return "";
    }
    return startContract;
  }
}

export default WorkerRegistryDetails;
