import React from "react";
import Icon from "./Icon";

interface Props {
  className: string;
  width: number;
  height: number;
  color: string;
}

export default class IconTrash extends Icon<Props> {
  /************************************************
   * PROPS
   ************************************************/
  static defaultProps = {
    className: "",
    width: 100,
    height: 100,
    color: "#df5858"
  };

  /************************************************
   * LIFECYCLE
   ************************************************/
  render() {
    return (
      <svg className={"IconTrash " + this.props.className}
           width={this.props.width}
           height={this.props.height}
           viewBox={`0 0 100 100`}>
        <path d="m26.218 99.521c-0.06212-0.0126-0.42295-0.0651-0.80027-0.11615-2.2207-0.29977-4.5663-1.4828-6.2532-3.1539-1.526-1.512-2.4548-3.0699-3.0419-5.1032-0.44916-1.5552-0.42377 0.54907-0.42377-35.116v-32.696h-7.6224v-7.6225h22.851l0.03818-2.6488c0.03217-2.3536 0.05688-2.7338 0.20253-3.4111 0.49106-2.2471 1.4615-4.0645 3.0391-5.6918 1.859-1.9174 4.2287-3.0998 6.8361-3.411 0.9437-0.11347 16.969-0.11347 17.913 0 1.8099 0.21608 3.5478 0.87131 5.1071 1.9257 0.96124 0.65011 2.3911 2.0948 3.0327 3.0643 0.85916 1.2983 1.4012 2.5834 1.7356 4.1129 0.14857 0.67736 0.17013 1.0577 0.20253 3.4111l0.03836 2.6488h22.851v7.6225h-7.6232v32.697c0 28.618-0.01462 32.805-0.11343 33.568-0.57878 4.4144-3.7595 8.1489-8.0574 9.4598-1.6089 0.49076 0.56553 0.45132-25.825 0.46832-13.185 8e-3 -24.024 5e-3 -24.087-8e-3zm47.557-7.7001c1.3769-0.32466 2.4619-1.4093 2.7863-2.7863 0.1026-0.43162 0.11613-4.2821 0.11613-33.094l0.0011-32.605h-53.357l0.0011 32.605c9.95e-4 28.811 0.01421 32.662 0.11616 33.094 0.32034 1.3586 1.4064 2.4573 2.7513 2.7827 0.38111 0.09183 3.8977 0.108 23.772 0.11343 20.549 0.0045 23.383-0.0082 23.813-0.1107zm-42.831-34.184v-26.679h7.6225v53.357h-7.6225zm15.245 0v-26.679h7.6225v53.357h-7.6225zm15.245 0v-26.679h7.6225v53.357h-7.6225zm0-44.054c0-1.261-0.03525-2.2955-0.08373-2.5345-0.21607-1.0328-1.0163-2.0975-1.8976-2.5221-0.93586-0.45102-0.5966-0.43538-9.426-0.43538-5.6177 0-8.2219 0.026211-8.5021 0.083753-1.415 0.29519-2.5783 1.4582-2.8732 2.8732-0.04863 0.24041-0.08373 1.2735-0.08373 2.5345v2.1305h22.868z"
                fill={this.props.color}
                strokeWidth=".020328"
          />
      </svg>);
  }
}
