import React from 'react';
import {Redirect, Route, useLocation} from 'react-router-dom';
import TopMenu from "../components/TopMenu/TopMenu";
import LoginApi from "../api/login/LoginApi";
import {RouteComponentProps} from "react-router-dom";


export default function FullPagePrivateRoute({component: Component, ...rest}) {
  const location = useLocation();
  return (
    <Route
      {...rest}
      render={(props:RouteComponentProps) =>
        true ? (
          <div>
            <Component {...props} />
          </div>
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: {from: location},
            }}
          />
        )
      }
    />
  );
}
