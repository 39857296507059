import StatusPoller from "./StatusPoller";
import React, {Component, ComponentType} from "react";
import {NextPlanning} from "../api/engine/EngineApi";

interface State {
  nextMonthPlanning: NextPlanning
}

export interface WithStatusPollingProps{
  nextMonthPlanning:NextPlanning
}


export default function withStatusPolling<P>(WrappedComponent: ComponentType<P & WithStatusPollingProps>) {
  return class extends Component<P, State> {
    constructor(props) {
      super(props);
      this.state = {
        // "DataSource" is some global data source
        nextMonthPlanning: StatusPoller.getInstance().currentStatus
      };
    }

    componentDidMount() {
      this.listenerId = StatusPoller.getInstance().listenToStatusChange(async nextMonthPlanning => this.setState({nextMonthPlanning}))
    }

    componentWillUnmount() {
      StatusPoller.getInstance().unregisterToStatusChange(this.listenerId)
    }

    render() {
      return (
        <div>
          <WrappedComponent nextMonthPlanning={this.state.nextMonthPlanning} {...this.props}/>
        </div>
      );
    }

    /**************************************************
     * VARIABLES
     **************************************************/
    listenerId
  }
}
