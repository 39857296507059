import {VehicleResponseNt} from "./nt/VehicleResponseNt";
import {VehicleDto} from "../VehicleDto";

export class VehicleResponse {
  status: string;
  errors: string[];
  content: VehicleDto[];
  totalPages: number;
  currentPages: number;
  size: number;
  total: number;

  constructor(responseNt: VehicleResponseNt) {
    this.status = responseNt.status;
    this.errors = responseNt.errors;
    this.content = responseNt.content.map((vehicleDto) => new VehicleDto(vehicleDto))
    this.totalPages = responseNt.totalPages;
    this.currentPages = responseNt.currentPages;
    this.size = responseNt.size;
    this.total = responseNt.total;
  }

}
