import React from "react";
import "./DailyVehicleCell.scss"
import {Vehicle} from "../../../VehicleManagement/MonthlyVehicleTimeLine/MonthlyVehicleTimeLine";
import IconVehicle from "../../../../icons/IconVehicle";

interface Props {
  className?: string
  vehicle: Vehicle
  type: string
  id: string
  onClick: (vehicle: Vehicle) => void
}

export default function DailyVehicleCell(props: Props) {
  return <div className={"DailyVehicleCell " + (props.className != undefined ? props.className : "")} id={"dvc_" + props.vehicle.id} onClick={() => {
    props.onClick(props.vehicle)
  }}>
    <div className={"DailyVehicleCell-machineName"}>
      {(props.type == "EM/INTERH")
        ? <>
          <div className="DailyServiceCell-serviceType EM">EM</div>
          <div className="DailyServiceCell-serviceType INTERH">INTERH</div>
        </>
        : <div className={"DailyServiceCell-serviceType " + (props.type == "EM+" ? "EM_PLUS" : props.type)}>
          {props.type}
        </div>}

      <div className={"clear"}/>
      <div className={"vehicleIcon-Name"}>
        <IconVehicle className={"icon"}/>
        <div className="name">{props.vehicle.name}</div>
      </div>
    </div>
  </div>

}
