import React, {Component, ReactNode} from "react";
import {DateTime} from "luxon";
import Time from "../../../lib/utils/Time";
import DailyVehicleHeader from "./DailyWorkerHeader/DailyVehicleHeader";
import TimeLineData from "../../../components/TimeLine/TimeLineData";
import {VehicleDto} from "../../../api/entity/VehicleDto";
import DailyVehicleCell from "./DailyVehicleCell/DailyVehicleCell";
import DailyEmployeeLine from "../../Dashboard/DailyPlanning/DailyEmployeeLine/DailyEmployeeLine";
import TimeLine from "./TimeLine/TimeLine";
import {VehicleUnavailabilityDto} from "../../../api/entity/VehicleUnavailabilityDto";
import {KbModeType} from "../../../components/KebabSmallMenuModal/KBMenuModal";
import {VehicleDailyPlanningDto} from "../../../api/entity/VehicleDailyPlanningDto";
import GfDateUtils from "../../../components/DatePicker/GfDateUtils";
import VehicleManagementUtils from "../VehicleManagementUtils";
import VehicleBusyLine from "../../Dashboard/DailyPlanning/VehicleBusyLine/VehicleBusyLine";
import TimeLineMatrix = TimeLineData.TimeLineMatrix;
import Interval = TimeLineData.Interval;

interface Props {
  day: DateTime,
  allVehicles: VehicleDto[],
  vehicleUnavailabilities: VehicleUnavailabilityDto[],
  dailyPlanningVehicles: VehicleDailyPlanningDto[],
  promptMenu: (x: number, y: number) => void;
  onVehicleIdLoad: (id: number) => void;
  onToggleDeleteConfirmModal: (v: VehicleUnavailabilityDto) => void;
}

interface State {
  menuX: number,
  menuY: number,
  isMenuHidden: boolean,
  menuModeType: KbModeType
  lastSelectedId: number,
}

export type Vehicle = {
  id: number,
  name: string,
  plate: string,
  category: string,
  circuit: string
};
type VehicleActivity = { date: string, lav: boolean, off: boolean, service: boolean, start: string, end: string };

export default class MonthlyVehicleTimeLine extends Component<Props, State> {

  private readonly FILTERED_VEHICLE_SERVICE = [];

  /**************************************************
   * CONSTRUCTOR
   **************************************************/
  constructor(props) {
    super(props);
    this.state = {
      menuX: 0,
      menuY: 0,
      menuModeType: KbModeType.CREATE,
      isMenuHidden: true,
      lastSelectedId: null
    }
  }

  /**************************************************
   * LIFECYCLE
   **************************************************/
  render() {

    const days: DateTime[] = Time.getDaysInMonth(this.props.day).map(d => DateTime.fromJSDate(d));

    return <TimeLine
      columnWidth={80}
      kebabX={this.state.menuX}
      kebabY={this.state.menuY}
      kebabModeType={this.state.menuModeType}
      isMenuHidden={this.state.isMenuHidden}
      headers={this.dailyVehicleHeaders(days)}
      timeLines={this.vehicleTimeLines(days)}
      onScroll={() => this.hideMenuWhenOpen()}
      onCreateClick={() => this.onVehicleLoadClick()}
      onDeleteClick={() => this.targetDeletion()}
      onToggleDeleteConfirm={() => this.targetDeletion()}
    />
  }

  /**************************************************
   * PRIVATE FUNCTIONS
   **************************************************/
  private dailyVehicleHeaders(days: DateTime[]): ReactNode[] {
    const headers: ReactNode[] = []

    for (const day of days)
      headers.push(<DailyVehicleHeader day={day.toJSDate()}/>)
    return headers
  }

  private vehicleTimeLines(days: DateTime[]): TimeLineData.TimeLineMatrix[] {
    const timeLines: (TimeLineMatrix & { service: string })[] = [];

    const vehicles: Vehicle[] = this.props.allVehicles.map((vehicle) => {
      return {
        id: vehicle.id,
        name: vehicle.name,
        plate: vehicle.plate,
        circuit: vehicle.circuit,
        category: vehicle.category
      }
    });

    for (let i = 0; i < vehicles.length; i++) {
      let vehicle = vehicles[i];
      const timelineIntervals: Interval[] = []
      let service = ""

      const vuDto = this.props.vehicleUnavailabilities.filter(vu => vu.vehicleId == vehicle.id);
      const vEntries = this.props.dailyPlanningVehicles.filter(vu => vu.vehicleId == vehicle.id &&
        this.FILTERED_VEHICLE_SERVICE.indexOf(vu.containerVehicle) == -1);

      const dailyVehiclePlanningMap:{[date:string]:VehicleActivity} = {}

      for(const vE of vEntries){
        if(!dailyVehiclePlanningMap[vE.date])
          dailyVehiclePlanningMap[vE.date] = {
            lav:false,
            off:false,
            service:false,
            date:vE.date,
            start:vE.vehicleStartTime,
            end:vE.vehicleEndTime
          }

        if(vE.containerVehicle.startsWith("LAV"))
          dailyVehiclePlanningMap[vE.date].lav = true
        else if(vE.containerVehicle.startsWith("OFF"))
          dailyVehiclePlanningMap[vE.date].off = true
        else
          dailyVehiclePlanningMap[vE.date].service = true


        if(this.isOvernight(vE.vehicleStartTime,vE.vehicleEndTime)){
          let tomorrow = DateTime.fromFormat(vE.date,"yyyy-MM-dd").toFormat("yyyy-MM-dd");
          if(!dailyVehiclePlanningMap[tomorrow])
            dailyVehiclePlanningMap[tomorrow] = {
              lav:false,
              off:false,
              service:false,
              date:tomorrow,
              start:vE.vehicleStartTime,
              end:vE.vehicleEndTime
            }

          if(vE.containerVehicle.startsWith("LAV"))
            dailyVehiclePlanningMap[tomorrow].lav = true
          else if(vE.containerVehicle.startsWith("OFF"))
            dailyVehiclePlanningMap[tomorrow].off = true
          else
            dailyVehiclePlanningMap[tomorrow].service = true
        }
      }

      for(const date in dailyVehiclePlanningMap){
        timelineIntervals.push(this.getBusySquareTimeLine({...dailyVehiclePlanningMap[date],...{date:date}}, days))
      }

      // vEntries.forEach(vE => timelineIntervals.push(this.getBusySquareTimeLine(vE, days)))
      vuDto.forEach(vu => timelineIntervals.push(this.getTimeLineForUnavailability(vu, days)));


      timeLines.push({
        backgroundColor: VehicleManagementUtils.CELL_BG_COLOR,
        service,
        intervals: timelineIntervals,
        title: <DailyVehicleCell
          name={vehicle.name} type={vehicle.category}
          vehicleId={vehicle.id}
          id={i.toString()}
          onClick={(x, y) => this.promptCreateMenu(x, y, vehicle.id)}
        />
      })
    }
    return timeLines;
  }


  private getBusySquareTimeLine(vehicleActivity:VehicleActivity, days: DateTime[]) {
    const start = this.convertToTime(days, DateTime.fromFormat(vehicleActivity.date, GfDateUtils.STORED_DATE_FORMAT), "00:00");
    const end = this.convertToTime(days, DateTime.fromFormat(vehicleActivity.date, GfDateUtils.STORED_DATE_FORMAT), "00:00", true);

    return {
      // color: "#c6ecfd",
      color: this.getBusySquareColor(vehicleActivity),
      textColor: VehicleManagementUtils.TEXT_COLOR,
      start,
      end,
      height: 100,
      content:""
      // content: <VehicleBusyLine
      //   onClick={() => {
      //   }}
      //   // id={ve.id}
      //   textColor={VehicleManagementUtils.TEXT_COLOR}
      //   turnName={""}
      //   start={"00:00"}
      //   end={"00:00"} // Full day
      // />
    }
  }


  private getBusySquareColor(vehicleActivity: { date: string; lav: boolean; off: boolean; service: boolean }) {
    if(vehicleActivity.service && (vehicleActivity.off || vehicleActivity.lav))
      return MonthlyVehicleTimeLine.SERVICE_AND_UNAVAILABILITY_BUSY_COLOR
    else if(vehicleActivity.lav && !vehicleActivity.service && !vehicleActivity.off)
      return MonthlyVehicleTimeLine.LAV_ONLY_BUSY_COLOR
    else return MonthlyVehicleTimeLine.SERVICE_ONLY_BUSY_COLOR


  }

  private isOvernight(start:string, end:string) {
    return Time.hourStringToNumber(start) > Time.hourStringToNumber(end);
  }

  getTimeLineForUnavailability(vehicleUnavailabilityDto: VehicleUnavailabilityDto, days: DateTime[]) {
    const subject = vehicleUnavailabilityDto;

    const start = this.convertToTime(days, subject.fromDate, subject.fromTime);
    const end = this.convertToTime(days, subject.toDate, subject.toTime);

    const textElectedColor = VehicleManagementUtils.getTimelineTextColorUnavailability(subject.motivation);

    return {
      color: VehicleManagementUtils.getTimelineBgColorUnavailability(subject.motivation),
      textColor: textElectedColor,
      start,
      end,
      content: <DailyEmployeeLine
        onClick={(id, pos) => this.promptDeleteMenu(pos.x, pos.y, id)}
        id={subject.id}
        textColor={textElectedColor}
        turnName={subject.motivation.toUpperCase()}
        start={subject.fromTime}
        end={subject.toTime}
        extraText={subject.description}
      />
    }
  }

  private convertToTime(days: DateTime[], targetDate: DateTime, hourString: string, isEndTime = false, isOvernight: boolean = false) {
    const start = days[0];
    const end = days[days.length - 1];

    if (isEndTime && hourString == "00:00") {
      // In case a value "00:00" is used to mean the end of the day instead of the beginning
      const dayOffset = MonthlyVehicleTimeLine.isDayBeforeStartOfMonth(targetDate, start) ? 0 : targetDate.day;
      const dayWidth = 1.01;
      const dayNr = isOvernight ? (dayWidth * 2) : dayWidth;
      return (dayOffset - 1) + dayNr
    }


    let value = Time.hourStringToNumber(hourString);
    let hoursDecimalVal = value / 24;

    const firstDayOfMonthAtStart = start.startOf("day");
    const startOfTargetDay = targetDate.startOf("day");
    const lastDayTargetDay = end.endOf("day");

    // In case of daily dates overlapping the last
    // day of month and the following first day of month
    if (firstDayOfMonthAtStart > startOfTargetDay) return 0;
    if (firstDayOfMonthAtStart == startOfTargetDay) return hoursDecimalVal;
    if (lastDayTargetDay < startOfTargetDay) return 32

    return (targetDate.day - 1) + hoursDecimalVal;
  }

  private static isDayBeforeStartOfMonth(date: DateTime, start: DateTime) {
    return date.toMillis() < start.toMillis();
  }

  private hideMenu() {
    this.setState({isMenuHidden: true});
  }

  private hideMenuWhenOpen() {
    if (!this.state.isMenuHidden) {
      this.hideMenu();
    }
  }

  private promptCreateMenu(x: number, y: number, id: number) {
    this.setState({
      menuY: y - this.OFFSET_Y,
      menuX: x + this.OFFSET_X,
      isMenuHidden: false,
      lastSelectedId: id,
      menuModeType: KbModeType.CREATE
    });
  }

  private promptDeleteMenu(x: number, y: number, id: number) {
    this.setState({
      menuY: y - this.OFFSET_Y,
      menuX: x - this.OFFSET_X,
      isMenuHidden: false,
      lastSelectedId: id,
      menuModeType: KbModeType.DELETE
    });
  }

  private targetDeletion() {
    const vehicleUnavailabilityDtos = this.props.vehicleUnavailabilities.filter(v => v.id == this.state.lastSelectedId);
    if (vehicleUnavailabilityDtos.length == 1) {
      this.props.onToggleDeleteConfirmModal(vehicleUnavailabilityDtos[0]);
    }
    this.hideMenu();
  }

  private onVehicleLoadClick() {
    this.props.onVehicleIdLoad(this.state.lastSelectedId);
    this.hideMenu();
  }


  /**************************************************
   * VARIABLES
   **************************************************/

  private readonly OFFSET_X = 100;
  private readonly OFFSET_Y = 220;

  static readonly SERVICE_ONLY_BUSY_COLOR = "#c6ecfd"
  static readonly SERVICE_AND_UNAVAILABILITY_BUSY_COLOR = "#ffca8c"
  static readonly LAV_ONLY_BUSY_COLOR = VehicleManagementUtils.OFF_TIMELINE_COLOR
}
