import React, {Component} from "react";
import "./WorkerListModal.scss";
import Button from "../../Button/Button";
import I18n from "../../../lib/I18n";
import {WorkerDto} from "../../../api/entity/WorkerDto";

interface Props {
  workers: WorkerDto[]
  title: string,
  onCancel: () => void
}

export class WorkerListModal extends Component<Props,{}> {
  render() {
    return (
      <div className="ConfirmModal-Overlay">
        <div className="ConfirmModal">
          <h2>{this.props.title}</h2>
          <div className="ConfirmModal-Body">
            <ul className={"plain-list"}>
              {this.props.workers.map(w =>
                <li>{w.lastname} {w.name}</li>
              )}
            </ul>
          </div>
          <div className="ConfirmModal-Buttons">
            <Button secondary={true} onClick={() => this.props.onCancel()}
                    className="ConfirmModal-CancelButton">
              {I18n.get().MonthlyPlanning.viewOverloaded.closeModal}
            </Button>
          </div>
        </div>
      </div>
    )
  }
}
