import React, {BaseSyntheticEvent, Component} from "react";
import "./WorkerModal.scss";
import Button from "../../Button/Button";
import TextInput from "../../TextInput/TextInput";
import I18n from "../../../lib/I18n";
import Select from "../../Select/Select";
import AddWorkerRegistryValidator from "../../../api/worker/validator/AddWorkerRegistryValidator";
import {WorkerDto} from "../../../api/entity/WorkerDto";
import {ModalType} from "../ModalType";
import {GfDatePicker} from "../../DatePicker/GfDatePicker";
import WorkersRegistryUtils from "../../../pages/WorkersRegistry/WorkerUtils";
import {DateTime} from "luxon";

export interface Props {
  title: string,
  type: ModalType,
  /** This defines additional classes for the Button */
  className: string,
  model: WorkerDto,
  /** Disables onclick
   *  @default false
   **/
  onSubmit: (WorkerDto) => void;
  onError: (errors) => void;
  onCancel: () => void;
  disabled: boolean;
}

interface State {
  worker: WorkerDto,
  errors: WorkerModalErrors
}

export interface WorkerModalErrors {
  name: string,
  lastname: string,
  cf: string,
  birthdate: string
}

export class WorkerModal extends Component<Props, State> {

  public static companies: string[] = ["Catis", "Ismobo"];
  public static gender: string[] = ["F", "M"];

  private workerRegistryUtils = new WorkersRegistryUtils();

  private HEIGHT_OPTIONS = ["", "short", "medium", "tall"].map(s => s.toUpperCase());

  private heights: string[] = [I18n.get().WorkersRegistry.heightOptions.unspecified,
    I18n.get().WorkersRegistry.heightOptions.short, I18n.get().WorkersRegistry.heightOptions.average,
    I18n.get().WorkersRegistry.heightOptions.tall];
  /************************************************
   * PROPS
   ************************************************/
  static defaultProps: Partial<Props> = {
    className: "",
    onCancel: () => {
    },
    disabled: false
  };

  /************************************************
   * CONSTRUCTOR
   ************************************************/
  constructor(props: Props) {
    super(props);
    this.restoreForm();
  }

  onCancel() {
    this.restoreForm();
    this.props.onCancel();
  }

  async onSubmit(event: BaseSyntheticEvent) {
    event.preventDefault();

    let workerDto: WorkerDto = {
      id: this.props.type == ModalType.CREATE ? null : this.props.model.id,
      name: this.state.worker.name,
      lastname: this.state.worker.lastname,
      cf: this.state.worker.cf,
      height: this.state.worker.height,
      birthdate: this.state.worker.birthdate,
      firstContract: this.state.worker.firstContract,
      endContract: this.state.worker.endContract,
      circuit: this.state.worker.circuit,
      role: this.state.worker.role,
      skill: this.state.worker.skill,
      moss: this.state.worker.moss,
      active: this.state.worker.active,
      company: this.state.worker.company,
      creationDate: this.state.worker.creationDate,
      tutoring: this.state.worker.tutoring,
      tutoringId: this.state.worker.tutoringId,
      tutoredId: this.state.worker.tutoredId,
      tutoringFullName: this.state.worker.tutoringFullName,
      tutoredFullName: this.state.worker.tutoredFullName,
      flagNottiConsecutive: this.state.worker.flagNottiConsecutive,
      numNottiConsecutive: this.state.worker.numNottiConsecutive,
      incomplete: false,
      gender: this.state.worker.gender
    };
    let validationErrors = AddWorkerRegistryValidator.validate(workerDto)
    this.setState({errors: validationErrors});

    if (WorkerModal.isValidRequest(validationErrors)) {
      this.props.onSubmit(workerDto);
    }
  }

  private static isValidRequest(validation: WorkerModalErrors) {
    for (const error in validation) {
      if (validation.hasOwnProperty(error))
        if (validation[error]) return false;
    }
    return true;
  }

  private restoreForm() {
    if (this.props.type == ModalType.CREATE) {
      this.state = {
        worker: this.workerRegistryUtils.getEmptyWorkerDto(),
        errors: this.workerRegistryUtils.getEmptyErrorState()
      }
    }
    this.state = {
      worker: this.props.model,
      errors: this.workerRegistryUtils.getEmptyErrorState()
    }
  }

  /************************************************
   * LIFECYCLE
   ************************************************/
  render() {
    return (
      <div className={"Modal AddWorkerModal " + this.props.className}>
        <form onSubmit={(event) => this.onSubmit(event)}>
          <div>
            <h1>
              {I18n.get().WorkersRegistry.addWorker}
            </h1>
          </div>
          <div>
            <div className={"inner-modal"}>
              <div className={"w-h"}>
              <TextInput className={"text-input"} errorMessage={this.state.errors.name}
                         onChange={(e) => this.setState({
                           worker: {...this.state.worker, name: e},
                           errors: {...this.state.errors, name: ""}
                         })}
                         label={I18n.get().WorkersRegistry.name} defaultValue={this.props.model.name}
                         placeholder={I18n.get().WorkersRegistry.name}/>
              </div>
              <div className={"w-h"}>
              <TextInput className={"text-input"} errorMessage={this.state.errors.lastname}
                         onChange={(e) => this.setState({
                           worker: {...this.state.worker, lastname: e},
                           errors: {...this.state.errors, lastname: ""}
                         })}
                         label={I18n.get().WorkersRegistry.surname} defaultValue={this.props.model.lastname}
                         placeholder={I18n.get().WorkersRegistry.surname}/>
              </div>
              <div className={"w-h"}>
              <Select label={I18n.get().WorkersRegistry.gender}
                      className={"gender-select"} items={WorkerModal.gender}
                      defaultValue={WorkerModal.gender.indexOf(this.state.worker.gender)}
                      onChange={(index) => this.setState({
                        worker: {
                          ...this.state.worker,
                          gender: WorkerModal.gender[index]
                        }
                      })} disabled={false}/>
              </div>
              <div className={"w-h"}>
              <GfDatePicker label={I18n.get().WorkersRegistry.birthdate}
                            onChange={(birthdate) => {
                              this.setState({
                                worker: {...this.state.worker, birthdate},
                                errors: {...this.state.errors, birthdate: ""}
                              })
                            }}
                            date={this.props.model.birthdate}
                            maxDate={DateTime.now()}
                            errorMessage={this.state.errors.birthdate}/>
              </div>
              <TextInput className={"text-input"} errorMessage={this.state.errors.cf}
                         onChange={(e) => this.setState({
                           worker: {...this.state.worker, cf: e},
                           errors: {...this.state.errors, cf: ""}
                         })}
                         label={I18n.get().WorkersRegistry.cf} defaultValue={this.props.model.cf}
                         placeholder={I18n.get().WorkersRegistry.cf}/>
              <Select label={I18n.get().WorkersRegistry.height}
                      className={"height-select"} items={this.heights}
                      defaultValue={this.HEIGHT_OPTIONS.indexOf(this.state.worker.height)}
                      onChange={(index) => {
                        this.setState({
                          worker: {
                            ...this.state.worker,
                            height: this.HEIGHT_OPTIONS[index]
                          }
                        })
                      }} disabled={false}/>
              <Select label={I18n.get().WorkersRegistry.company}
                      className={"height-select"} items={WorkerModal.companies}
                      defaultValue={WorkerModal.companies.indexOf(this.props.model.company)}
                      onChange={(index) => this.setState({
                        worker: {
                          ...this.state.worker,
                          company: WorkerModal.companies[index]
                        }
                      })} disabled={false}/>

            </div>
            <div className={"buttons"}>
              <Button onClick={(event) => this.onSubmit(event)}
                      className={"add-btn"}>
                {this.props.type == ModalType.CREATE ? I18n.get().WorkersRegistry.confirmAddWorker :
                  I18n.get().WorkersRegistry.confirmModifyWorker}
              </Button>
              <Button secondary={true}
                      onClick={() => this.onCancel()}
                      className={"cancel-btn"}>
                {I18n.get().WorkersRegistry.cancelAddWorker}</Button>
            </div>
          </div>
        </form>
      </div>
    )
  }
}
