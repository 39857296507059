import React, {Component} from "react";
import "./RadioRegistryModal.scss";
import Button from "../../Button/Button";
import I18n from "../../../lib/I18n";
import CreatableSelect from 'react-select/creatable';
import {RadioCodeDto} from "../../../api/entity/RadioCodeDto";
import RadioCodeApi from "../../../api/radio/RadioCodeApi";

interface Props {
  title: string,
  radioCodes: RadioCodeDto[],
  selectedContainerVehicle: string,
  selectedRadioCodeId: number
  onSubmit: (containerVehicle: string, radioCodeId: number) => void
  onCancel: () => void
}

interface State {
  radioCodes: RadioCodeDto[]
  selectedRadioCode: RadioCodeDto
}

interface Selection {
  label: string,
  value: string
}

export class RadioRegistryModal extends Component<Props, State> {

  private radioRegistryApi: RadioCodeApi = new RadioCodeApi();

  public constructor(props) {
    super(props);
    const radioCodes = [this.radioRegistryApi.getEmptyRadioCode()]
      .concat(props.radioCodes);
    this.state = {
      radioCodes: radioCodes,
      selectedRadioCode: radioCodes.filter((rc) => rc.id == this.props.selectedRadioCodeId)[0]
    }
  }

  private saveNewRadio(newValue: Selection) {
    const value = Number(newValue.value);
    const isExistingAlready = !isNaN(value);
    if (isExistingAlready) {
      this.setState({selectedRadioCode: {id: value, name: newValue.label}});
    } else {
      this.setState({selectedRadioCode: {id: null, name: newValue.label}})
    }
  }

  private onSave() {
    this.radioRegistryApi.saveRadioCode({
      id: this.state.selectedRadioCode.id,
      name: this.state.selectedRadioCode.name
    }).then((response) => {
      if (response.status == "OK") {
        this.props.onSubmit(
          this.props.selectedContainerVehicle,
          response.content[0].id);
      }
    });
  }

  /************************************************
   * LIFECYCLE
   ************************************************/
  render() {
    return (
      <div className="RadioRegistry-Overlay">
        <div className="RadioRegistry">
          <h2>{I18n.get().RadioCodeRegistry.modal.title}</h2>
          <h3>{this.props.title}</h3>
          <div className="RadioRegistry-Body">
            <p>

            </p>
            <div>
              <label>{I18n.get().RadioCodeRegistry.table.radioCode}</label>
              <CreatableSelect closeMenuOnSelect={true}
                               onChange={(newValue) => this.saveNewRadio(newValue)}
                               options={this.state.radioCodes.map(option => {
                                 return {value: option.id, label: option.name}
                               })}
                               value={[this.state.selectedRadioCode].map(value => {
                                 return {value: value.id, label: value.name}
                               })}
              />
            </div>
          </div>
          <div className="RadioRegistry-Buttons">
            <Button onClick={() => this.onSave()}
                    className="RadioRegistry-OkButton">
              {I18n.get().RadioCodeRegistry.modal.modify}
            </Button>
            <Button secondary={true} onClick={() => this.props.onCancel()}
                    className="RadioRegistry-CancelButton">
              {I18n.get().MonthlyPlanning.cancelModal}
            </Button>
          </div>
        </div>
      </div>
    )
  }
}
