import React, {Component} from "react";
import "./SideBar.scss";


export interface Props {
  /** This defines additional classes for the Button */
  className: string,
  label: string,
  /** This dictates what the button will do on click */
  onOpen: () => void;
  onClose: () => void;
  isOpen: boolean;
}

interface State {
  isOpen: boolean;
  selection: string
}

export class SideBar extends Component<Props, State> {

  /************************************************
   * PROPS
   ************************************************/
  static defaultProps: Partial<Props> = {
    className: "",
    label: "",
    isOpen: false,
    onOpen: () => {
    },
    onClose: () => {
    },
    /*onToggleModify: () => {
    },
    sections: {
      registryDetails: {
        workerDto: WorkerUtils.getEmptyWorkerDto(),
        calculatedAge: 0
      }
    }*/
  };

  /************************************************
   * CONSTRUCTOR
   ************************************************/
  constructor(props: Props) {
    super(props);
    this.state = {selection: "", isOpen: false}
  }

  /************************************************
   * LIFECYCLE
   ************************************************/
  render() {
    //const workerDto = this.props.sections.registryDetails.workerDto;
    return (
      <div className={this.props.className + " SideBar " + (this.props.isOpen ? "open" : "")}>
        <div onClick={() => this.props.onClose()} className={"close"}>&times;</div>
        <div className={"SideBarContent"}>
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default SideBar;
