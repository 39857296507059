import React from "react";
import "./Spinner.scss";


export interface Props {
}

export default function Spinner(){
    return (
      <div className="Spinner"></div>
    )
}
