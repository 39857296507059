import {VehicleUnavailabilityDto} from "../VehicleUnavailabilityDto";
import {VehicleUnavailabilityResponseNt} from "./nt/VehicleUnavailabilityResponseNt";

export class VehicleUnavailabilityResponse {
  status: string;
  errors: string[];
  content: VehicleUnavailabilityDto[];

  constructor(responseNt: VehicleUnavailabilityResponseNt) {
    this.status = responseNt.status;
    this.errors = responseNt.errors;
    this.content = responseNt.content.map((vehicleUnavailabilityDto) =>
      new VehicleUnavailabilityDto(vehicleUnavailabilityDto))
  }

}
