import {DateTime} from "luxon";
import {MonthlyPlannedRowNt} from "./nt/MonthlyPlannedRowNt";

export interface DateCodePairDto {
  date: string;
  code: string;
  imported: string;
}

export class MonthlyPlannedRow {
  workerId: number;
  fullName: string;
  startDate: DateTime;
  dateToCode: DateCodePairDto[];

  constructor(mtp: MonthlyPlannedRowNt) {
    this.workerId = mtp.workerId;
    this.startDate = DateTime.fromJSDate(mtp.startDate)
    this.fullName = mtp.fullName;
    this.dateToCode = mtp.dateToCode;
  }

}
