import AuthorizeApi from "../AuthorizeApi";
import {VehiclePlanningResponse} from "../entity/response/VehiclePlanningResponse";
import GfDateUtils from "../../components/DatePicker/GfDateUtils";
import {DateTime} from "luxon";
import {VehicleDailyPlanningResponse} from "../entity/response/VehicleDailyPlanningResponse";
import {VehicleDailyPlanningResponseNt} from "../entity/response/nt/VehicleDailyPlanningResponseNt";
import {VehicleDailyPlanningDto} from "../entity/VehicleDailyPlanningDto";
import Time from "../../lib/utils/Time";
import {VehicleDailyPlanningDtoNt} from "../entity/nt/VehicleDailyPlanningDtoNt";

export default class VehiclePlanningApi {

  private base: string = '/daily-planning-vehicle';

  private authApi: AuthorizeApi = new AuthorizeApi();

  public async get(date: DateTime): Promise<VehiclePlanningResponse> {
    let response: VehiclePlanningResponse;
    const dateFormat = date.toFormat(GfDateUtils.STORED_DATE_FORMAT);
    await this.authApi.get<VehiclePlanningResponse>(`${this.base}/planning/` + dateFormat).then((resp) => {
      response = resp;
    });
    return response;
  }

  public async getLast(): Promise<VehiclePlanningResponse> {
    let response: VehiclePlanningResponse;
    await this.authApi.get<VehiclePlanningResponse>(`${this.base}/planning/last`).then((resp) => {
      response = resp;
    });
    return response;
  }

  public async getDailyPlanningVehicleByMonth(date: DateTime) {
    let response: VehicleDailyPlanningResponse;
    const dateFormat = date.toFormat(GfDateUtils.STORED_DATE_FORMAT);
    await this.authApi.get<VehicleDailyPlanningResponseNt>(`${this.base}/month/` + dateFormat)
      .then((resp) => {
        response = new VehicleDailyPlanningResponse(resp);
      });
    return response;
  }

  public async create(entry: VehicleDailyPlanningDto) {
    let response: VehicleDailyPlanningResponse;
    await this.authApi.post<VehicleDailyPlanningDto, VehicleDailyPlanningResponseNt>(`${this.base}`, entry)
      .then((resp) => {
        response = new VehicleDailyPlanningResponse(resp);
      });
    return response;
  }

  public async update(entry: VehicleDailyPlanningDto) {
    let response: VehicleDailyPlanningResponse;
    await this.authApi.put<VehicleDailyPlanningDto, VehicleDailyPlanningResponseNt>(`${this.base}`, entry)
      .then((resp) => {
        response = new VehicleDailyPlanningResponse(resp);
      });
    return response;
  }

  public async delete(id: number) {
    let response: VehicleDailyPlanningResponse;
    await this.authApi.delete<void, VehicleDailyPlanningResponseNt>(`${this.base}/${id}`)
      .then((resp) => {
        response = new VehicleDailyPlanningResponse(resp);
      });
    return response;
  }

  public async getDailyPlanningVehicleByDay(date: DateTime): Promise<VehicleDailyPlanningDtoNt[]> {
    let response: VehicleDailyPlanningResponse;
    const dateFormat = date.toFormat(GfDateUtils.STORED_DATE_FORMAT);
    await this.authApi.get<VehicleDailyPlanningResponseNt>(`${this.base}/day/` + dateFormat)
      .then((resp) => {
        response = new VehicleDailyPlanningResponse(resp);
      });
    const dailyVehicles = [];
    for (const d of response.content) {
      if (d.date == date.toFormat(GfDateUtils.STORED_DATE_FORMAT)) {
        dailyVehicles.push(d)
      }
    }
    for (const d of response.content) {
      if (d.date == date.minus({day: 1})
        .toFormat(GfDateUtils.STORED_DATE_FORMAT)) {
        if (d.vehicleStartTime && d.vehicleEndTime &&
          Time.hourStringToNumber(d.vehicleStartTime) >=
          Time.hourStringToNumber(d.vehicleEndTime)) {
          d.vehicleStartTime = "00:00:00"
          // d.vehicleEndTime = "00:00:00"
          d.date = date.toFormat(GfDateUtils.STORED_DATE_FORMAT)
          d.previousDay = true;
          dailyVehicles.push(d)
        }
      }
    }
    return dailyVehicles;
  }

}
