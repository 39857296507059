import {NeedVehicleDtoNt} from "./nt/NeedVehicleDtoNt";

export class NeedVehicleDto {
  id: number;
  category: string;
  fromTime: string;
  toTime: string;
  numberVehiclesWorkdays: string[];
  numberVehiclesFestive: string[];
  needId: number;
  publishing: string;
  parkingDescription: string;
  convention: string;

  constructor(dtoNt: NeedVehicleDtoNt) {
    this.id = dtoNt.id;
    this.category = dtoNt.category;
    this.fromTime = dtoNt.fromTime;
    this.toTime = dtoNt.toTime;
    this.numberVehiclesFestive = dtoNt.numberVehiclesFestive.map(a => a.toString());
    this.numberVehiclesWorkdays = dtoNt.numberVehiclesWorkdays.map(a => a.toString());
    this.needId = dtoNt.needId;
    this.parkingDescription = dtoNt.parkingDescription;
    this.publishing = dtoNt.publishing;
    this.convention = dtoNt.convention;
  }
}
