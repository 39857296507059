import React, {Component} from "react";
import "./VehicleServiceTurnModal.scss";
import I18n from "../../../lib/I18n";
import Button from "../../Button/Button";
import {DateTime} from "luxon";
import Select from "react-select";
import {VehicleDailyPlanningDto} from "../../../api/entity/VehicleDailyPlanningDto";
import {Vehicle} from "../../../pages/VehicleManagement/MonthlyVehicleTimeLine/MonthlyVehicleTimeLine";
import TimeInputPicker from "../../TimeInputPicker/TimeInputPicker";
import {VehicleServiceTurnModalError, VehicleServiceTurnUtils} from "./VehicleServiceTurnUtils";
import GfDateUtils from "../../DatePicker/GfDateUtils";
import {DailyContainerVehicle} from "../../../api/entity/response/nt/ContainerVehicleResponseNt";
import {WorkerServiceTurnModal} from "../WorkerServiceTurnModal/WorkerServiceTurnModal";
import {NeedVehicleDto} from "../../../api/entity/NeedVehicleDto";


interface SelectOption {
  label: string,
  value: string,
}

interface Props {
  radioMap: Map<number, string>,
  date: DateTime,
  vehicle: Vehicle
  records: VehicleDailyPlanningDto[]
  vehicleNeedMap: Map<number, NeedVehicleDto>
  containerVehicles: DailyContainerVehicle[]
  onSave: (created: VehicleDailyPlanningDto[],
           update: VehicleDailyPlanningDto[],
           deletedRecords: VehicleDailyPlanningDto[]) => void
  onCancel: () => void
}

interface State {
  records: VehicleDailyPlanningDto[],
  containerVehiclesPlusOffOptions: ContainerToRadio[]
  // Changes
  deletedRecords: VehicleDailyPlanningDto[]
  errors: VehicleServiceTurnModalError[]
}

interface ContainerToRadio {
  name: string
  startVehicleTime: string,
  endVehicleTime: string,
  radioId: number
}

export class VehicleServiceTurnModal extends Component<Props, State> {

  public static VEHICLE_LAV_SERVICE = "LAV";
  public static VEHICLE_OFF_SERVICE = "OFF";


  private TIME_OFF_COMBINATIONS: ContainerToRadio[] =
    [{name: VehicleServiceTurnModal.VEHICLE_LAV_SERVICE, radioId: 0, startVehicleTime: "", endVehicleTime: ""},
    {name: VehicleServiceTurnModal.VEHICLE_OFF_SERVICE, radioId: 0, startVehicleTime: "", endVehicleTime: ""}]
  private excludedContainers = WorkerServiceTurnModal.TIME_OFF_COMBINATION.map(t => t.serviceOrContainerVehicle)
  private timeOffContainers = [VehicleServiceTurnModal.VEHICLE_LAV_SERVICE, VehicleServiceTurnModal.VEHICLE_OFF_SERVICE];
  private vehicleTurnUtils = new VehicleServiceTurnUtils();

  constructor(props) {
    super(props);
    this.state = {
      containerVehiclesPlusOffOptions: [],
      deletedRecords: [],
      records: [], errors: []
    }
  }

  async componentDidMount() {
    this.setState({
      containerVehiclesPlusOffOptions: Array.from(new Set(this.props.containerVehicles.map(r => {
        const startEnd = this.getNeed(r.containervehicle);
        return {
          name: r.containervehicle,
          radioId: (r.radiocodeid) ? r.radiocodeid : 0,
          startVehicleTime: startEnd.start,
          endVehicleTime: startEnd.end
        }
      })
        .filter(r => this.excludedContainers.indexOf(r.name) == -1)
        .filter(r => this.timeOffContainers.indexOf(r.name) == -1)
        .sort()
        .concat(this.TIME_OFF_COMBINATIONS))),
      errors: this.props.records.map(u => this.vehicleTurnUtils.getEmptyError()),
      records: this.props.records.filter(r => !r.previousDay)
    })
  }

  addEntry() {
    let {records, containerVehiclesPlusOffOptions, errors} = this.state;
    const defaultContainer = containerVehiclesPlusOffOptions[0];
    records.push(VehicleDailyPlanningDto.getEmpty(
      this.props.date.toFormat(GfDateUtils.STORED_DATE_FORMAT),
      this.props.vehicle.id, defaultContainer.startVehicleTime, defaultContainer.endVehicleTime,
      defaultContainer.name, defaultContainer.radioId));
    errors.push(this.vehicleTurnUtils.getEmptyError());
    this.setState({records, errors});
  }

  deleteAt(index: number) {
    let {deletedRecords, records, errors} = this.state;
    const deletedElement = records.splice(index, 1)[0];
    if (deletedElement.id != null) {
      deletedRecords.push(deletedElement);
    }
    errors.splice(index, 1);
    this.setState({deletedRecords, records, errors});
  }


  private async changeContainerVehicleLists(itemIndex: number, selectOption: SelectOption) {
    const {records} = this.state;
    const containerToRadios = this.state.containerVehiclesPlusOffOptions.filter(ct=> ct.name == selectOption.value);
    if(containerToRadios.length == 0){
      throw new Error("No value found");
    }
    const containerRadioElected = containerToRadios[0];
    records[itemIndex].containerVehicle = containerRadioElected.name;
    records[itemIndex].radiocodeid = containerRadioElected.radioId;
    records[itemIndex].vehicleStartTime = containerRadioElected.startVehicleTime;
    records[itemIndex].vehicleEndTime = containerRadioElected.endVehicleTime;
    records[itemIndex].service = this.getServiceToRecord(containerRadioElected.name);
    this.setState({records})
  }

  private getServiceToRecord(serviceOrContainerVehicle: string) {
    if(!serviceOrContainerVehicle) return "";
    const splitContainerVehicle = serviceOrContainerVehicle.split("_");
    const isContainerVehicleSelected = splitContainerVehicle.length == 3;
    if(isContainerVehicleSelected) {
      return splitContainerVehicle[0].toUpperCase();
    }
    return "";
  }

  private async changeFromTime(itemIndex: number, value: string) {
    let {records} = this.state;
    const modifiedItem = records[itemIndex];
    let id = modifiedItem.id;
    if (id == null) {
      // newly created vehicles
      modifiedItem.vehicleStartTime = value;
    } else {
      records.filter(r => r.id == id)[0].vehicleStartTime = value;
    }
    this.setState({records})
  }

  private async changeToTime(itemIndex: number, value: string) {
    let {records} = this.state;
    const modifiedItem = records[itemIndex];
    let id = modifiedItem.id;
    if (id == null) {
      // newly created vehicles
      modifiedItem.vehicleEndTime = value;
    } else {
      records.filter(r => r.id == id)[0].vehicleEndTime = value;
    }
    this.setState({records})
  }

  private triggerClose() {
    this.props.onCancel()
  }

  private getLabel(radioCode: string, containerVehicle: string) {
    const splitContainerVehicle = containerVehicle.split("_");
    if (splitContainerVehicle.length > 2) {
      const vehicleId = parseInt(splitContainerVehicle[1]);
      const vehicleNumber = parseInt(splitContainerVehicle[2]);

      if (isNaN(vehicleId)) throw new Error();
      if (isNaN(vehicleNumber)) throw new Error();

      const containerName = radioCode ? radioCode : containerVehicle;
      const electedVehicle = this.props.vehicleNeedMap.get(vehicleId);
      if (electedVehicle) {
        const category = electedVehicle.category;
        const timeLabel = `${electedVehicle.fromTime}-${electedVehicle.toTime}`;
        return `${containerName} ${category} ${timeLabel} (${vehicleNumber})`;
      }
      return containerVehicle;
    }
    return containerVehicle;
  }

  private getNeed(containerVehicle: string) : {start: string, end: string} {
    const splitContainerVehicle = containerVehicle.split("_");
    if (splitContainerVehicle.length > 2) {
      const vehicleId = parseInt(splitContainerVehicle[1]);
      const electedVehicle = this.props.vehicleNeedMap.get(vehicleId);
      if (electedVehicle) {
        return {start: electedVehicle.fromTime, end: electedVehicle.toTime};
      }
    }
    return {start: "", end: ""};
  }


  private onSave() {
    const vehicleServiceTurnModalErrors = this.vehicleTurnUtils.getErrors(this.state.records);

    this.setState({errors: vehicleServiceTurnModalErrors});
    let isError = vehicleServiceTurnModalErrors.map(er => this.vehicleTurnUtils.isError(er)).filter(er => er).length > 0;
    if (isError) {
      return;
    }
    let created = this.state.records.filter((r) => r.id == null);
    let createAsRecord: VehicleDailyPlanningDto[] = created.map(c => {
      return {
        id: null,
        containerVehicle: c.containerVehicle,
        serviceCategory: this.props.vehicle.category,
        vehicleStartTime: c.vehicleStartTime,
        vehicleEndTime: c.vehicleEndTime,
        manuallyCreated: true,
        date: c.date,
        vehicleId: this.props.vehicle.id,
        serviceStartTime: c.vehicleStartTime,
        serviceEndTime: c.vehicleEndTime,
        vehicleShortName: this.props.vehicle.name,
        previousDay: false,
        service: c.containerVehicle.split("_")[0],
        radiocodeid: c.radiocodeid
      }
    });
    let deletedRecords = this.state.deletedRecords.filter((dr) => dr.id != null);
    this.props.onSave(createAsRecord, this.state.records.filter(r => r.id != null), deletedRecords);
  }

  private getContainerOptions() {
    return Array.from(
      new Set(this.state.containerVehiclesPlusOffOptions
        .map(s => {
          return {
            label: this.getLabel(this.getRadioCode(s.radioId), s.name),
            value: s.name
          }
        })));
  }

  private getRadioCode(radioCodeId: number) {
    if (radioCodeId == 0) return ""
    return this.props.radioMap.get(radioCodeId);
  }

  /************************************************
   * LIFECYCLE
   ************************************************/
  render() {
    return (
      <div className="VehicleServiceTurnModal-Overlay">
        <div className="VehicleServiceTurnModal">
          <h2>
            {this.props.vehicle.name}
          </h2>
          <div className="WorkerServiceTurnModal-Body">
            {this.state.records.map((r, i) => {
              const radioCode = this.getRadioCode(r.radiocodeid);
              return (
                <div key={r.id + "_" + i}>
                  <div className={"row"}>
                    <div className={"w-h-g w-h"}>
                      <label
                        className={"TextInput-label full"}>{I18n.get().RadioCodeRegistry.table.containerVehicle}</label>
                      <Select className={"row-w height"}
                              onChange={containerValue => this.changeContainerVehicleLists(i, containerValue)}
                              options={this.getContainerOptions()}
                              value={{label: this.getLabel(radioCode, r.containerVehicle), value: r.containerVehicle}}
                      />
                    </div>
                    <div className={"w-h"}>
                      <TimeInputPicker
                        onCorrectChange={(time) => {
                          this.changeFromTime(i, time);
                        }}
                        onChange={(e) => {
                          let {errors} = this.state;
                          errors[i].fromTime = e;
                        }}
                        label={I18n.get().VehicleUnavailabilityModal.from}
                        placeholder={"00:00"}
                        className={"row-w"}
                        defaultValue={this.state.records[i].vehicleStartTime ?
                          VehicleDailyPlanningDto.getTimeString(this.state.records[i].vehicleStartTime) : ""}
                        error={this.state.errors[i].fromTime != ""}
                      />
                    </div>
                    <div className={"w-h"}>
                      <TimeInputPicker
                        onCorrectChange={(time) => {
                          this.changeToTime(i, time);
                        }}
                        onChange={(e) => {
                          let {errors} = this.state;
                          errors[i].toTime = e;
                        }}
                        label={I18n.get().VehicleUnavailabilityModal.to}
                        placeholder={"00:00"}
                        className={"row-w"}
                        defaultValue={this.state.records[i].vehicleEndTime ?
                          VehicleDailyPlanningDto.getTimeString(this.state.records[i].vehicleEndTime) : ""}
                        error={this.state.errors[i].toTime != ""}
                      />
                    </div>
                      <div className="close" onClick={() => {
                        this.deleteAt(i)
                      }}>×
                      </div>
                  </div>
                </div>
              )
            })}
            <div className={"add-btn"} onClick={() => this.addEntry()}>
              <span className={"icon"}>&#43;</span>
              <span>{I18n.get().DailyPlanning.addBtn}</span>
            </div>
          </div>

          <div className="VehicleServiceTurnModal-Buttons">
            <Button onClick={() => this.onSave()}
                    className="WorkerServiceTurnModal-OkButton">
              Ok
            </Button>
            <Button onClick={() => this.triggerClose()}
                    secondary={true}
                    className="ConfirmModal-CancelButton">
              {I18n.get().MonthlyPlanning.cancelModal}
            </Button>
          </div>
        </div>
      </div>
    )
  }
}
