import React from "react";
import Icon, {Props} from "./Icon";

export default class IconCalendarVehicle extends Icon {
  static defaultProps: Partial<Props> = {
    height: 18,
    width: 18,
    color: "#2E3A59"
  };

  render() {
    return (
      <svg className="IconClock"
           width={this.props.width}
           height={this.props.height}
           viewBox="0 0 18 18">
        <path fill={this.props.color}
              d="M6 8v1.333H4.667V8H6zm0 2.667V12H4.667v-1.333H6zM7.333 8v1.333h1.334V8H7.333zm1.334 2.667V12H7.333v-1.333h1.334zM10 8v1.333h1.333V8H10zm1.333 2.667V12H10v-1.333h1.333zm1.334 4H3.333c-.735 0-1.333-.598-1.333-1.334V4c0-.735.598-1.333 1.333-1.333h1.334V1.333H6v1.334h4V1.333h1.333v1.334h1.334C13.402 2.667 14 3.265 14 4v9.333c0 .736-.598 1.334-1.333 1.334zm0-9.334V4H3.333v1.333h9.334zm0 1.334v6.666H3.333V6.667h9.334z" mask="url(#hu5qbrskzc)"/>
      </svg>
    );
  }
}
