import {DateTime} from "luxon";
import {VehicleUnavailabilityNt} from "./nt/VehicleUnavailabilityNt";
import GfDateUtils from "../../components/DatePicker/GfDateUtils";

export class VehicleUnavailabilityDto {

  id: number;
  vehicleId: number;
  fromDate: DateTime;
  toDate: DateTime;
  fromTime: string;
  toTime: string;
  motivation: string;
  description: string;

  constructor(dtoNt: VehicleUnavailabilityNt) {
    this.id = dtoNt.id;
    this.vehicleId = dtoNt.vehicleId;
    this.fromTime = dtoNt.fromTime;
    this.fromDate = DateTime.fromFormat(dtoNt.fromDate, GfDateUtils.STORED_DATE_FORMAT);
    this.toDate = DateTime.fromFormat(dtoNt.toDate,  GfDateUtils.STORED_DATE_FORMAT);
    this.toTime = dtoNt.toTime;
    this.motivation = dtoNt.motivation;
    this.description = dtoNt.description;
  }
}
