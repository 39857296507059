import IconError from "../../icons/IconError";
import React from "react";
import "./InfoBar.scss"

interface Props{
  type:"alert"|"warning"
  text:string
}

export default function InfoBar(props:Props) {
  return <div className={"InfoBar "+props.type}>
    <IconError className={"icon-warning"}color={"#df5858"}/> {props.text}
  </div>
}
