import React from 'react';
import {Redirect, Route, useLocation} from 'react-router-dom';
import TopMenu from "../components/TopMenu/TopMenu";
import LoginApi from "../api/login/LoginApi";
import {RouteComponentProps} from "react-router-dom";

export default function PrivateRoute({component: Component, ...rest}) {
  const location = useLocation();
  return (
    <Route
      {...rest}
      render={(props:RouteComponentProps) =>
        true ? (
          <div>
            <TopMenu
              history={props.history}
              location={props.location}
            />
            {/*<div className="Dashboard">*/}
            <Component {...props} />
            {/*</div>*/}
          </div>
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: {from: location},
            }}
          />
        )
      }
    />
  );
}
