import {MonthlyUnavailabilityRowDto} from "../../MonthlyUnavailabilityRowDto";
import {MonthlyUnavailabilityRowEntryNt} from "../../nt/MonthlyUnavailabilityRowEntryNt";
import {MonthlyUnavailabilityRowEntry} from "../../MonthlyUnavailabilityRowEntry";

export interface MonthlyUnavailabilityRowDtoNt {
  id: number;
  workerId: number;
  date: string;
  entries: MonthlyUnavailabilityRowEntryNt[];
}

export interface MonthlyPlannedUnavailabilityEntryResponseNt {
  status: string,
  errors: string[],
  content: MonthlyUnavailabilityRowEntryNt[],
  totalPages: number;
  currentPages: number;
  size: number;
  total: number;
}

export interface MonthlyPlannedUnavailabilityResponseNt {
  status: string,
  errors: string[],
  content: MonthlyUnavailabilityRowDtoNt[],
  totalPages: number;
  currentPages: number;
  size: number;
  total: number;
}

export class MonthlyPlannedUnavailabilityResponse {
  status: string;
  errors: string[];
  content: MonthlyUnavailabilityRowDto[];
  totalPages: number;
  currentPages: number;
  size: number;
  total: number;

  constructor(mt: MonthlyPlannedUnavailabilityResponseNt) {
    this.status = mt.status;
    this.errors = mt.errors;
    this.content = mt.content.map(a=> new MonthlyUnavailabilityRowDto(a))
    this.totalPages = mt.totalPages;
    this.currentPages = mt.currentPages;
    this.size = mt.size;
    this.total = mt.total;
  }
}

export class MonthlyPlannedUnavailabilityEntryResponse {
  status: string;
  errors: string[];
  content: MonthlyUnavailabilityRowEntry[];
  totalPages: number;
  currentPages: number;
  size: number;
  total: number;

  constructor(mt: MonthlyPlannedUnavailabilityEntryResponseNt) {
    this.status = mt.status;
    this.errors = mt.errors;
    this.content = mt.content.map(a=> new MonthlyUnavailabilityRowEntry(a))
    this.totalPages = mt.totalPages;
    this.currentPages = mt.currentPages;
    this.size = mt.size;
    this.total = mt.total;
  }
}

