import React, {Component} from "react";
import "./Pagination.scss"
import ReactPaginate from "react-paginate";

interface Props {
  onPageChange: (page: number) => void;
  totalPages: number,
  elementsPerPage: number,
  page: number,
}

interface State {
  currentPage: number
}

export class Pagination extends Component<Props, State> {
  render() {
    return <div className={"Pagination-Container"}>
      <ReactPaginate
        className={"Pagination"}
        breakLabel="..."
        nextLabel=">"
        onPageChange={(page) => this.props.onPageChange(page.selected)}
        pageRangeDisplayed={1}
        marginPagesDisplayed={1}
        pageCount={Math.ceil(this.props.totalPages / this.props.elementsPerPage)}
        forcePage={this.props.page}
        previousLabel="<"
        renderOnZeroPageCount={null}
      />
    </div>
  }
}
