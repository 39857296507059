import React, {Component} from "react";
import "./ConfirmModal.scss";
import Button from "../../Button/Button";
import I18n from "../../../lib/I18n";

interface Props {
  title: string,
  body: string,
  onClick: () => void
  onCancel: () => void
}

export class ConfirmModal extends Component<Props> {
  /************************************************
   * LIFECYCLE
   ************************************************/
  render() {
    return (
      <div className="ConfirmModal-Overlay">
        <div className="ConfirmModal">
          <h2>{this.props.title}</h2>
          <div className="ConfirmModal-Body">
            <p>
              {this.props.body}
            </p>
          </div>
          <div className="ConfirmModal-Buttons">
            <Button onClick={() => this.props.onClick()}
                    className="ConfirmModal-OkButton">
              Ok
            </Button>
            <Button secondary={true} onClick={() => this.props.onCancel()}
                    className="ConfirmModal-CancelButton">
              {I18n.get().MonthlyPlanning.cancelModal}
            </Button>
          </div>
        </div>
      </div>
    )
  }
}
