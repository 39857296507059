import {WorkerContractDto} from "../../entity/WorkerContractDto";
import {WorkerContractModalErrors} from "../../../components/Modal/WorkerContractModal/WorkerContractModal";
import WorkerUtils from "../../../pages/WorkersRegistry/WorkerUtils";
import I18n from "../../../lib/I18n";

export default class ContractValidator {


  public static validate(workerContracts: WorkerContractDto[]): WorkerContractModalErrors[] {
    let workerUtils = new WorkerUtils();

    let errorContracts = workerContracts.map(() => workerUtils.getEmptyContractErrorState());
    let size = errorContracts.length;

    for (let i = 0; i < size; i++) {
      let subject = workerContracts[i];

      if (subject.contractType.length == 0) {
        errorContracts[i].contract = I18n.get().WorkersRegistry.errors.notValid;
      }

      if (subject.weekHour == 0 || !subject.weekHour) {
        errorContracts[i].weekHour = I18n.get().WorkersRegistry.errors.notValid;
      }

      // test the contract such that DFC1<DIC2
      for (let z = 0; z < size; z++) {
        if (i == z) {
          continue;
        }

        let otherContract = workerContracts[z];

        if (z < i) {
          // If the start date is before other end-dates AND
          // If the end date is greater than other start-dates
          if (subject.startDate.toMillis() <= otherContract.endDate.toMillis() &&
            subject.endDate.toMillis() >= otherContract.startDate.toMillis()) {
            errorContracts[i].endDate = I18n.get().WorkersRegistry.contract.previousErrorTimespan;
            errorContracts[i].startDate = I18n.get().WorkersRegistry.contract.previousErrorTimespan;
            errorContracts[z].endDate = I18n.get().WorkersRegistry.contract.previousErrorTimespan;
            errorContracts[z].startDate = I18n.get().WorkersRegistry.contract.previousErrorTimespan;
          }
        }
      }
    }
    return errorContracts;
  }

  static isAnyErrorsPresent(errors: WorkerContractModalErrors[]) {
    for (let i = 0; i < errors.length; i++) {
      let a = errors[i];
      if (a.endDate != "" || a.startDate != ""
        || (a.weekHour != "" && a.weekHour != "00:00") || a.contract != "") {
        return true;
      }
    }
    return false;
  }
}
