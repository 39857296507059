import AuthorizeApi from "../AuthorizeApi";
import {WorkerWeeklyUnResponse} from "../entity/response/WorkerWeeklyUnResponse";
import {WorkerWeeklyUnResponseNt} from "../entity/response/nt/WorkerWeeklyUnResponseNt";
import WorkerWeeklyUnDto from "../entity/WorkerWeeklyUnDto";
import {UnavailabilityRequest} from "../entity/request/UnavailabilityRequest";

export default class WorkerRegistryApi {

  private authApi: AuthorizeApi = new AuthorizeApi();

  private base: string = '/unavailability';

  public async getUnavailability(userId: number) {
    let workerResponse: WorkerWeeklyUnResponse;
    await this.authApi.get<WorkerWeeklyUnResponseNt>(this.base + "/" + userId)
        .then((w) => {
        workerResponse = WorkerRegistryApi.mapToUnavailabilityResponse(w)
      });
    return workerResponse;
  }

  private static mapToUnavailabilityResponse(w: WorkerWeeklyUnResponseNt): WorkerWeeklyUnResponse {
    return new WorkerWeeklyUnResponse(w);
  }

  async modify(modifiedUnavailabilityData: WorkerWeeklyUnDto[]) {
    let unavailabilityRequest = new UnavailabilityRequest(modifiedUnavailabilityData);
    let workerResponse: WorkerWeeklyUnResponse;
    await this.authApi.post<UnavailabilityRequest, WorkerWeeklyUnResponseNt>(this.base, unavailabilityRequest)
      .then((w) => {
        workerResponse = WorkerRegistryApi.mapToUnavailabilityResponse(w)
      });
    return workerResponse;
  }
}
