import React, {PureComponent} from "react";
import "./Button.scss";


export interface Props {
  className: string,
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled: boolean;
  flat: boolean;
  secondary: boolean;
}

export default class Button extends PureComponent<Props> {
  /************************************************
   * PROPS
   ************************************************/
  static defaultProps: Partial<Props> = {
    className: "",
    secondary: false,
    flat: false,
    onClick: () => {
    },
    disabled: false
  };

  /************************************************
   * CONSTRUCTOR
   ************************************************/
  constructor(props: Props) {
    super(props);
  }

  /************************************************
   * LIFECYCLE
   ************************************************/
  render() {
    return <button
      className={`Button ${this.props.secondary ? "secondary" : ""} ${this.props.flat ? "flat" : ""} ${this.props.disabled ? "disabled" : ""}  ${this.props.className}`}
      disabled={this.props.disabled}
      onClick={(event) => {
        this.handleOnClick(event)
      }}>
      {this.props.children}
    </button>
  }

  private handleOnClick(event: React.MouseEvent<HTMLButtonElement>) {
    if (this.props.disabled) return;
    this.props.onClick(event)
  }
}
