import * as React from 'react';
import * as ReactDOM from 'react-dom';
import Application from './Application';
import I18n from "./lib/I18n";

const i18n = config.i18n;
const i18nConfig = i18n;

i18nConfig.lang = I18n.getLanguage();

Promise.all([I18n.setConfig(i18nConfig)])
  .then(() => ReactDOM.render(React.createElement(Application), document.getElementById('root')))
  .catch(err=> console.error(err));
