import {ReactNode} from "react";

export interface InformationModalProps{
  title: string,
  okButtonLabel?:string,
  cancelButtonLabel?:string,
  onOk?: () => void;
  onCancel?: () => void;
  body:ReactNode
}

export default class InformationModalController{
  /**************************************************
   * CONSTRUCTOR
   **************************************************/
  protected constructor() {}
  static getInstance(){
    if(!this.instance)
      this.instance = new InformationModalController();
    return this.instance
  }
  /**************************************************
   * PUBLIC FUNCTIONS
   **************************************************/
  public listenToPropsChange(callback){
    this.propsChangeCallback = callback
  }

  public show(props:InformationModalProps){
    if(this.propsChangeCallback)
      this.propsChangeCallback(props)
  }

  public hide(){
    if(this.propsChangeCallback)
      this.propsChangeCallback(null)
  }

  /**************************************************
   * VARIABLES
   **************************************************/
  private static instance: InformationModalController;
  private propsChangeCallback:(props:InformationModalProps)=>void = null
}
