import React, {Component} from "react";
import "./KBMenuModal.scss";
import I18n from "../../lib/I18n";

interface Props {
  onCreate: () => void
  onDelete: () => void
  modeType: KbModeType
  positionX: number
  positionY: number
  isHidden: boolean
}

export enum KbModeType {
  CREATE, DELETE
}

export class KBMenuModal extends Component<Props> {
  /************************************************
   * LIFECYCLE
   ************************************************/
  render() {
    return (
      <div className="KBMenuModal-Overlay" style={{
        "left": this.props.positionX,
        "top": this.props.positionY,
        "display": this.props.isHidden ? "none" : "inline"
      }}>
        <div className="KBMenuModal">
          <div className="KBMenuModal-Buttons">
            {this.props.modeType == KbModeType.CREATE ?
              <div onClick={() => this.props.onCreate()}>{I18n.get().VehicleModal.add}</div> : null}
            {this.props.modeType == KbModeType.DELETE ?
              <div onClick={() => this.props.onDelete()}>{I18n.get().VehicleModal.delete}</div> : null}
          </div>
        </div>
      </div>
    )
  }
}
