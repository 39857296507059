import React from "react";
import "./WorkerUnavailabilitySection.scss";
import WorkerWeeklyUnDto from "../../../api/entity/WorkerWeeklyUnDto";
import I18n from "../../../lib/I18n";
import IconCalendarUn from "../../../icons/IconCalendarUn";
import IconClock from "../../../icons/IconClock";
import GfDateUtils from "../../../components/DatePicker/GfDateUtils";

export interface Props {
  onToggleModify: (unavailabilityDtos) => void;
  weekUnDtos?: WorkerWeeklyUnDto[]
}

interface State {
  weeklyUnDtos: WorkerWeeklyUnDto[],
  weekendUnDtos: WorkerWeeklyUnDto[]
}

export class WorkerRegistryDetails extends React.PureComponent<Props, State> {

  static defaultProps: Partial<Props> = {
    onToggleModify: () => {
    }
  };

  private static isConditionToHideEntry(a: WorkerWeeklyUnDto) {
    return a.weekdays.length == 0 || a.fromTime == a.toTime;
  }

  private formatToDate(value: string): string {
    if (value == "00:00") return "24:00"
    return value;
  }

  render() {
    const weekdays = GfDateUtils.getWeekDaysShort();
    const weekend = GfDateUtils.getWeekendDaysShort();
    let weekdaysDto = this.props.weekUnDtos.slice(0, 3);
    let weekendDaysDto = this.props.weekUnDtos.slice(3, 5);

    // if at least one entry, then show the section.
    let isSectionToShow = (this.props.weekUnDtos.filter(a => a.weekdays.length > 0).length) > 0;
    let isWeeklyToHide = (weekdaysDto.filter(a => WorkerRegistryDetails.isConditionToHideEntry(a)).length) == GfDateUtils.WEEK_ENTRIES;
    let isWeekendToHide = (weekendDaysDto.filter(a => WorkerRegistryDetails.isConditionToHideEntry(a)).length) == GfDateUtils.WEEKEND_ENTRIES;

    // console.log(weekendDaysDto.filter(a => WorkerRegistryDetails.isConditionToHideEntry(a)));
    return (
      <div className={"WorkerUnavailabiltySection"}>
        <div className={"details"}>
          <div className={"registrySubtitle"}>
            <span className={"subtitle"}>{I18n.get().WorkersRegistry.sidebar.unavailability} </span>
            <span className={"actionButton"} onClick={() => this.props.onToggleModify(this.props.weekUnDtos)}>
                {I18n.get().ActionButton.modify}
              </span>
          </div>
          {isSectionToShow ? (<div className={"registryDetails"}>

            {/* Weekdays */}

            {!isWeeklyToHide ? (
              <div>
                <div className={"row"}>
                  <div className={"lbl"}>
                    <IconCalendarUn/>
                    {weekdaysDto.map(a => {
                      if (WorkerRegistryDetails.isConditionToHideEntry(a)) {
                        return <div key={a.id}/>
                      } else {
                        let days = a.weekdays.sort().map(wd => weekdays[wd]);
                        let joinedDays = days.join(", ");
                        return <label key={a.id} className={"weekday-entry"}>
                          {joinedDays}
                        </label>;
                      }
                    })
                    }
                  </div>
                </div>
                <div className={"row"}>
                  <div className={"lbl"}>
                    <IconClock/>
                    {weekdaysDto.map(a => {
                        if (WorkerRegistryDetails.isConditionToHideEntry(a)) {
                          return <div key={a.id}/>
                        } else {
                          return <div key={a.id} className={"week-days time"}>
                            <label key={a.id}>{a.fromTime} - {this.formatToDate(a.toTime)}</label>
                          </div>
                        }
                      }
                    )}
                  </div>
                </div>
              </div>) : null}

            {/* Weekend */}
            {!isWeekendToHide ? (<div>
              <div className={"row"}>
                <div className={"lbl"}>
                  <IconCalendarUn color={"#df5858"}/>
                  {
                    weekendDaysDto.map(a => {
                        let days = a.weekdays.sort().map(wd => weekend[wd - (GfDateUtils.WEEK_ENTRIES + GfDateUtils.WEEKEND_ENTRIES)]);
                        let joinedDays = days.join(", ");
                        if (WorkerRegistryDetails.isConditionToHideEntry(a)) {
                          return <div key={a.id}/>
                        } else {
                          return <div key={a.id} className={"week-days"}>
                            <label key={a.id} className={"weekday-entry"}>
                              {joinedDays}
                            </label>
                          </div>
                        }
                      }
                    )
                  }
                </div>
              </div>
              <div className={"row"}>
                <div className={"lbl"}>
                  <IconClock/>
                  {weekendDaysDto.sort().map(a => {
                      if (WorkerRegistryDetails.isConditionToHideEntry(a)) {
                        return <div key={a.id}/>
                      }
                      return <div key={a.id} className={"week-days time"}>
                        <label key={a.id}>{a.fromTime} - {this.formatToDate(a.toTime)}</label>
                      </div>
                    }
                  )}
                </div>
              </div>
            </div>) : null}
          </div>) : null}
        </div>
      </div>
    )
  }
}

export default WorkerRegistryDetails;
