import React, {BaseSyntheticEvent, PureComponent} from "react";
import "./TitleModal.scss";
import Button from "../../Button/Button";
import I18n from "../../../lib/I18n";
import {WorkerDto} from "../../../api/entity/WorkerDto";
import Select from "react-select";
import ShiftUtils from "../../../utils/ShiftUtils";

export interface Props {
  className: string,
  model: WorkerDto,
  onSubmit: (workerDto: WorkerDto) => void;
  onCancel: () => void;
  disabled: boolean;
}

export interface State {
  model: WorkerDto,
  entriesRole: Entry[]
  entriesCircuit: Entry[]
}

export interface Entry {
  value: string,
  label: string
}

export class TitleModal extends PureComponent<Props, State> {

  private shiftUtils = new ShiftUtils();

  /************************************************
   * PROPS
   ************************************************/
  static defaultProps: Partial<Props> = {
    className: "",
    onCancel: () => {
    },
    disabled: false,
  };

  /************************************************
   * CONSTRUCTOR
   ************************************************/
  constructor(props: Props) {
    super(props);
    this.state = {
      model: props.model,
      entriesRole: this.shiftUtils.ROLE_DTO.map((cat) => {
        return {value: cat, label: this.shiftUtils.resolveLabelForRole(cat)}
      }),
      entriesCircuit: this.shiftUtils.CIRCUIT_DTO.map((cat) => {
        return {value: cat, label: cat}
      })
    }
  }

  onCancel() {
    this.props.onCancel();
  }

  async onSubmit(event: BaseSyntheticEvent) {
    event.preventDefault();
    this.props.onSubmit(this.state.model)
  }

  private onRoleChange(e : Entry) {
    this.setState({
      model: {...this.state.model, role: e.value}
    })
  }

  private onCircuitChange(e : Entry) {
    this.setState({
      model: {...this.state.model, circuit: e.value}
    })
  }

  /************************************************
   * LIFECYCLE
   ************************************************/
  render() {
    return (
      <div className={"Modal TitleModal " + this.props.className}>
        <form onSubmit={(event) => this.onSubmit(event)}>
          <div>
            <h1>
              {I18n.get().WorkersRegistry.modifyUserDetails}
            </h1>
          </div>
          <div>
            <h2>
              {I18n.get().WorkersRegistry.modifyRole}
            </h2>
            <Select className={"Select"}
                    onChange={(e) => this.onRoleChange(e)}
                    options={this.state.entriesRole}
                    value={{
                      label: this.shiftUtils.resolveLabelForRole(this.state.model.role),
                      value: this.state.model.role
                    }}
            />
          </div>
          <div>
            <h2>
              {I18n.get().WorkersRegistry.modifyCircuit}
            </h2>
            <Select className={"Select"}
                    onChange={(e) => this.onCircuitChange(e)}
                    options={this.state.entriesCircuit}
                    value={{
                      label: this.state.model.circuit,
                      value: this.state.model.circuit
                    }}
            />
          </div>
          <div>
            <div className={"buttons"}>
              <Button onClick={(event) => this.onSubmit(event)}
                      className={"add-btn"}>
                {I18n.get().WorkersRegistry.modifyWorker}
              </Button>
              <Button secondary={true}
                      onClick={() => this.onCancel()}
                      className={"cancel-btn"}>
                {I18n.get().WorkersRegistry.cancelAddWorker}</Button>
            </div>
          </div>
        </form>
      </div>
    )
  }
}
