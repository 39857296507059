import React, {Component} from "react";

interface Props{
  size:number
}

export default class IconLoading extends Component<Props> {
  static defaultProps: Partial<Props> = {
    size: 40,
  };

  render() {
    const height = this.props.size;
    const width = this.props.size*3;

    return <svg className="IconLoading"
         width={width}
         height={height}
         viewBox={`0 0 ${width} ${height}`}>
      <circle cx={width/8} cy={width/8} r={width/8} fill={"#09a7ef"}>
        <animate attributeName="r" from={width/8} to={width/8}
                 begin="0s" dur="0.8s"
                 values={`${width/8};${width/16};${width/8}`} calcMode="linear"
                 repeatCount="indefinite"/>
        <animate attributeName="fill-opacity" from="1" to="1"
                 begin="0s" dur="0.8s"
                 values="1;.5;1" calcMode="linear"
                 repeatCount="indefinite"/>
      </circle>
      <circle cx={width/2} cy={width/8} r="9" fillOpacity="0.3" fill={"#09a7ef"}>
        <animate attributeName="r" from={width/16} to={width/16}
                 begin="0s" dur="0.8s"
                 values={`${width/16};${width/8};${width/16}`} calcMode="linear"
                 repeatCount="indefinite"/>
        <animate attributeName="fill-opacity" from="0.5" to="0.5"
                 begin="0s" dur="0.8s"
                 values=".5;1;.5" calcMode="linear"
                 repeatCount="indefinite"/>
      </circle>
      <circle cx={(width/8)*7} cy={width/8} r={width/8} fill={"#09a7ef"}>
        <animate attributeName="r" from={width/8} to={width/8}
                 begin="0s" dur="0.8s"
                 values={`${width/8};${width/16};${width/8}`} calcMode="linear"
                 repeatCount="indefinite"/>
        <animate attributeName="fill-opacity" from="1" to="1"
                 begin="0s" dur="0.8s"
                 values="1;.5;1" calcMode="linear"
                 repeatCount="indefinite"/>
      </circle>
    </svg>;
  }
}
