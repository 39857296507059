import React from "react";
import Icon, {Props} from "./Icon";

export default class IconError extends Icon {
  static defaultProps: Partial<Props> = {
    height: 18,
    width: 18
  };

  render() {
    return (
      <svg className={"IconError " + this.props.className }
           width={this.props.width}
           height={this.props.height}
           viewBox={"0 0 " + this.props.width + " " + this.props.height}>
        <defs>
          <filter id="e0c2nsorda">
            <feColorMatrix in="SourceGraphic"
                           values="0 0 0 0 0.995804 0 0 0 0 0.696590 0 0 0 0 0.360788 0 0 0 1.000000 0"/>
          </filter>
          <path id="8y529cg10b" d="M0 0H18V18H0z"/>
        </defs>
        <g fill="none" fillRule="evenodd">
          <g>
            <g>
              <g transform="translate(-.002)">
                <path fill={this.props.color != null ? this.props.color : "#df5858"}
                      d="M9 1.5C4.86 1.5 1.5 4.86 1.5 9c0 4.14 3.36 7.5 7.5 7.5 4.14 0 7.5-3.36 7.5-7.5 0-4.14-3.36-7.5-7.5-7.5-2.76 0-2.76 0 0 0zm-.75 11.25v-1.5h1.5v1.5h-1.5zm0-7.5v4.5h1.5v-4.5h-1.5z"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>


    );
  }
}
