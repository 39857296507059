import AuthorizeApi from "../AuthorizeApi";
import {DateTime} from "luxon";
import {MonthlyPlanningDto} from "../entity/MonthlyPlanningDto";
import {MonthlyPlanningDtoNt} from "../entity/nt/MonthlyPlanningDtoNt";
import {MonthlyPlanningUpdateRequest} from "../entity/request/MonthlyPlanningUpdateRequest";
import GfDateUtils from "../../components/DatePicker/GfDateUtils";
import {MonthlyData} from "../../pages/Dashboard/MonthlyPlanning/MonthlyDTO";

export default class MonthlyPlanningApi {

  private base: string = '/planner/monthly';

  private authApi: AuthorizeApi = new AuthorizeApi();

  public async getByDate(date: DateTime): Promise<MonthlyPlanningDto> {
    const response = await this.authApi.get<MonthlyPlanningDtoNt>(`${this.base}/` +
      date.toFormat(GfDateUtils.STORED_DATE_FORMAT));
    return MonthlyPlanningApi.mapToNeedResponse(response)
  }

  public async getForDay(date: DateTime): Promise<{[id:number]:string}> {
    return  await this.authApi.get<{[id:number]:string}>(`${this.base}/${date.toFormat(GfDateUtils.STORED_DATE_FORMAT)}/day`);
  }

  public async savePlanningData(date: DateTime, monthlyData: MonthlyData): Promise<MonthlyPlanningDto> {
    const response = await this.authApi.put<MonthlyPlanningUpdateRequest, MonthlyPlanningDtoNt>(`${this.base}/` +
      date.toFormat(GfDateUtils.STORED_DATE_FORMAT),
      {data: JSON.stringify(monthlyData)});
    return MonthlyPlanningApi.mapToNeedResponse(response)
  }


  async exportExcel(date: DateTime) {
    return await this.authApi.download(`${this.base}/` +
      date.toFormat(GfDateUtils.STORED_DATE_FORMAT) + "/export",
      date.toFormat(GfDateUtils.FORMAT_MONTH_DASH) + "-monthly-planning.xlsx",
      {}, {}
    );
  }

  async exportElapsed(date: DateTime) {
    return await this.authApi.download(`${this.base}/` +
      date.toFormat(GfDateUtils.STORED_DATE_FORMAT) + "/export/elapsed",
      date.toFormat(GfDateUtils.FORMAT_MONTH_DASH) + "-monthly-elapsed.xlsx",
      {}, {}
    );
  }

  async exportOverload(date: DateTime) {
    return await this.authApi.download(`${this.base}/` +
      date.toFormat(GfDateUtils.STORED_DATE_FORMAT) + "/export/overload",
      date.toFormat(GfDateUtils.FORMAT_MONTH_DASH) + "-monthly-overload.xlsx",
      {}, {}
    );
  }

  private static mapToNeedResponse(monthlyPlannedRowNt: MonthlyPlanningDtoNt): MonthlyPlanningDto {
    return new MonthlyPlanningDto(monthlyPlannedRowNt);
  }
}
