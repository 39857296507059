import {StatusEnum} from "../engine/EngineApi";
import {DateTime} from "luxon";
import GfDateUtils from "../../components/DatePicker/GfDateUtils";

export interface StartEndDates {
  start: string,
  end: string
}
export class ContainerVehicleLogicUtils {

  static getStartEndDateStrings(nextMonthlyStatus : StatusEnum) : StartEndDates {
    const todayDate = DateTime.now().startOf('day').toFormat(GfDateUtils.STORED_DATE_FORMAT);
    if(nextMonthlyStatus == StatusEnum.DAILY_PLANNING_COMPLETED) {
      return { start : todayDate,
        end: DateTime.now().plus({'month': 1}).endOf('month').toFormat(GfDateUtils.STORED_DATE_FORMAT) }
    }
    return {
      start: todayDate,
      end: DateTime.now().endOf('month').toFormat(GfDateUtils.STORED_DATE_FORMAT)
    }
  }

}
