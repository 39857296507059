import React, {Component} from "react";
import {DateTime} from "luxon";
import {ErrorMessageModal, ErrorModal} from "../../../components/Modal/ErrorMessageModal/ErrorMessageModal";
import {
  EditingShift,
  MonthlyPlanningModal,
  Type
} from "../../../components/Modal/MonthlyPlanningModal/MonthlyPlanningModal";
import TimeOffApi, {TimeOffTypeDto} from "../../../api/timeOff/TimeOffApi";
import {ShiftDto} from "../../../api/entity/ShiftDto";
import {WorkerDto} from "../../../api/entity/WorkerDto";

import WorkerRegistryApi from "../../../api/worker/WorkerRegistryApi";
import TimeLine from "../../../components/TimeLine/TimeLine";

import {MonthlyDataWithStatus, MonthlyEmployee} from "../../Dashboard/MonthlyPlanning/MonthlyDTO";
import MonthlyTimeLineUtils, {EmployeeEdit} from "../MonthlyTimeLineUtils";
import "./MonthlyPlanningTable.scss"
import {FixedShiftDto} from "../../../api/entity/FixedShiftDto";
import {CircuitSelect} from "../../../components/CircuitSelect/CircuitSelect";

export interface Props {
  month:DateTime;
  monthlyData: MonthlyDataWithStatus;
  excludedMonthlyData: WorkerDto[];
  shifts:ShiftDto[];
  fixedShifts:FixedShiftDto[]
  editMode:boolean;
  onMonthlyPlanningEdit:(monthlyData:MonthlyDataWithStatus)=>void
  lastDayMap:{[id:number]:string[]},
  searchValue: string,
  circuitFilter: string,
}

interface State{
  errorModal: ErrorModal
  editingShift: EditingShift
  timeOffTypes:TimeOffTypeDto[]
}



export default class MonthlyPlanningTable extends Component<Props,State> {
  static defaultProps:Partial<Props> = {
    editMode:false,
    onMonthlyPlanningEdit:()=>{}
  }
  /************************************************
   * CONSTRUCTOR
   ************************************************/
  constructor(props: Props) {
    super(props);
    this.state = {
      errorModal: null,
      editingShift: null,
      timeOffTypes:[]
    }
    this.loadTimeOffTypes();
  }

  /************************************************
   * LIFECYCLE
   ************************************************/
  render() {
    const {monthlyData, shifts, month, editMode, fixedShifts,lastDayMap, searchValue, circuitFilter} = this.props;
    let filterMonthlyEntries = !monthlyData ? [] : this.filterMonthlyEntries(monthlyData, searchValue, circuitFilter);
    const days = monthlyData.days;
    return (
      <div className="MonthlyPlanningTable">

          {this.state.errorModal &&
          <ErrorMessageModal
            errorModal={this.state.errorModal}
            onClick={() => this.closeErrorModal()}
          />}

          {this.state.editingShift &&
          <MonthlyPlanningModal
            onSubmit={shift => this.saveEditedShift(shift)}
            editingShift={this.state.editingShift}
            onCancel={() => this.closeEditShiftModal()}
            shifts={shifts}
            timeOffTypes={this.state.timeOffTypes}
          />}

        {monthlyData &&
        <TimeLine
            headerHeight={120}
            headers={new MonthlyTimeLineUtils().generateHeaders(month, filterMonthlyEntries)}
            timeLines={new MonthlyTimeLineUtils().generateIntervalSettings(days, filterMonthlyEntries, [],
              shifts, fixedShifts,true,lastDayMap, searchValue, circuitFilter,
              (workerName, errors) => this.openErrorModal(workerName, errors),
                      editMode
                        ? (employeeEdit) => this.openEditShiftModal(employeeEdit)
                        : (() => {}), editMode)}
            spacing columnWidth={70}/>}
      </div>
    )
  }
  /**************************************************
   * PRIVATE FUNCTIONS
   **************************************************/
  private async loadTimeOffTypes() {
    const timeOffTypes = await new TimeOffApi().getTypes()
    this.setState({timeOffTypes})
  }

  private filterMonthlyEntries(monthlyData: MonthlyDataWithStatus, searchValue: string, circuitFilter: string) : MonthlyEmployee[] {
    let filteredEmployeesByName = monthlyData.employees.filter(
      s => searchValue == "" ||
        (s.name ?? "").toLowerCase().indexOf(searchValue.toLowerCase()) > -1
    );
    return filteredEmployeesByName.filter((filtered) => filtered.circuit)
      .filter(filtered => circuitFilter == CircuitSelect.NO_FILTER || filtered.circuit.toUpperCase() == circuitFilter);
  }

  private async openEditShiftModal(employeeEdit: EmployeeEdit) {
    const userId = employeeEdit.monthlyData.employees[employeeEdit.employeeIndex].id;
    const workerResponse = await this.workerRegistryApi.getDetails(userId);
    const workerDto = workerResponse.content[0];
    const shiftValue = this.getValue(employeeEdit, workerDto);


    const type = this.props.shifts.filter(s => s.shiftCode == shiftValue).length > 0
      ? Type.SHIFT
      : (this.state.timeOffTypes.filter(t => t.value == shiftValue).length > 0
        ? Type.ABSENCE
        : Type.NULL);

    this.setState({
      editingShift: {
        day: DateTime.fromFormat(employeeEdit.monthlyData.days[employeeEdit.dayIndex].toString(),
          this._storedDateFormat),
        type: type,
        timeOff: Type.ABSENCE ? shiftValue : null,
        shift: Type.SHIFT ? shiftValue : null,
        worker: workerDto
      }
    });
  }

  private closeEditShiftModal(){
    this.setState({editingShift:null})
  }
  private openErrorModal(worker: string, errors: string[]) {
    const errorModal = { worker, errors }
    this.setState({errorModal});
  }

  private closeErrorModal() {
    const errorModal = null;
    this.setState({errorModal});
  }

  private saveEditedShift(editingShift: EditingShift) {
    const monthlyData = this.props.monthlyData;

    const workerIndex = monthlyData.employees
          .findIndex((em) => em.id == editingShift.worker.id)
    const dayIndex = editingShift.day.day - 1;

    monthlyData.employees[workerIndex].turns[dayIndex] = editingShift.type == Type.SHIFT
      ? editingShift.shift
      : editingShift.timeOff;

    this.props.onMonthlyPlanningEdit(monthlyData)
    this.closeEditShiftModal()
  }

  private getValue(employeeEdit: EmployeeEdit, workerDto: WorkerDto) {
    let plannedRows = this.props.monthlyData.employees.filter(mp => mp.id == workerDto.id);
    if (plannedRows.length > 0)
      return plannedRows[0].turns[employeeEdit.dayIndex];
    return "";
  }



  /**************************************************
   * VARIABLES
   **************************************************/
  private workerRegistryApi: WorkerRegistryApi = new WorkerRegistryApi();
  private readonly _storedDateFormat = "yyyy-MM-dd";
}
