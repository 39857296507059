import React, {Component} from "react";
import "./ErrorMessageModal.scss";
import Button from "../../Button/Button";
import I18n from "../../../lib/I18n";

export interface ErrorModal {
  errors: string[],
  worker: string
}

interface Props {
  errorModal:ErrorModal
  onClick: () => void
}

export class ErrorMessageModal extends Component<Props> {
  /************************************************
   * LIFECYCLE
   ************************************************/
  render() {
    const {errors, worker} = this.props.errorModal;
    return (
      <div className="InformationModal-Overlay">
        <div className="InformationModal">
          <h2>{I18n.get().MonthlyPlanning.errors} {worker}</h2>
          <div className="InformationModal-Body">
            <ul>
              {errors.map(err => <li>{err}</li>)}
            </ul>
          </div>
          <div className="InformationModal-Buttons">
            <Button onClick={() => this.props.onClick()}
                    className="InformationModal-OkButton">
              Ok
            </Button>
          </div>
        </div>
      </div>
    )
  }
}
