import {WorkerDto} from "../../entity/WorkerDto";
import {WorkerModalErrors} from "../../../components/Modal/WorkerModal/WorkerModal";
import WorkersRegistryUtils from "../../../pages/WorkersRegistry/WorkerUtils";
import GfDateUtils from "../../../components/DatePicker/GfDateUtils";
import I18n from "../../../lib/I18n";

export default class AddWorkerRegistryValidator {

  static regexCf = "^[a-zA-Z]{6}[0-9]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9]{2}([a-zA-Z]{1}[0-9]{3})[a-zA-Z]{1}$";

  public static MIN_VALUE_COUNT = 2;

  private static AGE_LOWER_BOUND = 1900;

  public static validate(workerDto: WorkerDto): WorkerModalErrors {

    let workerRegistryUtils = new WorkersRegistryUtils();

    let errorMessages = workerRegistryUtils.getEmptyErrorState();

    if (workerDto.name.length < this.MIN_VALUE_COUNT) {
      errorMessages.name = "`" + I18n.get().WorkersRegistry.name + "`" + I18n.get().WorkersRegistry.errors.empty;
    }
    if (workerDto.lastname.length < this.MIN_VALUE_COUNT) {
      errorMessages.lastname = "`" + I18n.get().WorkersRegistry.surname + "`" + I18n.get().WorkersRegistry.errors.empty;
    }
    // if (!new RegExp(AddWorkerRegistryValidator.regexCf).test(workerDto.cf)) {
    //   errorMessages.cf = "`" + I18n.get().WorkersRegistry.cf + "`" + I18n.get().WorkersRegistry.errors.notValid;
    // }
    if (workerDto.cf.length < this.MIN_VALUE_COUNT) {
      errorMessages.cf = "`" + I18n.get().WorkersRegistry.cf + "`" + I18n.get().WorkersRegistry.errors.empty;
    }

    if (workerDto.birthdate.year < this.AGE_LOWER_BOUND || GfDateUtils.calculateWorkerAge(workerDto.birthdate) < 1) {
      console.warn("!")
      errorMessages.birthdate = "`" + I18n.get().WorkersRegistry.birthdate + "`" + I18n.get().WorkersRegistry.errors.notValid;
    }
    return errorMessages;
  }
}
