import "./HumanEntry.scss";
import React, {PureComponent} from "react";
import Select from "../../../Select/Select";
import I18n from "../../../../lib/I18n";
import Table, {Header} from "../../../Table/Table";
import TextInput from "../../../TextInput/TextInput";
import ShiftUtils from "../../../../utils/ShiftUtils";
import {NeedHumanErrors} from "../../../../pages/NeedModal/NeedModal";
import {NeedHumanShiftDto} from "../../../../api/entity/NeedHumanShiftDto";
import {ShiftDto} from "../../../../api/entity/ShiftDto";
import {TimeOffUtils} from "../../../../api/timeOff/TimeOffApi";


export interface Props {
  /** This defines additional classes for the Button */
  className?: string,
  needId,
  number,
  /** Disables onclick
   *  @default false
   **/
  shifts: ShiftDto[]
  shiftUtils : ShiftUtils
  needHumanShiftDto: NeedHumanShiftDto;
  needHumanErrors: NeedHumanErrors;
  onChange: (a: NeedHumanShiftDto) => void;
  onDelete: () => void;
}

interface State {
  humanShiftDto: NeedHumanShiftDto,
  humanShiftErrors: NeedHumanErrors,
  headers: Header<TableEntry>[];
  itemsWeekly: TableEntry;
  itemsFestive: TableEntry;
}

export interface TableEntry {
  monday: string
  tuesday: string
  wednesday: string
  thursday: string
  friday: string
  saturday: string
  sunday: string
}

export class HumanShiftEntry extends PureComponent<Props, State> {

  private readonly _commonClassName = "row-w";

  // @ts-ignore
  private weekRender: Partial<Record<TableEntry, (value: any) => React.ReactNode>> = {
    monday: (value) => <TextInput defaultValue={value}
                                  onChange={(value) => this.setDayValueForWeekDay(value, 0)}
                                  isNumeric={true} errorMessage={this.state.humanShiftErrors.weekTimeErrors[0]}
                                  className={this._commonClassName}
                                  onKeyPressValidation={TextInput.preventOtherKeysButNumber}/>,
    tuesday: (value) => <TextInput defaultValue={value}
                                   onChange={(value) => this.setDayValueForWeekDay(value, 1)}
                                   isNumeric={true} errorMessage={this.state.humanShiftErrors.weekTimeErrors[1]}
                                   className={this._commonClassName}
                                   onKeyPressValidation={TextInput.preventOtherKeysButNumber}/>,
    wednesday: (value) => <TextInput defaultValue={value}
                                     onChange={(value) => this.setDayValueForWeekDay(value, 2)}
                                     isNumeric={true} errorMessage={this.state.humanShiftErrors.weekTimeErrors[2]}
                                     className={this._commonClassName}
                                     onKeyPressValidation={TextInput.preventOtherKeysButNumber}/>,
    thursday: (value) => <TextInput defaultValue={value}
                                    onChange={(value) => this.setDayValueForWeekDay(value, 3)}
                                    isNumeric={true} errorMessage={this.state.humanShiftErrors.weekTimeErrors[3]}
                                    className={this._commonClassName}
                                    onKeyPressValidation={TextInput.preventOtherKeysButNumber}/>,
    friday: (value) => <TextInput defaultValue={value}
                                  onChange={(value) => this.setDayValueForWeekDay(value, 4)}
                                  isNumeric={true} errorMessage={this.state.humanShiftErrors.weekTimeErrors[4]}
                                  className={this._commonClassName}
                                  onKeyPressValidation={TextInput.preventOtherKeysButNumber}/>,
    saturday: (value) => <TextInput defaultValue={value}
                                    onChange={(value) => this.setDayValueForWeekDay(value, 5)}
                                    isNumeric={true} errorMessage={this.state.humanShiftErrors.weekTimeErrors[5]}
                                    className={this._commonClassName}
                                    onKeyPressValidation={TextInput.preventOtherKeysButNumber}/>,
    sunday: (value) => <TextInput defaultValue={value}
                                  onChange={(value) => this.setDayValueForWeekDay(value, 6)}
                                  isNumeric={true} errorMessage={this.state.humanShiftErrors.weekTimeErrors[6]}
                                  className={this._commonClassName}
                                  onKeyPressValidation={TextInput.preventOtherKeysButNumber}/>
  };

  // @ts-ignore
  private weekendRender: Partial<Record<TableEntry, (value: any) => React.ReactNode>> = {
    monday: (value) => <TextInput defaultValue={value}
                                  onChange={(value) => this.setDayValueForWeekend(value, 0)}
                                  isNumeric={true} errorMessage={this.state.humanShiftErrors.weekEndTimeErrors[0]}
                                  className={this._commonClassName}
                                  onKeyPressValidation={TextInput.preventOtherKeysButNumber}/>,
    tuesday: (value) => <TextInput defaultValue={value}
                                   onChange={(value) => this.setDayValueForWeekend(value, 1)}
                                   isNumeric={true} errorMessage={this.state.humanShiftErrors.weekEndTimeErrors[1]}
                                   className={this._commonClassName}
                                   onKeyPressValidation={TextInput.preventOtherKeysButNumber}/>,
    wednesday: (value) => <TextInput defaultValue={value}
                                     onChange={(value) => this.setDayValueForWeekend(value, 2)}
                                     isNumeric={true} errorMessage={this.state.humanShiftErrors.weekEndTimeErrors[2]}
                                     className={this._commonClassName}
                                     onKeyPressValidation={TextInput.preventOtherKeysButNumber}/>,
    thursday: (value) => <TextInput defaultValue={value}
                                    onChange={(value) => this.setDayValueForWeekend(value, 3)}
                                    isNumeric={true} errorMessage={this.state.humanShiftErrors.weekEndTimeErrors[3]}
                                    className={this._commonClassName}
                                    onKeyPressValidation={TextInput.preventOtherKeysButNumber}/>,
    friday: (value) => <TextInput defaultValue={value}
                                  onChange={(value) => this.setDayValueForWeekend(value, 4)}
                                  isNumeric={true} errorMessage={this.state.humanShiftErrors.weekEndTimeErrors[4]}
                                  className={this._commonClassName}
                                  onKeyPressValidation={TextInput.preventOtherKeysButNumber}/>,
    saturday: (value) => <TextInput defaultValue={value}
                                    onChange={(value) => this.setDayValueForWeekend(value, 5)}
                                    isNumeric={true} errorMessage={this.state.humanShiftErrors.weekEndTimeErrors[5]}
                                    className={this._commonClassName}
                                    onKeyPressValidation={TextInput.preventOtherKeysButNumber}/>,
    sunday: (value) => <TextInput defaultValue={value}
                                  onChange={(value) => this.setDayValueForWeekend(value, 6)}
                                  isNumeric={true} errorMessage={this.state.humanShiftErrors.weekEndTimeErrors[6]}
                                  className={this._commonClassName}
                                  onKeyPressValidation={TextInput.preventOtherKeysButNumber}/>
  };

  static defaultProps: Partial<Props> = {
    className: ""
  };

  public constructor(props) {
    super(props);
    this.state = {
      humanShiftErrors: this.props.needHumanErrors,
      humanShiftDto: this.props.needHumanShiftDto,
      itemsWeekly: {
        monday: (this.props.needHumanShiftDto.numberVehiclesWorkdays)[0],
        tuesday: (this.props.needHumanShiftDto.numberVehiclesWorkdays)[1],
        wednesday: (this.props.needHumanShiftDto.numberVehiclesWorkdays)[2],
        thursday: (this.props.needHumanShiftDto.numberVehiclesWorkdays)[3],
        friday: (this.props.needHumanShiftDto.numberVehiclesWorkdays)[4],
        saturday: (this.props.needHumanShiftDto.numberVehiclesWorkdays)[5],
        sunday: (this.props.needHumanShiftDto.numberVehiclesWorkdays)[6]
      },
      itemsFestive: {
        monday: (this.props.needHumanShiftDto.numberVehiclesFestive)[0],
        tuesday: (this.props.needHumanShiftDto.numberVehiclesFestive)[1],
        wednesday: (this.props.needHumanShiftDto.numberVehiclesFestive)[2],
        thursday: (this.props.needHumanShiftDto.numberVehiclesFestive)[3],
        friday: (this.props.needHumanShiftDto.numberVehiclesFestive)[4],
        saturday: (this.props.needHumanShiftDto.numberVehiclesFestive)[5],
        sunday: (this.props.needHumanShiftDto.numberVehiclesFestive)[6]
      },
      headers: [
        {name: I18n.get().Weekdays.monday, field: "monday"},
        {name: I18n.get().Weekdays.tuesday, field: "tuesday"},
        {name: I18n.get().Weekdays.wednesday, field: "wednesday"},
        {name: I18n.get().Weekdays.thursday, field: "thursday"},
        {name: I18n.get().Weekdays.friday, field: "friday"},
        {name: I18n.get().Weekdays.saturday, field: "saturday"},
        {name: I18n.get().Weekdays.sunday, field: "sunday"},
      ]
    }
  }

  componentDidMount() {
    let shiftDto = this.props.shifts.find(a => a.id == this.props.needHumanShiftDto.turnId);
    if(!shiftDto) { shiftDto = this.props.shifts[0]; }
    const {humanShiftDto} = this.state;
    humanShiftDto.role = this.state.humanShiftDto.role ? this.state.humanShiftDto.role : this.props.shiftUtils.ROLE_DTO[0];
    humanShiftDto.turnId = shiftDto.id;
    humanShiftDto.turnCode = shiftDto.shiftCode;
    this.setState({humanShiftDto});
    this.setState( { humanShiftDto:
        {... this.state.humanShiftDto,
          moss: shiftDto.typology.indexOf(ShiftUtils.MOSS) != -1,
          timeSpan: shiftDto.fromTime + "-" +
          (shiftDto.toTime == "00:00" ? "24:00" : shiftDto.toTime)}
    })
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot ?: any) {
    if (this.props.needHumanShiftDto != prevProps.needHumanShiftDto ||
      this.props.needHumanErrors != prevProps.needHumanErrors) {
      let humanShiftDto = this.props.needHumanShiftDto;
      this.setState({
        humanShiftDto: humanShiftDto,
        humanShiftErrors: this.props.needHumanErrors,
        itemsWeekly: {
          monday: (humanShiftDto.numberVehiclesWorkdays)[0],
          tuesday: (humanShiftDto.numberVehiclesWorkdays)[1],
          wednesday: (humanShiftDto.numberVehiclesWorkdays)[2],
          thursday: (humanShiftDto.numberVehiclesWorkdays)[3],
          friday: (humanShiftDto.numberVehiclesWorkdays)[4],
          saturday: (humanShiftDto.numberVehiclesWorkdays)[5],
          sunday: (humanShiftDto.numberVehiclesWorkdays)[6]
        },
        itemsFestive: {
          monday: (humanShiftDto.numberVehiclesFestive)[0],
          tuesday: (humanShiftDto.numberVehiclesFestive)[1],
          wednesday: (humanShiftDto.numberVehiclesFestive)[2],
          thursday: (humanShiftDto.numberVehiclesFestive)[3],
          friday: (humanShiftDto.numberVehiclesFestive)[4],
          saturday: (humanShiftDto.numberVehiclesFestive)[5],
          sunday: (humanShiftDto.numberVehiclesFestive)[6]
        }
      })
    }
  }

  private setDayValueForWeekDay(value, index) {
    const {humanShiftDto} = this.state;
    humanShiftDto.numberVehiclesWorkdays[index] = value;
    let weekErrors = this.state.humanShiftErrors.weekTimeErrors;
    weekErrors[index] = "";
    this.setState({
      humanShiftDto: humanShiftDto,
      humanShiftErrors: {...this.state.humanShiftErrors, weekEndTimeErrors: weekErrors}
    })
    this.setState({humanShiftDto: humanShiftDto})
    this.props.onChange(humanShiftDto);
  }

  setDayValueForWeekend(value, index) {
    const {humanShiftDto} = this.state;
    humanShiftDto.numberVehiclesFestive[index] = value;
    let weekendErrors = this.state.humanShiftErrors.weekEndTimeErrors;
    weekendErrors[index] = "";
    this.setState({
      humanShiftDto: humanShiftDto,
      humanShiftErrors: {...this.state.humanShiftErrors, weekEndTimeErrors: weekendErrors}
    })
    this.props.onChange(humanShiftDto);
  }

  render() {
    return (
      <div className={"HumanEntry " + this.props.className}>
        <span className={"closeIcon row-w"} onClick={() => this.props.onDelete()}>&times;</span>
        <div className={"first-row"}>
          <Select
            defaultValue={this.props.shiftUtils.ROLE_DTO.indexOf(this.state.humanShiftDto.role)}
            label={I18n.get().NeedManagement.addNewNeedManagement.human.role}
            items={this.props.shiftUtils.ROLE_LABEL} className={"row-w height"}
            onChange={(index) => {
              const {humanShiftDto} = this.state;
              humanShiftDto.role = this.props.shiftUtils.ROLE_DTO[index];
              this.setState({humanShiftDto: humanShiftDto});
              this.props.onChange(humanShiftDto);
            }}
          />
          <Select
            defaultValue={this.props.shiftUtils.ENABLEMENT_DTO.indexOf(this.state.humanShiftDto.enablement)}
            label={I18n.get().NeedManagement.addNewNeedManagement.human.enablement}
            items={this.props.shiftUtils.ENABLEMENT_DTO} className={"row-w height"}
            onChange={(index) => {
              const {humanShiftDto} = this.state;
              humanShiftDto.enablement = this.props.shiftUtils.ENABLEMENT_DTO[index]
              this.setState({humanShiftDto: humanShiftDto});
              this.props.onChange(humanShiftDto);
            }}
          />
          <div className="moss">
            <label>{I18n.get().NeedManagement.addNewNeedManagement.human.Moss}</label>
            <div  className="radio-moss-container">
              <div className="radio-moss">
                <input type="radio" disabled={true} checked={this.state.humanShiftDto.moss} name={"moss" + this.props.number}/>
                <label>{I18n.get().NeedManagement.addNewNeedManagement.confirm.yes}</label>
              </div>
              <div className={"radio-moss"}>
                <input type="radio" disabled={true} checked={!this.state.humanShiftDto.moss} name={"moss" + this.props.number}/>
                <label>{I18n.get().NeedManagement.addNewNeedManagement.confirm.no}</label>
              </div>
            </div>
          </div>
        </div>

        <div className={"second-row"}>
          <Select
            label={I18n.get().NeedManagement.addNewNeedManagement.human.turnCode}
            defaultValue={this.props.shifts.map(a => a.id).indexOf(this.state.humanShiftDto.turnId)}
            items={this.props.shifts.map(a => a.shiftCode)} className={"row-w height"}
            onChange={(index) => {
              const {humanShiftDto} = this.state;
              let targetShift = this.props.shifts[index];
              humanShiftDto.turnCode = targetShift.shiftCode
              humanShiftDto.turnId = targetShift.id
              humanShiftDto.timeSpan = targetShift.fromTime + "-" +
                (targetShift.toTime == "00:00" ? "24:00" : targetShift.toTime);
              humanShiftDto.moss = targetShift.typology.indexOf(ShiftUtils.MOSS) != -1;
              this.setState({humanShiftDto: humanShiftDto});
              this.props.onChange(humanShiftDto);
            }}
          />
          <span className={"timespan"}>
            <label>{I18n.get().NeedManagement.addNewNeedManagement.human.timeSpan}</label>
            <div>{this.state.humanShiftDto.timeSpan}</div>
          </span>
        </div>
        <div >
          {I18n.get().NeedManagement.addNewNeedManagement.vehicle.workdays}
        </div>
        <div className={"fourth-row"}>
          <div className="table-wrapper">
          <Table onClick={() => {
          }}
                 headers={this.state.headers}
                 items={[this.state.itemsWeekly]}
                 itemsRender={this.weekRender}
          />
        </div>
        </div>
        <div >
          {I18n.get().NeedManagement.addNewNeedManagement.vehicle.festive}
        </div>
        <div className={"fifth-row"}>
          <div className="table-wrapper">
          <Table onClick={() => {
          }}
                 headers={this.state.headers}
                 items={[this.state.itemsFestive]}
                 itemsRender={this.weekendRender}
          />
        </div>
        </div>
      </div>
    )
  }

}
