import React from 'react';
import './NeedManagement.scss';
import Page from "../Page";
import I18n from "../../lib/I18n";
import Table, {CellVisibilityRule, Header} from "../../components/Table/Table";
import Toast from "../../components/Toast/Toast";
import Button from "../../components/Button/Button";
import SearchInput from "../../components/SearchInput/SearchInput";
import {Pagination} from "../../components/Table/Pagination";
import NeedManagementApi from "../../api/need/NeedManagementApi";
import {NeedResponse} from "../../api/entity/response/NeedResponse";
import {Pages} from "../../router/Pages";
import {Menu, MenuButton, MenuItem, MenuList} from "@reach/menu-button";
import "@reach/menu-button/styles.css";
import {OperationResult} from "../../api/entity/OperationResult";
import InformationModalController from '../../components/Modal/InformationModal/InformationModalController';
import GfDateUtils from "../../components/DatePicker/GfDateUtils";
import { DateTime } from 'luxon';
import {ContainerVehicleLogicUtils} from "../../api/container-vehicle/ContainerVehicleLogicUtils";
import ContainerVehicleApi from "../../api/container-vehicle/ContainerVehicleApi";
import {StatusEnum} from "../../api/engine/EngineApi";
import MonthlyPlanningApi from "../../api/planning/MonthlyPlanningApi";
import {FocusPanel} from "../../components/FocusPanel/FocusPanel";
import Spinner from "../../components/Spinner/Spinner";

interface State {
  table: {
    headers: Header<TableEntry>[];
    items: TableEntry[];
    currentSlice: number;
  };
  isToastOpen: boolean;
  showDeleteDialog: boolean;
  idItemSelected: number;
  registryCount: number;
  isLoadingToShow: boolean;
  nextMonthPlanning: StatusEnum;
  toast: {
    isError: boolean;
    errorList: string[];
    message: string;
  }
  loading:boolean
}

interface TableEntry {
  id: number,
  serviceCode: string,
  circuit: string,
  startDate: string,
  endDate: string,
  action: number
}

export default class NeedManagement extends Page<{}, State> {

  private readonly TIMEOUT_WAIT_MS = 2500;

  private readonly elementsPerPage = 50;

  private needManagerApi = new NeedManagementApi();
  private planningApi = new MonthlyPlanningApi();
  private containerVehiclesApi = new ContainerVehicleApi();

  // @ts-ignore
  private itemsRender: Partial<Record<TableEntry, (value: any) => React.ReactNode>> = {
    circuit: (value) => <div>{value}</div>,
    startDate: (value) => <div>{DateTime.fromFormat(value, GfDateUtils.STORED_DATE_FORMAT).toFormat(GfDateUtils.DATE_FORMAT)}</div>,
    endDate: (value) => <div>{DateTime.fromFormat(value, GfDateUtils.STORED_DATE_FORMAT).toFormat(GfDateUtils.DATE_FORMAT)}</div>,
    action: (value) =>
      <Menu>
        {({isExpanded}) => (
          <React.Fragment>
            <MenuButton>
              <img src={"assets/menu-vertical-" + (isExpanded ? "open" : "close") + ".svg"} alt={"menu"}/>
            </MenuButton>
            <MenuList className={"slide-down"}>
              <MenuItem className={"itemMenu"}
                        onSelect={() => this.modifyElement(value)}>{I18n.get().ActionButton.modify}</MenuItem>
              <MenuItem className={"itemMenu"}
                        onSelect={() => this.deleteElement(value)}>{I18n.get().ActionButton.delete}</MenuItem>
            </MenuList>
          </React.Fragment>
        )}
      </Menu>
  };

  public constructor(props) {
    super(props);

    this.state = {
      isLoadingToShow: false,
      isToastOpen: false,
      showDeleteDialog: false,
      registryCount: 0,
      idItemSelected: -1,
      nextMonthPlanning: StatusEnum.NO_PLANNING,
      table: {
        headers: [],
        items: [],
        currentSlice: 0
      },
      toast: {
        isError: false,
        message: "",
        errorList: [],
      },
      loading:true
    }
  }

  async componentDidMount() {
    try {
      if (this.props.location.state != undefined) {
        this.promptOperationMessage();
      }
      this.planningApi.getByDate(DateTime.now().startOf('month').plus({'month': 1}))
        .then((resp) => {
          this.setState({nextMonthPlanning: resp.status});
        })
      await this.fetchNeeds();
      this.setState({loading: false})
    } catch {
      this.setState({loading: false})
    }
  }

  private promptOperationMessage() {
    // @ts-ignore
    if (this.props.location.state.success) {
      this.setState({
        isToastOpen: true,
        toast: {...this.state.toast, message: I18n.get().NeedManagement.successMessage}
      })
    } else {
      this.setState({
        isToastOpen: true,
        toast: {...this.state.toast, message: I18n.get().NeedManagement.errorMessage}
      })
    }
  }

  private async fetchNeeds() {
    let needResponse = await this.needManagerApi.getOrSearchByServiceCodeOrCircuit("",
      this.elementsPerPage,
      this.elementsPerPage * (this.state.table.currentSlice));
    this.setState({registryCount: needResponse.total});
    this.updateNeedTable(needResponse);
  }

  private closeToast() {
    this.setState({isToastOpen: false});
  }

  private goTo(location: string) {
    this.props.history.push(location);
  }

  private async onSearch(value: string) {
    if (value) {
      let response = await this.needManagerApi.getOrSearchByServiceCodeOrCircuit(value,
        this.elementsPerPage,
        this.elementsPerPage * (this.state.table.currentSlice));
      this.setState({registryCount: response.content.length});
      this.resetTable();
      this.updateNeedTable(response);
    } else {
      this.resetTable();
      await this.fetchNeeds();
    }
  }

  private async onPaginationChange(page) {
    this.state.table.currentSlice = page;
    await this.fetchNeeds();
  }

  private resetTable() {
    this.state.table.currentSlice = 0;
  }

  private updateNeedTable(needResponse: NeedResponse) {
    this.setState({
        table: {
          ...this.state.table,
          headers: [
            {
              name: I18n.get().NeedManagement.table.serviceCode,
              field: "serviceCode"
            },
            {
              name: I18n.get().NeedManagement.table.circuit,
              field: "circuit"
            },
            {
              name: I18n.get().NeedManagement.table.startDate,
              field: "startDate"
            },
            {
              name: I18n.get().NeedManagement.table.endDate,
              field: "endDate",
              visibility:CellVisibilityRule.FOR_TABLET_PORTRAIT_UP
            },
            {
              name: '',
              field: "action"
            }
          ],
          items:
            needResponse.content.map(function (e) {
              return {
                id: e.id,
                serviceCode: e.serviceCode,
                circuit: e.circuit,
                startDate: e.startDate,
                endDate: e.endDate,
                action: e.id
              };
            })
        }
      }
    );
  }

  private modifyElement(id: number) {
    this.closeDialog();
    this.goTo(Pages.NEED_MANAGEMENT_MODAL + "/" + id);
  }

  private async deleteElement(id: number) {
    this.closeDialog();
    let confirmDeleteMessage = I18n.get().NeedManagement.confirmDeleteMessage;

    InformationModalController.getInstance().show(
      {
        body: <div>{confirmDeleteMessage}</div>,
        title: I18n.get().NeedManagement.confirmDeleteTitle,
        cancelButtonLabel: I18n.get().ActionButton.cancel,
        onOk: () => this.deleteEntry(id),
        onCancel: () => InformationModalController.getInstance().hide()
      });
  }

  private async deleteEntry(id: number) {
    let response = await this.needManagerApi.delete(id);
    this.setState({isLoadingToShow: true});
    InformationModalController.getInstance().hide()
    if (response.status == OperationResult.OK) {

      const startEndDateStrings = ContainerVehicleLogicUtils.getStartEndDateStrings(this.state.nextMonthPlanning);
      await this.containerVehiclesApi.updateContainerVehicles(
        startEndDateStrings.start,
        startEndDateStrings.end,
        () => {
          this.waitForProcessToFinish();
          return;
        });

      await this.fetchNeeds();
    }
  }

  private toggleDeleteDialog(id: number) {
    this.setState({showDeleteDialog: true, idItemSelected: id});
  }

  private closeDialog() {
    this.setState({idItemSelected: -1, showDeleteDialog: false});
  }

  private waitForProcessToFinish() {
    setTimeout(() => {
      this.containerVehiclesApi.getStatus(() => {
          this.setState({isLoadingToShow: false});
          this.setState({toast: {...this.state.toast, message: I18n.get().NeedManagement.successDelete}, isToastOpen: true})
        },
        () => {
          this.waitForProcessToFinish();
        });
    }, this.TIMEOUT_WAIT_MS);
  }

  render() {
    if(this.state.loading)
      return <Spinner />
    return (
      <div>
        <FocusPanel className={""} text={I18n.get().NeedManagement.deletingMessage} show={this.state.isLoadingToShow}/>
        <div className={"inner-content"}>
          <Toast isError={this.state.toast.isError}
                 message={this.state.toast.message}
                 errors={this.state.toast.errorList}
                 visible={this.state.isToastOpen}
                 trigger={() => this.closeToast()}/>
          <div className={"top"}>
            <div className={"up-title"}>
              <h1>{I18n.get().Menu.needManagement}</h1>
            </div>
            <div className={"up-controls"}>
              <Button onClick={() => {
                this.goTo(Pages.NEED_MANAGEMENT_MODAL)
              }}>
                {I18n.get().NeedManagement.addNewNeed}</Button>
            </div>
          </div>

          <div className={"search-location"}>
            <SearchInput className="search-input" label="" defaultValue=""
                         placeholder={I18n.get().NeedManagement.findNeed} onChange={(value) => this.onSearch(value)}
                         readonly={false} disabled={false} password={false} errorMessage={""}/>
          </div>
          <div className={"search-result"}>
            <Table headers={this.state.table.headers}
                   items={this.state.table.items}
                   itemsRender={this.itemsRender}
                   onClick={() => {
                   }}/>

            <Pagination
              page={this.state.table.currentSlice}
              onPageChange={(page) => this.onPaginationChange(page)}
              totalPages={this.state.registryCount}
              elementsPerPage={this.elementsPerPage}/>
          </div>
        </div>
      </div>
    );
  }

}
