import WorkerDtoNT from "./nt/WorkerDtoNT";
import {DateTime} from "luxon";

export class WorkerDto {

  id: number;
  name: string;
  lastname: string;
  height: string;
  cf: string;
  birthdate: DateTime;
  endContract: DateTime;
  firstContract: DateTime;
  circuit: string;
  role: string;
  skill: string;
  moss: boolean;
  active: boolean;
  incomplete: boolean;
  company: string;
  creationDate: DateTime;
  tutoring: string;
  tutoringId: number;
  tutoredId: number;
  tutoringFullName: string;
  tutoredFullName: string;
  gender: string;
  flagNottiConsecutive: number;
  numNottiConsecutive: number;

  constructor(dtoNt: WorkerDtoNT) {
    this.cf = dtoNt.cf;
    this.lastname = dtoNt.lastname;
    this.name = dtoNt.name;
    this.id = dtoNt.id;
    this.height = dtoNt.height;
    this.birthdate = DateTime.fromISO(dtoNt.birthdate);
    this.firstContract = (dtoNt.firstContract) ? DateTime.fromISO(dtoNt.firstContract) : null;
    this.endContract = (dtoNt.endContract) ? DateTime.fromISO(dtoNt.endContract) : null;
    this.creationDate = DateTime.fromISO(dtoNt.creationDate);
    this.circuit = dtoNt.circuit;
    this.role = dtoNt.role;
    this.skill = dtoNt.skill;
    this.moss = dtoNt.moss > 0;
    this.incomplete = dtoNt.incomplete;
    this.active = dtoNt.active;
    this.company = dtoNt.company;
    this.tutoring = dtoNt.tutoring;
    this.tutoringId = dtoNt.tutoringId;
    this.tutoredId = dtoNt.tutoredId;
    this.tutoredFullName = dtoNt.tutoredFullName;
    this.tutoringFullName = dtoNt.tutoringFullName;
    this.gender = dtoNt.gender,
    this.flagNottiConsecutive = dtoNt.flagNottiConsecutive;
    this.numNottiConsecutive = dtoNt.numNottiConsecutive;
  }
}
