import React, {BaseSyntheticEvent, PureComponent} from "react";
import "./LoginForm.scss";
import Button from "../Button/Button";
import TextInput from "../TextInput/TextInput";
import I18n from "../../lib/I18n";
import LoginApi from "../../api/login/LoginApi";


export interface Props {
  /** This defines additional classes for the Button */
  className: string,
  /** This dictates what the button will do on click */
  onSubmit: (event: React.FormEvent) => void;
  /** Disables onclick
   *  @default false
   **/
  onSuccessLogin: (bearerToken: string) => void;
  disabled: boolean;
}

interface State {
  username: string,
  password: string,
  errorMessage: string,
}

export class LoginForm extends PureComponent<Props, State> {
  private loginApi: LoginApi = new LoginApi();

  /************************************************
   * PROPS
   ************************************************/
  static defaultProps: Partial<Props> = {
    className: "",
    onSubmit: () => {

    },
    disabled: false
  };

  /************************************************
   * CONSTRUCTOR
   ************************************************/
  constructor(props: Props) {
    super(props);
    this.state = {username: "", password: "", errorMessage: ""}
  }

  private handlePasswordChange(value) {
    this.setState({password: value});
  }

  private handleUsernameChange(value) {
    this.setState({username: value})
  }

  async onSubmit(event : BaseSyntheticEvent) {
    event.preventDefault();

    this.setState({"errorMessage": ""});

    const token = await this.loginApi.login({
      username: this.state.username,
      password: this.state.password
    });

    // console.log(LoginForm.isWrongBearerToken(token));

    if (LoginForm.isWrongBearerToken(token)) {
      this.setState({errorMessage: I18n.get().Login.cannotAuthErrorMessage});
    } else {
      this.props.onSuccessLogin(token);
    }
  }

  private static isWrongBearerToken(bearerToken: string) {
    return bearerToken.length == 0;
  }

  /************************************************
   * LIFECYCLE
   ************************************************/
  render() {
    return (
      <div className={this.props.className + " LoginForm"}>
        <form onSubmit={(event)=>this.onSubmit(event)}>
          <div>
            <h1>
              {I18n.get().Login.title}
            </h1>
          </div>
          <div>
            <h2>
              {I18n.get().Login.subtitle}
            </h2>
          </div>
          <TextInput className={"text-input"} onChange={e => this.handleUsernameChange(e)}
                     label={I18n.get().Login.username}
                     placeholder={I18n.get().Login.username}/>
          <TextInput className={"text-input"} password={true}
                     onChange={e => this.handlePasswordChange(e)}
                     label="Password"
                     placeholder="Password"/>
          <p>{this.state.errorMessage}</p>
          {/*<p className={"forgotten-link"}><a>{I18n.get().Login.forgottenPassword}</a></p>*/}
          <Button onClick={(event) => this.onSubmit(event)} className={"login-btn"}>{I18n.get().Login.accessBtn}</Button>
        </form>
      </div>
    )
  }
}

export default LoginForm;
