import React, {Component} from "react";
import "./DailyServiceHeader.scss"
import Time from "../../../../lib/utils/Time";
interface Props{
  day:Date
  hour:string
}

interface State{

}

export default class DailyServiceHeader extends Component<Props,State>{
  render() {
    const {day,hour} = this.props
    return <div className="DailyServiceHeader">
      {/*<div className="DailyServiceHeader-subtext">{Time.toDoubleDigit(day.getDate())} {Time.getStringMonth(day)} {day.getFullYear()}</div>*/}
      <div className="DailyServiceHeader-subtext">{Time.getStringDay(day)}</div>
      <div className="DailyServiceHeader-text">{hour}</div>
    </div>
  }

}
