import AuthorizeApi from "../AuthorizeApi";
import Time from "../../lib/utils/Time";
import {DateTime} from "luxon";
import GfDateUtils from "../../components/DatePicker/GfDateUtils";

export enum StatusEnum {
  NO_PLANNING                   = 'NO_PLANNING',
  CONVERGENCE_CHECK_IN_PROGRESS = 'CONVERGENCE_CHECK_IN_PROGRESS',
  CONVERGENCE_CHECK_COMPLETED   = 'CONVERGENCE_CHECK_COMPLETED',
  MONTHLY_PLANNING_IN_PROGRESS  = 'MONTHLY_PLANNING_IN_PROGRESS',
  MONTHLY_PLANNING_COMPLETED    = 'MONTHLY_PLANNING_COMPLETED',
  CONSTRAINT_CHECK_IN_PROGRESS  = 'CONSTRAINT_CHECK_IN_PROGRESS',
  DAILY_PLANNING_IN_PROGRESS    = 'DAILY_PLANNING_IN_PROGRESS',
  DAILY_PLANNING_COMPLETED      = 'DAILY_PLANNING_COMPLETED',
}

export interface NextPlanning {
  status: StatusEnum
}

export interface PlanningResponse {
  planning: any
}

export default class EngineApi {

  private authApi: AuthorizeApi = new AuthorizeApi();
  private static BASE_PATH = `/engine-config`;


  public async checkConvergence(): Promise<void> {
    return await this.authApi.get<void>(`${EngineApi.BASE_PATH}/monthly-planning/${Time.getISONextMonth()}/checkConvergence`)
  }

  public async startMonthlyPlanning(): Promise<void> {
    return await this.authApi.get<void>(`${EngineApi.BASE_PATH}/monthly-planning/${Time.getISONextMonth()}/startMonthlyPlanning`)
  }

  public async resetPlanning(): Promise<void> {
    return await this.authApi.get<void>(`${EngineApi.BASE_PATH}/monthly-planning/${Time.getISONextMonth()}/resetPlanning`)
  }

  public async checkConstraints(): Promise<void> {
    return await this.authApi.get<void>(`${EngineApi.BASE_PATH}/monthly-planning/${Time.getISONextMonth()}/checkConstraints`)
  }

  public async startDailyPlanning(): Promise<void> {
    return await this.authApi.get<void>(`${EngineApi.BASE_PATH}/monthly-planning/${Time.getISONextMonth()}/startDailyPlanning`)
  }

  public async stopAnyPlanning(): Promise<void> {
    return await this.authApi.get<void>(`${EngineApi.BASE_PATH}/planning/stop`)
  }

  public async startVehiclePlanning(date : DateTime) : Promise<void> {
    return await this.authApi.get<void>(`/vehicle-planning/start/` + date.toFormat(GfDateUtils.STORED_DATE_FORMAT))
  }

  public async startVehicleSubmission() {
    return await this.authApi.get<void>("/submission")
  }

  public getStatus(onCannotReadStatus: () => void = () => {
  }): Promise<NextPlanning> {
    return this.authApi.get<NextPlanning>(
      `/engine-config/monthly-planning/${Time.getISONextMonth()}/status`,
      onCannotReadStatus);
  }

  public get(date: Date): Promise<PlanningResponse> {
    return this.authApi.get<PlanningResponse>(`/engine-config/monthly-planning/${Time.toISOMonth(date)}`)
  }
}
