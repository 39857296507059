import "./DailyServiceLine.scss"
import React from "react";

interface Props{
  name:string
  start:string
  end:string
}

export default function DailyServiceLine(props:Props){
  const {name, start, end} = props;
  const text = `${name} - ${start} - ${end}`
  return <div className="DailyServiceLine" title={text}>{text}</div>
}
