import AuthorizeApi from "../AuthorizeApi";
import {FixedShiftResponse} from "../entity/response/FixedShiftResponse";
import {FixedShiftResponseNt} from "../entity/response/nt/FixedShiftResponseNt";
import {FixedShiftRequest} from "../entity/request/FixedShiftRequest";

export default class FixedShiftApi {

  private base: string = '/fixed-shift';

  private authApi: AuthorizeApi = new AuthorizeApi();

  public async getAllFixedShifts():Promise<FixedShiftResponse> {
    let shiftResponse: FixedShiftResponse;
    await this.authApi.get<FixedShiftResponseNt>(this.base)
      .then((w) => {
        shiftResponse = FixedShiftApi.mapToFixedShiftResponse(w)
      });
    return shiftResponse;
  }

  public async getFixedShifts(id: number) {
    let shiftResponse: FixedShiftResponse;
    await this.authApi.get<FixedShiftResponseNt>(this.base + "/" + id)
        .then((w) => {
        shiftResponse = FixedShiftApi.mapToFixedShiftResponse(w)
      });
    return shiftResponse;
  }

  public async modifyFixedShifts(fixedShiftRequest: FixedShiftRequest) {
    let shiftResponse: FixedShiftResponse;
    await this.authApi.post<FixedShiftRequest, FixedShiftResponseNt>(this.base, fixedShiftRequest)
      .then((w) => {
        shiftResponse = FixedShiftApi.mapToFixedShiftResponse(w)
      });
    return shiftResponse;
  }

  private static mapToFixedShiftResponse(fixedShiftResponseNt: FixedShiftResponseNt) {
    return new FixedShiftResponse(fixedShiftResponseNt);
  }

}
