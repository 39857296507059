import React from 'react';
import './WorkersRegistry.scss';
import Page from "../Page";
import I18n from "../../lib/I18n";
import Button from "../../components/Button/Button";
import {WorkerModal} from "../../components/Modal/WorkerModal/WorkerModal";
import {FocusPanel} from "../../components/FocusPanel/FocusPanel";
import WorkerRegistryApi from "../../api/worker/WorkerRegistryApi";
import AddWorkerRegistryValidator from "../../api/worker/validator/AddWorkerRegistryValidator";
import Toast from "../../components/Toast/Toast";
import {OperationResult} from "../../api/entity/OperationResult";
import {WorkerDto} from "../../api/entity/WorkerDto";
import {WorkerResponse} from "../../api/entity/response/WorkerResponse";
import Table, {CellVisibilityRule, Header} from "../../components/Table/Table";
import SearchInput from "../../components/SearchInput/SearchInput";
import {Pagination} from "../../components/Table/Pagination";
import SideBar from "../../components/SideBar/SideBar";
import WorkersRegistryUtils from "./WorkerUtils";
import {ModalType} from "../../components/Modal/ModalType";
import GfDateUtils from "../../components/DatePicker/GfDateUtils";
import WorkerRegistryDetails from "./WorkerRegistryDetails/WorkerRegistryDetails";
import LineHorizontal from "../../components/LineHorizontal/LineHorizontal";
import {WorkerContractDto} from "../../api/entity/WorkerContractDto";
import WorkerRegistryContracts from "./WorkerRegistryContracts/WorkerRegistryContracts";
import {WorkerContractModal} from "../../components/Modal/WorkerContractModal/WorkerContractModal";
import WorkerContractApi from "../../api/worker/WorkerContractApi";
import {UnavailabilityModal} from "../../components/Modal/UnavailabilityModal/UnavailabilityModal";
import {WorkerWeeklyUnResponse} from "../../api/entity/response/WorkerWeeklyUnResponse";
import WorkerWeeklyUnDto from "../../api/entity/WorkerWeeklyUnDto";
import UnavailabilityApi from '../../api/worker/UnavailabilityApi';
import WorkerUnavailabilitySection from "./WorkerUnavailabilitySection/WorkerUnavailabilitySection";
import SkillsSection from "./SkillsSection/SkillsSection";
import {SkillsModal} from "../../components/Modal/SkillsModal/SkillsModal";
import WorkerSkillsApi from "../../api/worker/WorkerSkillsApi";
import {SkillDto} from "../../api/entity/SkillDto";
import {SkillType} from "../../api/entity/SkillType";
import FixedShiftApi from "../../api/worker/FixedShiftApi";
import {FixedShiftModal} from "../../components/Modal/FixedShiftModal/FixedShiftModal";
import FixedShiftSection from "./FixedShiftSection/FixedShiftSection";
import {FixedShiftDto} from "../../api/entity/FixedShiftDto";
import ShiftRegistryApi from "../../api/shift/ShiftRegistryApi";
import {ShiftDto} from "../../api/entity/ShiftDto";
import {TitleModal} from "../../components/Modal/TitleModal/TitleModal";
import ShiftUtils from "../../utils/ShiftUtils";
import {TutoringModal} from '../../components/Modal/TutoringModal/TutoringModal';
import IconError from "../../icons/IconError";
import IconToastSuccess from "../../icons/IconToastSuccess";
import SyncApi from '../../api/sync/SyncApi';
import Spinner from "../../components/Spinner/Spinner";

interface State {

  table: {
    headers: Header<TableEntry>[];
    items: TableEntry[];
    currentSlice: number;
  };

  selected: {
    isSelected: boolean;
    worker: WorkerDto;
    unavailability: WorkerWeeklyUnDto[];
    calculatedAge: number;
    contracts: WorkerContractDto[];
    certificates: SkillDto[];
    service: string;
    fixedShifts: FixedShiftDto[];
  };

  eligibleTutors: WorkerDto[];
  searchKey: string;
  isSkillsModalOpen: boolean;
  isTutoringModalOpen: boolean;
  isCreateModifyModalOpen: boolean;
  isUnavailabilityModalOpen: boolean;
  isContractModalOpen: boolean;
  isToastOpen: boolean;
  isFixedShiftModalOpen: boolean;
  isTitleModalOpen: boolean;
  registryCount: number;
  commonSkills: SkillDto[];
  shifts: ShiftDto[];
  isSyncingWorkers: boolean,
  loading: boolean;
  syncing: {
    text: string
  }
  toast: {
    isError: boolean;
    errorList: string[];
    message: string;
  }
}

interface TableEntry {
  company: string,
  id: number,
  name: string,
  tutor_level: string,
  tutor: string,
  // tutored: string,
  incomplete: any,
  endContract: string,
  role: string,
  circuit: string,
  service: string,
  isActive: any
}

export default class WorkersRegistry extends Page<{}, State> {

  private readonly elementsPerPage = 50;

  private workerRegistryUtils = new WorkersRegistryUtils();

  private workerApi = new WorkerRegistryApi();
  private unavailabilityApi = new UnavailabilityApi();
  private workerSkillsApi = new WorkerSkillsApi();
  private contractApi: WorkerContractApi = new WorkerContractApi();
  private fixedShiftApi: FixedShiftApi = new FixedShiftApi();
  private shiftApi: ShiftRegistryApi = new ShiftRegistryApi();
  private syncApi: SyncApi = new SyncApi();

  public constructor(props) {
    super(props);

    this.state = {
      selected: {
        worker: this.workerRegistryUtils.getEmptyWorkerDto(),
        isSelected: false,
        calculatedAge: 0,
        unavailability: [],
        contracts: [],
        certificates: [],
        service: "",
        fixedShifts: []
      },
      syncing: {
        text: ""
      },
      eligibleTutors: [],
      searchKey: "",
      shifts: [],
      isCreateModifyModalOpen: false,
      isSkillsModalOpen: false,
      isUnavailabilityModalOpen: false,
      isContractModalOpen: false,
      isTutoringModalOpen: false,
      isToastOpen: false,
      isFixedShiftModalOpen: false,
      isTitleModalOpen: false,
      isSyncingWorkers: false,
      registryCount: 0,
      table: {
        headers: [],
        items: [],
        currentSlice: 0
      },
      toast: {
        isError: false,
        message: "",
        errorList: [],
      },
      commonSkills: [],
      loading: true
    }
  }

  private async fetchUnavailability(id: number) {
    let weeklyResponse: WorkerWeeklyUnResponse = await this.unavailabilityApi.getUnavailability(id);
    this.setState({
      selected: {
        ...this.state.selected, unavailability:
          this.getSortedContentByWeekRow(weeklyResponse)
      }
    });
    return weeklyResponse;
  }

  private getSortedContentByWeekRow(weeklyResponse: WorkerWeeklyUnResponse) {
    return weeklyResponse.content.sort((a, b) => (a.weekRow > b.weekRow) ? 1 : -1);
  }

  async componentDidMount() {
    try {
      await this.fetchWorkers();
      await this.fetchUnavailability(1);
      let shiftResponse = await this.shiftApi.get("", 1000, 0);
      this.setState({shifts: shiftResponse.content, loading: false});
    } catch {
      this.setState({loading: false})
    }
  }

  private async fetchWorkers() {
    let workerResponse = await this.workerApi.getOrSearch(this.state.searchKey, this.elementsPerPage,
      this.elementsPerPage * (this.state.table.currentSlice));
    this.setState({registryCount: workerResponse.total});
    this.updateWorkerTable(workerResponse);
  }

  private toggleCreateModifyModal() {
    this.setState({isCreateModifyModalOpen: !this.state.isCreateModifyModalOpen});
    // this.toggleSidebar();
  }

  private toggleCreateModifyTitle() {
    this.setState({isTitleModalOpen: !this.state.isTitleModalOpen});
    // this.toggleSidebar();
  }

  private toggleUnavailabilityModal() {
    this.setState({isUnavailabilityModalOpen: !this.state.isUnavailabilityModalOpen})
    // this.toggleSidebar();
  }

  private toggleContractModal() {
    this.setState({isContractModalOpen: !this.state.isContractModalOpen});
    // this.toggleSidebar();
  }

  private async toggleSkillsModal() {
    await this.reloadSkills();
    this.setState({isSkillsModalOpen: !this.state.isSkillsModalOpen});
    // this.toggleSidebar();
  }

  private async reloadSkills() {
    let skills = await this.workerSkillsApi.getAll();
    this.setState({commonSkills: skills.content});
  }

  private closeToast() {
    this.setState({isToastOpen: false});
  }

  private toggleErrorMessage(errors: string[]) {
    this.setState({
      toast: {
        isError: true,
        message: I18n.get().WorkersRegistry.errorMessage,
        errorList: errors,
      },
      isToastOpen: true
    });
  }

  private toggleSuccessMessage() {
    this.setState({
      toast: {
        isError: false,
        message: I18n.get().WorkersRegistry.successMessage,
        errorList: [],
      },
      isToastOpen: true
    })
  }

  private async createWorker(worker: WorkerDto) {
    AddWorkerRegistryValidator.validate(worker);
    let createdWorkerResponse = await this.workerApi.create(worker);
    if (createdWorkerResponse.status == OperationResult.OK) {
      this.toggleSuccessMessage();
      await this.fetchWorkers();
    } else {
      this.toggleErrorMessage(createdWorkerResponse.errors);
    }
  }

  private async updateUnavailability(modifiedUnavailabilityData: WorkerWeeklyUnDto[]) {
    let workerWeeklyUnResponse =
      await this.unavailabilityApi.modify(modifiedUnavailabilityData);
    if (workerWeeklyUnResponse.status == OperationResult.OK) {
      this.toggleUnavailabilityModal();
      this.setState({toast: {...this.state.toast, message: I18n.get().WorkersRegistry.successMessageUnavailability}})
      this.setState({isToastOpen: true});
    }
  }

  private async modifyWorker(worker: WorkerDto) {
    AddWorkerRegistryValidator.validate(worker);
    let updateResult = await this.workerApi.modify(worker);
    if (updateResult.status == OperationResult.OK && updateResult.content.length > 0) {
      this.toggleSuccessMessage();
      let updatedWorker = updateResult.content[0];
      this.setState({
        ...this.state, selected: {
          ...this.state.selected,
          worker: updatedWorker,
          calculatedAge: GfDateUtils.calculateWorkerAge(updatedWorker.birthdate)
        }
      });
      await this.fetchWorkers();
    } else {
      this.toggleErrorMessage(updateResult.errors);
    }
  }

  private async modifyContractWorker(contracts: WorkerContractDto[], toBeDeleted: WorkerContractDto[], workerId: number) {
    let updateResult = await this.contractApi.modify(contracts);
    toBeDeleted.forEach(a => this.contractApi.delete(a.id));
    if (updateResult.status == OperationResult.OK) {
      this.toggleContractModal();
      let updatedContracts = updateResult.contracts;
      this.setState({
        ...this.state, selected: {
          ...this.state.selected,
          contracts: updatedContracts
        }
      });
      this.toggleSuccessMessage()
      await this.fetchWorkers();
    } else {
      this.toggleErrorMessage(updateResult.errors);
    }
  }

  private onError(errors: string[]) {
    this.toggleErrorMessage(errors);
  }

  private async onSearch(value: string) {
    this.setState({searchKey: value, table: {currentSlice: 1, ...this.state.table}}, async () => {
      if (value == null || value == "") {
        await this.fetchWorkers();
        return;
      }
      let response = await this.workerApi.getOrSearch(value,
        this.elementsPerPage, this.elementsPerPage * (this.state.table.currentSlice - 1));
      this.setState({registryCount: response.total});
      this.resetTable();
      this.updateWorkerTable(response);
    });

  }

  private async onPaginationChange(page) {
    this.state.table.currentSlice = page;
    await this.fetchWorkers();
  }

  private resetTable() {
    this.state.table.currentSlice = 1;
  }

  private updateWorkerTable(workerResponse: WorkerResponse) {
    this.setState({
        table: {
          ...this.state.table,
          headers: [
            {
              name: I18n.get().WorkersRegistry.company,
              field: "company",
              visibility: CellVisibilityRule.FOR_TABLET_LANDSCAPE_UP
            },
            {
              name: I18n.get().WorkersRegistry.name,
              field: "name"
            },
            {
              name: "",
              field: "incomplete"
            },
            {
              name: I18n.get().WorkersRegistry.table.role,
              field: "role",
              visibility: CellVisibilityRule.FOR_TABLET_LANDSCAPE_UP
            },
            {
              name: I18n.get().WorkersRegistry.table.tutor_level,
              field: "tutor_level",
              visibility: CellVisibilityRule.FOR_DESKTOP_UP
            },
            {
              name: I18n.get().WorkersRegistry.table.tutor,
              field: "tutor",
              visibility: CellVisibilityRule.FOR_DESKTOP_UP
            },
            {
              name: I18n.get().WorkersRegistry.table.service,
              field: "service",
              visibility: CellVisibilityRule.FOR_TABLET_PORTRAIT_UP
            },
            {
              name: I18n.get().WorkersRegistry.table.endContract,
              field: "endContract",
              visibility: CellVisibilityRule.FOR_TABLET_LANDSCAPE_UP
            },
            {
              name: I18n.get().WorkersRegistry.table.circuit,
              field: "circuit",
              visibility: CellVisibilityRule.FOR_TABLET_PORTRAIT_UP
            },
            {
              name: I18n.get().WorkersRegistry.table.status,
              field: "isActive"
            }
          ], items:
            workerResponse.content.map(function (e) {
              return {
                company: e.company.toUpperCase(),
                id: e.id,
                tutor_level: e.tutoring == "" ? "-" : e.tutoring.toUpperCase(),
                tutor: e.tutoringFullName == "" ? "-" : e.tutoringFullName.toUpperCase(),
                // tutored: e.tutoredFullName == "" ? "-" : e.tutoredFullName,
                incomplete: e.incomplete ?
                  <div className={"headerCompleteness"}>
                    <IconError color={"#ffca8c"}/>
                    <div className={"wrap"}>{I18n.get().WorkersRegistry.incompleteMessage}</div>
                  </div>
                  : <IconToastSuccess/>,
                service: e.skill,
                name: (`${e.lastname} ${e.name}`).toUpperCase(),
                endContract: WorkersRegistry.resolveEndContractLabel(e),
                role: new ShiftUtils().resolveLabelForRole(e.role),
                circuit: e.circuit.toUpperCase(),
                isActive: e.active ? <div className={"active"}>{I18n.get().WorkersRegistry.active}</div> :
                  <div className={"not-active"}>{I18n.get().WorkersRegistry.notActive}</div>
              };
            })
        }
      }
    );
  }

  private toggleAddWorkerUser() {
    this.state.selected.worker = this.workerRegistryUtils.getEmptyWorkerDto();
    this.state.selected.isSelected = false;
    this.toggleCreateModifyModal();
  }

  private async updateSkills(newlyAssignedCertificates: SkillDto[], skill: string,
                             moss: boolean, tutoring: string, flagNottiConsecutive: number, numNottiConsecutive: number) {
    const {worker} = this.state.selected;
    worker.tutoring = tutoring;
    worker.moss = moss;
    worker.skill = skill;
    worker.flagNottiConsecutive = flagNottiConsecutive;
    worker.numNottiConsecutive = numNottiConsecutive;
    //minimum 2 consecutive nights
    if (!worker.numNottiConsecutive || worker.numNottiConsecutive < 2) {
      worker.numNottiConsecutive = 2;
    }

    let workerResponse = await this.workerApi.modify(worker);
    let workerSkillResponse = await this.workerSkillsApi
      .connectToWorker(this.state.selected.worker.id, newlyAssignedCertificates);
    if (workerSkillResponse.status == OperationResult.OK &&
      workerResponse.status == OperationResult.OK) {
      this.setState({
        isToastOpen: true, toast: {
          ...this.state.toast,
          message: I18n.get().WorkersRegistry.successMessageSkills
        }
      })
      /*await*/ this.loadSelectedWorkerDetails(this.state.selected.worker.id);
      await this.fetchWorkers();
      await this.toggleSkillsModal();
      await this.reloadSkills();

    }
  }

  private async onWorkerRowClick(entry: TableEntry) {
    await this.loadSelectedWorkerDetails(entry.id);
  }

  private async loadSelectedWorkerDetails(workerId:number) {
    const details = await this.workerApi.getDetails(workerId);
    const workerDto = details.content[0];

    const contracts = await this.workerApi.getContracts(workerId);
    const unavailability = await this.fetchUnavailability(workerId);
    const fixedShift = await this.fetchFixedShifts(workerId);
    if (workerDto != null) {
    const skills = await this.workerSkillsApi.get(workerDto.id);
      this.setState({
        selected:
          {
            isSelected: true, worker: workerDto, calculatedAge:
              GfDateUtils.calculateWorkerAge(workerDto.birthdate),
            unavailability: unavailability.content,
            contracts: contracts ? contracts.contracts : [],
            fixedShifts: fixedShift.content,
            certificates: skills.content.filter(s => s.type == SkillType.CERTIFICATE),
            service: workerDto.skill
          }
      });
    }
  }

  private toggleSidebar() {
    this.setState({selected: {...this.state.selected, isSelected: !this.state.selected}});
  }

  private toggleFixedShiftModal() {
    this.setState({isFixedShiftModalOpen: !this.state.isFixedShiftModalOpen});
    // this.toggleSidebar();
  }

  private isCreate() {
    return this.state.selected.worker.id == 0;
  }

  private async toggleTutorModal() {
    if (!this.state.isTutoringModalOpen) {
      let availableTutors = await this.workerApi.getAvailableTutors();
      this.setState({eligibleTutors: availableTutors.content.filter(at => at.id != this.state.selected.worker.id)});
    }
    this.setState({isTutoringModalOpen: !this.state.isTutoringModalOpen});
    // this.toggleSidebar();
  }

  render() {
    if (this.state.loading)
      return <Spinner/>
    return (
      <div>
        {this.state.isTitleModalOpen ?
          <TitleModal
            model={this.state.selected.worker}
            onSubmit={(modifiedWorker) => {
              this.modifyWorker(modifiedWorker)
              this.toggleCreateModifyTitle();
            }}
            onCancel={() => this.toggleCreateModifyTitle()}
          />
          : null
        }
        {this.state.isTutoringModalOpen ?
          <TutoringModal model={this.state.selected.worker}
                         onSubmit={(workerDto => {
                           this.modifyWorker(workerDto);
                           this.toggleTutorModal();
                         })}
                         onCancel={() => this.toggleTutorModal()}
                         eligibleTutors={this.state.eligibleTutors}
          />
          : null
        }
        {this.state.isSkillsModalOpen ?
          <SkillsModal
            allSkills={this.state.commonSkills}
            assignedCertificates={this.state.selected.certificates}
            assignedService={this.state.selected.service}
            assignedMoss={this.state.selected.worker.moss}
            assignedTutoring={this.state.selected.worker.tutoring}
            flagConsecutiveNights={this.state.selected.worker.flagNottiConsecutive}
            numNottiConsecutive={this.state.selected.worker.numNottiConsecutive}
            onSubmit={(newlyAssignedCertificates, assignedServiceName, moss, assignedTutoring, flagConsecutiveNights, numConsecutiveNights) =>
              this.updateSkills(newlyAssignedCertificates, assignedServiceName, moss, assignedTutoring, flagConsecutiveNights, numConsecutiveNights)}
            onCancel={() => this.toggleSkillsModal()}
          />
          : null
        }
        {this.state.isFixedShiftModalOpen ?
          <FixedShiftModal onSubmit={(shift) => this.onFixedShiftSubmit(shift)}
                           shifts={this.state.shifts}
                           shiftsDto={this.state.selected.fixedShifts}
                           onCancel={() => this.toggleFixedShiftModal()}
          /> : null}

        {this.state.isUnavailabilityModalOpen ?
          <UnavailabilityModal
            onSubmit={(modifiedUnavailabilityData: WorkerWeeklyUnDto[]) => this.updateUnavailability(modifiedUnavailabilityData)}
            isOpen={this.state.isUnavailabilityModalOpen}
            onClose={() => this.setState({isUnavailabilityModalOpen: !this.state.isUnavailabilityModalOpen})}
            unavailabilityData={this.state.selected.unavailability}
          />
          : null}
        <FocusPanel show={this.state.isSyncingWorkers ||
        this.state.isCreateModifyModalOpen ||
        this.state.isContractModalOpen || this.state.isUnavailabilityModalOpen ||
        this.state.isSkillsModalOpen || this.state.isFixedShiftModalOpen ||
        this.state.isTutoringModalOpen
        || this.state.isTitleModalOpen}
                    text={this.state.syncing.text}/>
        <div className={"inner-content"}>
          <Toast isError={this.state.toast.isError}
                 message={this.state.toast.message}
                 errors={this.state.toast.errorList}
                 visible={this.state.isToastOpen}
                 trigger={() => this.closeToast()}/>

          <div className={"top"}>
            <div className={"up-title"}>
              <h1>{I18n.get().Menu.workersRegistry}</h1>
            </div>
            <div className={"up-controls"}>
              <Button onClick={() => this.toggleAddWorkerUser()}>
                {I18n.get().WorkersRegistry.addWorker}</Button>

              <span className={"right-btn"}>
                <Button secondary={true} onClick={() => this.onSyncWorkers()}>
                  {I18n.get().WorkersRegistry.syncWorkers}</Button>
              </span>
              <span className={"right-btn"}>
                <Button secondary={true} onClick={() => this.syncApi.sync()}>
                  {"Sync assenze"}</Button>
              </span>
            </div>
          </div>

          <div className={"search-location"}>
            <SearchInput className="search-input" label="" defaultValue=""
                         placeholder={I18n.get().WorkersRegistry.findWorkers} onChange={(value) => this.onSearch(value)}
                         readonly={false} disabled={false} password={false} errorMessage={""}/>
          </div>
          <div className={"search-result WorkersTableContainer"}>
            <p>{I18n.get().WorkersRegistry.workers + ": " + this.state.registryCount}</p>
            <Table headers={this.state.table.headers}
                   items={this.state.table.items} onClick={(i) => this.onWorkerRowClick(i)}/>


            <Pagination page={this.state.table.currentSlice}
                        onPageChange={(page) => this.onPaginationChange(page)}
                        totalPages={this.state.registryCount}
                        elementsPerPage={this.elementsPerPage}/>

            {this.workerModal()}
            {this.workerContractModal()}
          </div>
          <SideBar
            className={"side"} onOpen={() => console.log("open")}
            onClose={() => this.toggleSidebar()}
            isOpen={this.state.selected.isSelected}>
            <WorkerRegistryDetails
              onToggleModify={() => this.toggleCreateModifyModal()}
              onToggleTutor={() => this.toggleTutorModal()}
              onToggleModifyRelated={() => this.toggleCreateModifyTitle()}
              sections={{
                registryDetails: {
                  workerDto: this.state.selected.worker,
                  calculatedAge: this.state.selected.calculatedAge
                }
              }}
            >
            </WorkerRegistryDetails>
            <LineHorizontal className={"Full"}/>
            <SkillsSection certificates={this.state.selected.certificates}
                           service={this.state.selected.service}
                           moss={this.state.selected?.worker?.moss}
                           tutoring={this.state.selected.worker.tutoring}
                           flagNottiConsecutive={this.state.selected.worker.flagNottiConsecutive}
                            numNottiConsecutive={this.state.selected.worker.numNottiConsecutive}
                           onToggleModify={() => this.toggleSkillsModal()}/>
            <LineHorizontal className={"Full"}/>
            <WorkerRegistryContracts
              workerContracts={this.state.selected.contracts}
              onToggleModify={() => this.toggleContractModal()}
            >
            </WorkerRegistryContracts>
            <LineHorizontal className={"Full"}/>
            <FixedShiftSection
              fixedShifts={this.state.selected.fixedShifts}
              onToggleModify={() => this.toggleFixedShiftModal()}/>
            <LineHorizontal className={"Full"}/>
            <WorkerUnavailabilitySection weekUnDtos={this.state.selected.unavailability}
                                         onToggleModify={() => this.toggleUnavailabilityModal()}/>
          </SideBar>
        </div>
      </div>
    );
  }

  workerModal() {
    if (this.state.isCreateModifyModalOpen) {
      return <WorkerModal
        type={this.isCreate() ? ModalType.CREATE : ModalType.MODIFY}
        model={this.state.selected.worker}
        onSubmit={(workerDto) => this.createOrModifyWorker(workerDto)}
        onError={(errors) => this.onError(errors)}
        onCancel={() => this.toggleCreateModifyModal()}/>
    }
    return null;
  }

  private createOrModifyWorker(workerDto) {
    if (this.isCreate()) {
      this.createWorker(workerDto);
    } else {
      this.modifyWorker(workerDto);
    }
    this.toggleCreateModifyModal();
  }

  private workerContractModal() {
    if (this.state.isContractModalOpen) {
      return <WorkerContractModal
        title={I18n.get().WorkersRegistry.addWorker}
        model={this.state.selected.contracts}
        workerId={this.state.selected.worker.id}
        onSubmit={(contracts, toBeDeleted, workerId) =>
          this.modifyContractWorker(contracts, toBeDeleted, workerId)}
        onCancel={() => this.toggleContractModal()}/>
    }
    return null;
  }

  private async onFixedShiftSubmit(shifts: FixedShiftDto[]) {
    let fixedShiftResponse = await this.fixedShiftApi.modifyFixedShifts({
      shiftList: shifts,
      userId: this.state.selected.worker.id
    });

    if (fixedShiftResponse.status == OperationResult.OK) {
      this.toggleSuccessMessage();
      this.toggleFixedShiftModal();
    }
  }

  private async fetchFixedShifts(id: number) {
    let fixedShiftResponse = await this.fixedShiftApi.getFixedShifts(id);
    this.setState({
      selected: {
        ...this.state.selected, fixedShifts: fixedShiftResponse.content
      }
    });
    return fixedShiftResponse;
  }

  private static resolveEndContractLabel(e: WorkerDto) {
    let endContract = I18n.printDate(e.endContract, GfDateUtils.DATE_FORMAT);
    let creationContract = I18n.printDate(e.creationDate, GfDateUtils.DATE_FORMAT);
    if (creationContract == endContract) {
      return "";
    }
    return endContract;
  }

  private onSyncWorkers() {
    this.workerApi.syncWorkers().then(r => {
      if (!r.running) {
        let {isSyncingWorkers, syncing} = this.state;
        isSyncingWorkers = !isSyncingWorkers;
        syncing = {text: I18n.get().WorkersRegistry.syncingWorkersMsg}
        this.setState({isSyncingWorkers, syncing});
        setInterval(() => this.checkSyncProgress(), 1000);
      }
    })
  }

  private checkSyncProgress() {
    this.workerApi.getWorkersSync().then(resp => {
      if (resp.running === true) {
        setTimeout(() => this.checkSyncProgress(), 500)
      } else {
        this.setState({isSyncingWorkers: false, syncing: {text: ""}});
        window.location.reload();
      }
    });
  }
}
