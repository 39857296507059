import {ShiftDto} from "../ShiftDto";
import {ShiftResponseNt} from "./nt/ShiftResponseNt";

export class ShiftResponse {
  status: string;
  errors: string[];
  content: ShiftDto[];
  totalPages: number;
  currentPages: number;
  size: number;
  total: number;

  constructor(shiftResponseNt: ShiftResponseNt) {
    this.status = shiftResponseNt.status;
    this.errors = shiftResponseNt.errors;
    this.content = shiftResponseNt.content.map((shiftDtoNt) => new ShiftDto(shiftDtoNt))
    this.totalPages = shiftResponseNt.totalPages;
    this.currentPages = shiftResponseNt.currentPages;
    this.size = shiftResponseNt.size;
    this.total = shiftResponseNt.total;
  }

}
