import AuthorizeApi from "../AuthorizeApi";
import {WorkerSkillResponse} from "../entity/response/WorkerSkillResponse";
import {WorkerSkillsResponseNt} from "../entity/response/nt/WorkerSkillResponseNt";
import {SkillsToWorkerRequest} from "../entity/request/SkillsToWorkerRequest";
import {SkillDto} from "../entity/SkillDto";

export default class WorkerSkillsApi {

  private base: string = '/skills';

  private authApi: AuthorizeApi = new AuthorizeApi();

  public async get(id: number) {
    let response: WorkerSkillResponse;
    await this.authApi.get<WorkerSkillsResponseNt>(this.base + "/" + id)
        .then((w) => {
        response = WorkerSkillsApi.mapToWorkerSkills(w)
      });
    return response;
  }

  public async getAll() {
    let response: WorkerSkillResponse;
    await this.authApi.get<WorkerSkillsResponseNt>(this.base, () => {
        AuthorizeApi.redirectToLogin()
    }, {"offset": 0, "limit": 100})
        .then((w) => {
        response = WorkerSkillsApi.mapToWorkerSkills(w)
      });
    return response;
  }

  public async connectToWorker(idWorker: number, skillDtos: SkillDto[]) {
    let workerContractResponse: WorkerSkillResponse;
    await this.authApi.post<SkillsToWorkerRequest, WorkerSkillsResponseNt>(this.base, new SkillsToWorkerRequest(idWorker, skillDtos))
      .then((w) => {
        workerContractResponse = WorkerSkillsApi.mapToWorkerSkills(w)
      });
    return workerContractResponse;
  }

  private static mapToWorkerSkills(workerContractResponseNt: WorkerSkillsResponseNt) {
    return new WorkerSkillResponse(workerContractResponseNt);
  }
}
