import React, {BaseSyntheticEvent, Component} from "react";
import "./TutoringModal.scss";
import Button from "../../Button/Button";
import I18n from "../../../lib/I18n";
import {WorkerDto} from "../../../api/entity/WorkerDto";
import {ModalType} from "../ModalType";
import Select from 'react-select';
import ShiftUtils from "../../../utils/ShiftUtils";


export interface Props {
  type: ModalType,
  /** This defines additional classes for the Button */
  className: string,
  model: WorkerDto,
  eligibleTutors: WorkerDto[],
  /** Disables onclick
   *  @default false
   **/
  onSubmit: (workerDto: WorkerDto) => void;
  onCancel: () => void;

}

interface State {
  options: Option[]
  workerDto: WorkerDto
}

interface Option {
  value: number;
  label: string;
}

export class TutoringModal extends Component<Props, State> {

  private shiftUtils = new ShiftUtils();
  /************************************************
   * PROPS
   ************************************************/
  static defaultProps: Partial<Props> = {
    className: "",
    onCancel: () => {
    },
  };

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (this.props.eligibleTutors != prevProps.eligibleTutors) {

    }
  }

  /************************************************
   * CONSTRUCTOR
   ************************************************/
  constructor(props: Props) {
    super(props);
    let options = this.props.eligibleTutors.map(option => {
      return {value: option.id, label: `${option.lastname} ${option.name}`}
    })
    if (this.props.model.tutoringId != 0) {
      options.push({value: 0, label: I18n.get().WorkersRegistry.nothing});
    }

    this.state = {
      workerDto: props.model,
      options: options
    };
  }

  private onChange(selection: Option) {
    let {workerDto} = this.state;
    workerDto.tutoringId = selection.value;
    workerDto.tutoringFullName = selection.label;
    this.setState({workerDto});
  }

  async onSubmit(event: BaseSyntheticEvent) {
    event.preventDefault();
    this.props.onSubmit(this.state.workerDto);
  }

  onCancel() {
    this.props.onCancel();
  }


  /************************************************
   * LIFECYCLE
   ************************************************/
  render() {
    return (
      <div className={"Modal TutoringModal " + this.props.className}>
        <form onSubmit={(event) => this.onSubmit(event)}>
          <div>
            <h1>
              {I18n.get().WorkersRegistry.tutoringModal.title}
            </h1>
          </div>
          <div>
            <h2>
              {I18n.get().WorkersRegistry.tutoringModal.label} {`${this.props.model.lastname} ${this.props.model.name}`}
            </h2>
          </div>
          <div>
            <Select
              onChange={(newValue) => this.onChange(newValue)}
              options={this.state.options}
              value={{value: this.props.model.tutoringId, label: this.props.model.tutoringFullName}}
            />
          </div>
          <div>
            <div className={"buttons"}>
              <Button onClick={(event) => this.onSubmit(event)}
                      className={"add-btn"}>
                {this.props.type == ModalType.CREATE ? I18n.get().WorkersRegistry.confirmAddWorker :
                  I18n.get().WorkersRegistry.confirmModifyWorker}
              </Button>
              <Button secondary={true}
                      onClick={() => this.onCancel()}
                      className={"cancel-btn"}>
                {I18n.get().WorkersRegistry.cancelAddWorker}</Button>
            </div>
          </div>
        </form>
      </div>
    )
  }
}
