import React, {BaseSyntheticEvent, Component} from "react";
import "./VehicleModal.scss";
import Button from "../../Button/Button";
import TextInput from "../../TextInput/TextInput";
import I18n from "../../../lib/I18n";
import Select from "../../Select/Select";
import {ModalType} from "../ModalType";
import {GfDatePicker} from "../../DatePicker/GfDatePicker";
import {DateTime} from "luxon";
import ShiftUtils from "../../../utils/ShiftUtils";
import {VehicleDto} from "../../../api/entity/VehicleDto";
import {RadioBoolean} from "../RadioBoolean/RadioBoolean";
import GfDateUtils from "../../DatePicker/GfDateUtils";
import VehicleApi from "../../../api/vehicle/VehicleApi";

export interface Props {
  title: string,
  type: ModalType,
  /** This defines additional classes for the Button */
  className: string,
  model: VehicleDto,
  /** Disables onclick
   *  @default false
   **/
  onSubmit: (VehicleDto) => void;
  onCancel: () => void;
  disabled: boolean;
}

interface State {
  vehicle: VehicleDto,
  errors: VehicleErrors
}

interface VehicleErrors {
  name: string,
  plate: string,
  telephone: string,
  dateError: string
}

export class VehicleModal extends Component<Props, State> {

  private vehicleApi = new VehicleApi();
  private shiftUtils = new ShiftUtils();
  /************************************************
   * PROPS
   ************************************************/
  static defaultProps: Partial<Props> = {
    className: "",
    onCancel: () => {
    },
    disabled: false
  };

  /************************************************
   * CONSTRUCTOR
   ************************************************/
  constructor(props: Props) {
    super(props);
    this.state = {
      vehicle: this.props.model,
      errors: {
        plate: "", telephone: "", dateError: "", name: ""
      }
    }
  }

  onCancel() {
    this.props.onCancel();
  }

  async onSubmit(event: BaseSyntheticEvent) {
    event.preventDefault();

    const vehicleName = this.state.vehicle.name.trim();
    const vehicleId = this.state.vehicle.id;
    const vehicleResponse = await this.vehicleApi.getByName(vehicleName);
    if (vehicleResponse.content.length > 0 && vehicleResponse.content[0].id != vehicleId) {
      this.setState({errors: {...this.state.errors, name: I18n.get().VehicleRegistry.modal.nameExists}})
      return;
    }


    if (this.state.vehicle.name == "") {
      this.setState({errors: {...this.state.errors, name: I18n.get().VehicleRegistry.modal.emptyField}})
      return;
    }
    if (DateTime.fromFormat(this.state.vehicle.validitystart, GfDateUtils.STORED_DATE_FORMAT).toMillis() >=
      DateTime.fromFormat(this.state.vehicle.validityend, GfDateUtils.STORED_DATE_FORMAT).toMillis()) {
      this.setState({errors: {...this.state.errors, dateError: I18n.get().VehicleRegistry.modal.dateError}});
      return;
    }
    this.props.onSubmit(this.state.vehicle);
  }

  /************************************************
   * LIFECYCLE
   ************************************************/
  render() {
    return (
      <div className={"Modal VehicleModal " + this.props.className}>
        <form onSubmit={(event) => this.onSubmit(event)}>
          <div>
            <h1>
              {I18n.get().VehicleModal.title}
            </h1>
          </div>
          <div>
            <div className={"form"}>
              <div className={"row"}>
                <div className={"w-h"}>
                  <TextInput className={"text-input"} errorMessage={this.state.errors.name}
                             onChange={(e) => this.setState({
                               vehicle: {...this.state.vehicle, name: e},
                               errors: {...this.state.errors, name: ""}
                             })}
                             label={I18n.get().VehicleModal.name} defaultValue={this.props.model.name}
                             placeholder={I18n.get().VehicleModal.name}/>
                </div>
                <div className={"w-h"}>
                  <Select label={I18n.get().VehicleModal.category}
                          className={"circuit-select"} items={this.shiftUtils.VEHICLE_ENABLEMENT_DTO}
                          defaultValue={this.shiftUtils.VEHICLE_ENABLEMENT_DTO.indexOf(this.state.vehicle.category)}
                          onChange={(index) => this.setState({
                            vehicle: {
                              ...this.state.vehicle,
                              category: this.shiftUtils.VEHICLE_ENABLEMENT_DTO[index]
                            }
                          })} disabled={false}/>
                </div>
              </div>
              <div className={"row"}>
                <div className={"w-h"}>
                  <TextInput className={"text-input"} errorMessage={this.state.errors.plate}
                             onChange={(e) => this.setState({
                               vehicle: {...this.state.vehicle, plate: e},
                               errors: {...this.state.errors, plate: ""}
                             })}
                             label={I18n.get().VehicleModal.plate} defaultValue={this.props.model.plate}
                             placeholder={I18n.get().VehicleModal.plate}/>
                </div>
                <div className={"w-h"}>
                  <Select label={I18n.get().VehicleModal.circuit}
                          className={"circuit-select"} items={this.shiftUtils.CIRCUIT_DTO}
                          defaultValue={this.shiftUtils.CIRCUIT_DTO.indexOf(this.state.vehicle.circuit)}
                          onChange={(index) => this.setState({
                            vehicle: {
                              ...this.state.vehicle,
                              circuit: this.shiftUtils.CIRCUIT_DTO[index]
                            }
                          })} disabled={false}/>
                </div>
              </div>
              <div className={"row"}>
                <div className={"w-h"}>
                  <GfDatePicker label={I18n.get().VehicleModal.validityStart}
                                onChange={(registrationDate) => {
                                  this.setState({
                                    vehicle: {
                                      ...this.state.vehicle,
                                      validitystart: registrationDate.toFormat(GfDateUtils.STORED_DATE_FORMAT)
                                    },
                                    errors: {...this.state.errors, dateError: ""}
                                  })
                                }}
                                date={DateTime.fromFormat(this.state.vehicle.validitystart, GfDateUtils.STORED_DATE_FORMAT)}
                                errorMessage={this.state.errors.dateError}/>
                </div>
                <div className={"w-h"}>
                  <GfDatePicker label={I18n.get().VehicleModal.validityEnd}
                                onChange={(registrationDate) => {
                                  this.setState({
                                    vehicle: {
                                      ...this.state.vehicle,
                                      validityend: registrationDate.toFormat("yyyy-MM-dd")
                                    },
                                    errors: {...this.state.errors, dateError: ""}
                                  })
                                }}
                                date={DateTime.fromFormat(this.state.vehicle.validityend, "yyyy-MM-dd")}
                                errorMessage={this.state.errors.dateError}/>
                </div>
                <div className={"w-h"}>
                  <TextInput className={"text-input"} errorMessage={this.state.errors.telephone}
                             onChange={(e) => this.setState({
                               vehicle: {...this.state.vehicle, telephone: e},
                               errors: {...this.state.errors, telephone: ""}
                             })}
                             label={I18n.get().VehicleModal.telephone} defaultValue={this.props.model.telephone}
                             placeholder={I18n.get().VehicleModal.telephone}/>
                </div>
              </div>
              <div className={"row checkbox-row"}>
                <div className={"w-h"}>
                  <label className={"checkbox-label"}>Aria</label>
                  <RadioBoolean onChange={(v) => {
                    this.setState({vehicle: {...this.state.vehicle, air: v}})
                  }} name={"air"} value={this.state.vehicle.air} trueValueLabel={I18n.get().VehicleModal.yes}
                                falseValueLabel={I18n.get().VehicleModal.no}/>
                </div>
                <div className={"w-h"}>
                  <label className={"checkbox-label"}>Obesi</label>
                  <RadioBoolean onChange={(v) => {
                    this.setState({vehicle: {...this.state.vehicle, obese: v}})
                  }} name={"obese"} value={this.state.vehicle.obese} trueValueLabel={I18n.get().VehicleModal.yes}
                                falseValueLabel={I18n.get().VehicleModal.no}/>
                </div>
              </div>
              <div className={"row checkbox-row"}>
                <div className={"w-h"}>
                  <label className={"checkbox-label"}>Termo culla</label>
                  <RadioBoolean onChange={(v) => {
                    this.setState({vehicle: {...this.state.vehicle, babycradle: v}})
                  }} name={"babyCradle"} value={this.state.vehicle.babycradle}
                                trueValueLabel={I18n.get().VehicleModal.yes}
                                falseValueLabel={I18n.get().VehicleModal.no}/>
                </div>
                <div className={"w-h"}>
                  <label className={"checkbox-label"}>PC Car</label>
                  <RadioBoolean onChange={(v) => {
                    this.setState({vehicle: {...this.state.vehicle, pccar: v}})
                  }} name={"pcCar"} value={this.state.vehicle.pccar} trueValueLabel={I18n.get().VehicleModal.yes}
                                falseValueLabel={I18n.get().VehicleModal.no}/>
                </div>
              </div>
              <div className={"row checkbox-row"}>
                <div className={"w-h"}>
                  <label className={"checkbox-label"}>Telepass</label>
                  <RadioBoolean onChange={(v) => {
                    this.setState({vehicle: {...this.state.vehicle, telepass: v}})
                  }} name={"telepass"} value={this.state.vehicle.telepass} trueValueLabel={I18n.get().VehicleModal.yes}
                                falseValueLabel={I18n.get().VehicleModal.no}/>
                </div>
                <div className={"w-h"}>
                  <label className={"checkbox-label"}>Pedimate</label>
                  <RadioBoolean onChange={(v) => {
                    this.setState({vehicle: {...this.state.vehicle, pedimate: v}})
                  }} name={"pedimate"} value={this.state.vehicle.pedimate} trueValueLabel={I18n.get().VehicleModal.yes}
                                falseValueLabel={I18n.get().VehicleModal.no}/>
                </div>
              </div>
            </div>

            <div className={"buttons"}>
              <Button onClick={(event) => this.onSubmit(event)}
                      className={"add-btn"}>
                {this.props.type == ModalType.CREATE ? I18n.get().WorkersRegistry.confirmAddWorker :
                  I18n.get().WorkersRegistry.confirmModifyWorker}
              </Button>
              <Button secondary={true}
                      onClick={() => this.onCancel()}
                      className={"cancel-btn"}>
                {I18n.get().WorkersRegistry.cancelAddWorker}</Button>
            </div>
          </div>
        </form>
      </div>
    )
  }
}
