import React, {Component} from "react";
import {DateCodePairDto, MonthlyPlannedRow} from "../../../../api/entity/MonthlyPlannedRow";
import TimeOffApi, {TimeOffTypeDto, TimeOffUtils} from "../../../../api/timeOff/TimeOffApi";
import MonthlyPlanningStepApi from "../../../../api/planning/MonthlyPlanningStepApi";
import WorkerRegistryApi from "../../../../api/worker/WorkerRegistryApi";
import {WorkerDto} from "../../../../api/entity/WorkerDto";
import {ShiftDto} from "../../../../api/entity/ShiftDto";

import {
  EditingShift,
  MonthlyPlanningModal,
  Type
} from "../../../../components/Modal/MonthlyPlanningModal/MonthlyPlanningModal";
import I18n from "../../../../lib/I18n";
import SearchInput from "../../../../components/SearchInput/SearchInput";
import Button from "../../../../components/Button/Button";
import MonthlyTimeLineWithShiftEdit from "./MonthlyTimeLineWithShiftEdit";
import {DateTime} from "luxon";
import WizardStepOneNavigation from "./WizardStepOneNavigation";

import "./WizardStepOne.scss"
import {EmployeeEdit} from "../../MonthlyTimeLineUtils";
import IconError from "../../../../icons/IconError";
import {FixedShiftDto} from "../../../../api/entity/FixedShiftDto";

interface Props {
  currentDate: DateTime
  workers: WorkerDto[]
  excludedWorkers: WorkerDto[]
  shifts: ShiftDto[]
  fixedShifts: FixedShiftDto[]
  goToStepTwo: () => void
  goBack: () => void
  lastDayMap: { [id: number]: string[] },
  circuitFilter: string
}

interface State {
  search: string;
  isModalOpen: boolean,
  monthlyPlannedRows: MonthlyPlannedRow[]
  timeOffTypes: TimeOffTypeDto[]
  editingShift: EditingShift
  cannotAdvanceReason: string,
}


export default class WizardStepOne extends Component<Props, State> {
  /**************************************************
   * CONSTRUCTOR
   **************************************************/
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      isModalOpen: false,
      monthlyPlannedRows: [],
      timeOffTypes: [],
      editingShift: null,
      cannotAdvanceReason: "",
    }

    this.loadPlannedShiftForMonth(this.props.currentDate)
  }

  public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (prevProps.circuitFilter != this.props.circuitFilter) {
      this.loadPlannedShiftForMonth(this.props.currentDate);
    }
  }

  /**************************************************
   * LIFECYCLE
   **************************************************/
  render() {
    const labels = I18n.get().MonthlyTimeLine;
    return <div className="WizardStepOne">

      {this.state.isModalOpen && <MonthlyPlanningModal
        onSubmit={(editingShift) => this.saveEditedShift(editingShift)}
        editingShift={this.state.editingShift}
        onCancel={() => this.closeModal()}
        shifts={this.props.shifts}
        timeOffTypes={this.state.timeOffTypes}
      />}

      <div className="WizardStepOne-actionBar">
        <SearchInput label="" defaultValue="" placeholder={labels.searchLabel}
                     onChange={search => this.setState({search})}/>

        {this.props.excludedWorkers.length > 0 &&
        <div className={"dehactivated-users"}>
          <IconError color={"#ffca8c"}/> <span>{I18n.get().MonthlyPlanning.incompleteMessage}</span>
        </div>
        }
        <div>

        </div>
        <div title={this.state.cannotAdvanceReason}>
          <Button disabled={!this.canGoToStepTwo()}
                  onClick={() => this.props.goToStepTwo()}>{labels.next}
          </Button>
        </div>
      </div>

      <WizardStepOneNavigation
        onClick={() => this.props.goBack()}
        currentDate={this.props.currentDate}
      />

      <MonthlyTimeLineWithShiftEdit
        excludedWorkers={this.props.excludedWorkers}
        currentDate={this.props.currentDate} workers={this.props.workers}
        shifts={this.props.shifts}
        search={this.state.search}
        circuitSearch={this.props.circuitFilter}
        fixedShifts={this.props.fixedShifts}
        timeOffTypes={this.state.timeOffTypes}
        monthlyPlannedRows={this.state.monthlyPlannedRows}
        lastDayMap={this.props.lastDayMap}
        onError={(errors) => {
          return
        }}
        onSelect={(employeeEdit) => this.editShift(employeeEdit)}/>
    </div>
  }

  /**************************************************
   * PRIVATE FUNCTIONS
   **************************************************/
  private async loadPlannedShiftForMonth(date: DateTime) {
    const timeOffTypes = await this.timeOffApi.getTypes()
    const monthlyPlannedRows = (
      await this.monthlyPlanningApi.getPlanned(date.startOf("month"))).content;
    this.setState({monthlyPlannedRows, timeOffTypes})
  }


  private openModal(employeeEdit: EmployeeEdit, workerDto: WorkerDto, value: string) {
    // TODO: we should not check based on values
    const type = this.props.shifts.filter(s => s.shiftCode == value).length > 0
      ? Type.SHIFT
      : (this.state.timeOffTypes.filter(t => t.value == value).length > 0
        ? Type.ABSENCE
        : Type.NULL);

    this.setState({
      editingShift: {
        day: DateTime.fromJSDate(employeeEdit.monthlyData.days[employeeEdit.dayIndex]),
        type,
        timeOff: Type.ABSENCE ? value : null,
        shift: Type.SHIFT ? value : null,
        worker: workerDto
      },
      isModalOpen: true
    })
  }

  private closeModal() {
    this.setState({
      editingShift: null,
      isModalOpen: false
    });
  }

  private async editShift(employeeEdit: EmployeeEdit) {
    let workerResponse = await this.workerRegistryApi.getDetails(employeeEdit.monthlyData.employees[employeeEdit.employeeIndex].id);
    let workerDto = workerResponse.content[0];

    let shift = this.getValue(employeeEdit, workerDto);
    this.openModal(employeeEdit, workerDto, shift)
  }

  private getValue(employeeEdit: EmployeeEdit, workerDto: WorkerDto) {
    let plannedRows = this.state.monthlyPlannedRows.filter(mp => mp.workerId == workerDto.id);
    if (plannedRows.length > 0)
      return plannedRows[0].dateToCode[employeeEdit.dayIndex].code;
    return "";
  }

  private async saveEditedShift(editingShift: EditingShift) {
    let startOfMonth = editingShift.day.startOf('month');
    let monthlyPlannedRow = this.state.monthlyPlannedRows.filter(row => row.workerId == editingShift.worker.id)[0]

    let value;

    if (editingShift.type == Type.SHIFT) {
      value = editingShift.shift
    }

    if (editingShift.type == Type.ABSENCE) {
      value = editingShift.timeOff
    }

    if (editingShift.type == Type.NULL) {
      value = ""
    }

    if (!monthlyPlannedRow?.dateToCode) {
      monthlyPlannedRow = {
        workerId: editingShift.worker.id,
        fullName: `${editingShift.worker.lastname} ${editingShift.worker.name}`,
        startDate: startOfMonth,
        dateToCode: WizardStepOne.generateEmptyMonthShiftsList(editingShift.day)
      }
    }

    // console.log(editingShift.day);
    let dayNumber = editingShift.day.day;
    let index = dayNumber - 1;
    let dateTime = editingShift.day.startOf('month').plus({day: dayNumber});
    monthlyPlannedRow.dateToCode[index] = {
      date: dateTime
        .toISO(), code: value, imported: "false"
    };

    const monthlyPlannedResponse = await this.monthlyPlanningApi.updateForMonth(
      {
        startDate: startOfMonth.toISODate(),
        plannedRowRequest: {
          workerId: editingShift.worker.id,
          dateToCode: monthlyPlannedRow.dateToCode
        }
      });

    this.setState({monthlyPlannedRows: monthlyPlannedResponse.content});
    this.closeModal()
  }

  private static generateEmptyMonthShiftsList(date: DateTime): DateCodePairDto[] {
    const beginningOfMonth = date.startOf('month');
    const returnedValue: DateCodePairDto[] = [];
    for (let i = 0; i < date.daysInMonth; i++) {
      returnedValue.push({
        date: beginningOfMonth.plus({days: i}).toISODate(),
        code: "",
        imported: "false"
      });
    }
    return returnedValue;
  }

  private canGoToStepTwo() {
    for (const row of this.state.monthlyPlannedRows) {
      let hasTurnSet = false;
      let hasUnsetDays = false;
      for (const dateCode of row.dateToCode) {
        if (!dateCode.code)
          hasUnsetDays = true;
        else if (!TimeOffUtils.isTimeOff(dateCode.code))
          hasTurnSet = true;

        if (hasTurnSet && hasUnsetDays) {
          const cannotAdvanceReason = `L'utente ${row.fullName} ha alcuni turni impostati, ma non tutti.\nImposta tutti i turni della riga, o rimuovi tutti i turni non-ferie dalla riga.`
          if (this.state.cannotAdvanceReason != cannotAdvanceReason)
            this.setState({cannotAdvanceReason})
          return false
        }
      }
    }
    if (this.state.cannotAdvanceReason)
      this.setState({cannotAdvanceReason: ""})
    return true;
  }

  /**************************************************
   * VARIABLES
   **************************************************/
  private monthlyPlanningApi: MonthlyPlanningStepApi = new MonthlyPlanningStepApi();
  private workerRegistryApi: WorkerRegistryApi = new WorkerRegistryApi();
  private timeOffApi: TimeOffApi = new TimeOffApi();
}
