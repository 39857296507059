import React, {Component} from "react";
import "./Select.scss";


export interface Props<T> {
  /** This defines additional classes for the Button */
  defaultValue: number,
  className: string,
  label: string,
  items: string[],
  /** This dictates what the button will do on click */
  onChange: (value: number) => void;
  /** Disables onclick
   *  @default false
   **/
  disabled: boolean;
}

interface State {
  value: number;
  values: string[];
}

export class Select<T> extends Component<Props<string>, State> {
  /************************************************
   * PROPS
   ************************************************/
  static defaultProps: Partial<Props<any>> = {
    defaultValue: 0,
    className: "",
    label: "",
    items: [],
    onChange: () => {
    },
    disabled: false
  }

  /************************************************
   * CONSTRUCTOR
   ************************************************/
  constructor(props: Props<T>) {
    super(props);
    this.state = {value: this.props.defaultValue, values: []}
  }

  componentDidMount() {
    this.setState({ value: this.props.defaultValue, values: this.props.items})
  }

  componentDidUpdate(prevProps: Readonly<Props<T>>, prevState: Readonly<State>, snapshot?: any) {
    if (prevProps != this.props) {
      this.setState({value: this.props.defaultValue, values: this.props.items.map((e) => e.toString())})
    }
  }

  /************************************************
   * LIFECYCLE
   ************************************************/
  render() {

    return (
      <div className={this.props.className + " Select"}>
        <label><span className="TextInput-label">{this.props.label}</span>
          <select value={this.state.values[this.state.value]} disabled={this.props.disabled}
                  onChange={(e) =>
            this.props.onChange(e.target.options.selectedIndex)}>
            {this.state.values.map((value, index) => {
              return <option key={index}>{value}</option>
            })}
          </select>
        </label>
      </div>
    )
  }
}

export default Select;
