import React, {Component, ReactElement} from 'react';
import "./TextInput.scss";
import TooltipWrapper from "../TooltipWrapper/TooltipWrapper";

export interface Props {
  className: string;
  label: string;
  defaultValue: string;
  placeholder: string;
  onChange: (value: string) => void;
  readonly: boolean,
  disabled: boolean,
  password: boolean
  errorMessage: string
  isNumeric?: boolean
  internalLabel?: string
  value? : string
  tooltip?: {
    child: ReactElement,
    text: string
  }
  onKeyPressValidation?: (event: React.KeyboardEvent) => void;
  cancelSignal?: number;
}

interface State {
  value: string
}

export default class TextInput extends Component<Props, State> {
  /************************************************
   * PROPS
   ************************************************/
  static defaultProps: Partial<Props> = {
    className: "",
    label: "",
    defaultValue: "",
    placeholder: "",
    onChange: () => {
    },
    readonly: false,
    disabled: false,
    errorMessage: "",
    internalLabel: "",
    isNumeric: false,
    onKeyPressValidation: () => {}
  };

  /************************************************
   * CONSTRUCTOR
   ************************************************/
  protected constructor(props: Props) {
    super(props);
    this.state = {
      value: this.props.defaultValue,
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if(this.props.defaultValue != prevProps.defaultValue){
      this.setState({value: this.props.defaultValue});
    }

    if (this.props.cancelSignal != prevProps.cancelSignal) {
      this.setState({value: ""});
    }
  }

  /************************************************
   * LIFECYCLE
   ************************************************/
  render() {

    return <label className={`TextInput ${this.props.className}`}>
      {!!this.props.label && <span className="TextInput-label">{this.props.label}
        {this.props.tooltip ?
          <span><TooltipWrapper content={this.props.tooltip.text} children={this.props.tooltip.child}/></span> : null}
      </span>}
      <input
        type={this.props.password ? "password" : this.props.isNumeric ? "number" : "text"}
        min={0}
        disabled={this.props.disabled}
        readOnly={this.props.readonly}
        value={this.state.value ? this.state.value : ""}
        onChange={event => this.handleChange(event)}
        onKeyDown={event => this.props.onKeyPressValidation(event)}
        placeholder={this.props.placeholder}
        className={!!this.props.errorMessage ? "TextInput-borderError" : ""}
      />
      {this.props.internalLabel ? <label className={"Internal-label"}>{this.props.internalLabel}</label> : null}
      {!!this.props.errorMessage && <span className="TextInput-error">{this.props.errorMessage}</span>}
    </label>
  }

  handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({value: event.target.value})
    this.props.onChange(event.target.value);
  }

  public static preventOtherKeysButNumber(e: React.KeyboardEvent) {
    if((e.keyCode == 8) || (e.keyCode == 46) ||
      (e.keyCode == 39) || (e.keyCode == 37) ||
      (e.keyCode == 9) ||
      isFinite(parseInt(e.key))){
      return;
    }
    e.preventDefault();
  }
}
