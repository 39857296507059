import AuthorizeApi from "../AuthorizeApi";
import {DateTime} from "luxon";
import GfDateUtils from "../../components/DatePicker/GfDateUtils";
import {
  TotemHrResponse,
  TotemHrResponseDto,
  TotemManagementResponse,
  TotemManagementResponseDto,
  TotemNursingResponse,
  TotemNursingResponseDto
} from "../entity/response/TotemResponse";
import {TotemHrEntryDto, TotemManagementEntryDto, TotemNursingEntryDto} from "../entity/TotemDto";

export default class TotemApi {

  private base: string = '/user/totem';
  private baseModify: string = '/totem';

  private authApi: AuthorizeApi = new AuthorizeApi();

  public async getHr(date: DateTime, circuit: string ) {
    let radioResponse: TotemHrResponse;
    const dateAsString = date.toFormat(GfDateUtils.STORED_DATE_FORMAT);
    await this.authApi.get <TotemHrResponseDto>(`${this.base}/hr/${circuit}/${dateAsString}`)
      .then((w) => radioResponse = {
        entries: w.entries.map(t => {
          return {
            date: DateTime.fromFormat(t.date, GfDateUtils.STORED_DATE_FORMAT),
            id: t.id,
            time: t.time,
            time2: t.time2,
            time3: t.time3
          }
        })
      });
    return radioResponse;
  }

  public async getNursing(date: DateTime, circuit: string) {
    let radioResponse: TotemNursingResponse;
    const dateAsString = date.toFormat(GfDateUtils.STORED_DATE_FORMAT);
    await this.authApi.get <TotemNursingResponseDto>(`${this.base}/nursing/${circuit}/${dateAsString}`)
      .then((w) => radioResponse = {
        entries: w.entries.map(t => {
          return {
            date: DateTime.fromFormat(t.date, GfDateUtils.STORED_DATE_FORMAT),
            id: t.id,
            time: t.time,
            time2: t.time2,
            time3: t.time3
          }
        })
      });
    return radioResponse;
  }

  public async getManagement(date: DateTime, circuit: string) {
    let radioResponse: TotemManagementResponse;
    const dateAsString = date.toFormat(GfDateUtils.STORED_DATE_FORMAT);
    await this.authApi.get <TotemManagementResponseDto>(`${this.base}/management/${circuit}/${dateAsString}`)
      .then((w) => {
        radioResponse = {
          entries: w.entries.map(t => {
            return {
              date: DateTime.fromFormat(t.date, GfDateUtils.STORED_DATE_FORMAT),
              id: t.id,
              time: t.time,
              time2: t.time2,
              time3: t.time3
            }
          })
        };
      });
    return radioResponse;
  }

  async upsertHrValue(entry: TotemHrEntryDto) {
    await this.authApi.post <TotemHrEntryDto, TotemHrResponseDto>(`${this.baseModify}/hr`, entry)
  }

  async upsertManagementValue(entry: TotemManagementEntryDto) {
    await this.authApi.post <TotemManagementEntryDto, TotemManagementResponseDto>(`${this.baseModify}/management`, entry)
  }

  async upsertNursingValue(entry: TotemNursingEntryDto) {
    await this.authApi.post <TotemNursingEntryDto, TotemNursingResponseDto>(`${this.baseModify}/nursing`, entry)
  }
}
