import AuthorizeApi from "../AuthorizeApi";
import {ElapsedNeedResponse} from "../entity/response/ElapsedNeedResponse";
import {ReadTimeRequest} from "../entity/request/ReadTimeRequest";

export default class ElapsedNeedApi {

  private base: string = '/elapsed-need';
  private authApi: AuthorizeApi = new AuthorizeApi();

  public async searchByDate(date: string = null, count : number, search: string, skip: number, size: number, circuit: string) {
    const req : ReadTimeRequest = {
      count: count,
      date: date,
      searchKey: search,
      circuit: circuit,
      skip: skip,
      size: size
    }
    return await this.authApi.post <ReadTimeRequest, ElapsedNeedResponse>(`${this.base}`, req);
  }

}
