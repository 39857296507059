import React from "react";
import Icon, {Props} from "./Icon";

export default class IconTelephone extends Icon {
  static defaultProps: Partial<Props> = {
    height: 18,
    width: 18,
    color: "#2E3A59"
  };

  render() {
    return (
      <svg className="IconClock"
           width={this.props.width}
           height={this.props.height}
           viewBox="0 0 18 18">
        <path fill={this.props.color} d="M10.862 8.195L9.799 9.258c-.492-.147-1.412-.48-1.994-1.063-.583-.582-.916-1.502-1.063-1.994l1.063-1.063c.26-.26.26-.682 0-.943L5.138 1.53c-.26-.261-.682-.261-.943 0L2.387 3.337c-.253.253-.396.601-.39.956.015.95.266 4.247 2.865 6.846 2.599 2.598 5.896 2.849 6.846 2.865h.019c.352 0 .684-.139.936-.39l1.808-1.809c.261-.26.261-.682 0-.942l-2.666-2.668c-.261-.26-.682-.26-.943 0zm-5.057 2C3.56 7.951 3.343 5.095 3.33 4.28l1.337-1.336L6.39 4.667l-.862.862c-.16.158-.228.388-.182.608.016.076.408 1.894 1.514 3.001 1.107 1.107 2.925 1.498 3.002 1.514.222.047.449-.021.608-.18l.862-.863 1.724 1.724-1.337 1.337c-.832-.014-3.679-.237-5.915-2.475z" mask="url(#psbr702fnc)"/>
      </svg>
    );
  }
}
