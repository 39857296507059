import React, {Component} from "react";
import "./ModifyEntryModal.scss";
import Button from "../../../components/Button/Button";
import I18n from "../../../lib/I18n";
import {DayValuesDto} from "../../../api/entity/DayValuesDto";
import TextInput from "../../../components/TextInput/TextInput";

interface Props {
  title: string,
  body: string,
  entriesLabels: string[],
  dayValueDto: DayValuesDto,
  onClick: (changedValue: DayValuesDto) => void
  onCancel: () => void
}

interface State {
  modifiedValue: DayValuesDto
}

export class ModifyEntryModal extends Component<Props, State> {

  public constructor(props) {
    super(props);
    this.state = {
      modifiedValue: props.dayValueDto
    }
  }

  componentDidMount() {
    this.setState({modifiedValue: this.props.dayValueDto});
  }

  /************************************************
   * LIFECYCLE
   ************************************************/
  render() {
    const {entriesLabels} = this.props;
    const {modifiedValue} = this.state;

    return (
      <div className="ModifyEntryModal-Overlay">
        <div className="ModifyEntryModal">
          <h2>{this.props.title}</h2>
          <p>{""}</p>
          <div className="ModifyEntryModal-Body">
            <p>
              {this.props.body}
            </p>
            <div className={"entry-row"}>

              <span>
                <TextInput label={entriesLabels[0]}
                           className={""} onChange={(value) => {
                  this.setState({modifiedValue: {...this.state.modifiedValue, morning: value}})
                }} defaultValue={modifiedValue.morning}/>
              </span>

              <span>
                <TextInput label={entriesLabels[1]} className={""} onChange={(value) => {
                  this.setState({modifiedValue: {...this.state.modifiedValue, central: value}})
                }} defaultValue={modifiedValue.central}/>
              </span>

              {entriesLabels[2] != "" ?
                <span>
                <TextInput label={entriesLabels[2]}
                           className={""} onChange={(value) => {
                  this.setState({modifiedValue: {...this.state.modifiedValue, night: value}})}} defaultValue={modifiedValue.night}/>
                </span>
                : null}
            </div>
          </div>
          <div className="ModifyEntryModal-Buttons">
            <Button onClick={() => this.props.onClick(this.state.modifiedValue)}
                    className="ConfirmModal-OkButton">
              Ok
            </Button>
            <Button secondary={true} onClick={() => this.props.onCancel()}
                    className="ModifyEntryModal-CancelButton">
              {I18n.get().MonthlyPlanning.cancelModal}
            </Button>
          </div>
        </div>
      </div>
    )
  }
}
