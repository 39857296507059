import React, {Component} from "react";
import "./RadioBoolean.scss";

interface Props {
  onChange: (value: boolean) => void
  name: string,
  value: boolean,
  trueValueLabel: string
  falseValueLabel: string
}

export class RadioBoolean extends Component<Props> {
  render() {
    return <div className={"radio-group"}>
      <span className={"w-h"}>
        <label>{this.props.trueValueLabel}</label>
      <input type={"radio"} name={this.props.name} onClick={() => this.props.onChange(true)} defaultChecked={this.props.value} />
      </span>
      <span className={"w-h"}>
        <label>{this.props.falseValueLabel}</label>
      <input type={"radio"} name={this.props.name} onClick={() => this.props.onChange(false)} defaultChecked={!this.props.value} />
      </span>
    </div>
  }
}
