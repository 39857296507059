import React from "react";
import "./DailyVehicleCell.scss"
import IconKebab from "../../../../icons/IconKebab";
import IconVehicle from "../../../../icons/IconVehicle";

interface Props {
  name: string
  type: string
  vehicleId: number
  id: string
  onClick: (x: number, y: number, id: number) => void
}


export default function DailyVehicleCell(props: Props) {
  return <div className="DailyVehicleCell" id={"dvc_" + props.id}>
    <div className={"DailyVehicleCell-machineName"}>
      {(props.type == "EM/INTERH")
        ? <>
          <div className="skillLabel EM">EM</div>
          <div className="skillLabel INTERH">INTERH</div>
        </>
        : <div className={"skillLabel " + (props.type == "EM+" ? "EM_PLUS" : props.type)}>{props.type}</div>}
      <div className={"clear"}/>
      <div>
        <IconVehicle />
        <div className="name">{props.name}</div>
      </div>
    </div>
    <div className="KebabIcon" onClick={() => {
      const element = document.getElementById("dvc_" + props.id);
      props.onClick(element.getBoundingClientRect().x, element.getBoundingClientRect().y, props.vehicleId);
    }}>
      <IconKebab/>
    </div>
  </div>
}
