import "./DailyVehicleLine.scss"
import React from "react";
import {DateTime} from "luxon";
import GfDateUtils from "../../../../components/DatePicker/GfDateUtils";

interface Props {
  onClick?: (id, position: Position) => void
  id?: number
  name: string
  start: string
  startDate?: DateTime
  endDate?: DateTime
  end: string
  textColor: string
}

export interface Position {
  x: number,
  y: number
}

export default function DailyVehicleLine(props: Props) {
  let {name, start, end, startDate, endDate} = props;
  const startDateElectedText = startDate ? startDate.toFormat(GfDateUtils.DATE_FORMAT) : "";
  const endElectedText = endDate ? endDate.toFormat(GfDateUtils.DATE_FORMAT) : "";
  const text = `${name} ${startDateElectedText} ${start} - ${endElectedText} ${end}`
  return <div className="DailyEmployeeLine"
              onClick={(event) => props.onClick(props.id, {x: event.clientX, y: event.clientY})}
              style={{color: props.textColor}}
              title={text}>{text}</div>
}

