import AuthorizeApi from "../AuthorizeApi";
import {DateTime} from "luxon";
import GfDateUtils from "../../components/DatePicker/GfDateUtils";
import {UserDailyShiftResponse} from "../entity/response/UserDailyShiftResponse";

export default class UserDailyShiftApi {

  private base: string = '/user/day-shifts';

  private authApi: AuthorizeApi = new AuthorizeApi();

  public async getByDate(dateTime: DateTime) : Promise<UserDailyShiftResponse> {
    let response: UserDailyShiftResponse;
    await this.authApi.get<UserDailyShiftResponse>(`${this.base}/` + dateTime.toFormat(GfDateUtils.STORED_DATE_FORMAT))
      .then((resp) => {
        response = resp;
      });
    return response;
  }



  public async export(date: DateTime) {

    return await this.authApi.download(`${this.base}/` +
      date.toFormat(GfDateUtils.STORED_DATE_FORMAT) + "/export",
      date.toFormat(GfDateUtils.STORED_DATE_FORMAT) + "-totem.xlsx",
      {}, {}
    );
  }


}
