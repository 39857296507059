import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
//Router component
import PrivateRoute from './router/PrivateRoute';

//Page components
import Login from './pages/Login/Login';
import Error404 from './pages/Error404/Error404';
import DailyPlanning from "./pages/DailyPlanning/DailyPlanning";
import ApplicationRootComponent from "./pages/ApplicationRootComponent";
import {Pages} from "./router/Pages";
import MonthlyPlanning from "./pages/MonthlyPlanning/MonthlyPlanning";
import WorkersRegistry from "./pages/WorkersRegistry/WorkersRegistry";
import ShiftRegistry from "./pages/ShiftRegistry/ShiftRegistry";
import NeedManagement from "./pages/NeedManagement/NeedManagement";
import {NeedModal} from "./pages/NeedModal/NeedModal";
import InformationModalController, {InformationModalProps} from "./components/Modal/InformationModal/InformationModalController";
import {InformationModal} from "./components/Modal/InformationModal/InformationModal";
import SettingModal from "./pages/SettingModal/SettingModal";
import StatusPoller from "./utils/StatusPoller";
import VehicleRegistry from "./pages/VehicleRegistry/VehicleRegistry";
import VehicleManagement from './pages/VehicleManagement/VehicleManagement';
import RadioCodeRegistry from "./pages/RadioCodeRegistry/RadioCodeRegistry";
import TotemWorkers from "./pages/TotemWorkers/TotemWorkers";
import TotemManagers from "./pages/TotemManagers/TotemManagers";
import DailyOverview from './pages/DailyOverview/DailyOverview';
import LoginApi from "./api/login/LoginApi";
import {Roles} from "./api/Roles";
import FullPagePrivateRoute from './router/FullPagePrivateRoute';

interface Props {
  onLogoutClick: () => void
}

interface State {
  modalProps: InformationModalProps
}

class Application extends ApplicationRootComponent<Props, State> {

  constructor(props) {
    super(props);
    this.state = {
      modalProps: null
    }

    if (LoginApi.isUserRoleHigherThanUser()) {
      this.modalController = InformationModalController.getInstance()
      this.modalController.listenToPropsChange(modalProps => this.setState({modalProps}))
    }
  }

  componentDidMount() {
    StatusPoller.getInstance().startOrStopStatusPoller()
  }

  componentDidUpdate() {
    StatusPoller.getInstance().startOrStopStatusPoller()
  }

  componentWillUnmount() {
    StatusPoller.getInstance().disposeInstance()
  }



  render() {
    return (
      <div>
        <Router>
          <Switch>
            <Route exact path={Pages.LOGIN} component={Login}/>
            <PrivateRoute exact path={Pages.MONTHLY_PLANNING} component={MonthlyPlanning}/>
            <PrivateRoute exact path={Pages.DAILY_PLANNING} component={DailyPlanning}/>
            <PrivateRoute exact path={Pages.WORKERS_REGISTRY} component={WorkersRegistry}/>
            <PrivateRoute exact path={Pages.VEHICLE_REGISTRY} component={VehicleRegistry}/>
            <PrivateRoute exact path={Pages.SHIFT_REGISTRY} component={ShiftRegistry}/>
            <PrivateRoute exact path={Pages.NEED_MANAGEMENT} component={NeedManagement}/>
            <PrivateRoute exact path={Pages.VEHICLE_MANAGEMENT} component={VehicleManagement}/>
            <PrivateRoute exact path={Pages.NEED_MANAGEMENT_MODAL} component={NeedModal}/>
            <PrivateRoute exact path={Pages.NEED_MANAGEMENT_MODIFY} component={NeedModal}/>
            <PrivateRoute exact path={Pages.RADIO_CODE} component={RadioCodeRegistry}/>
            <PrivateRoute exact path={Pages.TOTEM_WORKERS} component={TotemWorkers}/>
            <PrivateRoute exact path={Pages.TOTEM_MANAGERS} component={TotemManagers}/>
            <PrivateRoute exact path={Pages.TOOLS} component={SettingModal}/>
            <PrivateRoute exact path={Pages.TOTEM_WORKERS} component={SettingModal}/>
            <PrivateRoute exact path={Pages.TOTEM_MANAGERS} component={SettingModal}/>
            <FullPagePrivateRoute exact path={Pages.DAILY_OVERVIEW} component={DailyOverview}/>
            {/*  <Route exact path='/error' component={NoMatch}/> */}
            <Route component={Error404}/>
          </Switch>
        </Router>
        {this.state.modalProps && <InformationModal {...this.state.modalProps} />}
      </div>
    );
  }

  /**************************************************
   * VARIABLES
   **************************************************/
  private modalController: InformationModalController
}

export default Application;
