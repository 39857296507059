import React, {Component, ReactNode} from "react";
import "./TimeLine.scss"
import TimeLineXAxis from "../../../../components/TimeLine/TimeLineXAxis/TimeLineXAxis";
import {TimeLineYAxis} from "../../../../components/TimeLine/TimeLineYAxis/TimeLineYAxis";
import TimeLineSeparatorsHorizontal
  from "../../../../components/TimeLine/TimeLineSeparatorsHorizontal/TimeLineSeparatorsHorizontal";
import TimeLineDataRow from "../../../../components/TimeLine/TimeLineDataRow/TimeLineDataRow";
import TimeLineData from "../../../../components/TimeLine/TimeLineData";
import {KBMenuModal, KbModeType} from "../../../../components/KebabSmallMenuModal/KBMenuModal";
import TimeLineMatrix = TimeLineData.TimeLineMatrix;

interface Props {
  headers: ReactNode[]
  timeLines: TimeLineMatrix[]
  spacing: boolean
  columnWidth: number
  rowHeight: number
  headerHeight: number
  bufferCell: ReactNode

  kebabX: number,
  kebabY: number,
  isMenuHidden: boolean
  kebabModeType: KbModeType

  onScroll: () => void;
  onCreateClick: () => void;
  onDeleteClick: () => void;
  onToggleDeleteConfirm: () => void;
}


export default class TimeLine extends Component<Props> {
  /**************************************************
   * PRIVATE FUNCTIONS
   **************************************************/
  static defaultProps: Partial<Props> = {
    spacing: false,
    columnWidth: 80,
    headerHeight: 60,
    rowHeight: 60,
    bufferCell: null
  }

  /**************************************************
   * LIFECYCLE
   **************************************************/
  render() {
    const {
      // title,
      headers,
      timeLines,
      spacing,
      columnWidth,
      rowHeight,
      headerHeight,
      bufferCell,
    } = this.props;
    return <div className="TimeLine" onClick={()=> this.hideOpenMenu()}>


      <div className="TimeLine-content">
        <KBMenuModal positionX={this.props.kebabX} positionY={this.props.kebabY}
                     isHidden={this.props.isMenuHidden}
                     modeType={this.props.kebabModeType}
                     onDelete={() => this.props.onToggleDeleteConfirm()}
                     onCreate={() => this.props.onCreateClick()}/>
        <div className="TimeLine-table-wrapper" onScroll={() => {
          this.props.onScroll()
        }}>
          <TimeLineYAxis
            timeLines={timeLines}
            rowHeight={rowHeight}
            height={((timeLines.length) * rowHeight) + headerHeight}
            headerHeight={headerHeight}
            bufferCell={bufferCell}/>
          <div className="TimeLine-table" style={{
            minWidth: (headers.length * columnWidth),
            minHeight: ((timeLines.length) * rowHeight) + headerHeight
          }}>
            <TimeLineXAxis
              headers={headers}
              headerHeight={headerHeight}
            />
            <TimeLineSeparatorsHorizontal totalHeaders={headers.length}/>{
            timeLines.map((timeLine, i) => {
              return <TimeLineDataRow key={i} intervals={timeLine.intervals}
                                      segmentsNum={headers.length} spacing={spacing}
                                      style={{
                                        backgroundColor: timeLine.backgroundColor ? timeLine.backgroundColor : "transparent",
                                        height: rowHeight
                                      }}/>
            })}
          </div>
        </div>
      </div>
    </div>
  }

  private hideOpenMenu() {
    this.props.onScroll();
  }
}
