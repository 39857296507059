import React from "react";
import "./Legend.scss"

interface Props{
  legend:{label:string,color:string}[]
}

export class Legend extends React.Component<Props> {
  static defaultProps:Partial<Props> = {
    legend:[]
  }

  render() {
    return <div className="Legend">
      {this.props.legend.map(l => <div className="Legend-item">
        <div className="Legend-sample" style={{backgroundColor:l.color}} />
        <div className="Legend-label">
          {l.label}
        </div>
      </div>)}
    </div>;
  }
}
