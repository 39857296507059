import WorkerContractDtoNT from "./nt/WorkerContractDtoNT";
import {DateTime} from "luxon";

export class WorkerContractDto {

  id: number;
  idWorker: number;
  contractType: string;
  startDate: DateTime;
  endDate: DateTime;
  weekHour: number;
  oscillation: number;
  overrun: number;
  // flagNottiConsecutive: boolean;
  // numNottiConsecutive: number;

  constructor(dtoNt: WorkerContractDtoNT) {
    this.id = dtoNt.id;
    this.idWorker = dtoNt.idWorker;
    this.contractType = dtoNt.contractType;
    this.startDate = DateTime.fromISO(dtoNt.startDate);
    this.endDate = DateTime.fromISO(dtoNt.endDate);
    this.weekHour = dtoNt.weekHour;
    this.oscillation = dtoNt.oscillation;
    this.overrun = dtoNt.overrun;
  }
}
