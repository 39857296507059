import I18n from "../../lib/I18n";


export class HeightOptionUtils {
  public static getHeightInternationalized(heightOption: string) {
    if (heightOption == "") {
      return ""
    }
    if (heightOption == "MEDIUM") {
      return I18n.get().WorkersRegistry.heightOptions.average
    }
    if (heightOption == "SHORT") {
      return I18n.get().WorkersRegistry.heightOptions.short
    }
    if (heightOption == "TALL") {
      return I18n.get().WorkersRegistry.heightOptions.tall
    }

  }
}
