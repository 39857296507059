import I18n from "../../../lib/I18n";
import {EngineConfigDto} from "../../entity/EngineConfigDto";
import {EngineConfError, EngineConfErrors} from "../../../pages/SettingModal/SettingModal";
import {DateTime} from "luxon";
import GfDateUtils from "../../../components/DatePicker/GfDateUtils";
import TimeInputPicker from "../../../components/TimeInputPicker/TimeInputPicker";
import { max } from "moment";

export default class EngineConfigValidator {

  public static validate(engineConfDto: EngineConfigDto): EngineConfError {
    const errors: EngineConfError = this.getEmptyError();

    if (!this.isPositiveInt(engineConfDto.dayConstraintPerMonth.toString())) {
      errors.dayConstraintPerMonth = I18n.get().Items.errors.valueOutOfBound
    }

    if (!this.isPositiveInt(engineConfDto.maxNumberOfNightsPerWeek.toString())) {
      errors.maxNumberOfNightsPerWeek = I18n.get().Items.errors.valueOutOfBound
    }
    if (!this.isPositiveInt(engineConfDto.maxNumberOfMorningPerMonth.toString())) {
      errors.maxNumberOfMorningPerMonth = I18n.get().Items.errors.valueOutOfBound
    }
    if (!this.isPositiveInt(engineConfDto.maxNumberOfAfternoonPerMonth.toString())) {
      errors.maxNumberOfAfternoonPerMonth = I18n.get().Items.errors.valueOutOfBound
    }
    if (!this.isPositiveInt(engineConfDto.maxNumberOfLongPerMonth.toString())) {
      errors.maxNumberOfLongPerMonth = I18n.get().Items.errors.valueOutOfBound
    }

    if (!this.isTimeInCorrectFormatIgnoreOutOfRange(engineConfDto.intervalsTurn)) {
      errors.intervalsTurn = I18n.get().Items.errors.valueError
    }
    if (!this.isTimeInCorrectFormatIgnoreOutOfRange(engineConfDto.intervalsWeekend)) {
      errors.intervalsWeekend = I18n.get().Items.errors.valueError
    }
    if (!this.isPositiveInt(engineConfDto.maxNumberPerWeekLength.toString())) {
      errors.maxNumberPerWeekLength = I18n.get().Items.errors.valueOutOfBound
    }
    if (!this.isPositiveInt(engineConfDto.intervalLongTurns.toString())) {
      errors.intervalLongTurns = I18n.get().Items.errors.valueOutOfBound
    }
    if (!this.isPositiveInt(engineConfDto.maximumAvailabilityPerMonth.toString())) {
      errors.maximumAvailabilityPerMonth = I18n.get().Items.errors.valueOutOfBound
    }
    // if (!this.isPositiveInt(engineConfDto.otherTurns.toString())) {
    //   errors.otherTurns = I18n.get().Items.errors.valueOutOfBound
    // }
    if (!this.isPositiveInt(engineConfDto.availabilityFirst.toString())) {
      errors.availabilityFirst = I18n.get().Items.errors.valueOutOfBound
    }
    if (!this.isPositiveInt(engineConfDto.tutorTimeLength.toString())) {
      errors.tutorTimeLength = I18n.get().Items.errors.valueOutOfBound
    }
    if (!this.isPositiveInt(engineConfDto.noTurnsPerDay.toString())) {
      errors.noTurnsPerDay = I18n.get().Items.errors.valueOutOfBound
    }
    if (!this.isPositiveInt(engineConfDto.cleaningInterval.toString())) {
      errors.cleaningInterval = I18n.get().Items.errors.valueOutOfBound
    }
    if (!this.isPositiveInt(engineConfDto.numMezziLav1.toString())) {
      errors.numMezziLav1 = I18n.get().Items.errors.valueOutOfBound
    }
    if (!this.isPositiveInt(engineConfDto.numMezziLav2.toString())) {
      errors.numMezziLav2 = I18n.get().Items.errors.valueOutOfBound
    }


    return errors;
  }

  private static isTimeInCorrectFormat(value: string) {
    if(!new RegExp(TimeInputPicker.regexTime).test(value)) return false;
    return !GfDateUtils.isTimeOutOfRange(value.split(GfDateUtils.TIME_SEPARATOR));
  }

  private static isTimeInCorrectFormatIgnoreOutOfRange(value: string) {
    return new RegExp(TimeInputPicker.regexTime).test(value)
  }

  public static isAnyErrorPresent(shiftModalErrors: EngineConfErrors): boolean {
    let validationResults = shiftModalErrors.engineConfErrors.map(engineConfError=> {
      let values = Object.values(engineConfError);
      return values.filter(a => a.length != "").length > 0;
    });
    return validationResults.filter(isError => isError == true).length == 0;
  }

  public static isOneOrGreater(value: string) {
    let n = Math.floor(Number(value));
    return n > 0;
  }

  public static isPositiveInt(value: string) {
    let n = Math.floor(Number(value));
    return n !== Infinity && String(n) === value && n >= 0;
  }

  public static getEmptyError() {
    return {
      dayConstraintPerMonth: "",
      maxNumberOfNightsPerWeek: "",
      maxNumberOfMorningPerMonth: "",
      maxNumberOfAfternoonPerMonth: "",
      maxNumberOfLongPerMonth: "",
      intervalsTurn: "",
      intervalsWeekend: "",
      maxNumberPerWeekLength: "",
      intervalLongTurns: "",
      maximumAvailabilityPerMonth: "",
      otherTurns: "",
      availabilityFirst: "",
      tutorTimeLength: "",
      noTurnsPerDay: "",
      cleaningInterval: "",
      numMezziLav1: "",
      numMezziLav2: ""
    };
  }
}
