import AuthorizeApi from "../AuthorizeApi";
import {MonthlyPlannedResponse} from "../entity/response/MonthlyPlannedResponse";
import {MonthlyPlannedRequest} from "../entity/request/MonthlyPlannedRequest";
import {MonthlyPlannedResponseNt} from "../entity/response/nt/MonthlyPlannedResponseNt";
import {PlannedShiftRequest} from "../entity/request/PlannedShiftRequest";
import {DateTime} from "luxon";
import GfDateUtils from "../../components/DatePicker/GfDateUtils";

export default class MonthlyPlanningStepApi {

  private base: string = '/monthly';

  private authApi: AuthorizeApi = new AuthorizeApi();

  public async getPlanned(date: DateTime): Promise<MonthlyPlannedResponse> {
    const response = await this.authApi.post<MonthlyPlannedRequest, MonthlyPlannedResponseNt>(`${this.base}`,
      {date: date.toFormat(GfDateUtils.STORED_DATE_FORMAT)})
    return MonthlyPlanningStepApi.mapToNeedResponse(response)
  }

  public async updateForMonth(planningRequest: PlannedShiftRequest): Promise<MonthlyPlannedResponse> {
    const response = await this.authApi.put<PlannedShiftRequest,
      MonthlyPlannedResponseNt>(`${this.base}/update`, planningRequest)
    return MonthlyPlanningStepApi.mapToNeedResponse(response);
  }

  private static mapToNeedResponse(monthlyPlannedRowNt: MonthlyPlannedResponseNt): MonthlyPlannedResponse {
    return new MonthlyPlannedResponse(monthlyPlannedRowNt);
  }
}
