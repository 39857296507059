import TimeLine from "../../../components/TimeLine/TimeLine";
import React, {Component, ReactNode} from "react";
import {DailyPlanningWorkerDtoNt} from "../../../api/entity/response/nt/DailyPlanningWorkerResponseNt";
import {DateTime} from "luxon";
import {NeedDto} from "../../../api/entity/NeedDto";
import Time from "../../../lib/utils/Time";
import TimeLineData from "../../../components/TimeLine/TimeLineData";
import DailyServiceHeader from "../DailyServicesTimeLineOverview/DailyServicesHeader/DailyServiceHeader";
import DailyServiceLine from "../DailyServicesTimeLineOverview/DailyServiceLine/DailyServiceLine";
import DailyServiceCell from "../DailyServicesTimeLineOverview/DailyServiceCell/DailyServiceCell";
import {WorkerDto} from "../../../api/entity/WorkerDto";
import DailyEmployeeLine from "../../Dashboard/DailyPlanning/DailyEmployeeLine/DailyEmployeeLine";
import Range from "../../../utils/Range";
import NeedUtils from "../../../api/need/NeedUtils";
import {VehicleDailyPlanningDto} from "../../../api/entity/VehicleDailyPlanningDto";
import DailyVehicleCell from "../DailyVehiclesTimeLine/DailyVehicleCell/DailyVehicleCell";
import {VehicleDto} from "../../../api/entity/VehicleDto";
import VehiclesTimeLine from "../DailyVehiclesTimeLine/VehiclesTimeLine";
import TimeLineMatrix = TimeLineData.TimeLineMatrix;
import Interval = TimeLineData.Interval;
import {ContainerVehicle, TimeLineUtils} from "../TimeLineUtils";
import {DailyContainerVehicle} from "../../../api/entity/response/nt/ContainerVehicleResponseNt";
import DailyEmployeeCell from "../../Dashboard/DailyPlanning/DailyEmployeeCell/DailyEmployeeCell";
import {CircuitSelect} from "../../../components/CircuitSelect/CircuitSelect";
import {NeedVehicleDto} from "../../../api/entity/NeedVehicleDto";

interface Props {
  day: DateTime,
  dailyWorkersForService: DailyPlanningWorkerDtoNt[]
  dailyVehicleForService: VehicleDailyPlanningDto[]
  dailyContainerVehiclesForService: DailyContainerVehicle[]
  need: NeedDto
  allWorkers: WorkerDto[]
  vehicleNeeds: Map<number, NeedVehicleDto>,
  allVehicles: VehicleDto[]
  circuitFilter: string,
  radioCodeMap: Map<number, string>
}

interface State {
}

interface VehicleToIntervals {
  vDto: VehicleDailyPlanningDto
  intervals: Interval[]
}

interface WorkerToIntervals {
  dwto: DailyPlanningWorkerDtoNt
  intervals: Interval[]
}

export default class DailyServicesTimeLineDetail extends Component<Props, State> {

  private BG_COLOR = "rgb(243, 246, 248)";
  public static EMPTY_ENTRY_ID: number = -1;

  /**************************************************
   * LIFECYCLE
   **************************************************/
  render() {
    return <TimeLine
      headers={this.dailyServicesHeaders(this.props.day)}
      timeLines={this.dailyTimeLines()}
    />
  }

  /**************************************************
   * PRIVATE FUNCTIONS
   **************************************************/
  private dailyServicesHeaders(currentDate: DateTime): ReactNode[] {
    const headers: ReactNode[] = []
    const hours = Time.getHoursInDay()
    for (const hour of hours)
      headers.push(<DailyServiceHeader day={currentDate.toJSDate()} hour={hour}/>)
    return headers
  }

  public dailyTimeLines(): TimeLineMatrix[] {
    const timeLines: TimeLineMatrix[] = [];
    let distinctContainerVehicles = TimeLineUtils.getContainerVehiclesForServices(
      this.props.dailyWorkersForService,
      this.props.dailyVehicleForService,
      this.props.dailyContainerVehiclesForService,
      this.props.radioCodeMap)[this.props.need.serviceCode];


    if(!distinctContainerVehicles)
      distinctContainerVehicles = []

    for (const containerVehicle of distinctContainerVehicles) {

      timeLines.push(this.getDailyServiceTimeLine(containerVehicle))

      const vehiclesTimelines = this.dailyVehicleTimeLines(containerVehicle);
      vehiclesTimelines.forEach((wt) => timeLines.push(wt));

      const workersTimelines = this.dailyWorkersTimeLines(containerVehicle);
      workersTimelines.forEach((wt) => timeLines.push(wt));
    }

    return timeLines;
  }

  private getDailyServiceTimeLine(containerVehicle: ContainerVehicle) {
    let timelineIntervals: Interval[] = []

    // if you want to have the total duration of the service
    // timelineIntervals = timelineIntervals.concat(TimeLineUtils.getTodayNeedIntervals(this.props.day, this.props.need, this.props.shifts, "rgba(169,226,252,0.5)"))

    for (const range of containerVehicle.ranges) {
      if(range.start != range.end)
        timelineIntervals.push({
          color: "#A9E2FC",
          start: range.boundStart,
          end: range.boundEnd,
          content: <DailyServiceLine
            name={containerVehicle.container}
            start={range.startString}
            end={range.endString}
          />
        })
    }
    // }

    let totalActualHumanNeed = NeedUtils.getActualServiceHumanNeedByContainerVehicle(this.props.dailyWorkersForService, containerVehicle.container);
    let totalActualVehicleNeed = NeedUtils.getActualServiceVehicleNeedByContainerVehicle(this.props.dailyVehicleForService, containerVehicle.container);

    // const types = []
    // for (const daily of this.props.dailyWorkersForService) {
    //   if (daily.containervehicle == containerVehicle.container)
    //     types.push(daily.servicecategory)
    // }

    const electedName = !containerVehicle.radioCodeName ? containerVehicle.container : containerVehicle.radioCodeName;
    return {
      // backgroundColor: "#f3f6f8",
      intervals: timelineIntervals,
      title: <DailyServiceCell
        actualHumanNeed={totalActualHumanNeed}
        actualVehicleNeed={totalActualVehicleNeed}
        name={electedName}
        types={containerVehicle.categories}
      />
    }
  }

  private dailyVehicleTimeLines(containerVehicle: ContainerVehicle): TimeLineMatrix[] {
    const filteredByServiceCodeAndContainerVehicle: VehicleDailyPlanningDto[] =
      this.props.dailyVehicleForService.filter(
        (dwDto) => dwDto.service == this.props.need.serviceCode &&
          dwDto.containerVehicle == containerVehicle.container && dwDto.vehicleId > DailyServicesTimeLineDetail.EMPTY_ENTRY_ID
      )
    const circuitFilter = this.props.circuitFilter;
    const vehicles = VehiclesTimeLine.mapVehicles(this.props.allVehicles)
      .filter(v => v.circuit && circuitFilter == CircuitSelect.NO_FILTER
        || circuitFilter.toLowerCase() == v.circuit.toLowerCase());
    const vehicleIntervals = this.mapToVehicleEntriesToIntervals(filteredByServiceCodeAndContainerVehicle);
    const presentVehicles = vehicles.filter(v => vehicleIntervals.filter(vi => vi.vDto.vehicleId == v.id).length > 0);

    return presentVehicles.map((it) => {
      const vehicleDaily = vehicleIntervals.filter(vD => vD.vDto.vehicleId == it.id)[0];
      return {
        backgroundColor: "#e6e9ed",
        intervals: vehicleDaily.intervals,
        title: <DailyVehicleCell
          className={"bg-gray"}
          vehicle={it}
          id={it.id.toString()}
          type={it.category}
          onClick={() => {
          }}
        />
      }
    });
  }


  private dailyWorkersTimeLines(containerVehicle: ContainerVehicle): TimeLineMatrix[] {

    const filteredByServiceCodeAndContainerVehicle: DailyPlanningWorkerDtoNt[] =
      this.props.dailyWorkersForService.filter(
        (dwDto) => dwDto.service == this.props.need.serviceCode &&
          dwDto.containervehicle == containerVehicle.container
      )

    const dailyWorkersInRegistryEntries =
      filteredByServiceCodeAndContainerVehicle.filter((dw) => this.getWorkerById(dw.workerid))

    const spotWorkersEntries =
      filteredByServiceCodeAndContainerVehicle.filter((dw) =>
        !this.getWorkerById(dw.workerid) && dw.shiftstart && dw.shiftend && dw.workername &&
        CircuitSelect.NO_FILTER == this.props.circuitFilter);

    const workerToIntervalsHavingAtLeastOneInterval =
      this.mapToWorkerEntriesToIntervals(dailyWorkersInRegistryEntries).filter(dw=> dw.intervals.length != 0);
    const spotWorkersToIntervalsHavingAtLeastOneInterval =
      this.mapToWorkerEntriesToIntervals(spotWorkersEntries).filter(dw=> dw.intervals.length != 0);

    const spotWorkersToTimeLines = spotWorkersToIntervalsHavingAtLeastOneInterval.map(wToIntervals => {
      return {
        backgroundColor: "#f3f6f8",
        intervals: wToIntervals.intervals,
        title: <DailyEmployeeCell
          className={"create_employee"}
          mainService={wToIntervals.dwto.service}
          name={wToIntervals.dwto.workername}
          skill={""}
          background={this.BG_COLOR}/>
      }
    })

    const workersToTimeLines = workerToIntervalsHavingAtLeastOneInterval.map(wToIntervals => {
      let worker = this.getWorkerById(wToIntervals.dwto.workerid);
      return {
        backgroundColor: "#f3f6f8",
        intervals: wToIntervals.intervals,
        title: <DailyEmployeeCell mainService={wToIntervals.dwto.service}
                                  skill={""}
                                  name={`${worker.lastname} ${worker.name}`}
                                  background={this.BG_COLOR}
                                  role={worker.role}/>
      }
    })

    return workersToTimeLines.concat(spotWorkersToTimeLines);
  }

  private mapToWorkerEntriesToIntervals(entries: DailyPlanningWorkerDtoNt[]): WorkerToIntervals[] {
    let workerToEntries: WorkerToIntervals[] = [];
    entries.forEach(dw => {

      let groupIndex = -1;
      // Check if in groups a dw like the one observed already exists;
      for (let i = 0; i < workerToEntries.length; i++) {
        let wToIntervals = workerToEntries[i];
        if (wToIntervals.dwto == dw) {
          groupIndex = i;
          break;
        }
      }
      // Either add a new entry or push it into the correct group
      const interval = this.getInterval(dw);
      const isEmptyContainerVehicleRange = interval.start == null || interval.end == null;
      if(isEmptyContainerVehicleRange) { return; }
      if (groupIndex == -1) {
        workerToEntries.push({dwto: dw, intervals: [interval]});
      } else {
        workerToEntries[groupIndex].intervals.push(interval);
      }
    });
    return workerToEntries;
  }

  private mapToVehicleEntriesToIntervals(entries: VehicleDailyPlanningDto[]): VehicleToIntervals[] {
    let workerToEntries: VehicleToIntervals[] = [];
    entries.forEach(dw => {

      let groupIndex = -1;
      // Check if in groups a dw like the one observed already exists;
      for (let i = 0; i < workerToEntries.length; i++) {
        let wToIntervals = workerToEntries[i];
        if (wToIntervals.vDto == dw) {
          groupIndex = i;
          break;
        }
      }
      // Either add a new entry or push it into the correct group
      if (groupIndex == -1) {
        workerToEntries.push({vDto: dw, intervals: [this.getIntervalForVehicle(dw)]});
      } else {
        workerToEntries[groupIndex].intervals.push(this.getIntervalForVehicle(dw));
      }
    });
    return workerToEntries;
  }


  private getInterval(dailyWorker: DailyPlanningWorkerDtoNt): Interval {
    const range = new Range(dailyWorker.shiftstart, dailyWorker.shiftend);
    return {
      color: "#A9E2FC",
      start: range.boundStart,
      end: range.boundEnd,
      content: <DailyEmployeeLine
        textColor={"#60707b"}
        turnName={dailyWorker.turn}
        start={range.startString}
        end={range.endString}
      />
    };
  }

  private getIntervalForVehicle(dailyVehicleDto: VehicleDailyPlanningDto): Interval {
    const range = new Range(dailyVehicleDto.vehicleStartTime, dailyVehicleDto.vehicleEndTime);

    const placement = VehiclesTimeLine.getPlacement(dailyVehicleDto.containerVehicle, this.props.vehicleNeeds);

    return {
      color: "#A9E2FC",
      start: range.boundStart,
      end: range.boundEnd,
      content: <DailyEmployeeLine
        textColor={"#60707b"}
        turnName={""}
        start={range.startString}
        end={range.endString}
        extraText={placement}
      />
    };
  }

  getWorkerById(workerId: number) {
    if (workerId == null) return null;
    const circuitFilter = this.props.circuitFilter;
    const results = this.props.allWorkers.filter(worker => worker.id == workerId)
      .filter(v => v.circuit && circuitFilter == CircuitSelect.NO_FILTER ||
        circuitFilter.toLowerCase() == v.circuit.toLowerCase());
    return results.length > 0 ? results[0] : null;
  }

}
