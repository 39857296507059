import React from "react";
import Icon from "./Icon";

interface Props {
  className: string;
  width: number;
  height: number;
  color: string;
}

export default class IconVehicle extends Icon<Props> {
  /************************************************
   * PROPS
   ************************************************/
  static defaultProps = {
    className: "",
    width: 15,
    height: 15,
    color: "#919da5"
  };

  /************************************************
   * LIFECYCLE
   ************************************************/
  render() {
    return <svg className="IconVehicle" xmlns="http://www.w3.org/2000/svg" width={this.props.width} height={this.props.height}  viewBox={`0 0 17 11`}>
            <g fill={this.props.color} fillRule="nonzero">
              <path transform="translate(0 3)" d="M17 6.46c0-.807-.507-1.54-1.261-1.825l-.383-.144-1.373-2.742h-1.896V0H0v9.581h1.66c.22.816.967 1.419 1.851 1.419.885 0 1.632-.603 1.851-1.419h5.796c.22.816.966 1.419 1.851 1.419s1.632-.603 1.851-1.419H17V6.46zm-2.812-2.082h-2.101V2.742h1.282l.82 1.636zM3.511 10.007c-.508 0-.922-.414-.922-.922 0-.509.414-.923.922-.923.51 0 .923.414.923.923 0 .508-.414.922-.923.922zm0-2.838c-.885 0-1.63.603-1.85 1.419H.994V.993h10.099v7.595h-5.73c-.22-.816-.967-1.419-1.852-1.419zm9.498 2.838c-.509 0-.922-.414-.922-.922 0-.509.413-.923.922-.923.509 0 .923.414.923.923 0 .508-.414.922-.923.922zm2.997-1.419H14.86c-.22-.816-.966-1.419-1.85-1.419-.335 0-.65.086-.924.237V5.371h2.79l.511.193c.37.14.62.5.62.895v2.129z"/>
              <path transform="translate(0 3)" d="m6.421 7h1.158v-1.421h1.421v-1.158h-1.421v-1.421h-1.158v1.421h-1.421v1.158h1.421z"/>
            </g>
      </svg>
  }
}




