import {SkillDto} from "../SkillDto";
import {WorkerSkillsResponseNt} from "./nt/WorkerSkillResponseNt";

export class WorkerSkillResponse {
  status: string;
  errors: string[];
  content: SkillDto[];
  totalPages: number;
  currentPages: number;
  size: number;
  total: number;

  constructor(workerSkillResponseNt: WorkerSkillsResponseNt) {
    this.status = workerSkillResponseNt.status;
    this.errors = workerSkillResponseNt.errors;
    this.content = workerSkillResponseNt.content.map((skill)=> new SkillDto(skill));
    this.totalPages = workerSkillResponseNt.totalPages;
    this.currentPages = workerSkillResponseNt.currentPages;
    this.size = workerSkillResponseNt.size;
    this.total = workerSkillResponseNt.total;
  }
}
