import {VehicleDailyPlanningDtoNt} from "./nt/VehicleDailyPlanningDtoNt";
import {DateTime} from "luxon";
import GfDateUtils from "../../components/DatePicker/GfDateUtils";

export class VehicleDailyPlanningDto {
  id: number;
  serviceCategory: string;
  date: string;
  vehicleId: number;
  containerVehicle: string;
  serviceStartTime: string;
  serviceEndTime: string;
  vehicleStartTime: string;
  vehicleEndTime: string;
  service: string;
  vehicleShortName: string;
  manuallyCreated: boolean;
  previousDay: boolean;
  radiocodeid: number;

  constructor(vehicle : VehicleDailyPlanningDtoNt) {
    this.id = vehicle.id;
    this.serviceCategory = vehicle.serviceCategory;
    this.date = vehicle.date;
    this.vehicleId = vehicle.vehicleId;
    this.containerVehicle = vehicle.containerVehicle;
    this.serviceStartTime = vehicle.serviceStartTime;
    this.serviceEndTime = vehicle.serviceEndTime;
    this.vehicleStartTime = vehicle.vehicleStartTime;
    this.vehicleEndTime = vehicle.vehicleEndTime;
    this.service = vehicle.service;
    this.previousDay = vehicle.previousDay;
    this.vehicleShortName = vehicle.vehicleShortName;
    this.manuallyCreated = vehicle.manuallyCreated;
    this.radiocodeid = vehicle.radiocodeid;
  }

  static getTimeString(time: string) {
    const strings = time.split(":");
    return strings[0] + ":" + strings[1];
  }

  static getEmpty(date: string,
                  vehicleId: number,
                  start: string,
                  end: string,
                  containerVehicle: string,
                  radioCodeId: number) : VehicleDailyPlanningDto {
    const defStartTime = "";
    return {
      id: null,
      serviceCategory: "",
      date: date,
      vehicleId: vehicleId,
      containerVehicle: containerVehicle,
      serviceStartTime: defStartTime,
      serviceEndTime: defStartTime,
      vehicleStartTime: start,
      vehicleEndTime: end,
      service: "",
      vehicleShortName: "",
      manuallyCreated: true,
      previousDay: false,
      radiocodeid: radioCodeId,
    }
  }
}
