import TimeLine from "../../../../components/TimeLine/TimeLine";
import React, {Component} from "react";
import {MonthlyData, MonthlyEmployee} from "../../../Dashboard/MonthlyPlanning/MonthlyDTO";
import Time from "../../../../lib/utils/Time";

import {MonthlyPlannedRow} from "../../../../api/entity/MonthlyPlannedRow";
import {WorkerDto} from "../../../../api/entity/WorkerDto";
import {TimeOffTypeDto} from "../../../../api/timeOff/TimeOffApi";
import {ShiftDto} from "../../../../api/entity/ShiftDto";
import {DateTime} from "luxon";
import MonthlyTimeLineUtils, {EmployeeEdit} from "../../MonthlyTimeLineUtils";
import {FixedShiftDto} from "../../../../api/entity/FixedShiftDto";
import {CircuitSelect} from "../../../../components/CircuitSelect/CircuitSelect";

interface Props {
  monthlyPlannedRows: MonthlyPlannedRow[];
  currentDate: DateTime;
  workers: WorkerDto[];
  excludedWorkers: WorkerDto[];
  shifts: ShiftDto[];
  fixedShifts: FixedShiftDto[];
  timeOffTypes: TimeOffTypeDto[];
  onSelect: (employeeEdit: EmployeeEdit) => void;
  onError: (name, errors: string[]) => void;
  search: string;
  circuitSearch: string;
  lastDayMap:{[id:number]:string[]}
}

export default class MonthlyTimeLineWithShiftEdit extends Component<Props> {
  private readonly EMPTY_VAL = "";

  /**************************************************
   * LIFECYCLE
   **************************************************/
  render() {
    const {
      shifts,
      currentDate,
      monthlyPlannedRows,
      timeOffTypes,
      onError,
      fixedShifts,
      lastDayMap,
      circuitSearch
    } = this.props;
    const monthlyEmployeeManualSetData = this.plannedRowsToTimeLineMatrix(monthlyPlannedRows)
    let days = monthlyEmployeeManualSetData.days;
    let filterMonthlyEntries = !monthlyEmployeeManualSetData ? [] :
      monthlyEmployeeManualSetData.employees;

    return monthlyEmployeeManualSetData && <TimeLine
      headerHeight={120}
      headers={new MonthlyTimeLineUtils().generateHeaders(currentDate, filterMonthlyEntries)}
      timeLines={new MonthlyTimeLineUtils().generateIntervalSettings(
        days, filterMonthlyEntries,
        timeOffTypes, shifts, fixedShifts,
        false, lastDayMap, "", circuitSearch,
        (workerName, errors) => onError(workerName, errors),
        (employeeEdit) => this.onEdit(employeeEdit))}
      spacing columnWidth={70}
    />
  }

  /**************************************************
   * PRIVATE FUNCTIONS
   **************************************************/
  public plannedRowsToTimeLineMatrix(monthlyPlannedResponse: MonthlyPlannedRow[]): MonthlyData {

    const days = Time.getDaysInMonth(this.props.currentDate)
    let workers = this.props.workers;
    let employees: MonthlyEmployee[] = []

    workers = workers.filter(w => this.props.excludedWorkers.indexOf(w) == -1);

    // For each worker
    for (const worker of workers) {
      // When no planning has been done, generate empty for all workers
      if (monthlyPlannedResponse.length == 0) {
        employees.push({
          id: worker.id,
          circuit: worker.circuit,
          name: `${worker.lastname} ${worker.name}`,
          turns: new Array(days.length).fill(this.EMPTY_VAL),
          errors: []
        })
        continue;
      }

      const selectedWorker = monthlyPlannedResponse.filter(row => row.workerId == worker.id)
      if (selectedWorker.length > 0) {
        employees.push({
          id: worker.id,
          name: `${worker.lastname} ${worker.name}`,
          circuit: worker.circuit,
          turns: selectedWorker[0].dateToCode.map(a => a.code),
          errors: []
        })
      } else {
        employees.push({
          id: worker.id,
          circuit: worker.circuit,
          name: `${worker.lastname} ${worker.name}`,
          turns: new Array(days.length).fill(this.EMPTY_VAL),
          errors: []
        })
      }
    }
    employees = this.filterBasedOnSearch(employees);
    return {employees, days}
  }

  private filterBasedOnSearch(monthlyPlannedRows: MonthlyEmployee[]) {
    const circuitSearch = this.props.circuitSearch;
    const filteredRows = this.filterByNameOrSurname(monthlyPlannedRows);
    if (circuitSearch != CircuitSelect.NO_FILTER) {
      const filterLowerCase = circuitSearch.toLowerCase();
      let filteredIds = this.props.workers
        .filter(w => w.circuit.toLowerCase() == filterLowerCase)
        .map(wi => wi.id);
      return filteredRows.filter(wd => filteredIds.indexOf(wd.id) > -1)
    }
    return filteredRows
  }

  private filterByNameOrSurname(monthlyPlannedRows: MonthlyEmployee[]) {
    const searchString = this.props.search.toLowerCase();
    return monthlyPlannedRows.filter(
      row => {
        const nameLower = row.name.toLowerCase();
        return searchString == "" ||
          nameLower == searchString ||
          nameLower.split(" ")
            .indexOf(searchString.toLowerCase()) > -1
      });
  }

  private onEdit(employeeEdit: EmployeeEdit) {
    this.props.onSelect(employeeEdit);
  }
}
