import WorkerWeeklyUnDtoNT from "./nt/WorkerWeeklyUnDtoNT";

export default class WorkerWeeklyUnDto {
  id: number;
  workerId: number;
  weekRow: number;
  weekdays: number[];
  fromTime: string;
  toTime: string;

  constructor(weeklyNt: WorkerWeeklyUnDtoNT) {
    this.id = weeklyNt.id;
    this.fromTime = weeklyNt.fromTime;
    this.toTime = weeklyNt.toTime;
    this.weekdays = weeklyNt.weekdays;
    this.weekRow = weeklyNt.weekRow;
    this.workerId = weeklyNt.workerId;
  }
}
