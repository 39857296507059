import AuthorizeApi from "../AuthorizeApi";
import RadioCodeResponse from "../entity/response/RadioCodeResponse";
import {RadioCodeDto} from "../entity/RadioCodeDto";
import I18n from "../../lib/I18n";

export default class RadioCodeApi {

  private base: string = '/radio';

  private authApi: AuthorizeApi = new AuthorizeApi();

  public async getRadioCodes(skip: number, limit: number) {
    let radioResponse: RadioCodeResponse;
    await this.authApi.get <RadioCodeResponse>(`${this.base}/${skip}/${limit}`)
      .then((w) => {
        radioResponse = w;
      });
    return radioResponse;
  }

  public async saveRadioCode(radioCode: RadioCodeDto) {
    let radioResponse: RadioCodeResponse;
    await this.authApi.post <RadioCodeDto, RadioCodeResponse>(`${this.base}`, radioCode)
      .then((w) => {
        radioResponse = w;
      });
    return radioResponse;
  }


  public getEmptyRadioCode(): RadioCodeDto {
    return {id: 0, name: I18n.get().RadioCodeRegistry.modal.nothingOption}
  }

}
