import React from 'react';
import './Error404.scss';
import Page from "../Page";

class Error404 extends Page {

  render() {
    return (
      <div className="Error404">
        <h1>NoMatch</h1>
      </div>
    );
  }
}

export default Error404;
