import React, {BaseSyntheticEvent, Component} from "react";
import "./MonthlyUnavailabilityPlanningModal.scss";
import I18n from "../../../lib/I18n";
import Select from "react-select";
import {MonthlyUnavailabilityRowDto} from "../../../api/entity/MonthlyUnavailabilityRowDto";
import {WorkerDto} from "../../../api/entity/WorkerDto";
import {Entry} from "../MonthlyPlanningModal/MonthlyPlanningModal";
import {DateTime} from "luxon";
import {GfDatePicker} from "../../DatePicker/GfDatePicker";
import Button from "../../Button/Button";
import TimeInputPicker from "../../TimeInputPicker/TimeInputPicker";
import {MonthlyUnavailabilityRowEntry} from "../../../api/entity/MonthlyUnavailabilityRowEntry";
import MonthlyPlanningValidator from "../../../api/worker/validator/MonthlyPlanningValidator";
import {ModalType} from "../ModalType";
import {FocusPanel} from "../../FocusPanel/FocusPanel";


export interface Props {
  modalType: ModalType,
  subject: MonthlyUnavailabilityRowDto,
  targetDate: DateTime,
  workers: WorkerDto[],
  onCreate: (modifiedSubject: MonthlyUnavailabilityRowDto) => void;
  onModify: (modifiedSubject: MonthlyUnavailabilityRowDto) => void;
  onDelete: (id: number) => void;
  onCancel: () => void;
}

interface State {
  modifiedSubject: MonthlyUnavailabilityRowDto
  workerSelectOptions: Entry[]
  selectedWorkerValue: Entry
  error: MonthlyUnavailabilityError
}

export interface MonthlyUnavailabilityError {
  entryError: MonthlyUnavailabilityErrorEntry[]
}

export interface MonthlyUnavailabilityErrorEntry {
  date: string;
  time: string;
}

export class MonthlyUnavailabilityPlanningModal extends Component<Props, State> {


  /************************************************
   * PROPS
   ************************************************/
  static defaultProps: Partial<Props> = {
    modalType: ModalType.CREATE,
    subject: {id: 0, date: null, workerId: 0, entries: []},
    onCancel: () => {
    },
  };

  /************************************************
   * CONSTRUCTOR
   ************************************************/
  constructor(props: Props) {
    super(props);
    this.state = {
      workerSelectOptions: [],
      modifiedSubject: {id: 0, date: null, workerId: 0, entries: []},
      error: {entryError: []},
      selectedWorkerValue: {value: "", label: ""}
    }
  }

  componentDidMount() {
    this.populateWorkerSelect();

    if (this.props.modalType != ModalType.CREATE) {
      let selectedWorkerDto = this.props.workers.filter(workerOptions => workerOptions.id
        == this.props.subject.workerId)[0];

      this.setState({
        selectedWorkerValue: {
          label: selectedWorkerDto.lastname + " " + selectedWorkerDto.name,
          value: selectedWorkerDto
        },
        modifiedSubject: this.props.subject
      });
    }
  }

  onCancel(event: BaseSyntheticEvent) {
    event.preventDefault();
    this.props.onCancel();
  }

  async onSubmit(event: BaseSyntheticEvent) {
    event.preventDefault();
    let validation = MonthlyPlanningValidator.validate(this.props.targetDate, this.state.modifiedSubject);
    if (MonthlyPlanningValidator.isValid(validation) &&
      this.state.modifiedSubject.entries.length > 0) {
      this.props.modalType == ModalType.CREATE ?
        this.props.onCreate(this.state.modifiedSubject) :
        this.props.onModify(this.state.modifiedSubject)
      return;
    }
    this.setState({error: validation});
  }

  private setSelectWorker(entry: Entry) {
    let {modifiedSubject} = this.state;
    modifiedSubject.workerId = entry.value.id;
    this.setState({
      modifiedSubject,
      selectedWorkerValue: {label: entry.label, value: entry.value}
    });
  }

  private populateWorkerSelect() {
    let {workerSelectOptions} = this.state;
    workerSelectOptions = [];
    let entries: Entry[] = this.props.workers.map(a => {
      return {label: `${a.lastname} ${a.name}`, value: a}
    });
    entries.forEach(entry => workerSelectOptions.push(entry));
    if (entries.length > 0) {
      let firstWorker = this.props.workers[0];
      let initialValue = this.props.targetDate.startOf("month");
      this.setState({
        error: {entryError: [{date: "", time: ""}]},
        modifiedSubject: {
          ...this.state.modifiedSubject,
          workerId: firstWorker.id,
          entries: [{date: initialValue, fromtime: "", totime: "", unavailabilityid: null, id: null}],
          date: initialValue
        },
        workerSelectOptions: workerSelectOptions,
        selectedWorkerValue: {label: `${firstWorker.lastname} ${firstWorker.name}`, value: firstWorker}
      });
    }
  }

  private onAddEntry() {
    let {modifiedSubject} = this.state;
    let {error} = this.state;
    error.entryError.push({
      date: "",
      time: ""
    });
    modifiedSubject.entries.push(
      {
        id: null, totime: "", fromtime: "", unavailabilityid: this.state.modifiedSubject.id,
        date: this.props.targetDate.startOf("month")
      }
    );
    this.setState({error, modifiedSubject});
  }

  /************************************************
   * LIFECYCLE
   ************************************************/
  render() {

    return (
      <><FocusPanel show/>
        <div className="Modal MonthlyPlanningModal show ">
          <form onSubmit={(event) => this.onSubmit(event)}>
            <div>
              <h1>
                {I18n.get().MonthlyTimeLine.setUnavailabilityTitle}
              </h1>
            </div>
            <div>
              <div className={"row"}>
                <div className={"selector-first"}>
                  <label>{I18n.get().MonthlyTimeLine.worker}</label>
                  <Select className={"worker-select"}
                          onChange={(value) => this.setSelectWorker(value)}
                          options={this.props.workers.map(worker => {
                            return {value: worker, label: `${worker.lastname} ${worker.name}`}
                          })}
                          value={this.state.selectedWorkerValue}
                          isDisabled={this.props.modalType == ModalType.MODIFY}
                  />
                </div>
              </div>
              {this.state.modifiedSubject.entries.map((a, index) =>
                <div className={"row"} key={a.id + "_" + index}>
                  <div className={"selector"}>
                    <GfDatePicker
                      label={I18n.get().MonthlyTimeLine.table.date}
                      errorMessage={this.state.error.entryError[index].date}
                      onChange={(date) => {
                        const {modifiedSubject} = this.state;
                        modifiedSubject.entries[index].date = date;
                        this.setState({modifiedSubject});
                      }}
                      minDate={this.props.targetDate.startOf("month")}
                      maxDate={this.props.targetDate.endOf("month")}
                      date={a.date}/>
                  </div>
                  <div className={"selector-time"}>
                    <TimeInputPicker
                      onCorrectChange={(time) => {
                        const {modifiedSubject} = this.state;
                        modifiedSubject.entries[index].fromtime = time;
                        this.setState({modifiedSubject});
                      }}
                      isToTime={true}
                      label={I18n.get().WorkersRegistry.fromTime}
                      placeholder={"00:00"}
                      className={"row-w"}
                      defaultValue={a.fromtime}
                      error={this.state.error.entryError[index].time != ""}
                    />
                    <span className={"dash"}>&mdash;</span>
                    <TimeInputPicker
                      onCorrectChange={(time) => {
                        const {modifiedSubject} = this.state;
                        modifiedSubject.entries[index].totime =
                          time == "24:00" ? "00:00" : time;
                        this.setState({modifiedSubject});
                      }}
                      isToTime={true}
                      label={I18n.get().WorkersRegistry.toTime}
                      placeholder={"00:00"}
                      className={"row-w"}
                      defaultValue={a.totime}
                      error={this.state.error.entryError[index].time != ""}
                    />
                    <div>
                    <span className={"closeIcon row-w "}
                          onClick={() => this.onDelete(a, index)}>&times;</span>
                    </div>
                  </div>
                </div>)}
              <div>
                <div className={(this.props.modalType == ModalType.MODIFY ? "hide" : "")}>
                <span className={"add-btn"} onClick={() => this.onAddEntry()}>
                  + {I18n.get().MonthlyTimeLine.unavailabilityModal.addDate}</span>
                </div>
              </div>
              <div className={"buttons"}>
                <Button onClick={(event) => this.onSubmit(event)}
                        className={"add-btn"}>
                  {I18n.get().MonthlyTimeLine.unavailabilityModal.save}
                </Button>
                <Button secondary={true}
                        onClick={(event) => this.onCancel(event)}
                        className={"cancel-btn"}>
                  {I18n.get().WorkersRegistry.cancelAddWorker}</Button>
              </div>
            </div>
          </form>
        </div>
      </>
    )
  }

  private onDelete(a: MonthlyUnavailabilityRowEntry, i: number) {
    let {modifiedSubject} = this.state;
    let {error} = this.state;
    let deleteElement = modifiedSubject.entries.splice(i, 1);
    error.entryError.splice(i, 1);
    this.setState({modifiedSubject});
    this.setState({error});

    if (this.props.modalType == ModalType.MODIFY) {
      this.props.onDelete(a.id);
    }
  }
}
