import AuthorizeApi from "../AuthorizeApi";
import {EngineConfigResponse} from "../entity/response/EngineConfigResponse";
import {EngineConfRequest} from "../entity/request/EngineConfRequest";

export default class EngineConfigApi {

  private authApi: AuthorizeApi = new AuthorizeApi();

  private base: string = '/engine-config';

  public async get() {
    return await this.authApi.get<EngineConfigResponse>(this.base)
  }

  async modify(engineConfig: EngineConfRequest) {
    return await this.authApi.post<EngineConfRequest, EngineConfigResponse>(this.base, engineConfig)
  }
}
