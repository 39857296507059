import React, {Component, PureComponent} from "react";
import "./ToggleDayButton.scss";


export interface Props {
  /** This defines additional classes for the Button */
  className?: string,
  label?: string,
  isSelected: boolean,
  index: number,
  /** This dictates what the button will do on click */
  onToggle?: (selectedIndexes: number) => void;
}

interface State {
  isToggled: boolean;
}

export class ToggleDayButton extends Component<Props, State> {

  /************************************************
   * PROPS
   ************************************************/
  static defaultProps: Partial<Props> = {
    className: "",
    label: "Lun",
    isSelected: false,
    onToggle: () => {
    }
  };

  /************************************************
   * CONSTRUCTOR
   ************************************************/
  constructor(props: Props) {
    super(props);
    this.state = {isToggled: this.props.isSelected}
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (prevProps != this.props) {
      this.setState({isToggled: this.props.isSelected});
    }
  }

  private onToggle() {
    // this.setState({isToggled: !this.state.isToggled});
    this.props.onToggle(this.props.index);
  }

  /************************************************
   * LIFECYCLE
   ************************************************/
  render() {
    return (
      <div onClick={() => this.onToggle()}
           className={"Button ToggleDayButton " + (this.state.isToggled ? "selected" : "") + " " + this.props.className}>
        <div className={"label"}>{this.props.label}</div>
      </div>
    )
  }
}
