import React, {BaseSyntheticEvent, Component} from "react";
import "./SkillsModal.scss";
import Button from "../../Button/Button";
import I18n from "../../../lib/I18n";
import {WorkerDto} from "../../../api/entity/WorkerDto";
import {ModalType} from "../ModalType";
import CreatableSelect from 'react-select/creatable';
import {SkillDto} from "../../../api/entity/SkillDto";
import {SkillType} from "../../../api/entity/SkillType";
import ShiftUtils from "../../../utils/ShiftUtils";
import TextInputMask from "../../TextInputMask/TextInputMask";


export interface Props {
  type: ModalType,
  /** This defines additional classes for the Button */
  className: string,
  model: WorkerDto,
  /** Disables onclick
   *  @default false
   **/
  allSkills: SkillDto[];
  assignedCertificates: SkillDto[];
  assignedService: string;
  assignedMoss: boolean;
  assignedTutoring: string;
  flagConsecutiveNights: number;
  numNottiConsecutive: number;
  onSubmit: (newlyAssignedCertificates: SkillDto[],
             assignedService: string, moss: boolean,
             assignedTutoring: string,
              flagConsecutiveNights: number,
              numNottiConsecutive: number
            ) => void;
  onCancel: () => void;
  disabled: boolean;
}

interface State {
  assignedCertificates: SkillDto[],
  assignedServices: string,

  services: SkillDto[],
  certificates: SkillDto[]

  assignedTutoring: string
  servicesBool: boolean[]
  serviceMoss: boolean,
  flagNottiConsecutive: boolean,
  numNottiConsecutive: number,
  indexOfService: number
  error: string
}

export class SkillsModal extends Component<Props, State> {

  private shiftUtils = new ShiftUtils();
  /************************************************
   * PROPS
   ************************************************/
  static defaultProps: Partial<Props> = {
    className: "",
    onCancel: () => {
    },
    disabled: false,
    assignedCertificates: [],
    assignedService: ""
  };

  /************************************************
   * CONSTRUCTOR
   ************************************************/
  constructor(props: Props) {
    super(props);
    let servicesFromProps = this.getServicesFromProps();
    let indexOfService = this.shiftUtils.SERVICES_DTO.indexOf(props.assignedService)
    this.state = {
      assignedCertificates: this.props.assignedCertificates,
      assignedServices: this.props.assignedService,
      certificates: this.getCertificatesFromProps(),
      services: servicesFromProps,
      servicesBool: [],
      indexOfService: indexOfService,
      serviceMoss: this.props.assignedMoss,
      assignedTutoring: this.props.assignedTutoring,
      flagNottiConsecutive: this.props.flagConsecutiveNights == 1,
      numNottiConsecutive: this.props.numNottiConsecutive,
      error: "",
    };
  }

  componentDidMount() {
    // Update skill
    this.onClickSkill(this.props.assignedService);
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (prevProps.allSkills != this.props.allSkills) {
      this.setState({
        certificates: this.getCertificatesFromProps(),
        services: this.getServicesFromProps()
      },);
    }
  }

  private getServicesFromProps() {
    return this.props.allSkills.filter(a => a.type == SkillType.SKILL);
  }

  private getCertificatesFromProps() {
    return this.props.allSkills.filter(a => a.type == SkillType.CERTIFICATE);
  }

  onCancel() {
    this.props.onCancel();
  }

  async onSubmit(event: BaseSyntheticEvent) {
    event.preventDefault();
    if (this.state.error != "") {
      return;
    }
    this.props.onSubmit(
      this.state.assignedCertificates, this.state.assignedServices,
      this.state.serviceMoss, this.state.assignedTutoring,
      this.state.flagNottiConsecutive?1:0, this.state.numNottiConsecutive
    );
  }

  private handleChangeCertificates(newValue) {
    if (newValue.length > 5) {
      return;
    }
    this.setState({
      assignedCertificates:
        newValue.map(entry => this.findOrCreateANewSkillDto(entry.value, entry.label, SkillType.CERTIFICATE))
    })
  }

  private findOrCreateANewSkillDto(id, name: string, skillType: SkillType): SkillDto {
    let byId = this.findById(id);
    if (byId != null) return byId;
    return {id: null, name: name, type: skillType};
  }

  private findById(id: number): SkillDto {
    return this.props.allSkills.filter(a => a.id == id)[0]
  }

  private onClickSkill(name: string) {
    let index = this.shiftUtils.SERVICES_DTO.indexOf(name);

    let firstStep = this.shiftUtils.SERVICES_DTO
      .map((v, i) => {
        // false all higher elements
        if (i < index) {
          return false;
        }
        // if same, invert the value
        if (i == index) {
          return !this.state.servicesBool[i];
        }
      });
    let secondStep = firstStep.map((v, i) => {
      if (i > index) {
        return firstStep[index];
      }
      return firstStep[i];
    })

    this.setState({servicesBool: secondStep});

    // if no service is selected and moss is also not checked, then show error
    if (this.areAllServicesDeselected(secondStep) && !this.state.serviceMoss) {
      this.setState({error: I18n.get().WorkersRegistry.serviceError});
    } else {
      this.setState({error: ""})
    }

    // Services
    if (this.areAllServicesDeselected(secondStep)) {
      this.setState({assignedServices: ""});
      return;
    }
    this.setState({indexOfService: index});
    this.setState({assignedServices: this.shiftUtils.SERVICES_DTO[index]});
  }

  private areAllServicesDeselected(secondStep: boolean[]) {
    return secondStep.indexOf(true) == -1;
  }

  private onClickSkillMoss() {
    this.setState({serviceMoss: !this.state.serviceMoss, error: ""});
  }

  private onClickAbilitaNottiConsecutive() {
    this.setState({flagNottiConsecutive: !this.state.flagNottiConsecutive});
  }

  private isCheckboxDisabled(i: number) {
    if (i == 0) {
      return false;
    }
    if (i < this.state.indexOfService) {
      return false;
    }
    if (i > this.state.indexOfService) {
      return this.state.servicesBool[i];
    }
  }

  private onChange(a: string) {
    this.setState({assignedTutoring: a});
  }

  /************************************************
   * LIFECYCLE
   ************************************************/
  render() {
    return (
      <div className={"Modal SkillModal " + this.props.className}>
        <form onSubmit={(event) => this.onSubmit(event)}>
          <div>
            <h1>
              {I18n.get().WorkersRegistry.modifySkills}
            </h1>
          </div>
          <div>
            <h2>
              {I18n.get().WorkersRegistry.modifyCertificates}
            </h2>
          </div>
          <div>
            <CreatableSelect isClearable
                             isMulti
                             closeMenuOnSelect={false}
                             onChange={(newValue) => this.handleChangeCertificates(newValue)}
                             options={this.state.certificates.map(option => {
                               return {value: option.id, label: option.name}
                             })}
                             value={this.state.assignedCertificates.map(value => {
                               return {value: value.id, label: value.name}
                             })}/>
          </div>
          <div>
            <h2>
              {I18n.get().WorkersRegistry.modifyServices}
            </h2>
            <div>
              {this.shiftUtils.SERVICES_DTO.map((a, i) => {
                return <div className={"skill-row"} key={a}>
                  <label className={"skill-label"}>{a} {a == "EM+" && "(COA5)"}</label>
                  <input className={"skill-input"} type={"checkBox"} name={a} value={a}
                         checked={this.state.servicesBool[i]}
                         disabled={this.isCheckboxDisabled(i)}
                         onChange={(e) => this.onClickSkill(e.target.name)}/>
                </div>
              })
              }
              <div className={"skill-row"}>
                <label className={"skill-label"}>MOSS (118)</label>
                <input className={"skill-input"} type={"checkBox"} name={"MOSS"}
                       checked={this.state.serviceMoss}
                       onChange={(e) => this.onClickSkillMoss()}/>
              </div>
            </div>
          </div>

          <div className={"tutoring-section"}>
            <h2>
              {I18n.get().WorkersRegistry.modifyTutoring}
            </h2>
            <div>
              {this.shiftUtils.TUTORING_DTO.map((a) => {
                return <span>
                <input type={"radio"} name={"tutoring"} value={a}
                       defaultChecked={this.props.assignedTutoring == a}
                       onChange={(a) => this.onChange(a.target.value)}/>
                <label>{a == "" ? I18n.get().WorkersRegistry.nothing : a}</label>
              </span>
              })}
            </div>
          </div>

          <div>
            <h2>
              {I18n.get().WorkersRegistry.consecutiveNights}
            </h2>
            <div>

              <div className={"skill-row"}>
                <label className={"skill-label"}> {I18n.get().WorkersRegistry.flagConsecutiveNights}</label>
                <input className={"skill-input"} type={"checkBox"} name={"MOSS"}
                       checked={this.state.flagNottiConsecutive}
                       onChange={(e) => this.onClickAbilitaNottiConsecutive()}/>
              </div>
              <div className={"skill-row"}>


              {this.state.flagNottiConsecutive ? <TextInputMask  className={"text-input"}
                                       onChange={(hour) => {

                                       }}
                                       readonly={!this.state.flagNottiConsecutive}
                                       onValidate= {(hour) => {
                                          var parsedHour = parseInt(hour);
                                          //Se non è un numero o è minore di 2, allora annullo il cambio
                                          if (isNaN(parsedHour) || parsedHour < 2) {
                                            this.setState({
                                              numNottiConsecutive: 2
                                            });

                                            return false;
                                          }

                                          this.setState({
                                              numNottiConsecutive: parsedHour
                                          });
                                          return true;
                                        }}
                                       label={I18n.get().WorkersRegistry.numConsecutiveNights}
                                       defaultValue={ this.state.numNottiConsecutive?.toString() ?? "2" }
                                       mask={'99'}
                                       maskChar={" "}
                                       placeholder={"00"}/>
              : null}
              </div>
            </div>
          </div>



          <span className={"error"}>{this.state.error}</span>
          <div>
            <div className={"buttons"}>
              <Button onClick={(event) => this.onSubmit(event)}
                      className={"add-btn"}>
                {this.props.type == ModalType.CREATE ? I18n.get().WorkersRegistry.confirmAddWorker :
                  I18n.get().WorkersRegistry.confirmModifyWorker}
              </Button>
              <Button secondary={true}
                      onClick={(e) => {e.preventDefault(); this.onCancel()}}
                      className={"cancel-btn"}>
                {I18n.get().WorkersRegistry.cancelAddWorker}</Button>
            </div>
          </div>

        </form>
      </div>
    )
  }
}
