import React, {BaseSyntheticEvent, Component} from "react";
import "./MonthlyPlanningModal.scss";
import Button from "../../Button/Button";
import I18n from "../../../lib/I18n";
import Select from "react-select";
import {ShiftDto} from "../../../api/entity/ShiftDto";
import {DateTime} from "luxon";
import {TimeOffTypeDto} from "../../../api/timeOff/TimeOffApi";
import {WorkerDto} from "../../../api/entity/WorkerDto";
import {FocusPanel} from "../../FocusPanel/FocusPanel";

export interface EditingShift {
  day: DateTime
  shift: string
  timeOff: string
  worker: WorkerDto
  type: Type
}

export interface Props {
  editingShift: EditingShift

  shifts: ShiftDto[],
  timeOffTypes: TimeOffTypeDto[]

  onSubmit: (editingShift: EditingShift) => void;
  onCancel: () => void;
}

interface State {
  editingShift: EditingShift
}

export enum Type {
  NULL = "NULL", SHIFT = "SHIFT", ABSENCE = "ABSENCE"
}

export interface Entry {
  value: any,
  label: string
}

export class MonthlyPlanningModal extends Component<Props, State> {
  /************************************************
   * CONSTRUCTOR
   ************************************************/
  constructor(props: Props) {
    super(props);
    this.state = {
      editingShift: this.props.editingShift
    }
  }

  onCancel(event: BaseSyntheticEvent) {
    event.preventDefault();
    this.props.onCancel();
  }

  async onSubmit(event: BaseSyntheticEvent) {
    event.preventDefault();

    this.props.onSubmit(this.state.editingShift)
  }

  /************************************************
   * LIFECYCLE
   ************************************************/
  render() {
    const {day, worker, type, shift, timeOff} = this.props.editingShift;
    return (<>
      <FocusPanel show />
      <div className="Modal MonthlyPlanningModal show">
        <form onSubmit={(event) => this.onSubmit(event)}>
          <div>
            <h1>
              {I18n.get().MonthlyPlanning.title} {day.toLocaleString()} - {worker.lastname} {worker.name}
            </h1>
          </div>
          <div>
            <div className={"row"}>
              <div className={"radio-space"}>
                <input type={"radio"} name={"choice"} onClick={() => this.onShiftTypeSelect()}
                       defaultChecked={type == Type.SHIFT}/>
                <label>{I18n.get().MonthlyPlanning.shift}</label>
              </div>
              <div className={"selector"}>
                <Select className={"height-select"}
                        onChange={value => this.onShiftSelected(value)}
                        options={this.props.shifts.filter((shift) => !shift.archived).map(s => {
                          return {label: s.shiftCode, value: s.shiftCode}
                        })}
                        value={type == Type.SHIFT ? {value: shift, label: shift} : null}
                        isDisabled={type != Type.SHIFT}
                />
              </div>
            </div>
            <div className={"row"}>
              <div className={"radio-space"}>
                <input type={"radio"} name={"choice"} onClick={() => this.onTimeOffTypeSelect()}
                       defaultChecked={type == Type.ABSENCE}/>
                <label>{I18n.get().MonthlyPlanning.absence}</label>
              </div>
              <div className={"selector"}>
                <Select className={"height-select"}
                        options={this.props.timeOffTypes}
                        value={type == Type.ABSENCE ? this.props.timeOffTypes.filter(t => t.value == timeOff)[0] : null}
                        onChange={timeOff => this.onTimeOffSelected(timeOff)}
                        isDisabled={type != Type.ABSENCE}/>
              </div>
            </div>
            <div className={"row"}>
              <div className={"radio-space"}>
                <input type={"radio"} name={"choice"} onClick={() => this.onNoneTypeSelect()}
                       defaultChecked={type == Type.NULL}/>
                <label>{I18n.get().MonthlyPlanning.nothing}</label>
              </div>
            </div>
            <div className={"buttons"}>
              <Button onClick={(event) => this.onSubmit(event)}
                      className={"add-btn"}>
                {I18n.get().WorkersRegistry.confirmAddWorker}
              </Button>
              <Button secondary={true}
                      onClick={(event) => this.onCancel(event)}
                      className={"cancel-btn"}>
                {I18n.get().WorkersRegistry.cancelAddWorker}</Button>
            </div>
          </div>
        </form>
      </div></>
    )
  }

  private onShiftTypeSelect() {
    const {editingShift} = this.state;
    if (editingShift.type == Type.SHIFT) return
    editingShift.type = Type.SHIFT
    editingShift.shift = this.props.shifts[0]?.shiftCode
    editingShift.timeOff = null
    this.setState({editingShift});
  }

  private onTimeOffTypeSelect() {
    const {editingShift} = this.state;
    if (editingShift.type == Type.ABSENCE) return
    editingShift.type = Type.ABSENCE
    editingShift.shift = null
    editingShift.timeOff = this.props.timeOffTypes[0]?.value
    this.setState({editingShift});
  }

  private onNoneTypeSelect() {
    const {editingShift} = this.state;
    if (editingShift.type == Type.NULL) return
    editingShift.type = Type.NULL
    editingShift.shift = null
    editingShift.timeOff = null
    this.setState({editingShift});
  }

  private onShiftSelected(shift) {
    const {editingShift} = this.state;
    editingShift.shift = shift.value
    this.setState({editingShift});
  }

  private onTimeOffSelected(timeOff) {
    const {editingShift} = this.state;
    editingShift.timeOff = timeOff.value
    this.setState({editingShift});
  }

  /**************************************************
   * VARIABLES
   **************************************************/

}
