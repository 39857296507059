import {MonthlyPlannedRow} from "../MonthlyPlannedRow";
import {MonthlyPlannedResponseNt} from "./nt/MonthlyPlannedResponseNt";

export class MonthlyPlannedResponse {
  status: string;
  errors: string[];
  content: MonthlyPlannedRow[];
  totalPages: number;
  currentPages: number;
  size: number;
  total: number;

  constructor(mprNt: MonthlyPlannedResponseNt) {
    this.status = mprNt.status;
    this.errors = mprNt.errors;
    this.content = mprNt.content.map((mpr) => new MonthlyPlannedRow(mpr))
    this.totalPages = mprNt.totalPages;
    this.currentPages = mprNt.currentPages;
    this.size = mprNt.size;
    this.total = mprNt.total;
  }

}
