import React, {Component} from "react";
import "./MonthlyOverloadedTable.scss";
import Table, {Header} from "../../../components/Table/Table";
import I18n from "../../../lib/I18n";
import {DateTime} from "luxon";
import GfDateUtils from "../../../components/DatePicker/GfDateUtils";
import {OverloadedEntryDto} from "../../../api/entity/OverloadedEntryDto";

interface Props {
  entries: OverloadedEntryDto[]
  toggleWorkerModal: (id: number)=> void
}

interface TableEntry {
  day: string,
  peopleN: any,
  group: string,
  circuit: string,
  shift: string,
  overloadedN: string,
}

interface State {
  table: {
    headers: Header<TableEntry>[];
    items: TableEntry[];
    currentSlice: number;
  };
}

export default class MonthlyOverloadedTable extends Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = {
      table: {
        currentSlice: 1,
        items: [],
        headers: []
      }
    }
  }

  componentDidMount() {
    this.updateTable();

  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (prevProps.entries !== this.props.entries) {
      this.updateTable();
    }
  }

  updateTable() {
    const entries = this.props.entries;
    this.setState({
      table: {
        ...this.state.table,
        headers: [
          {
            name: I18n.get().MonthlyPlanning.viewOverloaded.table.day,
            field: "day"
          },
          {
            name: I18n.get().MonthlyPlanning.viewOverloaded.table.circuit,
            field: "circuit"
          },
          {
            name: I18n.get().MonthlyPlanning.viewOverloaded.table.people,
            field: "peopleN"
          },
          {
            name: I18n.get().MonthlyPlanning.viewOverloaded.table.group,
            field: "group"
          },
          {
            name: I18n.get().MonthlyPlanning.viewOverloaded.table.humanShift,
            field: "shift"
          },
          {
            name: I18n.get().MonthlyPlanning.viewOverloaded.table.overloadedQty,
            field: "overloadedN"
          },
        ], items:
          entries.map((e) => {
            return {
              day: DateTime.fromFormat(e.date, GfDateUtils.STORED_DATE_FORMAT).toFormat(GfDateUtils.DATE_FORMAT),
              peopleN: <div className={"overloaded-entry"} onClick={() => {
                this.showWorkerModal(e.id)
              }}>
                <div className={"wrap"}>{e.workers.length}</div>
              </div>,
              group: e.description,
              circuit: e.circuit.toUpperCase(),
              shift: e.shift,
              overloadedN: e.qtyOverloaded.toString(),
            };
          })
      }
    });
  }

  public showWorkerModal(id) {
    this.props.toggleWorkerModal(id);
  }

  public render() {
    return <div className={"MonthlyOverloadedTable"}>
      <Table headers={this.state.table.headers}
             items={this.state.table.items}
             onClick={(i) => console.log(i)}/>
    </div>;
  }
}
