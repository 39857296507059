import React, {Component} from "react";
import {WorkerDto} from "../../../../api/entity/WorkerDto";
import {MonthlyUnavailabilityRowDto} from "../../../../api/entity/MonthlyUnavailabilityRowDto";
import {ModalType} from "../../../../components/Modal/ModalType";
import MonthlyUnavailabilityPlanningApi from "../../../../api/planning/MonthlyUnavailabilityPlanningApi";
import I18n from "../../../../lib/I18n";
import {MonthlyUnavailabilityPlanningModal} from "../../../../components/Modal/MonthlyUnavailabilityPlanningModal/MonthlyUnavailabilityPlanningModal";
import {DateTime} from "luxon";
import IconBackBtn from "../../../../icons/IconBackBtn";
import SearchInput from "../../../../components/SearchInput/SearchInput";
import Button from "../../../../components/Button/Button";
import {MonthlyPlanningUnavailabilityTable} from "../../../../components/TimeLine/MonthlyPlanningUnavailabilityTable/MonthlyPlanningUnavailabilityTable";
import {MonthlyPlannedUnavailabilityRequest} from "../../../../api/entity/request/MonthlyPlannedUnavailabilityRequest";
import "./WizardStepTwo.scss"
import {MonthlyEmployee} from "../../../Dashboard/MonthlyPlanning/MonthlyDTO";
import {CircuitSelect} from "../../../../components/CircuitSelect/CircuitSelect";

interface Props {
  currentDate: Date,
  goToStepThree: () => void;
  workers: WorkerDto[],
  circuitSearch: string,
  onClickBack: () => void,
}

interface State {
  filter: string
  isUnavailabilityModalOpen: boolean,
  monthlyPlannedUnavailabilities: MonthlyUnavailabilityRowDto[]
  modalUnavailabilityType: ModalType,
  unavailabilityModel: MonthlyUnavailabilityRowDto
}

export default class WizardStepTwo extends Component<Props, State> {

  private unavailabilityPlanningApi: MonthlyUnavailabilityPlanningApi = new MonthlyUnavailabilityPlanningApi();

  /**************************************************
   * CONSTRUCTOR
   **************************************************/
  constructor(props) {
    super(props);
    this.state = {
      monthlyPlannedUnavailabilities: [],
      filter: "",
      isUnavailabilityModalOpen: false,
      modalUnavailabilityType: ModalType.CREATE,
      unavailabilityModel: this.getEmptyUnavailabilityModel()
    }

    this.loadUnavailabilityForMonth(this.props.currentDate);
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if(prevProps.circuitSearch != this.props.circuitSearch) {
      this.loadUnavailabilityForMonth(this.props.currentDate);
    }
  }

  /**************************************************
   * LIFECYCLE
   **************************************************/
  render() {
    let labels = I18n.get().MonthlyTimeLine;
    return <div className="WizardStepTwo">
      {this.state.isUnavailabilityModalOpen &&
      <MonthlyUnavailabilityPlanningModal
        subject={this.state.unavailabilityModel}
        workers={this.props.workers}
        targetDate={DateTime.fromJSDate(this.props.currentDate)}
        onCancel={() => this.toggleUnavailabilityModal()}
        modalType={this.state.modalUnavailabilityType}
        onModify={(modifiedSubject) => this.onModifyUnavailability(modifiedSubject)}
        onCreate={(modifiedSubject) => this.createUnavailability(modifiedSubject)}
        onDelete={(id) => this.onDeleteUnavailability(id, true)}
      />}

      <div className="WizardStepTwo-actionBar">
        <SearchInput
          placeholder={I18n.get().MonthlyTimeLine.searchLabel}
          onChange={(filter) => this.setState({filter}, () => this.loadUnavailabilityForMonth(this.props.currentDate))}
        />
        <Button onClick={() => this.toggleOpenCreateUnavailabilityModal()}>
          {labels.add}
        </Button>
        <div className="WizardStepTwo-actionBar-separator"/>
        <Button onClick={() => this.props.goToStepThree()}>
          {labels.next}
        </Button>
      </div>

      <div className="WizardStepTwo-navigation" onClick={() => this.backToStepOne()}>
        <IconBackBtn width={24} height={24}/>
        <p>{I18n.get().NeedManagement.addNewNeedManagement.backButton}</p>
      </div>

      <MonthlyPlanningUnavailabilityTable
        workers={this.props.workers}
        onDelete={(unavailabilityId => this.onDeleteUnavailability(unavailabilityId, false))}
        onEdit={(unavailabilityId) => this.loadUnavailabilityEntryEdit(unavailabilityId)}
        monthlyPlannedUnavailabilities={this.state.monthlyPlannedUnavailabilities}
      />
    </div>
  }

  private toggleOpenCreateUnavailabilityModal() {
    this.setState({modalUnavailabilityType: ModalType.CREATE});
    this.toggleModifyUnavailabilityModal();
  }

  private toggleUnavailabilityModal() {
    this.setState({isUnavailabilityModalOpen: !this.state.isUnavailabilityModalOpen});
  }

  private toggleModifyUnavailabilityModal() {
    let {isUnavailabilityModalOpen} = this.state;
    this.setState({isUnavailabilityModalOpen: !isUnavailabilityModalOpen});
  }

  private async loadUnavailabilityForMonth(date: Date) {
    const monthlyPlannedUnavailabilities: MonthlyUnavailabilityRowDto[] =
      (await this.unavailabilityPlanningApi.getUnavailabilityDate(
        DateTime.fromJSDate(date).startOf("month"), this.state.filter)).content;
    const circuitSearch = this.props.circuitSearch;
    if (circuitSearch != CircuitSelect.NO_FILTER) {
      const filterLowerCase = circuitSearch.toLowerCase();
      let filteredIds = this.props.workers
        .filter(w => w.circuit.toLowerCase() == filterLowerCase)
        .map(wi => wi.id);
      const filteredElements = monthlyPlannedUnavailabilities.filter(wd => filteredIds.indexOf(wd.workerId) > -1);
      this.setState({monthlyPlannedUnavailabilities: filteredElements});
    } else {
      this.setState({monthlyPlannedUnavailabilities});
    }
  }

  /**************************************************
   * PRIVATE FUNCTIONS
   **************************************************/

  private async createUnavailability(modifiedSubject: MonthlyUnavailabilityRowDto) {
    this.setState({unavailabilityModel: this.getEmptyUnavailabilityModel()});
    // TODO: maybe we can use NT entities?
    let request: MonthlyPlannedUnavailabilityRequest = {
      filterResponse: "", data: {
        date: modifiedSubject.date.toISO(),
        id: null, workerId: modifiedSubject.workerId, entries: modifiedSubject.entries.map(ms => {
          return {
            initialdate: ms.date.toISO(),
            fromtime: ms.fromtime, totime: ms.totime, unavailabilityid: ms.unavailabilityid, id: null
          }
        })
      }
    }

    let monthlyPlannedUnavailabilityResponse = await this.unavailabilityPlanningApi
      .upsert(request);

    if (monthlyPlannedUnavailabilityResponse.errors.length == 0) {
      this.toggleModifyUnavailabilityModal();
      this.loadUnavailabilityForMonth(this.props.currentDate)
    }
  }

  private async onDeleteUnavailability(id: number, toggleModal: boolean) {
    this.unavailabilityPlanningApi
      .deleteUnavailability(id).finally(() => {
        this.loadUnavailabilityForMonth(this.props.currentDate);
        if (toggleModal) {
          this.toggleModifyUnavailabilityModal();
        }
      }
    );
  }

  private async onModifyUnavailability(modifiedSubject: MonthlyUnavailabilityRowDto) {
    this.setState({unavailabilityModel: modifiedSubject});
    let request: MonthlyPlannedUnavailabilityRequest = {
      filterResponse: "", data: {
        date: modifiedSubject.date.toISO(),
        id: modifiedSubject.id,
        workerId: modifiedSubject.workerId, entries: modifiedSubject.entries.map(ms => {
          return {
            initialdate: ms.date.toISO(),
            fromtime: ms.fromtime, totime:
            ms.totime,
            unavailabilityid: ms.unavailabilityid,
            id: ms.id
          }
        })
      }
    }

    let monthlyPlannedUnavailabilityResponse = await this.unavailabilityPlanningApi
      .upsert(request);

    if (monthlyPlannedUnavailabilityResponse.errors.length == 0) {
      this.toggleModifyUnavailabilityModal();
      this.loadUnavailabilityForMonth(this.props.currentDate)
    }
  }

  private async loadUnavailabilityEntryEdit(unavailabilityId: number) {
    let monthlyPlannedUnavailabilityEntryResponse = await this.unavailabilityPlanningApi
      .getUnavailabilityEntryById(unavailabilityId);
    let content = monthlyPlannedUnavailabilityEntryResponse.content;
    let electedMonthlyAvailability = this.state.monthlyPlannedUnavailabilities.filter(ma => ma.id == content[0].unavailabilityid)[0];

    this.setState({
      unavailabilityModel: {
        id: electedMonthlyAvailability.id,
        workerId: electedMonthlyAvailability.workerId,
        date: DateTime.fromJSDate(this.props.currentDate),
        entries: content
      },
      modalUnavailabilityType: ModalType.MODIFY
    })
    this.toggleModifyUnavailabilityModal();
  }

  private getEmptyUnavailabilityModel() {
    return {id: null, workerId: null, date: DateTime.now(), entries: []};
  }

  private backToStepOne() {
    this.props.onClickBack();
  }
}
