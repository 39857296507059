import {NeedDto} from "../entity/NeedDto";
import Time from "../../lib/utils/Time";
import {DateTime} from "luxon";
import {DailyPlanningWorkerDtoNt} from "../entity/response/nt/DailyPlanningWorkerResponseNt";
import {VehicleDailyPlanningDto} from "../entity/VehicleDailyPlanningDto";
import DailyServicesTimeLineDetail from "../../pages/DailyPlanning/DailyServicesTimeLineDetail/DailyServicesTimeLineDetail";

export interface NeedMap {
  [service: string]: {
    [turn: string]: number
  }
}

export enum SpecialServices {
  FERIE = "FERIE",
  FUORI_TURNO = "FUORI TURNO",
  LIBERO = "LIBERO",
  REPERIBILE = "REPERIBILE",
  RIPOSO = "RIPOSO",
  SMONTO = "SMONTO",
}


export type ServiceMessages = { [service: string]: string[] };
export default class NeedUtils {
  private constructor() {
  }

  /**************************************************
   * GET SERVICE NEED MAPS AND COMPARE FOR ERRORS
   **************************************************/
  public static getExpectedWorkerNeedMap(needs: NeedDto[], day: DateTime): NeedMap {
    const workerNeedMap = {};
    for (const need of needs) {
      for (const human of need.needHumanDtoList) {
        if (!workerNeedMap[need.serviceCode])
          workerNeedMap[need.serviceCode] = {}
        if (!workerNeedMap[need.serviceCode][human.turnCode])
          workerNeedMap[need.serviceCode][human.turnCode] = 0
        const isHoliday = Time.isHoliday(day);
        const weekDay = day.weekday - 1
        workerNeedMap[need.serviceCode][human.turnCode] += isHoliday
          ? parseInt(human.numberVehiclesFestive[weekDay])
          : parseInt(human.numberVehiclesWorkdays[weekDay])
      }
    }
    return workerNeedMap;
  }

  public static getActualWorkerNeedMap(dailyWorkersForDay: DailyPlanningWorkerDtoNt[]): NeedMap {
    const workerNeedMap = {};
    for (const worker of dailyWorkersForDay) {
      if (!workerNeedMap[worker.service])
        workerNeedMap[worker.service] = {}
      if (!workerNeedMap[worker.service][worker.turn])
        workerNeedMap[worker.service][worker.turn] = 0
      workerNeedMap[worker.service][worker.turn]++
    }
    return workerNeedMap;
  }

  public static getExpectedVehicleNeedMap(needs: NeedDto[], day: DateTime): NeedMap {
    const vehicleNeedMap:NeedMap = {};
    for (const need of needs) {
      for (const vehicle of need.needVehicleDtoList) {
        if (!vehicleNeedMap[need.serviceCode])
          vehicleNeedMap[need.serviceCode] = {}
        let key = vehicle.fromTime+":00_"+vehicle.toTime+":00_"+vehicle.category.toUpperCase();


        if (!vehicleNeedMap[need.serviceCode][key])
          vehicleNeedMap[need.serviceCode][key] = 0
        const isHoliday = Time.isHoliday(day);
        const weekDay = day.weekday - 1
        vehicleNeedMap[need.serviceCode][key] += isHoliday
          ? parseInt(vehicle.numberVehiclesFestive[weekDay])
          : parseInt(vehicle.numberVehiclesWorkdays[weekDay])
      }
    }
    return vehicleNeedMap;
  }

  static getActualVehicleNeedMap(dailyVehicles: VehicleDailyPlanningDto[]) {
    const vehicleNeedMap:NeedMap = {};
    for (const vehicle of dailyVehicles) {
      if(vehicle.vehicleId != -1) {
        let key = vehicle.vehicleStartTime + "_" + vehicle.vehicleEndTime + "_" + vehicle.serviceCategory.toUpperCase();
        if (!vehicleNeedMap[vehicle.service])
          vehicleNeedMap[vehicle.service] = {}
        if (!vehicleNeedMap[vehicle.service][key])
          vehicleNeedMap[vehicle.service][key] = 0
        vehicleNeedMap[vehicle.service][key]++
      }
    }
    return vehicleNeedMap;
  }

  public static compareAndGetErrorsAndWarningsForWorkers(expected: NeedMap, actual: NeedMap): [ServiceMessages, ServiceMessages] {
    const errors = {};
    const warnings = {};

    for (const service in expected) {
      errors[service] = [];
      warnings[service] = [];
      if (expected.hasOwnProperty(service)) {
        // We check the service exists in the planning
/*        if (!actual[service]) {
          // If the service is expected but does not exists in the daily planning, we add an error
          errors[service].push(`Il servizio ${service} non è coperto.`)
        } else { */
          for (const turn in expected[service]) {
            if (expected[service].hasOwnProperty(turn)) {
              // We check if the service's turn exists in the planning
              let actualWorkers = actual[service] ? actual[service][turn] : 0;
              let expectedWorkers = expected[service][turn];


              if ((!actualWorkers) && expectedWorkers > 0) {
                // If the service's turn is expected but does not exists in the daily planning, we add an error
                errors[service].push(`Il turno ${turn} del servizio ${service} non è coperto da nessun dipendente.`)
                // We check if the service's turn existing workers match the expected
              } else if (actualWorkers < expectedWorkers) {
                // If lower than expected we add an error
                errors[service].push(`Il turno ${turn} del servizio ${service} è coperto solo da ${actualWorkers} dipendenti su ${expectedWorkers} richiesti`)
              } else if (actualWorkers > expectedWorkers) {
                // If greater than expected we add a warning
                warnings[service].push(`Il turno ${turn} del servizio ${service} è coperto da più dipendenti del necessario (${actualWorkers}/${expectedWorkers})`)
              }
            }
          }
        /*}*/
      }
    }

    for (const service in actual) {
      if (!warnings[service])
        warnings[service] = [];
      if (actual.hasOwnProperty(service)) {
        for (const turn in actual[service]) {
          if (actual[service].hasOwnProperty(turn)) {
            if (!expected[service] || !expected[service][turn]) {
              warnings[service].push(`Il turno ${turn} per servizio ${service} non è richiesto ma è coperto ugualmente da ${actual[service][turn]} dipendenti`)
            }
          }
        }
      }
    }

    return [errors, warnings];
  }

  public static compareAndGetErrorsAndWarningsForVehicles(expected: NeedMap, actual: NeedMap): [ServiceMessages, ServiceMessages] {
    const errors = {};
    const warnings = {};

    for (const service in expected) {
      errors[service] = [];
      warnings[service] = [];
      if (expected.hasOwnProperty(service)) {
        // We check the service exists in the planning
        /*        if (!actual[service]) {
                  // If the service is expected but does not exists in the daily planning, we add an error
                  errors[service].push(`Il servizio ${service} non è coperto.`)
                } else { */
        for (const key in expected[service]) {
          if (expected[service].hasOwnProperty(key)) {
            const [start,end,category] = key.split("_")
            // We check if the service's key exists in the planning
            let actualVehicles = actual[service] ? actual[service][key] : 0;
            let expectedVehicles = expected[service][key];

            if ((!actualVehicles) && expectedVehicles > 0) {
              // If the service's key is expected but does not exists in the daily planning, we add an error
              errors[service].push(`Il fabbisogno ${category} dalle ${start} alle ${end} del servizio ${service} non è coperto da nessun veicolo.`)
              // We check if the service's key existing workers match the expected
            } else if (actualVehicles < expectedVehicles) {
              // If lower than expected we add an error
              errors[service].push(`Il fabbisogno ${category} dalle ${start} alle ${end} del servizio ${service} è coperto solo da ${actualVehicles} veicoli su ${expectedVehicles} richiesti`)
            } else if (actualVehicles > expectedVehicles) {
              // If greater than expected we add a warning
              warnings[service].push(`Il fabbisogno ${category} dalle ${start} alle ${end} del servizio ${service} è coperto da più veicoli del necessario (${actualVehicles}/${expectedVehicles})`)
            }
          }
        }
        /*}*/
      }
    }

    for (const service in actual) {
      if (!warnings[service])
        warnings[service] = [];
      if (actual.hasOwnProperty(service)) {
        for (const key in actual[service]) {
          if (actual[service].hasOwnProperty(key)) {
            const [start,end,category] = key.split("_")
            if (!expected[service] || !expected[service][key]) {
              warnings[service].push(`Il fabbisogno ${category} dalle ${start} alle ${end} del servizio ${service} non è richiesto ma è coperto ugualmente da ${actual[service][key]} veicoli`)
            }
          }
        }
      }
    }

    return [errors, warnings];
  }

  /**************************************************
   * BY CONTAINER VEHICLE
   **************************************************/
  public static getActualServiceHumanNeedByContainerVehicle(dailyWorkers: DailyPlanningWorkerDtoNt[], containerVehicle: string): number {
    let totalActualHumanNeed = 0;
    for (const daily of dailyWorkers) {
      if (daily.containervehicle == containerVehicle && (daily.workerid || daily.workername))
        totalActualHumanNeed++
    }
    return totalActualHumanNeed;
  }

  public static getActualServiceVehicleNeedByContainerVehicle(dailyVehicles: VehicleDailyPlanningDto[], containerVehicle: string): number {
    let totalActualHumanNeed = 0;
    for (const daily of dailyVehicles) {
      if (daily.containerVehicle == containerVehicle && daily.vehicleId > DailyServicesTimeLineDetail.EMPTY_ENTRY_ID)
        totalActualHumanNeed++
    }
    return totalActualHumanNeed;
  }

  /**************************************************
   * TOTAL
   **************************************************/
  public static getTotalActualServiceHumanNeed(dailyWorkers: DailyPlanningWorkerDtoNt[], need: NeedDto) {
    const employeeTurns = []
    let totalActualHumanNeed = 0;
    for (const daily of dailyWorkers) {
      const workerIdentifier:string|number = daily.workerid ? daily.workerid : daily.workername;
      if (daily.service == need.serviceCode && !!workerIdentifier) {
        const key = `${daily.turn}_${workerIdentifier}`
        /** If the same employee has multiple instances of the same turn,
         * on the same day, for the same service
         * we only count it as one **/
        if(employeeTurns.indexOf(key) < 0) {
          employeeTurns.push(key)
          totalActualHumanNeed++
        }
      }
    }
    return totalActualHumanNeed;
  }

  public static getTotalActualServiceVehicleNeed(dailyVehicle: VehicleDailyPlanningDto[], need: NeedDto) {
    let totalActualVehicleNeed = 0;
    for (const daily of dailyVehicle) {
      if (daily.service == need.serviceCode && daily.vehicleId &&
        daily.vehicleId > DailyServicesTimeLineDetail.EMPTY_ENTRY_ID)
        totalActualVehicleNeed++
    }
    return totalActualVehicleNeed;

  }

  public static getTotalExpectedServiceHumanNeed(day: DateTime, need: NeedDto) {
    const isHoliday = Time.isHoliday(day);
    const weekDay = day.weekday - 1
    let total = 0;
    for (const human of need.needHumanDtoList) {
      if (isHoliday)
        total += parseInt(human.numberVehiclesFestive[weekDay])
      else
        total += parseInt(human.numberVehiclesWorkdays[weekDay])
    }
    return total;
  }

  public static getTotalExpectedServiceVehicleNeed(day: DateTime, need: NeedDto) {
    const isHoliday = Time.isHoliday(day);
    const weekDay = day.weekday - 1
    let total = 0;
    for (const vehicle of need.needVehicleDtoList) {
      if (isHoliday)
        total += parseInt(vehicle.numberVehiclesFestive[weekDay])
      else
        total += parseInt(vehicle.numberVehiclesWorkdays[weekDay])
    }
    return total;
  }

  /**************************************************
   * SERVICE TYPES
   **************************************************/
  public static isFree(service: string) {
    return !service || service?.toUpperCase() == SpecialServices.LIBERO.toUpperCase();
  }

  public static isAvailable(service: string) {
    return service?.toUpperCase() == SpecialServices.REPERIBILE.toUpperCase();
  }

  public static isVacation(service: string) {
    return service?.toUpperCase() == SpecialServices.FERIE.toUpperCase();
  }

  public static isRest(service: string) {
    return service?.toUpperCase() == SpecialServices.RIPOSO.toUpperCase();
  }

  public static isOutOfTurn(service: string) {
    return service?.toUpperCase() == SpecialServices.FUORI_TURNO.toUpperCase();
  }

  public static isUnmount(service: string) {
    return service?.toUpperCase() == SpecialServices.SMONTO.toUpperCase();
  }

  public static isOther(service: string) {
    return !NeedUtils.isFree(service)
      && !NeedUtils.isAvailable(service)
      && !NeedUtils.isVacation(service)
      && !NeedUtils.isRest(service)
      && !NeedUtils.isOutOfTurn(service)
      && !NeedUtils.isUnmount(service)
  }


}
