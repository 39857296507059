import {UserDailyShiftDto} from "../../api/entity/UserDailyShiftDto";
import {WTEntry} from "./WorkersTable/WorkersTable";

export class EntryMapper {
  public static mapDateToTableEntry(entry: UserDailyShiftDto): WTEntry {
    return {
      container: this.getRadioOrServiceCode(
        entry.radioCodeName, entry.containerVehicle),
      vehicle: entry.vehicles.join(", "),
      timeStart: entry.timeStart,
      timeEnd: entry.timeEnd,
      placement: entry.placement,
      circuit: entry.circuit,
      operatorThree: entry.operator3.toUpperCase(),
      operatorTwo: entry.operator2.toUpperCase(),
      operator: entry.operator.toUpperCase(),
      shift: entry.shiftCode
    }
  }

  private static getRadioOrServiceCode(radioCodeName: string, containerVehicle: string): string {
    return radioCodeName == "" ? containerVehicle : radioCodeName;
  }
}
