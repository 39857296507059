import React, {Component} from "react";
import TimeLineNavigation from "../../../components/TimeLine/TimeLineNavigation/TimeLineNavigation";
import MonthlyPlanningNavigationLabel from "./MonthlyPlanningNavigationLabel";
import {DateTime} from "luxon";
import {WizardStep} from "../MonthlyPlanningWizard/MonthlyPlanningWizard";
import GfDateUtils from "../../../components/DatePicker/GfDateUtils";

interface Props {
  currentDate: DateTime
  isFutureEnabled?: boolean
  step?: WizardStep
  onMonthChanged: (date: DateTime) => void
}

interface State {
  isCalendarOpen: boolean
}

export default class MonthlyNavigationBar extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      isCalendarOpen: false
    }
  }

  render() {
    if (this.props.step != WizardStep.ZERO) return null;
    return <TimeLineNavigation
      navigationLabel={<MonthlyPlanningNavigationLabel
        currentDate={this.props.currentDate}/>}
      selectedDate={this.props.currentDate}
      prevDisabled={this.isPrevDisabled()}
      onDateChange={month => this.triggerMonthChange(month)}
      prevPage={() => this.previousMonth()}
      nextDisabled={this.isNextDisabled()}
      nextPage={() => this.nextMonth()}
      granularity="month"
      maxDate={ this.getMaxDate()}
    />
  }

  private getMaxDate() {
    return this.props.isFutureEnabled ?
      DateTime.fromFormat("2099-12-31", GfDateUtils.STORED_DATE_FORMAT).toJSDate() :
      DateTime.now().startOf("month")
        .plus({month: 1}).toJSDate();
  }

  private triggerMonthChange(month: DateTime) {
    this.props.onMonthChanged(month.plus({day : 1}));
  }

  public isPrevDisabled(): boolean {
    return false
  }

  public isNextDisabled(): boolean {
    if(this.props.isFutureEnabled) return false;
    const [year, month] = [DateTime.now().year, DateTime.now().month];
    const {currentDate} = this.props;
    return (currentDate.year > year) || (currentDate.year == year && currentDate.month > month)
  }

  public nextMonth() {
    const {currentDate} = this.props;
    this.props.onMonthChanged(currentDate.plus({month: 1}).startOf("month"))
  }

  public previousMonth() {
    const {currentDate} = this.props;
    this.props.onMonthChanged(currentDate.minus({month: 1}).startOf("month"))
  }
}

