import React from "react";
import Icon, {Props} from "./Icon";

export default class sIconPencil extends Icon {
    static defaultProps:Partial<Props> = {
        height:15,
        width:15
    };

    render() {
        return (
            <svg className="IconCalendar"
                 width={this.props.width}
                 height={this.props.height}
                 viewBox="0 0 24 24">
              <path fill="#2E3A59" d="M3.058 13.996l-.204 2.245c-.047.518.387.952.905.905l2.245-.204 9.428-9.428-2.946-2.946-9.428 9.428zm14.731-8.84c.326-.325.326-.852 0-1.178l-1.767-1.767c-.326-.326-.853-.326-1.179 0l-1.768 1.767 2.947 2.947 1.767-1.768z" mask="url(#7q5182y1lc)"/>
            </svg>
        );
    }
}
