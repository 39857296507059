import React, {PureComponent} from "react";
import "./WorkerRegistryContracts.scss";
import {WorkerDto} from "../../../api/entity/WorkerDto";
import I18n from "../../../lib/I18n";
import WorkerUtils from "../../../pages/WorkersRegistry/WorkerUtils";
import {WorkerContractDto} from "../../../api/entity/WorkerContractDto";

export interface Props{
  onToggleModify: ([]) => void;
  workerContracts: WorkerContractDto[];
}

interface State {

}

export class WorkerRegistryContracts extends React.PureComponent<Props, State> {

  static defaultProps: Partial<Props> = {
    onToggleModify: () => {
    },
    workerContracts: []
  };

  render() {
    let contractsMapped = this.props.workerContracts.map((item, index) =>
      <div className={"contractDetails"} key={index}>
        <div className={"row"}>
          <div className={"lbl"}>
            <label>{I18n.get().WorkersRegistry.contract.contractType}</label>
            <div className={"value"}>{item.contractType}</div>
          </div>
          <div className={"lbl"}>
            <label>{I18n.get().WorkersRegistry.contract.startContract}</label>
            <div className={"value"}>{I18n.printDate(item.startDate, "dd-MM-yyyy")}</div>
          </div>
          <div className={"lbl"}>
            <label>{I18n.get().WorkersRegistry.contract.endContract}</label>
            <div className={"value"}>{I18n.printDate(item.endDate, "dd-MM-yyyy")}</div>
          </div>
        </div>
        <div className={"row"}>
          <div className={"lbl"}>
            <label>{I18n.get().WorkersRegistry.contract.weekHour}</label>
            <div className={"value"}>{item.weekHour}</div>
          </div>
          <div className={"lbl"}>
            <label>{I18n.get().WorkersRegistry.contract.oscillation}</label>
            <div className={"value"}>{item.oscillation}</div>
          </div>
          <div className={"lbl"}>
            <label>{I18n.get().WorkersRegistry.contract.overrun}</label>
            <div className={"value"}>{item.overrun}</div>
          </div>
        </div>
      </div>);
    return (
      <div className={"WorkerRegistryContracts"}>
        <div className={"contractSubtitle"}>
          <span className={"subtitle"}>{I18n.get().WorkersRegistry.sidebar.contractsDetails} </span>
          <span className={"actionButton"} onClick={() => this.props.onToggleModify(this.props.workerContracts)}>
            {I18n.get().ActionButton.modify}
          </span>
        </div>
        {contractsMapped}
      </div>
    )
  }
}

export default WorkerRegistryContracts;
