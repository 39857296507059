import {DateTime} from "luxon";

export default class Time{
  private constructor() {}

  public static numberToHourString(hour:number){
    const decimal = Math.round((hour % 1) * 100) / 100;
    const integer = Math.trunc(hour);

    const hourString = Time.toDoubleDigit(integer);
    const minuteString = Time.toDoubleDigit(Math.round(60*decimal))
    return hourString+":"+minuteString
  }

  public static hourStringToNumber(hourString:string){
    if(hourString?.length != 5 && hourString?.length != 8)
      return null;
    const time = hourString.split(":");
    return parseInt(time[0]) + (1/((60/parseInt(time[1]))))
  }

  public static toDoubleDigit(value:number):string{
    return value < 10 ? '0' + value : ''+value;
  }

  public static getStringMonth(date:Date):string{
    return this.months[date.getMonth()]
  }

  public static getStringDay(date:Date):string{
    return this.days[date.getDay()]
  }

  public static getDaysInMonthStartingFromPreviousDay(datetime:DateTime):Date[]{
    const date = datetime.toJSDate()
    const month = date.getMonth();
    const tempDate = new Date(date)
    tempDate.setDate(1);
    const days:Date[] = [datetime.minus({'day' : 1}).toJSDate()];
    while (tempDate.getMonth() === month) {
      days.push(new Date(tempDate));
      tempDate.setDate(tempDate.getDate() + 1);
    }
    return days;
  }


  public static getDaysInMonth(datetime:DateTime):Date[]{
      const date = datetime.toJSDate()
      const month = date.getMonth();
      const tempDate = new Date(date)
      tempDate.setDate(1);
      const days:Date[] = [];
      while (tempDate.getMonth() === month) {
        days.push(new Date(tempDate));
        tempDate.setDate(tempDate.getDate() + 1);
      }
      return days;
    }

  public static getHoursInDay():string[]{
    const hoursInDays = [];
    for(let i = 0; i < 24; ++i)
      hoursInDays.push(`${Time.toDoubleDigit(i)}:00`)
    return hoursInDays;
  }

  static getDaysDifference(date1: Date, date2: Date) {
    const diffTime = Math.abs(date1.valueOf() - date2.valueOf());
    // const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const diffDays = diffTime / (1000 * 60 * 60 * 24);
    return diffDays
  }

  static toISO(date:Date){
    return `${date.getFullYear()}-${Time.toDoubleDigit(date.getMonth()+1)}-${Time.toDoubleDigit(date.getDate())}`
  }

  static toISOMonth(date:Date){
    return `${date.getFullYear()}-${Time.toDoubleDigit(date.getMonth()+1)}-01`
  }

  static getISONextMonth():string{
    const d = new Date();
    d.setDate(1)
    d.setMonth(d.getMonth()+1)
    return this.toISOMonth(d)
  }


  static HHMMToMinutes(hhMM:string):number{
    const [hh,mm] = hhMM.split(":");
    const h = parseInt(hh)
    const m = parseInt(mm)
    return (h*60)+m
  }

  static minutesToHHMM(minutes:number):string{
    const h = Math.floor(minutes / 60)
    const m = minutes % 60
    return `${this.toDoubleDigit(h)}:${this.toDoubleDigit(m)}`
  }

  static getMinutesAndHours(minutes:number):[number,number]{
    const h = Math.floor(minutes / 60)
    const m = minutes % 60

    return [h,m]
  }

  static minutesToHourString(minutes:number):string{
    const h = Math.floor(minutes / 60)
    const m = minutes % 60

    const minutesString = `${m} minut${m==1?"o":"i"}`
    const hoursString = `${h} or${h==1?"a":"e"}`

    if(!h) return minutesString
    if(!m) return hoursString
    return `${hoursString} ${minutesString}`
  }

  static isHoliday(day: DateTime):boolean{
    const easter = Time.getEaster(day.year)
    if(day.month == easter[0] && day.day == easter[1])
      return true;
    if(day.month == easter[0] && day.day == easter[1]+1)
      return true;

    for(const holiday of this.bankHolidays)
      if(day.month == holiday[0] && day.day == holiday[1])
        return true;

    return false;
  }

  static isWorkDay(day: DateTime):boolean{
    return !this.isHoliday(day);
  }

  private static getEaster(year) {
    var f     = Math.floor,
        // Golden Number - 1
        G     = year % 19,
        C     = f(year / 100),
        // related to Epact
        H     = (C - f(C / 4) - f((8 * C + 13) / 25) + 19 * G + 15) % 30,
        // number of days from 21 March to the Paschal full moon
        I     = H - f(H / 28) * (1 - f(29 / (H + 1)) * f((21 - G) / 11)),
        // weekday for the Paschal full moon
        J     = (year + f(year / 4) + I + 2 - C + f(C / 4)) % 7,
        // number of days from 21 March to the Sunday on or before the Paschal full moon
        L     = I - J,
        month = 3 + f((L + 40) / 44),
        day   = L + 28 - 31 * f(month / 4);
    return [month, day];
  }


  /**************************************************
   * VARIABLES
   **************************************************/
  private  static readonly months = [
    "GEN","FEB","MAR","APR","MAG","GIU","LUG","AGO","SETT","OTT","NOV","DIC"
  ] as const

   private static readonly days = [
     'DOM', 'LUN','MAR','MER','GIO','VEN','SAB',
   ] as const

  private static readonly bankHolidays = [
    [1, 1], // New year
    [1, 6], // Epiphany
    [4, 25], // Liberation Day
    [5, 1], // International Workers' Day
    [6, 2], // Republic Day
    [8, 15], // Assumption of Mary to Heaven
    [10,4], // St Petronio
    [11, 1], // All Saints Day
    [12, 8], // Immaculate Conception
    [12, 25], // Christmas Day
    [12, 26], // St Stephen's Day
  ]
}

