import React, {Component} from "react";
import "./DailyWorkerHeader.scss"
import Time from "../../../../lib/utils/Time";
interface Props{
  day:Date
  hour:string
}

interface State{

}

export default class DailyWorkerHeader extends Component<Props,State>{
  render() {
    const {day,hour} = this.props
    return <div className="DailyWorkerHeader">
      <div className="DailyWorkerHeader-subtext">{Time.getStringDay(day)}</div>
      <div className="DailyWorkerHeader-text">{hour}</div>
    </div>
  }

}
