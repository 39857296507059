import React, {BaseSyntheticEvent, Component} from "react";
import "./WorkerContractModal.scss";
import Button from "../../Button/Button";
import TextInput from "../../TextInput/TextInput";
import I18n from "../../../lib/I18n";
import {GfDatePicker} from "../../DatePicker/GfDatePicker";
import WorkersRegistryUtils from "../../../pages/WorkersRegistry/WorkerUtils";
import {WorkerContractDto} from "../../../api/entity/WorkerContractDto";
import update from 'immutability-helper';
import {DateTime} from "luxon";
import TextInputMask from "../../TextInputMask/TextInputMask";
import ContractValidator from "../../../api/worker/validator/ContractValidator";

export interface Props {
  title: string,
  className: string,
  workerId: number,
  model: WorkerContractDto[],
  onSubmit: (contracts: WorkerContractDto[], toBeDeleted : WorkerContractDto[], workerId: number) => void;
  onError: (errors) => void;
  onCancel: () => void;
  disabled: boolean;
}

interface State {
  workerContracts: WorkerContractDto[],
  errors: WorkerContractModalErrors[],
  deleteContracts: WorkerContractDto[],
}

export interface WorkerContractModalErrors {
  contract: string;
  startDate: string;
  endDate: string;
  weekHour: string;
}

export class WorkerContractModal extends Component<Props, State> {

  private workerUtils = new WorkersRegistryUtils();

  /************************************************
   * PROPS
   ************************************************/
  static defaultProps: Partial<Props> = {
    model: [],
    onCancel: () => {
    },
    disabled: false
  };

  /************************************************
   * CONSTRUCTOR
   ************************************************/
  constructor(props: Props) {
    super(props);
    this.restoreForm();
  }

  onCancel() {
    this.restoreForm();
    this.props.onCancel();
  }

  async onSubmit(event: BaseSyntheticEvent) {
    event.preventDefault();
    let {errors} = this.state;
    errors = ContractValidator.validate(this.state.workerContracts);
    this.setState({errors: errors});
    if (ContractValidator.isAnyErrorsPresent(errors) == false) {
      this.props.onSubmit(this.state.workerContracts, this.state.deleteContracts, this.props.workerId);
    }
  }

  private restoreForm() {
    this.state = {
      workerContracts: this.props.model,
      errors: this.props.model.map(this.workerUtils.getEmptyContractErrorState),
      deleteContracts: []
    }
  }

  private deleteAt(index: number) {
    const {workerContracts, errors, deleteContracts} = this.state;

    deleteContracts.push(workerContracts[index]);
    workerContracts.splice(index, 1);
    errors.splice(index, 1);
    this.setState({workerContracts, errors, deleteContracts});

  }

  /************************************************
   * LIFECYCLE
   ************************************************/
  render() {

    return (
      <div className={"ModifyWorkerContractModal modal"}>
        <form onSubmit={(event) => this.onSubmit(event)}>
          <div className={"Title"}>{I18n.get().WorkersRegistry.contract.modifyContract}</div>
          <div className={"Content"}>
            <div className={"contractContainer"}>
              <div className={"contracts"}>
                {this.state.workerContracts.map((item, index) => {
                    return <div className={"Contract"}
                                key={item.idWorker + "_" + index + "_" + this.state.workerContracts.length}>
                      <div className="close" onClick={() => {
                        this.deleteAt(index)
                      }}>×
                      </div>
                      <div className={"row"}>
                        <TextInput
                          className={"text-input"}
                          label={I18n.get().WorkersRegistry.contract.contractType}
                          placeholder={I18n.get().WorkersRegistry.name}
                          errorMessage={this.state.errors[index].contract}
                          defaultValue={item.contractType}
                          onChange={(value) => {
                            let {workerContracts} = this.state;
                            workerContracts[index].contractType = value;
                            this.setState({workerContracts});
                          }}
                        />
                        <GfDatePicker
                          label={I18n.get().WorkersRegistry.contract.startContract}
                          onChange={(startDate) => {
                            this.setState({
                              workerContracts: update(this.state.workerContracts, {
                                [index]: {
                                  startDate: {$set: startDate},
                                  endDate: {
                                    $set: this.state.workerContracts[index].endDate < startDate
                                      ? startDate.plus({days: 1})
                                      : this.state.workerContracts[index].endDate
                                  }
                                }
                              }),
                              errors: update(this.state.errors, {[index]: {startDate: {$set: ""}}})
                            })
                          }}
                          date={item.startDate}
                          errorMessage={this.state.errors[index].startDate}/>
                        <GfDatePicker
                          label={I18n.get().WorkersRegistry.contract.endContract}
                          onChange={(endDate) => {
                            this.setState({
                              workerContracts: update(this.state.workerContracts, {[index]: {endDate: {$set: endDate}}}),
                              errors: update(this.state.errors, {[index]: {endDate: {$set: ""}}})
                            })
                          }}
                          minDate={item.startDate ? item.startDate.plus({days: 1}) : DateTime.now().plus({days: 1})}
                          date={item.endDate}
                          errorMessage={this.state.errors[index].endDate}/>
                      </div>
                      <div className={"row"}>
                        <TextInputMask className={"text-input"}
                                       errorMessage={this.state.errors[index].weekHour}
                                       onChange={(hour) => this.setState({
                                         workerContracts: update(this.state.workerContracts, {[index]: {weekHour: {$set: parseInt(hour)}}}),
                                         errors: update(this.state.errors, {[index]: {weekHour: {$set: ""}}})
                                       })}
                                       label={I18n.get().WorkersRegistry.contract.weekHour}
                                       defaultValue={item.weekHour ? item.weekHour.toString() : null}
                                       mask={'99'}
                                       maskChar={" "}
                                       placeholder={"00"}/>
                        <TextInputMask className={"text-input"}
                                       onChange={(oscillation) => this.setState({
                                         workerContracts: update(this.state.workerContracts, {[index]: {oscillation: {$set: parseInt(oscillation)}}})
                                       })}
                                       label={I18n.get().WorkersRegistry.contract.oscillation}
                                       defaultValue={item.oscillation ? item.oscillation.toString() : null}
                                       mask={'99'}
                                       maskChar={" "}
                                       tooltipMessage={I18n.get().WorkersRegistry.contract.oscillationDef}
                                       placeholder={"0"}/>
                        <TextInputMask className={"text-input"}
                                       onChange={(overrun) => this.setState({
                                         workerContracts: update(this.state.workerContracts,
                                           {[index]: {overrun: {$set: parseInt(overrun)}}})
                                       })}
                                       label={I18n.get().WorkersRegistry.contract.overrun}
                                       defaultValue={item.overrun ? item.overrun.toString() : null}
                                       mask={'99'}
                                       maskChar={" "}
                                       tooltipMessage={I18n.get().WorkersRegistry.contract.overrunDef}
                                       placeholder={"0"}/>
                      </div>
                    </div>
                  }
                )}
              </div>
            </div>
          </div>
          <div className={"Buttons"}>
            <div onClick={() => this.addEmptyContract()} className={"addEmptyContract"}>
              <span className={"icon"}>&#43;</span>
              <span className={"label"}>{I18n.get().WorkersRegistry.contract.addContract}</span>
            </div>
            <Button onClick={(event) => this.onSubmit(event)} className={"add-btn"}>
              {I18n.get().ActionButton.apply}
            </Button>
            <Button secondary={true} onClick={() => this.onCancel()} className={"cancel-btn"}>
              {I18n.get().ActionButton.cancel}
            </Button>
          </div>
        </form>
      </div>
    )
  }

  private addEmptyContract() {
    this.setState({
      workerContracts: update(this.state.workerContracts, {$push: [this.workerUtils.getEmptyWorkerContractDto(this.props.workerId)]}),
      errors: update(this.state.errors, {$push: [this.workerUtils.getEmptyContractErrorState()]})
    })
  }
}
