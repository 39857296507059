import AuthorizeApi from "../AuthorizeApi";
import {RadioCodeEntryResponse} from "../entity/response/RadioCodeEntryResponse";
import {OperationResult} from "../entity/OperationResult";


interface ReadPagedFilteredRequest {
  skip: number;
  count: number;
  searchKey: string;
  filterKey: string;
}

interface RadioCodeRequest {
  containerVehicle : string;
  radioCodeId : number;
}

interface ResultResponse {
  result : OperationResult
}
export default class ContainerRadioVehicleDailyApi  {

  private base: string = '/radio-entry';

  private authApi: AuthorizeApi = new AuthorizeApi();

  public async getContainerVehicleEntry(searchKey: string, filterKey: string, count: number, skip: number) {
    let response : RadioCodeEntryResponse;
    await this.authApi.post<ReadPagedFilteredRequest, RadioCodeEntryResponse>(`${this.base}/search`, {
      searchKey: searchKey,
      count: count,
      filterKey: filterKey,
      skip: skip
    })
      .then((w) => {
        response = w;
      })
    return response;
  }

  public async updateRadioIdForContainerVehicle(containerVehicle: string, radioCodeId: number) {
    return await this.authApi.post<RadioCodeRequest, ResultResponse>(`${this.base}`,
      {
        containerVehicle: containerVehicle,
        radioCodeId: radioCodeId
      });
  }
}
