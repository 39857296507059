import React, {Component} from 'react';
import "./TimeInputPicker.scss";
import GfDateUtils from "../DatePicker/GfDateUtils";

export interface Props {
  className: string;
  label: string;
  defaultValue: string;
  placeholder: string;
  isToTime: boolean;
  onBlur: (value: string) => void;
  onChange: (value: string, formatError: boolean) => void;
  onCorrectChange: (value: string) => void;
  onError: (value: string) => void;
  readonly: boolean,
  disabled: boolean,
  error: boolean,
  errorMessage?: string
  ignoreTimeOutOfRange:boolean
}

interface State {
  value: string,
  error: boolean
}

export default class TimeInputPicker extends Component<Props, State> {

  private static _defaultValue = "00:00";
  public static regexTime = "^[0-9]{2}:[0-9]{2}$"

  private readonly _separator = ":";
  /************************************************
   * PROPS
   ************************************************/
  static defaultProps: Partial<Props> = {
    className: "",
    label: "",
    isToTime: false,
    error: false,
    defaultValue: TimeInputPicker._defaultValue,
    placeholder: TimeInputPicker._defaultValue,
    onChange: () => {
    },
    onError: () => {
    },
    onCorrectChange: () => {
    },
    onBlur: () => {
    },
    readonly: false,
    disabled: false,
    errorMessage: "",
    ignoreTimeOutOfRange:false
  };


  /************************************************
   * CONSTRUCTOR
   ************************************************/
  protected constructor(props: Props) {
    super(props);
    this.state = {
      value: this.props.defaultValue,
      error: false,
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (prevProps.error != this.props.error) {
      this.setState({error: this.props.error});
    }
    if (prevProps.defaultValue != this.props.defaultValue){
      this.setState({value: this.props.defaultValue});
    }
  }

  handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    let value = event.target.value;
    this.setState({value: value})
  }

  private handleKeyUp(event: React.KeyboardEvent<HTMLInputElement>) {
    if (this.state.value.length == 2 && this.state.value.indexOf(this._separator) == -1) {
      this.setState({value: this.state.value + this._separator})
    }
  }

  private onFocus(event: React.FocusEvent<HTMLInputElement>) {
    if (this.state.error || this.state.value == TimeInputPicker._defaultValue) {
      this.setState({value: "", error: false})
    }
  }

  private handleBlur(event: React.FocusEvent<HTMLInputElement>) {
    if (this.state.value == "") {
      this.setState({value: TimeInputPicker._defaultValue});
      return;
    }

    let splitValue = this.state.value.split(this._separator);
    let value = this.state.value;

    let isError = this.props.ignoreTimeOutOfRange ? false: this.isError(value, splitValue);
    if (isError) {
      this.setState({"error": true});
      this.props.onError(value);
      return;
    } else {
      this.props.onCorrectChange(value);
    }
    this.setState({error: false});
    this.props.onBlur(this.state.value);
  }

  private isError(value: string, splitValue: string[]): boolean {
    return !new RegExp(TimeInputPicker.regexTime).test(value) ||
      GfDateUtils.isTimeOutOfRange(splitValue) ||
      this.isMidnightOfDayAfterOnStartTime(value);
  }

  private isMidnightOfDayAfterOnStartTime(value: string) {
    return !this.props.isToTime && value == "24:00";
  }

  /************************************************
   * LIFECYCLE
   ************************************************/
  render() {
    return <label className={`TextInput ` + (this.state.error ? "error" : "") + ` ${this.props.className}`}>
      {!!this.props.label && <span className="TextInput-label">{this.props.label}</span>}
      <input
        type="text"
        disabled={this.props.disabled}
        readOnly={this.props.readonly}
        value={this.state.value ? (this.props.isToTime && this.state.value == "00:00") ? "24:00" : this.state.value : ""}
        onChange={event => this.handleChange(event)}
        onKeyUp={(event) => this.handleKeyUp(event)}
        onBlur={(event) => this.handleBlur(event)}
        onFocus={(event) => this.onFocus(event)}
        placeholder={this.props.placeholder}
        className={this.state.error ? "TextInput-borderError" : ""}
      />
      {!!this.props.errorMessage && <span className="TextInput-error">{this.props.errorMessage}</span>}
    </label>
  }
}
