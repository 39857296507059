import Time from "../lib/utils/Time";
import {DateTime} from "luxon";

export interface TimeRange {
  start: number,
  end: number,
}

export default class Range {
  public static HOURS_PER_DAY = 24;
  /**************************************************
   * CONSTRUCTOR
   **************************************************/
  constructor(start: number, end: number);
  constructor(startString: string, endString: string);
  constructor(startAsNumberOrString: number | string, endAsNumberOrString: number | string) {
    this._start = this.numberOrStringToNumber(startAsNumberOrString);
    this._end =  this.numberOrStringToNumber(endAsNumberOrString);

    if(this._start > this._end){
      this._end = this._end + Range.HOURS_PER_DAY
    }

    /*if (typeof startLabel == "string") {
      this.startLabel = Range.removeSecondsFromLabel(startLabel)
    }
    if (typeof endLabel == "string") {
      this.endLabel = Range.removeSecondsFromLabel(endLabel)
    }*/
  }

  private roundUpToOneDecimal(value:number) {
    // we make sure visualization is not too ugly
    return  Math.round(value * 10) / 10
  }

  private numberOrStringToNumber(valueAsNumberOrString: number | string) {
    if (typeof valueAsNumberOrString == "string")
      return  Time.hourStringToNumber(valueAsNumberOrString)
    else if (typeof valueAsNumberOrString == "number")
      return  valueAsNumberOrString

    return 0;
  }

  /**************************************************
   * PROPERTIES
   **************************************************/
  get start(): number {
    return this._start;
  }

  get end(): number {
    return this._end;
  }

  get boundStart(): number {
    // We need this to ensure at least a 1h line is visible
    return this._start > (Range.HOURS_PER_DAY-1) ? (Range.HOURS_PER_DAY-1) : this._start;
  }

  get boundEnd(): number {
    // We need this to ensure the line doesn't go too much over the edge
    return this._end > Range.HOURS_PER_DAY ? Range.HOURS_PER_DAY : this._end;
  }

  get startString(): string {
    return Time.numberToHourString(this._start % Range.HOURS_PER_DAY);
  }

  get endString(): string {
    return Time.numberToHourString(this._end % Range.HOURS_PER_DAY);
  }

  /**************************************************
   * PUBLIC FUNCTIONS
   **************************************************/
  public contains(instant: number)
  public contains(range: Range)
  public contains(instantOrRange: number | Range): boolean {
    if (typeof instantOrRange == "number")
      return this._start <= instantOrRange && this._end >= instantOrRange;
    return this._start <= instantOrRange._start && this._end >= instantOrRange._end;
  }

  public isContainedBy(range: Range): boolean {
    return range.contains(this)
  }

  public overlaps(range: Range): boolean {
    return !!(this.contains(range._start) || this.contains(range._end) || this.contains(range) || this.isContainedBy(range));
  }

  public merge(range: Range): Range {
    const startMin = Math.min(this._start, range._start);
    const endMax = Math.max(this._end, range._end);
    return new Range(startMin, endMax/*, range.startLabel, range.endLabel*/)
  }

  public static mergeOverlappingRanges(ranges: Range[]): Range[] {
    let atLeastOneMerged;
    do {
      [atLeastOneMerged, ranges] = Range.doMergeRanges(ranges);
    }
    while (atLeastOneMerged)
    return ranges;
  }

  // public static getTimeRangeForDifferentDateRange(startNumber: number,
  //                                                 endNumber: number): TimeRange {
  //   const startTime = startNumber;
  //   const endTime = endNumber;
  //
  //   let start = startTime;
  //   let end = endTime;
  //
  //   console.log("Before:");
  //   console.log(startTime, endTime);
  //
  //   if (end < start) {
  //     end = this.endOfDayNumber;
  //   }
  //
  //   if (start > end) {
  //     start = this.newDayMidnightNumber;
  //   }
  //
  //   console.log("After:");
  //   console.log(start, end);
  //
  //   return {
  //     start: start,
  //     end: end
  //   }
  // }

  // public static getTimeRangeForShiftNumber(startNumber: number,
  //                                          endNumber: number,
  //                                          targetDate: DateTime,
  //                                          shiftDate: DateTime): TimeRange {
  //   let start = startNumber;
  //   let end = endNumber;
  //
  //   // // Manage previous day shift on this day
  //   // if (shiftDate < targetDate) {
  //   //   if (endNumber <= startNumber) {
  //   //     start = this.newDayMidnightNumber;
  //   //     end = endNumber;
  //   //   }
  //   // } else {
  //   //   end = endNumber < startNumber
  //   //     ? this.endOfDayNumber : endNumber;
  //   // }
  //   return {start: start, end: end};
  // }

  // public static getTimeRangeForShift(startTime: string,
  //                                    endTime: string,
  //                                    targetDate: DateTime,
  //                                    shiftDate: DateTime): TimeRange {
  //   const startNumber = Time.hourStringToNumber(startTime);
  //   const endNumber = Time.hourStringToNumber(endTime);
  //   return this.getTimeRangeForShiftNumber(startNumber, endNumber, targetDate, shiftDate);
  // }

  // public static removeSecondsFromLabel(timeString: string): string {
  //   if (timeString?.length == 8)
  //     return timeString.substr(0, 5)
  //   else
  //     return timeString
  // }


  private static doMergeRanges(serviceRanges: Range[]): [boolean, Range[]] {
    if (serviceRanges.length <= 1)
      return [false, serviceRanges]
    let range = serviceRanges.splice(0, 1)[0]
    const mergedRanges = [];
    let atLeastOneMerged = false;
    for (const remainingRange of serviceRanges) {
      if (range.overlaps(remainingRange)) {
        range = range.merge(remainingRange)
        atLeastOneMerged = true;
      } else {
        mergedRanges.push(remainingRange)
      }
    }
    mergedRanges.push(range)
    return [atLeastOneMerged, mergedRanges]
  }

  /**************************************************
   * VARIABLES
   **************************************************/
  private _start: number
  private _end: number
  public startLabel: string = null
  public endLabel: string = null
}
