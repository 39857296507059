import React from "react";
import Icon from "./Icon";

interface Props {
  className: string;
  width: number;
  height: number;
  color: string;
}

export default class IconToastError extends Icon<Props> {
  /************************************************
   * PROPS
   ************************************************/
  static defaultProps = {
    className: "",
    width: 20,
    height: 20,
    color: "#2E3A59"
  };

  /************************************************
   * LIFECYCLE
   ************************************************/
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={this.props.width} height={this.props.height} viewBox="0 0 24 24">
        <defs>
          <filter id="rojkfb3bha">
            <feColorMatrix in="SourceGraphic"
                           values="0 0 0 0 0.874995 0 0 0 0 0.344595 0 0 0 0 0.344595 0 0 0 1.000000 0"/>
          </filter>
          <path id="b4sffpi93b" d="M0 0H24V24H0z"/>
        </defs>
        <g fill="none" fillRule="evenodd">
          <g>
            <g filter="url(#rojkfb3bha)" transform="translate(-471 -42) translate(450 29)">
              <g transform="translate(21 13)">
                <mask id="k1tipiuz0c" fill="#fff">
                  <use xlinkHref="#b4sffpi93b"/>
                </mask>
                <path fill={this.props.color}
                      d="M2.734 21.002c-.77 0-1.251-.834-.865-1.501l9.266-16.004c.385-.665 1.345-.665 1.73 0L22.131 19.5c.386.667-.095 1.501-.865 1.501H2.734c-.513 0-.513 0 0 0zM13 18h-.935l-.065.002c-.022 0-.043 0-.065-.002H11v-2h2v2zm0-3.998h-2v-5h2v5z"
                      mask="url(#k1tipiuz0c)"/>
              </g>
            </g>
          </g>
        </g>
      </svg>

    );
  }
}
