import React, {BaseSyntheticEvent, Component} from "react";
import "./SettingModal.scss";
import I18n from "../../lib/I18n";
import Button from "../../components/Button/Button";
import TextInput from "../../components/TextInput/TextInput";
import {OperationResult} from "../../api/entity/OperationResult";
import {EngineConfigDto} from "../../api/entity/EngineConfigDto";
import EngineConfigValidator from "../../api/worker/validator/EngineConfigValidator";
import EngineConfigApi from "../../api/worker/EngineConfigApi";
import Toast from "../../components/Toast/Toast";
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import {StatusEnum} from "../../api/engine/EngineApi";
import InfoBar from "../../components/InfoBar/InfoBar";
import withStatusPolling, {WithStatusPollingProps} from "../../utils/WithStatusPolling";
import Select from "../../components/Select/Select";
import ShiftRegistryApi from "../../api/shift/ShiftRegistryApi";
import {ShiftDto} from "../../api/entity/ShiftDto";
import Spinner from "../../components/Spinner/Spinner";
import TimeInputPicker from "../../components/TimeInputPicker/TimeInputPicker";
import {DateTime, LocalZone} from "luxon";
import GfDateUtils from "../../components/DatePicker/GfDateUtils";

interface Props extends WithStatusPollingProps {
}

interface State {
  engineConfigDtos: EngineConfigDto[],
  shifts:ShiftDto[],
  errors: EngineConfErrors
  toast: {
    isError: boolean;
    errorList: string[];
    message: string;
  }
  isToastOpen: boolean
  loading:boolean
}

export interface EngineConfError {
  dayConstraintPerMonth: string;
  maxNumberOfNightsPerWeek: string;
  intervalsTurn: string;
  intervalsWeekend: string;
  maxNumberPerWeekLength: string;
  maxNumberOfMorningPerMonth: string;
  maxNumberOfAfternoonPerMonth: string;
  maxNumberOfLongPerMonth: string;
  intervalLongTurns: string;
  maximumAvailabilityPerMonth: string;
  otherTurns: string;
  availabilityFirst: string;
  tutorTimeLength: string;
  noTurnsPerDay: string;
  cleaningInterval: string;
  numMezziLav1: string;
  numMezziLav2: string;
}

export interface EngineConfErrors {
  engineConfErrors: EngineConfError[]
}

class SettingModal extends Component<Props, State> {

  private engineConfigApi = new EngineConfigApi();

  /************************************************
   * CONSTRUCTOR
   ************************************************/
  constructor(props) {
    super(props);
    this.state = {
      errors: {
        engineConfErrors: [],
      },
      engineConfigDtos: [],
      shifts:[],
      toast: {
        message: "",
        errorList: [],
        isError: false
      },
      isToastOpen: false,
      loading:true
    }
  }

  async componentDidMount() {
    try {
      const responses = await Promise.all([
        this.engineConfigApi.get(),
        new ShiftRegistryApi().getAll()
      ]);
      let engineConfigDtos = responses[0].content.map(a=> {
        // const intervalTurnHHMM = DateTime.fromFormat(a.intervalsTurn, GfDateUtils.HH_MM_SS).toFormat(GfDateUtils.HH_MM);
        // const intervalWeHHMM = DateTime.fromFormat(a.intervalsWeekend, GfDateUtils.HH_MM_SS).toFormat(GfDateUtils.HH_MM);
        // a.intervalsWeekend = intervalWeHHMM;
        // a.intervalsTurn = intervalTurnHHMM;
        return a;
      })
      let shifts = responses[1].content
      this.setState({
        loading:false,
        engineConfigDtos,
        shifts,
        errors: {
          engineConfErrors: engineConfigDtos.map(_ => EngineConfigValidator.getEmptyError())
        }
      });
    } catch {
      this.setState({loading: false})
    }
  }

  private async onSubmit(event: BaseSyntheticEvent) {
    event.preventDefault();
    let errors: EngineConfErrors = {engineConfErrors: this.state.engineConfigDtos.map(ec => EngineConfigValidator.validate(ec))};
    let isAnyError = !EngineConfigValidator.isAnyErrorPresent(errors);
    this.setState({errors: errors});

    if (isAnyError) {
      return;
    }

    let needResponsePromise = await this.engineConfigApi.modify({engineConfigsDto: this.state.engineConfigDtos});
    if (needResponsePromise.status == OperationResult.OK) {
      this.setState({toast: {...this.state.toast, message: I18n.get().Items.savedMessage}, isToastOpen: true});
    }
  }


  private closeToast() {
    this.setState({isToastOpen: false});
  }

  /************************************************
   * LIFECYCLE
   ************************************************/
  render() {
    if(this.state.loading){
      return <Spinner />
    }
    let cannotEditSettings = this.props?.nextMonthPlanning?.status == StatusEnum.CONVERGENCE_CHECK_COMPLETED;
    return (
      <div>
        <Toast isError={this.state.toast.isError}
               message={this.state.toast.message}
               errors={this.state.toast.errorList}
               visible={this.state.isToastOpen}
               trigger={() => this.closeToast()}/>
        <Tabs>
          <div>
            <div className="SettingModal inner-content">
              <div >
                <div className={"up-title"}>
                  <h1>{I18n.get().Items.title}</h1>
                </div>
                <div className={"up-controls"}>
                  <Button onClick={(event) => this.onSubmit(event)}
                          className={"add-btn"} disabled={cannotEditSettings}>
                    {I18n.get().NeedManagement.addNewNeedManagement.actionButton}
                  </Button>
                </div>
              </div>

              {/* WORKER CONSTRAINTS */}
              <div className={"form-content"}>
                <TabList>
                  {this.state.engineConfigDtos.map(ec => <Tab>{ec.domain}</Tab>)}
                </TabList>
                {cannotEditSettings ?
                  <InfoBar type="alert" text={I18n.get().Items.cannotEditConstraints} />: null
                }
                {this.state.engineConfigDtos.map((engineConfigDto, index) =>
                  <TabPanel>
                    <form onSubmit={(event) => this.onSubmit(event)}>
                      <div className={"form"} key={`${this.state.engineConfigDtos.length}_${index}`}>
                        <h2>{I18n.get().Items.workerConstraint.title}</h2>
                        <div className={"top-row"}>
                          <div className={"control"}>
                            <TextInput
                              defaultValue={engineConfigDto.dayConstraintPerMonth.toString()}
                              onChange={(code) => {
                                let {engineConfigDtos} = this.state;
                                let {errors} = this.state;
                                engineConfigDtos[index].dayConstraintPerMonth = parseInt(code);
                                errors.engineConfErrors[index].dayConstraintPerMonth = "";
                                this.setState({engineConfigDtos, errors: errors});
                              }}
                              onKeyPressValidation={(e) => TextInput.preventOtherKeysButNumber(e)}
                              isNumeric={true}
                              errorMessage={this.state.errors.engineConfErrors[index].dayConstraintPerMonth}
                              internalLabel={I18n.get().Items.timeUnits.days}
                              label={I18n.get().Items.workerConstraint.maxNightsMonth} className={"row-w"}/>
                          </div>
                          <div className={"control"}>
                            <TextInput
                              defaultValue={engineConfigDto.maxNumberOfNightsPerWeek.toString()}
                              onChange={(code) => {
                                let {engineConfigDtos} = this.state;
                                let {errors} = this.state;
                                engineConfigDtos[index].maxNumberOfNightsPerWeek = parseInt(code);
                                errors.engineConfErrors[index].maxNumberOfNightsPerWeek = "";
                                this.setState({engineConfigDtos, errors: errors});
                              }}
                              onKeyPressValidation={(e) => TextInput.preventOtherKeysButNumber(e)}
                              isNumeric={true}
                              internalLabel={I18n.get().Items.timeUnits.days}
                              errorMessage={this.state.errors.engineConfErrors[index].maxNumberOfNightsPerWeek}
                              label={I18n.get().Items.workerConstraint.maxNightsWeek} className={"row-w"}/>
                          </div>
                          <div className={"control"}>
                            <TimeInputPicker
                              defaultValue={engineConfigDto.intervalsTurn.toString()}
                              ignoreTimeOutOfRange
                              onCorrectChange={(code) => {
                                let {engineConfigDtos} = this.state;
                                let {errors} = this.state;
                                engineConfigDtos[index].intervalsTurn = code;
                                errors.engineConfErrors[index].intervalsTurn = "";
                                this.setState({engineConfigDtos, errors: errors});
                              }}
                              onError={(value)=>{
                                let {errors, engineConfigDtos} = this.state;
                                engineConfigDtos[index].intervalsTurn = value;
                                errors.engineConfErrors[index].intervalsTurn = I18n.get().Items.errors.valueError;
                                this.setState({errors, engineConfigDtos});
                              }}
                              errorMessage={this.state.errors.engineConfErrors[index].intervalsTurn}
                              label={I18n.get().Items.workerConstraint.intervalTurns} className={"row-w"}/>
                          </div>

                          <div className={"control"}>
                            <TimeInputPicker
                              defaultValue={engineConfigDto.intervalsWeekend.toString()}
                              ignoreTimeOutOfRange
                              onCorrectChange={(code) => {
                                let {engineConfigDtos} = this.state;
                                let {errors} = this.state;
                                engineConfigDtos[index].intervalsWeekend = code;
                                errors.engineConfErrors[index].intervalsWeekend = "";
                                this.setState({engineConfigDtos, errors: errors});
                              }}
                              onError={(value)=>{
                                let {errors, engineConfigDtos} = this.state;
                                engineConfigDtos[index].intervalsWeekend = value;
                                errors.engineConfErrors[index].intervalsWeekend = I18n.get().Items.errors.valueError;
                                this.setState({errors, engineConfigDtos});
                              }}
                              errorMessage={this.state.errors.engineConfErrors[index].intervalsWeekend}
                              label={I18n.get().Items.workerConstraint.intervalTurnsWeekend} className={"row-w"}/>
                          </div>
                        </div>

                        {/*  Second row */}
                        <div className={"top-row"}>
                          <div className={"control"}>
                            <TextInput
                              defaultValue={engineConfigDto.maxNumberPerWeekLength.toString()}
                              onChange={(code) => {
                                let {engineConfigDtos} = this.state;
                                let {errors} = this.state;
                                engineConfigDtos[index].maxNumberPerWeekLength = parseInt(code);
                                errors.engineConfErrors[index].maxNumberPerWeekLength = "";
                                this.setState({engineConfigDtos, errors: errors});
                              }}
                              onKeyPressValidation={(e) => TextInput.preventOtherKeysButNumber(e)}
                              isNumeric={true}
                              errorMessage={this.state.errors.engineConfErrors[index].maxNumberPerWeekLength}
                              label={I18n.get().Items.workerConstraint.maxNumberLongTurns} className={"row-w"}/>
                          </div>
                          <div className={"control"}>
                          <TextInput
                              defaultValue={engineConfigDto.maxNumberOfLongPerMonth.toString()  }
                              onChange={(code) => {
                                let {engineConfigDtos} = this.state;
                                let {errors} = this.state;
                                engineConfigDtos[index].maxNumberOfLongPerMonth = parseInt(code);
                                errors.engineConfErrors[index].maxNumberOfLongPerMonth = "";
                                this.setState({engineConfigDtos, errors: errors});
                              }}
                              onKeyPressValidation={(e) => TextInput.preventOtherKeysButNumber(e)}
                              isNumeric={true}
                              errorMessage={this.state.errors.engineConfErrors[index].maxNumberOfLongPerMonth}
                              label={I18n.get().Items.workerConstraint.maxLongMonth} className={"row-w"}/>
                          </div>

                          {/* interval longs */}
                          <div className={"control"}>
                            <TextInput
                              defaultValue={engineConfigDto.intervalLongTurns.toString()}
                              onChange={(code) => {
                                let {engineConfigDtos} = this.state;
                                let {errors} = this.state;
                                engineConfigDtos[index].intervalLongTurns = parseInt(code);
                                errors.engineConfErrors[index].intervalLongTurns = "";
                                this.setState({engineConfigDtos, errors: errors});
                              }}
                              onKeyPressValidation={(e) => TextInput.preventOtherKeysButNumber(e)}
                              isNumeric={true}
                              internalLabel={I18n.get().Items.timeUnits.days}
                              errorMessage={this.state.errors.engineConfErrors[index].intervalLongTurns}
                              label={I18n.get().Items.workerConstraint.intervalLongTurns} className={"row-w"}/>
                          </div>
                          <div className={"control"}>
                            <TextInput
                              defaultValue={engineConfigDto.maximumAvailabilityPerMonth.toString()}
                              onChange={(code) => {
                                let {engineConfigDtos} = this.state;
                                let {errors} = this.state;
                                engineConfigDtos[index].maximumAvailabilityPerMonth = parseInt(code);
                                errors.engineConfErrors[index].maximumAvailabilityPerMonth = "";
                                this.setState({engineConfigDtos, errors: errors});
                              }}
                              onKeyPressValidation={(e) => TextInput.preventOtherKeysButNumber(e)}
                              isNumeric={true}
                              internalLabel={I18n.get().Items.timeUnits.days}
                              errorMessage={this.state.errors.engineConfErrors[index].maximumAvailabilityPerMonth}
                              label={I18n.get().Items.workerConstraint.maxNumber} className={"row-w"}/>
                          </div>

                          {/* morning and afternoon per month */}

                          <div className={"control"}>
                            <TextInput
                              defaultValue={engineConfigDto.maxNumberOfMorningPerMonth.toString()}
                              onChange={(code) => {
                                let {engineConfigDtos} = this.state;
                                let {errors} = this.state;
                                engineConfigDtos[index].maxNumberOfMorningPerMonth = parseInt(code);
                                errors.engineConfErrors[index].maxNumberOfMorningPerMonth = "";
                                this.setState({engineConfigDtos, errors: errors});
                              }}
                              onKeyPressValidation={(e) => TextInput.preventOtherKeysButNumber(e)}
                              isNumeric={true}
                              errorMessage={this.state.errors.engineConfErrors[index].maxNumberOfMorningPerMonth}
                              label={I18n.get().Items.workerConstraint.maxMorningMonth} className={"row-w"}/>
                          </div>

                          <div className={"control"}>
                            <TextInput
                              defaultValue={engineConfigDto.maxNumberOfAfternoonPerMonth.toString()}
                              onChange={(code) => {
                                let {engineConfigDtos} = this.state;
                                let {errors} = this.state;
                                engineConfigDtos[index].maxNumberOfAfternoonPerMonth = parseInt(code);
                                errors.engineConfErrors[index].maxNumberOfAfternoonPerMonth = "";
                                this.setState({engineConfigDtos, errors: errors});
                              }}
                              onKeyPressValidation={(e) => TextInput.preventOtherKeysButNumber(e)}
                              isNumeric={true}
                              errorMessage={this.state.errors.engineConfErrors[index].maxNumberOfAfternoonPerMonth}
                              label={I18n.get().Items.workerConstraint.maxAfternoonMonth} className={"row-w"}/>
                            </div>
                            {/* OTHER TURNS */}

                            <div className={"control other-turns"}>
                            <Select
                                    label={I18n.get().Items.workerConstraint.otherTurns} className={"select-turn"}
                                    items={this.state.shifts.map(s => s.shiftCode)}
                                    defaultValue={this.getIndexFromShift(engineConfigDto.otherTurns)}
                                    onChange={(i) => {
                                        const code = this.getShiftFromIndex(i)
                                        let {engineConfigDtos, errors} = this.state;
                                        engineConfigDtos[index].otherTurns = code;
                                        errors.engineConfErrors[index].otherTurns = "";
                                        this.setState({engineConfigDtos, errors: errors});
                                    }}/>
                        </div>
                        </div>
                        {/* NEW WORKER */}
                        <h2>{I18n.get().Items.newWorkerConstraint.title}</h2>
                        <div className={"top-row"}>
                          <div className={"control"}>
                            <TextInput
                              defaultValue={engineConfigDto.availabilityFirst.toString()}
                              onChange={(code) => {
                                let {engineConfigDtos} = this.state;
                                let {errors} = this.state;
                                engineConfigDtos[index].availabilityFirst = parseInt(code);
                                errors.engineConfErrors[index].availabilityFirst = "";
                                this.setState({engineConfigDtos, errors: errors});
                              }}
                              onKeyPressValidation={(e) => TextInput.preventOtherKeysButNumber(e)}
                              isNumeric={true}
                              internalLabel={I18n.get().Items.timeUnits.days}
                              errorMessage={this.state.errors.engineConfErrors[index].availabilityFirst}
                              label={I18n.get().Items.newWorkerConstraint.noAvailabilityFirstDays} className={"row-w"}/>
                          </div>
                          <div className={"control"}>
                            <TextInput
                              defaultValue={engineConfigDto.tutorTimeLength.toString()}
                              onChange={(code) => {
                                let {engineConfigDtos} = this.state;
                                let {errors} = this.state;
                                engineConfigDtos[index].tutorTimeLength = parseInt(code);
                                errors.engineConfErrors[index].tutorTimeLength = "";
                                this.setState({engineConfigDtos, errors: errors});
                              }}
                              onKeyPressValidation={(e) => TextInput.preventOtherKeysButNumber(e)}
                              isNumeric={true}
                              internalLabel={I18n.get().Items.timeUnits.days}
                              errorMessage={this.state.errors.engineConfErrors[index].tutorTimeLength}
                              label={I18n.get().Items.newWorkerConstraint.tutorTime} className={"row-w"}/>
                          </div>
                          <div className={"control"}>
                            <TextInput
                              defaultValue={engineConfigDto.noTurnsPerDay.toString()}
                              onChange={(code) => {
                                let {engineConfigDtos} = this.state;
                                let {errors} = this.state;
                                engineConfigDtos[index].noTurnsPerDay = parseInt(code);
                                errors.engineConfErrors[index].noTurnsPerDay = "";
                                this.setState({engineConfigDtos, errors: errors});
                              }}
                              onKeyPressValidation={(e) => TextInput.preventOtherKeysButNumber(e)}
                              isNumeric={true}
                              internalLabel={I18n.get().Items.timeUnits.days}
                              errorMessage={this.state.errors.engineConfErrors[index].noTurnsPerDay}
                              label={I18n.get().Items.newWorkerConstraint.noTurnsMoss} className={"row-w"}/>
                          </div>
                          <div className={"control"}/>
                        </div>

                        {/* VEHICLE CONSTRAINTS */}
                        <h2>{I18n.get().Items.vehicleConstraint.title}</h2>
                        <div className={"top-row"}>
                          <div className={"control"}>
                            <TextInput
                              defaultValue={engineConfigDto.cleaningInterval.toString()}
                              onChange={(code) => {
                                let {engineConfigDtos} = this.state;
                                let {errors} = this.state;
                                engineConfigDtos[index].cleaningInterval = parseInt(code);
                                errors.engineConfErrors[index].cleaningInterval = "";
                                this.setState({engineConfigDtos, errors: errors});
                              }}
                              onKeyPressValidation={(e) => TextInput.preventOtherKeysButNumber(e)}
                              isNumeric={true}
                              internalLabel={I18n.get().Items.timeUnits.days}
                              errorMessage={this.state.errors.engineConfErrors[index].cleaningInterval}
                              label={I18n.get().Items.vehicleConstraint.washingFrequency} className={"row-w"}/>
                          </div>
                          <div className={"control"}>
                            <TextInput
                              defaultValue={engineConfigDto.numMezziLav1.toString()}
                              onChange={(code) => {
                                let {engineConfigDtos} = this.state;
                                let {errors} = this.state;
                                engineConfigDtos[index].numMezziLav1 = parseInt(code);
                                errors.engineConfErrors[index].numMezziLav1 = "";
                                this.setState({engineConfigDtos, errors: errors});
                              }}
                              onKeyPressValidation={(e) => TextInput.preventOtherKeysButNumber(e)}
                              isNumeric={true}
                              errorMessage={this.state.errors.engineConfErrors[index].numMezziLav1}
                              label={I18n.get().Items.vehicleConstraint.cleaningVehiclesNumberLav1} className={"row-w"}/>
                          </div>
                          <div className={"control"}>
                            <TextInput
                              defaultValue={engineConfigDto.numMezziLav2.toString()}
                              onChange={(code) => {
                                let {engineConfigDtos} = this.state;
                                let {errors} = this.state;
                                engineConfigDtos[index].numMezziLav2 = parseInt(code);
                                errors.engineConfErrors[index].numMezziLav2 = "";
                                this.setState({engineConfigDtos, errors: errors});
                              }}
                              onKeyPressValidation={(e) => TextInput.preventOtherKeysButNumber(e)}
                              isNumeric={true}
                              errorMessage={this.state.errors.engineConfErrors[index].numMezziLav2}
                              label={I18n.get().Items.vehicleConstraint.cleaningVehiclesNumberLav2} className={"row-w"}/>
                          </div>
                          </div>
                      </div>
                    </form>
                  </TabPanel>
                )}
              </div>
            </div>
          </div>
        </Tabs>
      </div>);
  }

  private getIndexFromShift(code: string):number {
    const index = this.state.shifts.map(s => s.shiftCode).indexOf(code)
    return index != -1 ? index : 0;
  }

  private getShiftFromIndex(index:number):string {
    return this.state.shifts[index] ? this.state.shifts[index].shiftCode : this.state.shifts[0].shiftCode
  }
}
export const SettingModalWithStatusPolling = withStatusPolling(SettingModal);
export default SettingModalWithStatusPolling
