import {NeedHumanShiftDtoNt} from "./nt/NeedHumanShiftDtoNt";

export class NeedHumanShiftDto {
  id: number;
  role: string;
  moss: boolean;
  enablement: string;
  turnCode: string;
  turnId: number;
  timeSpan: string;
  numberVehiclesWorkdays: string[];
  numberVehiclesFestive: string[];
  needId: number;

  constructor(dtoNt: NeedHumanShiftDtoNt) {
    this.id = dtoNt.id;
    this.role = dtoNt.role;
    this.moss = dtoNt.moss;
    this.enablement = dtoNt.enablement;
    this.turnCode = dtoNt.turnCode;
    this.turnId = dtoNt.turnId;
    this.timeSpan = dtoNt.timeSpan;
    this.numberVehiclesFestive = dtoNt.numberVehiclesFestive.map(a => a.toString());
    this.numberVehiclesWorkdays = dtoNt.numberVehiclesWorkdays.map(a => a.toString());
    this.needId = dtoNt.needId;
  }
}
