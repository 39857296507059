import React, {PureComponent} from "react";
import "./LineHorizontal.scss"

export interface Props {
  className: string;
}

class LineHorizontal extends PureComponent<Props> {
    static defaultProps =  {
        className: ""
    };

    render() {
        return (
            <div className={"LineHorizontal " + this.props.className}>
            </div>
        )
    }
}

export default LineHorizontal;
