import React, {ReactNode} from "react";
import "./TooltipWrapper.scss";

export interface Props {
  className?: string;
  placement?: string;
  visible?: boolean;
  showOnHover?: boolean;
  content: string | ReactNode | Element;
  onClick?: () => any;
  children: any;
  style?: any;
}

const defaultProps: Partial<Props> = {
  className: "",
  placement: "Top",
  visible: true,
  showOnHover: true,
  content: "",
  style: {},
  onClick: () => undefined
};

const TooltipWrapper = (props: Props) =>
  <div className={`TooltipWrapper ${props.className} ${props.showOnHover ? "ShowOnHover" : ""}`}
       onClick={props.onClick} style={props.style}>
    {props.visible && props.content && <div className={`TooltipContainer ${props.placement}`}>{props.content}</div>}
    {props.children}
  </div>;

TooltipWrapper.defaultProps = defaultProps;

export default TooltipWrapper;