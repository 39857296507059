import AuthorizeApi from "../AuthorizeApi";
import {WorkerResponse} from "../entity/response/WorkerResponse";
import {WorkerDto} from "../entity/WorkerDto";
import {WorkerResponseNt} from "../entity/response/nt/WorkerResponseNt";
import {WorkerContractResponse} from "../entity/response/WorkerContractResponse";
import {WorkerContractResponseNt} from "../entity/response/nt/WorkerContractResponseNt";
import {ReadRequest} from "../entity/request/ReadRequest";
import {DateTime} from "luxon";
import {ExecutionResponse} from "../entity/response/ExecutionResponse";

export default class WorkerRegistryApi {

  private base: string = '/worker';

  private authApi: AuthorizeApi = new AuthorizeApi();

  public async syncWorkers() {
    return await (this.authApi.post<void, ExecutionResponse>( this.base + "/sync", null));
  }

  public async getWorkersSync() {
    return await (this.authApi.get<ExecutionResponse>(this.base + "/sync"));
  }

  public async create(workerDto: WorkerDto) {
    return await this.authApi.put<WorkerDto, WorkerResponse>(this.base, workerDto);
  }

  public async modify(workerDto: WorkerDto) {
    let workerResponse: WorkerResponse;
    await this.authApi.post<WorkerDto, WorkerResponseNt>(this.base + "/update", workerDto)
      .then((w) => {
        workerResponse = WorkerRegistryApi.mapToWorkerResponse(w)
      });
    return workerResponse;
  }

  public async getDetails(id: number) {
    let workerResponse: WorkerResponse;
    await this.authApi.get<WorkerResponseNt>(`${this.base}/${id}`).then((w) => {
      workerResponse = WorkerRegistryApi.mapToWorkerResponse(w)
    });
    return workerResponse;
  }

  public async getAll(search: string = null) {
    const query =  search ? `?search=${search}` : ""
    return WorkerRegistryApi.mapToWorkerResponse(
      await this.authApi.get <WorkerResponseNt>(`${this.base}/all${query}`))
  }

  public async getAllActiveInDay(date: DateTime, search: string = null) {
    return this.getAllActiveInRange(date, date, search)
  }

  public async getAllActiveInMonth(date: DateTime, search: string = null) {
    return this.getAllActiveInRange(date, date.endOf("month"),search)
  }

  public async getAllActiveInRange(start: DateTime, end: DateTime, search: string = null) {
    const query =  search ? `?search=${search}` : ""
    const s = start.toFormat("yyyy-MM-dd");
    let e = end.toFormat("yyyy-MM-dd");
    return WorkerRegistryApi.mapToWorkerResponse(
      await this.authApi.get <WorkerResponseNt>(`${this.base}/all/${s}/${e}/active${query}`))
  }

  public async getOrSearch(key: string, count: number, skip: number) {
    let workerResponse: WorkerResponse;
    await this.authApi.post <ReadRequest, WorkerResponseNt>(`${this.base}`, {skip: skip, count: count, searchKey: key})
      .then((w) => {
        workerResponse = WorkerRegistryApi.mapToWorkerResponse(w)
      });
    return workerResponse;
  }

  public async getAvailableTutors() {
    let workerResponse: WorkerResponse;
    await this.authApi.get<WorkerResponseNt>(`${this.base}/available-tutor`)
        .then((w) => {
        workerResponse = WorkerRegistryApi.mapToWorkerResponse(w)
      });
    return workerResponse;
  }

  private static mapToWorkerResponse(workerResponseNt: WorkerResponseNt) {
    return new WorkerResponse(workerResponseNt);
  }

  public async getContracts(id: number) {
    let workerContractResponse: WorkerContractResponse;
    await this.authApi.get<WorkerContractResponseNt>(`${this.base}/${id}/contract`).then((w) => {
      workerContractResponse = new WorkerContractResponse(w);
    });
    return workerContractResponse;
  }
}
