import {DateTime} from "luxon";
import {MonthlyUnavailabilityRowEntryNt} from "./nt/MonthlyUnavailabilityRowEntryNt";

export class MonthlyUnavailabilityRowEntry {
  id: number;
  unavailabilityid: number;
  date: DateTime;
  fromtime: string;
  totime: string;

  constructor(med: MonthlyUnavailabilityRowEntryNt) {
    this.id = med.id;
    this.unavailabilityid = med.unavailabilityid;
    this.date = DateTime.fromISO(med.initialdate);
    this.fromtime = med.fromtime;
    this.totime = med.totime;
  }
}
