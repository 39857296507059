import React, {ReactNode} from "react";
import TableCell from "./TableCell";
import {Field, Header, ValueOf} from "./Table";
import {PartialRecord} from "../../lib/TypeUtils";

interface Props<T> {
  headers: Header<T>[]
  item: T
  onClick: (i) => void
  itemsRender?: PartialRecord<Field<T>, (value:ValueOf<T>,row:T) => ReactNode>
}

export default function TableRow<T>(props: Props<T>) {
  const {headers, item, itemsRender} = props;

  return <tr onClick={() => props.onClick(item)}>
    {headers.map((header, i) =>
      <TableCell key={i} visibility={header.visibility}
                 value={item[header.field]} row={item}
                 itemRender={itemsRender ? itemsRender[header.field] : undefined}/>)}
  </tr>
}
