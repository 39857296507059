import TimeLine from "../../../components/TimeLine/TimeLine";
import React, {Component, ReactNode} from "react";
import {DailyPlanningWorkerDtoNt} from "../../../api/entity/response/nt/DailyPlanningWorkerResponseNt";
import {WorkerDto} from "../../../api/entity/WorkerDto";
import {DateTime} from "luxon";
import Time from "../../../lib/utils/Time";
import DailyWorkerHeader from "./DailyWorkerHeader/DailyWorkerHeader";
import DailyEmployeeLine from "../../Dashboard/DailyPlanning/DailyEmployeeLine/DailyEmployeeLine";
import TimeLineData from "../../../components/TimeLine/TimeLineData";
import NeedUtils from "../../../api/need/NeedUtils";
import DailyEmployeeCell from "../../Dashboard/DailyPlanning/DailyEmployeeCell/DailyEmployeeCell";
import GfDateUtils from "../../../components/DatePicker/GfDateUtils";
import Range from "../../../utils/Range";
import {StringUtils} from "../../../utils/StringUtils";
import TimeLineMatrix = TimeLineData.TimeLineMatrix;
import Interval = TimeLineData.Interval;
import {MonthlyDataWithStatus, MonthlyEmployee} from "../../Dashboard/MonthlyPlanning/MonthlyDTO";
import {CircuitSelect} from "../../../components/CircuitSelect/CircuitSelect";

interface Props {
  day: DateTime,
  dailyWorkers: DailyPlanningWorkerDtoNt[]
  allWorkers: WorkerDto[]
  activeWorkers: WorkerDto[]
  onWorkerSelection: (worker: Worker) => void;
  onWorkerDelete: (worker: Worker) => void;
  filter: string;
  circuitFilter: string,
  radioMap: Map<number, string>
}
interface State {
  filteredTimeLineMatrix: TimeLineMatrix[]
}
export type Worker = {
  fullName: string,
  id: number,
  role: string,
  manuallyCreated: boolean,
  skill: string
};
export type Shift = {
  id: number,
  date: DateTime,
  shift: string,
  service: string,
  shiftStartTime: string,
  shiftEndTime: string,
  startTime: string,
  endTime: string,
  radioCodeName: string,
  containerVehicle:string,
  isPreviousDay: boolean
}
export type WorkerToShift = {
  workerId: number
  workerName: string
  workerSurname: string
  shifts: Shift[],
  showDelete: boolean,
}

export interface TimeLineMatrixConstr {
  timeline: TimeLineMatrix & AdditionalTimelineInfo,
  service: string
}

interface AdditionalTimelineInfo {
  service: string
  fullName: string
  role: string
  skill: string
}

export default class DailyWorkersTimeLine extends Component<Props, State> {
  /**************************************************
   * CONSTRUCTOR
   **************************************************/
  constructor(props) {
    super(props);
    this.state = {
      filteredTimeLineMatrix: []
    }
  }

  componentDidMount() {
    this.loadDailyTimeLines();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (prevProps.filter != this.props.filter ||
      prevProps.allWorkers != this.props.allWorkers ||
      prevProps.dailyWorkers != this.props.dailyWorkers ||
      prevProps.circuitFilter != this.props.circuitFilter) {
      this.loadDailyTimeLines();
    }
  }

  /**************************************************
   * LIFECYCLE
   **************************************************/
  render() {
    return <TimeLine
      headers={this.dailyWorkersHeaders(this.props.day)}
      timeLines={this.state.filteredTimeLineMatrix}
    />
  }

  /**************************************************
   * PRIVATE FUNCTIONS
   **************************************************/
  private dailyWorkersHeaders(currentDate: DateTime): ReactNode[] {
    const headers: ReactNode[] = []
    const hours = Time.getHoursInDay()
    for (const hour of hours)
      headers.push(<DailyWorkerHeader day={currentDate.toJSDate()} hour={hour}/>)
    return headers
  }

  private loadDailyTimeLines(): void {
    const timeLines: (TimeLineMatrix & AdditionalTimelineInfo)[] = [];
    const filter = this.props.filter.toLowerCase();
    let filterMap: WorkerToShift[] = [];

    // Add active workersForProcessing
    const circuitFilter = this.props.circuitFilter;
    this.props.activeWorkers.filter(this.filterByCircuit(circuitFilter)).forEach(
      aw => {
        const dailyPlanningWorkerDtoNts = this.props.dailyWorkers.filter(d => d.workerid == aw.id);
        filterMap.push({
          workerId: aw.id,
          workerName: aw.name,
          workerSurname: aw.lastname,
          shifts: dailyPlanningWorkerDtoNts.map(dp => {
            return {
              id: dp.id,
              shift: dp.turn, startTime: dp.servicestart,
              date: DateTime.fromFormat(dp.date, GfDateUtils.STORED_DATE_FORMAT),
              endTime: dp.serviceend, service: dp.service,
              shiftStartTime: dp.shiftstart, shiftEndTime: dp.shiftend,
              isPreviousDay: dp.previousDay ? dp.previousDay : false,
              radioCodeName: dp.radiocodeid != 0 ? this.props.radioMap.get(dp.radiocodeid) : "",
              containerVehicle: dp.containervehicle
            }
          }),
          showDelete: false
        });
      }
    );
    // Made up workersForProcessing
    this.props.dailyWorkers
      .filter(_=> circuitFilter == CircuitSelect.NO_FILTER)
      .filter(dw => dw.workerid == null && dw.manuallyCreated)
      .forEach(createWorker => {
        const foundMatchingWorkers = this.props.dailyWorkers.filter(w => w.workername == createWorker.workername && w.manuallyCreated);
        const shiftList: Shift[] =
          foundMatchingWorkers.map(dp => {
            return {
              id: dp.id,
              date: DateTime.fromFormat(dp.date, GfDateUtils.STORED_DATE_FORMAT),
              shift: dp.turn, startTime: dp.servicestart,
              endTime: dp.serviceend, service: dp.service,
              shiftStartTime: dp.shiftstart, shiftEndTime: dp.shiftend,
              isPreviousDay: dp.previousDay ? dp.previousDay : false,
              radioCodeName: dp.radiocodeid != 0 ? this.props.radioMap.get(dp.radiocodeid) : "",
              containerVehicle:dp.containervehicle
            }
          });
        const nameSurname = createWorker.workername?.split(" ");
        filterMap.push({
          workerId: null,
          workerName: nameSurname?.length > 0 ? nameSurname[0] : "",
          workerSurname: nameSurname?.length > 1 ? nameSurname[1] : "",
          shifts: shiftList,
          showDelete: true
        })
      });

    let filteredMap = filterMap.filter(fb =>
      StringUtils.contains(fb.workerSurname.toLowerCase(), filter) ||
      StringUtils.contains(fb.workerName.toLowerCase(), filter) ||
      this.isFoundInShifts(filter, fb.shifts))

    for (const worker of filteredMap) {
      const timelineIntervals: Interval[] = worker.shifts.map(shift => {

        const range = new Range(shift.shiftStartTime, shift.shiftEndTime);


        let radioCodeName = shift.radioCodeName
          ? shift.radioCodeName
          : NeedUtils.isOther(shift.service)
            ? shift.containerVehicle
            : "";


        return {
          shift:shift.shift,
          containerVehicle:shift.containerVehicle,
          color: shift.shift == "S" ? "rgba(169,226,252,0.5)" : "#A9E2FC",
          start: range.boundStart,
          end: range.boundEnd,
          content: <DailyEmployeeLine
            onClick={(id, position) => {
            }}
            id={shift.id}
            radioCodeName={radioCodeName}
            textColor={"#60707b"}
            turnName={shift.shift}
            start={range.startString}
            end={range.endString}
          />
        }
      });

      const service = worker.shifts.length == 0 ? "" : worker.shifts[0].service;

      const dailyServices: string[] = worker.shifts.length == 0 ? [] :
        Array.from(new Set(worker.shifts
          .filter(s => !s.isPreviousDay)
          .map(s => s.service)).values());

      const getWorkerByIdOrDefault = this.getWorkerDtoOrDefault(worker);
      let workerEnt: Worker = {
        fullName: `${worker.workerSurname} ${worker.workerName}`,
        id: worker.workerId,
        manuallyCreated: worker.showDelete,
        // either show the skill or MOSS in case no greater skill is available
        skill: getWorkerByIdOrDefault != null ? this.electSkill(getWorkerByIdOrDefault) : "",
        role: getWorkerByIdOrDefault != null ? getWorkerByIdOrDefault.role : ""
      }
      timeLines.push({
        // backgroundColor: "#f3f6f8",
        fullName: workerEnt.fullName,
        role: workerEnt.role,
        skill: workerEnt.skill,
        service: service,
        intervals: timelineIntervals,
        title:
          <DailyEmployeeCell
            className="actionable" onClick={
            () => this.props.onWorkerSelection(workerEnt)}
            mainService={service}
            allServices={dailyServices}
            name={`${workerEnt.fullName}`}
            role={workerEnt.role}
            skill={workerEnt.skill}
            manuallyCreated={worker.showDelete}
            onDelete={() => this.props.onWorkerDelete(workerEnt)}
          />
      })
    }

    const timeLinesServices: TimeLineMatrixConstr[] = timeLines.map(t => {
      return {timeline: t, service: t.service}
    });

    const sortedServices: TimeLineMatrixConstr[] =
      this.sortByServiceType(timeLinesServices);

    this.setState({
      filteredTimeLineMatrix: sortedServices
        .map(t => t.timeline)
    });
  }

  private filterByCircuit(circuitFilter: string) {
    return worker => circuitFilter == CircuitSelect.NO_FILTER ||
      worker.circuit.toLowerCase() == circuitFilter.toLowerCase();
  }

  private electSkill(getWorkerByIdOrDefault: WorkerDto) {
    if (getWorkerByIdOrDefault.skill != "") {
      return getWorkerByIdOrDefault.skill;
    } else if (getWorkerByIdOrDefault.moss) {
      return "MOSS";
    }
    return ""
  }

  private isFoundInShifts(filter: string, shifts: Shift[]) {

    return shifts.filter(s => s.shift && StringUtils.contains(s.shift?.toLowerCase(), filter)).length > 0
  }

  private getWorkerDtoOrDefault(worker: WorkerToShift): WorkerDto {
    const filteredList = this.props.activeWorkers.filter(w => w.id == worker.workerId);
    return filteredList.length == 0 ? null : filteredList[0];
  }

  removeSeconds(timeString: string): string {
    if (timeString?.length == 8)
      return timeString.substr(0, 5)
  }

  private getWorker(workerId: number): WorkerDto {
    for (const worker of this.props.allWorkers)
      if (worker.id == workerId)
        return worker
    return null;
  }

  private sortByServiceType(timeLines: TimeLineMatrixConstr[]): TimeLineMatrixConstr[] {
    const free = this.filterTimeLinesBy(timeLines, NeedUtils.isFree).sort(this.bySkillRoleAndFullname);
    const available = this.filterTimeLinesBy(timeLines, NeedUtils.isAvailable).sort(this.bySkillRoleAndFullname);
    const vacation = this.filterTimeLinesBy(timeLines, NeedUtils.isVacation).sort(this.bySkillRoleAndFullname);
    const rest = this.filterTimeLinesBy(timeLines, NeedUtils.isRest).sort(this.bySkillRoleAndFullname);
    const out_of_turn = this.filterTimeLinesBy(timeLines, NeedUtils.isOutOfTurn).sort(this.bySkillRoleAndFullname);
    const unmount = this.filterTimeLinesBy(timeLines, NeedUtils.isUnmount).sort(this.bySkillRoleAndFullname);
    const other = this.groupByService(this.filterTimeLinesBy(timeLines, NeedUtils.isOther)).sort(this.byServiceAndTurn);
    return free
      .concat(available)
      .concat(vacation)
      .concat(rest)
      .concat(other)
      .concat(out_of_turn)
      .concat(unmount);
  }

  private groupByService(sortedServices: TimeLineMatrixConstr[]): TimeLineMatrixConstr[] {
    // impl1
    let timeLineMap: { [service: string]: TimeLineMatrixConstr[] } = {};

    for (const t of sortedServices) {
      if (!timeLineMap[t.service])
        timeLineMap[t.service] = []
      timeLineMap[t.service].push(t)
    }
    let finalTimeLines: TimeLineMatrixConstr[] = []
    for (const s of Object.keys(timeLineMap)) {
      finalTimeLines = finalTimeLines.concat(timeLineMap[s])
    }

    finalTimeLines.sort((s, s1) => {
      if (s.service < s1.service) return -1;
      if (s.service > s1.service) return 1;
      return 0;
    })
    return finalTimeLines;
  }

  private bySkillRoleAndFullname(a: TimeLineMatrixConstr, b: TimeLineMatrixConstr) {
    if (a.timeline.skill != b.timeline.skill) {
      if (a.timeline.skill == "EM+") return -1
      else if (b.timeline.skill == "EM+") return 1
      else if (a.timeline.skill < b.timeline.skill) return -1;
      else if (a.timeline.skill > b.timeline.skill) return 1;
    } else {
      if (a.timeline.role < b.timeline.role) return -1
      else if (a.timeline.role > b.timeline.role) return 1
      else if (a.timeline.fullName < b.timeline.fullName) return -1;
      else if (a.timeline.fullName > b.timeline.fullName) return 1;
      else return 0;
    }
  }

  private byServiceAndTurn(a: TimeLineMatrixConstr, b: TimeLineMatrixConstr){
      if (a.service < b.service) return -1
      else if (a.service > b.service) return 1
      else if (a.timeline.intervals?.[0]?.containerVehicle < b.timeline.intervals?.[0]?.containerVehicle) return -1;
      else if (a.timeline.intervals?.[0]?.containerVehicle > b.timeline.intervals?.[0]?.containerVehicle) return 1;
      else return 0;
  }

  private filterTimeLinesBy(timeLines: TimeLineMatrixConstr[], comparatorFn: (service: string) => boolean):
    TimeLineMatrixConstr[] {
    const filtered: TimeLineMatrixConstr[] = []
    for (const t of timeLines) {
      if (comparatorFn(t?.service)) {
        filtered.push(t)
      }
    }
    return filtered;
  }
}
