import "./VehicleEntry.scss";
import React, {PureComponent} from "react";
import Select from "../../../Select/Select";
import {NeedVehicleDto} from "../../../../api/entity/NeedVehicleDto";
import I18n from "../../../../lib/I18n";
import TimeInputPicker from "../../../TimeInputPicker/TimeInputPicker";
import Table, {Header} from "../../../Table/Table";
import TextInput from "../../../TextInput/TextInput";
import ShiftUtils from "../../../../utils/ShiftUtils";
import {NeedVehicleErrors} from "../../../../pages/NeedModal/NeedModal";


export interface Props {
  /** This defines additional classes for the Button */
  className?: string,
  needId,
  number,
  /** Disables onclick
   *  @default false
   **/
  shiftUtils: ShiftUtils,
  vehicleDto: NeedVehicleDto;
  vehicleErrors?: NeedVehicleErrors
  onChange: (a: NeedVehicleDto) => void;
  onDelete: () => void;
}

interface State {
  vehicleDto?: NeedVehicleDto,
  vehicleErrors: NeedVehicleErrors,
  headers: Header<TableEntry>[];
  itemsWeekly: TableEntry;
  itemsFestive: TableEntry;
}

export interface TableEntry {
  monday: string
  tuesday: string
  wednesday: string
  thursday: string
  friday: string
  saturday: string
  sunday: string
}


export class VehicleEntry extends PureComponent<Props, State> {

  private readonly _commonClassName = "row-w";
  // @ts-ignore
  private weekRender: Partial<Record<TableEntry, (value: any) => React.ReactNode>> = {
    monday: (value) => <TextInput defaultValue={value}
                                  onChange={(value) => this.setDayValueForWeekDay(value, 0)}
                                  isNumeric={true} errorMessage={this.state.vehicleErrors.weekTimeErrors[0]}
                                  className={this._commonClassName}
                                  onKeyPressValidation={TextInput.preventOtherKeysButNumber}/>,
    tuesday: (value) => <TextInput defaultValue={value}
                                   onChange={(value) => this.setDayValueForWeekDay(value, 1)}
                                   isNumeric={true} errorMessage={this.state.vehicleErrors.weekTimeErrors[1]}
                                   className={this._commonClassName}
                                   onKeyPressValidation={TextInput.preventOtherKeysButNumber}/>,
    wednesday: (value) => <TextInput defaultValue={value}
                                     onChange={(value) => this.setDayValueForWeekDay(value, 2)}
                                     isNumeric={true} errorMessage={this.state.vehicleErrors.weekTimeErrors[2]}
                                     className={this._commonClassName}
                                     onKeyPressValidation={TextInput.preventOtherKeysButNumber}/>,
    thursday: (value) => <TextInput defaultValue={value}
                                    onChange={(value) => this.setDayValueForWeekDay(value, 3)}
                                    isNumeric={true} errorMessage={this.state.vehicleErrors.weekTimeErrors[3]}
                                    className={this._commonClassName}
                                    onKeyPressValidation={TextInput.preventOtherKeysButNumber}/>,
    friday: (value) => <TextInput defaultValue={value}
                                  onChange={(value) => this.setDayValueForWeekDay(value, 4)}
                                  isNumeric={true} errorMessage={this.state.vehicleErrors.weekTimeErrors[4]}
                                  className={this._commonClassName}
                                  onKeyPressValidation={TextInput.preventOtherKeysButNumber}/>,
    saturday: (value) => <TextInput defaultValue={value}
                                    onChange={(value) => this.setDayValueForWeekDay(value, 5)}
                                    isNumeric={true} errorMessage={this.state.vehicleErrors.weekTimeErrors[5]}
                                    className={this._commonClassName}
                                    onKeyPressValidation={TextInput.preventOtherKeysButNumber}/>,
    sunday: (value) => <TextInput defaultValue={value}
                                  onChange={(value) => this.setDayValueForWeekDay(value, 6)}
                                  isNumeric={true} errorMessage={this.state.vehicleErrors.weekTimeErrors[6]}
                                  className={this._commonClassName}
                                  onKeyPressValidation={TextInput.preventOtherKeysButNumber}/>
  };

  // @ts-ignore
  private weekendRender: Partial<Record<TableEntry, (value: any) => React.ReactNode>> = {
    monday: (value) => <TextInput defaultValue={value}
                                  onChange={(value) => this.setDayValueForWeekend(value, 0)}
                                  isNumeric={true} errorMessage={this.state.vehicleErrors.weekEndTimeErrors[0]}
                                  className={this._commonClassName}
                                  onKeyPressValidation={TextInput.preventOtherKeysButNumber}/>,
    tuesday: (value) => <TextInput defaultValue={value}
                                   onChange={(value) => this.setDayValueForWeekend(value, 1)}
                                   isNumeric={true} errorMessage={this.state.vehicleErrors.weekEndTimeErrors[1]}
                                   className={this._commonClassName}
                                   onKeyPressValidation={TextInput.preventOtherKeysButNumber}/>,
    wednesday: (value) => <TextInput defaultValue={value}
                                     onChange={(value) => this.setDayValueForWeekend(value, 2)}
                                     isNumeric={true} errorMessage={this.state.vehicleErrors.weekEndTimeErrors[2]}
                                     className={this._commonClassName}
                                     onKeyPressValidation={TextInput.preventOtherKeysButNumber}/>,
    thursday: (value) => <TextInput defaultValue={value}
                                    onChange={(value) => this.setDayValueForWeekend(value, 3)}
                                    isNumeric={true} errorMessage={this.state.vehicleErrors.weekEndTimeErrors[3]}
                                    className={this._commonClassName}
                                    onKeyPressValidation={TextInput.preventOtherKeysButNumber}/>,
    friday: (value) => <TextInput defaultValue={value}
                                  onChange={(value) => this.setDayValueForWeekend(value, 4)}
                                  isNumeric={true} errorMessage={this.state.vehicleErrors.weekEndTimeErrors[4]}
                                  className={this._commonClassName}
                                  onKeyPressValidation={TextInput.preventOtherKeysButNumber}/>,
    saturday: (value) => <TextInput defaultValue={value}
                                    onChange={(value) => this.setDayValueForWeekend(value, 5)}
                                    isNumeric={true} errorMessage={this.state.vehicleErrors.weekEndTimeErrors[5]}
                                    className={this._commonClassName}
                                    onKeyPressValidation={TextInput.preventOtherKeysButNumber}/>,
    sunday: (value) => <TextInput defaultValue={value}
                                  onChange={(value) => this.setDayValueForWeekend(value, 6)}
                                  isNumeric={true} errorMessage={this.state.vehicleErrors.weekEndTimeErrors[6]}
                                  className={this._commonClassName}
                                  onKeyPressValidation={TextInput.preventOtherKeysButNumber}/>
  };

  static defaultProps: Partial<Props> = {
    className: ""
  };

  public constructor(props) {
    super(props);
    this.state = {
      vehicleErrors: this.props.vehicleErrors,
      vehicleDto: this.props.vehicleDto,
      itemsWeekly: {
        monday: (this.props.vehicleDto.numberVehiclesWorkdays)[0],
        tuesday: (this.props.vehicleDto.numberVehiclesWorkdays)[1],
        wednesday: (this.props.vehicleDto.numberVehiclesWorkdays)[2],
        thursday: (this.props.vehicleDto.numberVehiclesWorkdays)[3],
        friday: (this.props.vehicleDto.numberVehiclesWorkdays)[4],
        saturday: (this.props.vehicleDto.numberVehiclesWorkdays)[5],
        sunday: (this.props.vehicleDto.numberVehiclesWorkdays)[6]
      },
      itemsFestive: {
        monday: (this.props.vehicleDto.numberVehiclesFestive)[0],
        tuesday: (this.props.vehicleDto.numberVehiclesFestive)[1],
        wednesday: (this.props.vehicleDto.numberVehiclesFestive)[2],
        thursday: (this.props.vehicleDto.numberVehiclesFestive)[3],
        friday: (this.props.vehicleDto.numberVehiclesFestive)[4],
        saturday: (this.props.vehicleDto.numberVehiclesFestive)[5],
        sunday: (this.props.vehicleDto.numberVehiclesFestive)[6]
      },
      headers: [
        {name: I18n.get().Weekdays.monday, field: "monday"},
        {name: I18n.get().Weekdays.tuesday, field: "tuesday"},
        {name: I18n.get().Weekdays.wednesday, field: "wednesday"},
        {name: I18n.get().Weekdays.thursday, field: "thursday"},
        {name: I18n.get().Weekdays.friday, field: "friday"},
        {name: I18n.get().Weekdays.saturday, field: "saturday"},
        {name: I18n.get().Weekdays.sunday, field: "sunday"},
      ]
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (this.props.vehicleDto != prevProps.vehicleDto ||
      this.props.vehicleErrors != prevProps.vehicleErrors) {
      let vehicleDto = this.props.vehicleDto;
      this.setState({
        vehicleDto: vehicleDto,
        vehicleErrors: this.props.vehicleErrors,
        itemsWeekly: {
          monday: (vehicleDto.numberVehiclesWorkdays)[0],
          tuesday: (vehicleDto.numberVehiclesWorkdays)[1],
          wednesday: (vehicleDto.numberVehiclesWorkdays)[2],
          thursday: (vehicleDto.numberVehiclesWorkdays)[3],
          friday: (vehicleDto.numberVehiclesWorkdays)[4],
          saturday: (vehicleDto.numberVehiclesWorkdays)[5],
          sunday: (vehicleDto.numberVehiclesWorkdays)[6]
        },
        itemsFestive: {
          monday: (vehicleDto.numberVehiclesFestive)[0],
          tuesday: (vehicleDto.numberVehiclesFestive)[1],
          wednesday: (vehicleDto.numberVehiclesFestive)[2],
          thursday: (vehicleDto.numberVehiclesFestive)[3],
          friday: (vehicleDto.numberVehiclesFestive)[4],
          saturday: (vehicleDto.numberVehiclesFestive)[5],
          sunday: (vehicleDto.numberVehiclesFestive)[6]
        }
      })
    }
  }

  private setDayValueForWeekDay(value, index) {
    const {vehicleDto} = this.state;
    vehicleDto.numberVehiclesWorkdays[index] = value;
    let weekErrors = this.state.vehicleErrors.weekTimeErrors;
    weekErrors[index] = "";
    this.setState({vehicleDto, vehicleErrors: {...this.state.vehicleErrors, weekEndTimeErrors: weekErrors}})
    this.setState({vehicleDto})
    this.props.onChange(vehicleDto);
  }

  private setDayValueForWeekend(value, index) {
    const {vehicleDto} = this.state;
    vehicleDto.numberVehiclesFestive[index] = value;
    let weekendErrors = this.state.vehicleErrors.weekEndTimeErrors;
    weekendErrors[index] = "";
    this.setState({vehicleDto, vehicleErrors: {...this.state.vehicleErrors, weekEndTimeErrors: weekendErrors}})
    this.props.onChange(vehicleDto);
  }

  render() {
    return (
      <div className={"VehicleEntry " + this.props.className}>
        <span className={"closeIcon row-w"} onClick={() => this.props.onDelete()}>&times;</span>
        <div className={"first-row"}>
          <Select
            defaultValue={this.props.shiftUtils.NEED_TOPOLOGY_DTO.indexOf(this.state.vehicleDto.category)}
            items={this.props.shiftUtils.NEED_TOPOLOGY_LIST} label={I18n.get().ShiftRegistry.table.typology}
            className={"row-w height"}
            onChange={(index) => {
              const {vehicleDto} = this.state;
              vehicleDto.category = this.props.shiftUtils.NEED_TOPOLOGY_LIST[index]
              this.setState({vehicleDto});
              this.props.onChange(vehicleDto);
            }}
          />
          <TimeInputPicker
            onCorrectChange={(time) => {
              const {vehicleDto} = this.state;
              vehicleDto.fromTime = time
              this.setState({vehicleDto});
              this.props.onChange(vehicleDto);
            }}
            label={I18n.get().WorkersRegistry.fromTime}
            placeholder={"00:00"}
            className={"row-w"}
            defaultValue={this.state.vehicleDto.fromTime}
            error={this.state.vehicleErrors.fromTimeError != "" || this.state.vehicleErrors.toTimeError != ""}
          />
          <TimeInputPicker
            onCorrectChange={(time) => {
              const {vehicleDto} = this.state;
              vehicleDto.toTime = time == "24:00" ? "00:00" : time;
              this.setState({vehicleDto});
              this.props.onChange(vehicleDto);
            }}
            isToTime={true}
            label={I18n.get().WorkersRegistry.toTime}
            placeholder={"00:00"}
            className={"row-w"}
            defaultValue={this.state.vehicleDto.toTime}
            error={this.state.vehicleErrors.fromTimeError != "" || this.state.vehicleErrors.toTimeError != ""}
          />

        </div>
        <div >
          <Select
            defaultValue={this.props.shiftUtils.PLACE_DTO.indexOf(this.state.vehicleDto.parkingDescription)}
            items={this.props.shiftUtils.PLACE_DTO}
            label={I18n.get().NeedManagement.addNewNeedManagement.vehicle.placeDescription} className={"row-w height"}
            onChange={(index) => {
              const {vehicleDto} = this.state;
              vehicleDto.parkingDescription = this.props.shiftUtils.PLACE_DTO[index]
              this.setState({vehicleDto});
              this.props.onChange(vehicleDto);
            }}
          />

          <Select
            defaultValue={this.props.shiftUtils.PUBLISHING_DTO.indexOf(this.state.vehicleDto.publishing)}
            items={this.props.shiftUtils.PUBLISHING_LABELS}
            label={I18n.get().NeedManagement.addNewNeedManagement.vehicle.publishing}
            className={"row-w height"}
            onChange={(index) => {
              const {vehicleDto} = this.state;
              vehicleDto.publishing = this.props.shiftUtils.PUBLISHING_DTO[index]
              this.setState({vehicleDto});
              this.props.onChange(vehicleDto);
            }}
          />

          <Select
            defaultValue={this.props.shiftUtils.NEED_CONVENTION_DTO.indexOf(this.state.vehicleDto.convention)}
            items={this.props.shiftUtils.NEED_CONVENTION_LIST}
            label={I18n.get().NeedManagement.addNewNeedManagement.convention}
            className={"height"}
            onChange={(index) => {
              const {vehicleDto} = this.state;
              vehicleDto.convention = this.props.shiftUtils.NEED_CONVENTION_DTO[index]
              this.setState({vehicleDto});
              this.props.onChange(vehicleDto);
            }}
          />
        </div>
        <div >
          {I18n.get().NeedManagement.addNewNeedManagement.vehicle.workdays}
        </div>
        <div className={"second-row"}>
          <div className="table-wrapper">
          <Table onClick={() => {}}
                 headers={this.state.headers}
                 items={[this.state.itemsWeekly]}
                 itemsRender={this.weekRender}
          />
          </div>
        </div>
        <div >
          {I18n.get().NeedManagement.addNewNeedManagement.vehicle.festive}
        </div>
        <div className={"third-row"}>
          <div className="table-wrapper">
          <Table onClick={() => {
          }}
                 headers={this.state.headers}
                 items={[this.state.itemsFestive]}
                 itemsRender={this.weekendRender}
          />
        </div>
        </div>
      </div>
    )
  }

}
