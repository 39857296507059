import React, {Component} from "react";
import "./DailyVehicleHeader.scss"
import Time from "../../../../lib/utils/Time";
interface Props{
  day:Date
}

interface State{

}

export default class DailyVehicleHeader extends Component<Props,State>{
  render() {
    const {day} = this.props
    return <div className="DailyWorkerHeader">
      <div className="DailyWorkerHeader-subtext"> {Time.getStringDay(day)}</div>
      <div className="DailyWorkerHeader-text"> {day.getDate()}</div>
    </div>
  }

}
