import React from "react";
import {StatusEnum} from "../../../api/engine/EngineApi";
import IconLoading from "../../../loader/LoaderDots";
import I18n from "../../../lib/I18n";
import "./MonthlyPlanningLoader.scss"

export interface Props {
  status: StatusEnum
}

export default function MonthlyPlanningLoader(props: Props) {


  function getLabels(status: StatusEnum) {
    let labels = I18n.get().MonthlyPlanning;

    switch (status) {
      case StatusEnum.CONVERGENCE_CHECK_IN_PROGRESS:
        return [labels.convergenceCheckInProgress, labels.convergenceCheckInProgressMessage]
      case StatusEnum.MONTHLY_PLANNING_IN_PROGRESS:
        return [labels.monthlyPlanningInProgress, labels.monthlyPlanningInProgressMessage]
      case StatusEnum.CONSTRAINT_CHECK_IN_PROGRESS:
        return [labels.constraintCheckInProgress, labels.constraintCheckInProgressMessage]
      case StatusEnum.DAILY_PLANNING_IN_PROGRESS:
        return [labels.dailyPlanningInProgress, labels.dailyPlanningInProgressMessage]
      default:
        return ["", ""]
    }
  }

  const [title, text] = getLabels(props.status)

  return <div className="MonthlyPlanningLoader">
    <div className="MonthlyPlanningLoader-wrapper">
      <IconLoading size={20}/>
      <p className="MonthlyPlanningLoader-title">{title}</p>
      <p>{text}</p>
    </div>
  </div>
}

