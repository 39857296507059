import URLUtils from '../lib/utils/URLUtils';

class Api {

  private readonly HTTP_NOT_AUTHORIZED = 403;

  constructor(private baseUrl: string = URLUtils.getBaseURL() + "/api/v1") {
  }

  /************************************************
   * PUBLIC FUNCTIONS
   ************************************************/
  public get<R>(
    path: string,
    query?: {
      [field: string]: string | number | boolean | null;
    },
    additionalHeaders?: { [key: string]: string },
    onAuthError?: () => void
  ): Promise<R> {
    if (query) {
      let queryString = '';
      for (const field in query) {
        queryString += queryString ? '&' : '?';
        queryString += field + '=' + encodeURIComponent(String(query[field]));
      }
      path += queryString;
    }

    let headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'origin': `${window.location.protocol}//${window.location.host}`,
    };

    if (additionalHeaders) for (let key in additionalHeaders) headers[key] = additionalHeaders[key];

    return fetch(this.getFullUrl(path), {
      credentials: 'include',
      headers,
    }).then(response => {
      if (response.status == this.HTTP_NOT_AUTHORIZED) {
        onAuthError();
      }
      if (!response.ok) throw response;
      return response.json();
    });
  }

  public post<T, R>(path: string, value: T, additionalHeaders?: { [key: string]: string },
                    onAuthError?: () => void): Promise<R> {
    let headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'origin': `${window.location.protocol}//${window.location.host}`,
    };

    if (additionalHeaders) for (let key in additionalHeaders) headers[key] = additionalHeaders[key];

    return fetch(this.getFullUrl(path), {
      method: 'post',
      credentials: 'include',
      headers,
      body: JSON.stringify(value),
    }).then(response => {
      if (response.status == this.HTTP_NOT_AUTHORIZED) {
        onAuthError();
      }
      if (!response.ok) throw response;
      return response.json();
    });
  }

  public put<T, R>(path: string, value: T, additionalHeaders?: { [key: string]: string },
                   onAuthError?: () => void): Promise<R> {
    let headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'origin': `${window.location.protocol}//${window.location.host}`,
    };

    if (additionalHeaders) for (let key in additionalHeaders) headers[key] = additionalHeaders[key];

    return fetch(this.getFullUrl(path), {
      method: 'put',
      credentials: 'include',
      headers,
      body: JSON.stringify(value),
    }).then(response => {
      if (response.status == this.HTTP_NOT_AUTHORIZED) {
        onAuthError();
      }
      if (!response.ok) throw response;
      return response.json();
    });
  }

  public delete<T, R>(path: string, additionalHeaders?: { [key: string]: string }, onAuthError?: () => void): Promise<R> {
    let headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'origin': `${window.location.protocol}//${window.location.host}`,
    };

    if (additionalHeaders) for (let key in additionalHeaders) headers[key] = additionalHeaders[key];

    return fetch(this.getFullUrl(path), {
      method: 'delete',
      credentials: 'include',
      headers,
    }).then(response => {
      if (response.status == this.HTTP_NOT_AUTHORIZED) {
        onAuthError();
      }
      if (!response.ok) throw response;
      return response.json();
    });
  }

  public download(path: string, fileName: string, query?: { [field: string]: string | number | boolean | null },
                  additionalHeaders?: { [key: string]: string },
                  onAuthError?: () => void): Promise<void> {
    if (query) {
      let queryString = '';
      for (const field in query) {
        queryString += queryString ? '&' : '?';
        queryString += field + '=' + encodeURIComponent(String(query[field]));
      }
      path += queryString;
    }

    let headers = {
      'Content-Type': 'application/octet-stream',
      'Accept': 'application/octet-stream',
      'origin': `${window.location.protocol}//${window.location.host}`,
    };

    if (additionalHeaders) for (let key in additionalHeaders) headers[key] = additionalHeaders[key];

    return fetch(this.getFullUrl(path), {
      credentials: 'include',
      headers,
    }).then(response => {
      if (response.status == this.HTTP_NOT_AUTHORIZED) {
        onAuthError();
      }
      if (!response.ok) throw response;
      let blob = response.blob();
      blob.then(blob => {
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, fileName);
        } else {
          let elem = window.document.createElement('a');
          elem.href = window.URL.createObjectURL(blob);
          elem.download = fileName;
          document.body.appendChild(elem);
          elem.click();
          document.body.removeChild(elem);
        }
      });
    });
  }

  public upload(path: string, formaData: FormData, additionalHeaders?: { [key: string]: string },
                onAuthError?: () => void) {
    let headers = {
      Accept: 'application/json',
      origin: `${window.location.protocol}//${window.location.host}`,
    };

    if (additionalHeaders) for (let key in additionalHeaders) headers[key] = additionalHeaders[key];

    return fetch(this.getFullUrl(path), {
      method: 'post',
      credentials: 'include',
      headers,
      body: formaData,
    }).then(response => {
      if (response.status == this.HTTP_NOT_AUTHORIZED) {
        onAuthError();
      }
      if (!response.ok) throw response;
      return response.text(); //.json();
    });
  }

  /************************************************
   * PRIVATE FUNCTIONS
   ************************************************/
  private getFullUrl(path: string) {
    if (!path) return this.baseUrl;
    if (path.indexOf('/') == 0) return this.baseUrl + path;
    return this.baseUrl + '/' + path;
  }
}

export default Api;
