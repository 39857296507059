import Api from '../Api';
import AuthenticationDto from '../entity/AuthenticationDto';
import AuthorizeApi from "../AuthorizeApi";
import AuthenticationCheckResponse from "../entity/response/AuthenticationCheckResponse";
import {Roles} from "../Roles";
import StatusPoller from "../../utils/StatusPoller";


export interface TokenPromise {
  token : string,
  role: string
}
export default class LoginApi {

  static readonly TOKEN = "TOKEN";
  private static ROLE: string = "ROLE";

  private base: string = '/auth';

  private api: Api = new Api();
  private authApi: AuthorizeApi = new AuthorizeApi();

  public async login(user: AuthenticationDto): Promise<string> {
    try {
      let tokenPromise = await this.api.post<AuthenticationDto, TokenPromise>(this.base, user);
      LoginApi.saveTokenWhenLoginSuccessful(tokenPromise);
      return tokenPromise.token;
    } catch (e) {
      return "";
    }
  }

  async refreshToken(token): Promise<string> {
    try {
      let tokenPromise = await this.api.post<{ token: string },
        TokenPromise>(this.base + "/refresh", {token: token});
      LoginApi.saveTokenWhenLoginSuccessful(tokenPromise);
      return tokenPromise.token;
    } catch (e) {
      return "";
    }
  }

  public async testSession(): Promise<boolean> {
    try {
      const isUp = await this.authApi.get<AuthenticationCheckResponse>(this.base, () => {
        console.log("User is not logged in, system is up")
      }, {}, {});
      return isUp.up;
    } catch (e) {
      return false;
    }
  }

  public static logout() {
    localStorage.removeItem(this.TOKEN);
    localStorage.removeItem(this.ROLE);
  }

  public static getToken(): string {
    return localStorage.getItem(this.TOKEN);
  }

  public static getRole(): string {
    return localStorage.getItem(this.ROLE);
  }

  public static isUserRoleHigherThanUser() {
    return LoginApi.getRole() && LoginApi.getRole() != Roles.user;
  }


  private static saveTokenWhenLoginSuccessful(tokenPromise: TokenPromise) {
    if (tokenPromise.token) {
      localStorage.setItem(this.TOKEN, tokenPromise.token);
      localStorage.setItem(this.ROLE, tokenPromise.role);
      StatusPoller.getInstance().startOrStopStatusPoller()
    }
  }
}
