import React, {Component} from "react";
import "./WorkersTable.scss";
import Table, {Header} from "../../../components/Table/Table";
import I18n from "../../../lib/I18n";

export interface WTEntry {
  container: string,
  shift: string,
  timeStart: string;
  timeEnd: string;
  operator: string,
  operatorTwo: string,
  operatorThree: string,
  circuit: string,
  vehicle: string,
  placement: string,
}

interface Props {
  entries: WTEntry[]
}

interface TableEntry {
  container: string,
  shift: string,
  timeStart: string;
  timeEnd: string;
  operator: string,
  operatorTwo: string,
  operatorThree: string,
  circuit: string,
  vehicle: string,
  placement: string,
}

interface State {
  table: {
    headers: Header<TableEntry>[];
    items: TableEntry[];
    currentSlice: number;
  };
}

export default class WorkersTable extends Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = {
      table: {
        currentSlice: 1,
        items: [],
        headers: []
      }
    }
  }

  componentDidMount() {
    this.updateTable();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (prevProps.entries !== this.props.entries) {
      this.updateTable();
    }
  }

  updateTable() {
    const entries = this.props.entries;
    this.setState({
      table: {
        ...this.state.table,
        headers: [
          {
            name: I18n.get().TotemWorkers.table.shift,
            field: "container"
          },
          {
            name: I18n.get().TotemWorkers.table.code,
            field: "shift"
          },
          {
            name: I18n.get().TotemWorkers.table.startTime,
            field: "timeStart"
          },
          {
            name: I18n.get().TotemWorkers.table.endTime,
            field: "timeEnd"
          },
          {
            name: I18n.get().TotemWorkers.table.operator,
            field: "operator"
          },
          {
            name: I18n.get().TotemWorkers.table.operatorTwo,
            field: "operatorTwo"
          },
          {
            name: I18n.get().TotemWorkers.table.operatorThree,
            field: "operatorThree"
          },
          {
            name: I18n.get().TotemWorkers.table.circuit,
            field: "circuit"
          },
          {
            name: I18n.get().TotemWorkers.table.vehicle,
            field: "vehicle"
          },
          {
            name: I18n.get().TotemWorkers.table.placement,
            field: "placement"
          }
        ], items:
          entries.map((e) => {
            return {
              container: e.shift,
              shift: e.container,
              timeStart: e.timeStart,
              timeEnd: e.timeEnd,
              operator: e.operator,
              operatorTwo: e.operatorTwo,
              operatorThree: e.operatorThree,
              circuit: e.circuit,
              placement: e.placement,
              vehicle: e.vehicle
            };
          })
      }
    });
  }

  render() {
    return <div>
      <Table headers={this.state.table.headers}
             items={this.state.table.items}
             onClick={(i) => console.log(i)}/>
    </div>;
  }
}
