import Page from "../Page";
import './VehicleRegistry.scss';
import React from "react";
import {FocusPanel} from "../../components/FocusPanel/FocusPanel";
import I18n from "../../lib/I18n";
import Button from "../../components/Button/Button";
import {ModalType} from "../../components/Modal/ModalType";
import {VehicleModal} from "../../components/Modal/VehicleModal/VehicleModal";
import {VehicleDto} from "../../api/entity/VehicleDto";
import VehicleApi from "../../api/vehicle/VehicleApi";
import ShiftUtils from "../../utils/ShiftUtils";
import Table, {CellVisibilityRule, Header} from "../../components/Table/Table";
import {VehicleResponse} from "../../api/entity/response/VehicleResponse";
import SearchInput from "../../components/SearchInput/SearchInput";
import HeaderSection from "./HeaderSection/HeaderSection";
import SideBar from "../../components/SideBar/SideBar";
import Toast from "../../components/Toast/Toast";
import {DateTime} from "luxon";
import GfDateUtils from "../../components/DatePicker/GfDateUtils";
import Spinner from "../../components/Spinner/Spinner";
import ReactPaginate from "react-paginate";
import { Pagination } from "../../components/Table/Pagination";

interface TableEntry {
  id: number,
  category: any,
  plate: string,
  name: string,
  circuit: string,
  telephone: string,
  endDate: string
}

interface TableInt {
  headers: Header<TableEntry>[];
  items: TableEntry[];
  currentSlice: number;
}

interface State {
  searchKey: string
  registryCount: number
  pagesCount: number
  isCreateModifyOpen: boolean
  modal: {
    createModify: ModalType,
    vehicle: VehicleDto
  }
  toast: {
    isError: boolean;
    errorList: string[];
    message: string;
  },
  isToastOpen: boolean;
  table: TableInt
  selectedId: number
  isSideBarVisibile: boolean
  loading: boolean
}

export default class VehicleRegistry extends Page<{}, State> {

  private readonly elementsPerPage = 50;

  private shiftUtils = new ShiftUtils();
  private vehicleApi: VehicleApi = new VehicleApi();

  public constructor(props) {
    super(props);

    this.state = {
      pagesCount: 0,
      isToastOpen: false,
      searchKey: "",
      registryCount: 1,
      isCreateModifyOpen: false,
      modal: {
        createModify: ModalType.CREATE,
        vehicle: this.shiftUtils.getEmptyVehicleDto()
      },
      toast: {
        isError: false,
        errorList: [],
        message: ""
      },
      table: {
        currentSlice: 0,
        items: [],
        headers: []
      },
      selectedId: null,
      isSideBarVisibile: false,
      loading: true
    }
  }

  async componentDidMount() {
    try {
      const vehicleResponse: VehicleResponse = await this.vehicleApi.getOrSearch(this.state.searchKey, this.elementsPerPage,
        this.elementsPerPage * (this.state.table.currentSlice));
      this.setState({registryCount: vehicleResponse.total, loading: false, pagesCount: vehicleResponse.totalPages});
      this.updateTable(vehicleResponse);
    } catch {
      this.setState({loading: false})
    }
  }

  private toggleCreateModifyModal() {
    let {isCreateModifyOpen} = this.state;
    isCreateModifyOpen = !isCreateModifyOpen;
    this.setState({isCreateModifyOpen});
  }

  private onChange(vehicleDto) {
    this.save(vehicleDto);
  }

  private save(vehicleDto) {
    const vehicleResponsePromise = this.vehicleApi.save(vehicleDto);
    vehicleResponsePromise.then((r) => {
      this.toggleCreateModifyModal();
      this.vehicleApi.getOrSearch(this.state.searchKey, this.elementsPerPage,
        this.elementsPerPage * (this.state.table.currentSlice))
        .then((r) => {
            this.updateTable(r);
            this.toggleSuccessMessage();
          }
        );
    });
  }

  private toggleSuccessMessage() {
    this.setState({
      toast: {
        isError: false,
        message: I18n.get().VehicleRegistry.savedVehicleMessage,
        errorList: [],
      },
      isToastOpen: true
    })
  }

  private setNewVehicleDto() {
    this.setState({modal: {createModify: ModalType.CREATE, vehicle: this.shiftUtils.getEmptyVehicleDto()}})
  }

  private async onTableClick(vehicleId) {
    await this.vehicleApi.getVehicleById(vehicleId.id).then((r) => {
      this.setState({selectedId: vehicleId, modal: {createModify: ModalType.MODIFY, vehicle: r.content[0]}})
      if (!this.state.isSideBarVisibile) {
        this.toggleSidebar();
      }
    });
  }

  private async onModifyClick() {
    this.toggleCreateModifyModal();
    this.toggleSidebar();
    this.onSearch(this.state.searchKey);
    this.resetTable();
  }

  private async onPaginationChange(page: number) {
    this.setState({table: {...this.state.table, currentSlice: page}},
      async () => {
      await this.fetchVehicles();
    })
  }

  private async fetchVehicles() {
    this.vehicleApi.getOrSearch(this.state.searchKey, this.elementsPerPage,
      this.elementsPerPage * (this.state.table.currentSlice))
      .then((r) =>
        this.updateTable(r)
      );
  }

  private toggleSidebar() {
    let {isSideBarVisibile} = this.state;
    isSideBarVisibile = !isSideBarVisibile;
    this.setState({isSideBarVisibile});
  }


  private updateTable(vehicleResponse: VehicleResponse) {
    const items = vehicleResponse.content.map(function (e) {
      return {
        id: e.id,
        category: (e.category == "EM/INTERH")
          ? <>
            <div className="skillLabel EM">EM</div>
            <div className="skillLabel INTERH">INTERH</div>
          </>
          : <div className={"skillLabel " + (e.category == "EM+" ? "EM_PLUS" : e.category)}>{e.category}</div>,
        plate: e.plate,
        name: e.name,
        circuit: e.circuit,
        telephone: e.telephone,
        endDate: DateTime.fromFormat(e.validityend, GfDateUtils.STORED_DATE_FORMAT).toFormat(GfDateUtils.DATE_FORMAT)
      };
    });
    this.setState({
      registryCount: vehicleResponse.total,
      table: {
        ...this.state.table,
        headers: [
          {
            name: I18n.get().VehicleRegistry.table.category,
            field: "category"
          },
          {
            name: I18n.get().VehicleRegistry.table.plate,
            field: "plate",
            visibility: CellVisibilityRule.FOR_TABLET_LANDSCAPE_UP
          },
          {
            name: I18n.get().VehicleRegistry.table.sigla,
            field: "name"
          },
          {
            name: I18n.get().VehicleRegistry.table.circuit,
            field: "circuit"
          },
          {
            name: I18n.get().VehicleRegistry.table.telephoneNumber,
            field: "telephone",
            visibility: CellVisibilityRule.FOR_TABLET_LANDSCAPE_UP
          },
          {
            name: I18n.get().VehicleRegistry.table.endDate,
            field: "endDate",
            visibility: CellVisibilityRule.FOR_TABLET_PORTRAIT_UP
          }
        ], items:
        items
      }
    });
  }

  private async onSearch(value: string) {
    this.setState({searchKey: value, table: {currentSlice: 0, ...this.state.table}}, async ()=> {
      if (value == null || value == "") {
        await this.fetchVehicles();
        return;
      }
      let response = await this.vehicleApi.getOrSearch(value,
        this.elementsPerPage, 0);
      this.setState({registryCount: response.total});
      this.resetTable();
      this.updateTable(response);
    })
  }

  private resetTable() {
    this.setState({table: { ... this.state.table, currentSlice: 0}});
  }

  private closeToast() {
    this.setState({isToastOpen: false});
  }

  render() {
    if (this.state.loading)
      return <Spinner/>
    return <div className={"VehicleRegistry"}>
      <FocusPanel show={this.state.isCreateModifyOpen}/>
      {this.state.isCreateModifyOpen ?
        <VehicleModal onSubmit={(vehicleDto) => this.onChange(vehicleDto)}
                      onCancel={() => this.toggleCreateModifyModal()}
                      model={this.state.modal.vehicle}
        /> : null}

      <div className={"inner-content"}>
        <Toast isError={this.state.toast.isError}
               message={this.state.toast.message}
               errors={this.state.toast.errorList}
               visible={this.state.isToastOpen}
               trigger={() => this.closeToast()}/>
        <div className={"top"}>
          <div className={"up-title"}>
            <h1>{I18n.get().VehicleRegistry.title}</h1>
          </div>
          <div className={"up-controls"}>
            <Button onClick={() => {
              this.setNewVehicleDto();
              this.toggleCreateModifyModal();
            }}>
              {I18n.get().VehicleRegistry.addBtn}</Button>
          </div>
          <div>
            {I18n.get().VehicleRegistry.vehicles}: {this.state.registryCount}
          </div>

          <div className={"search-location"}>
            <SearchInput className="search-input" label="" defaultValue=""
                         placeholder={I18n.get().VehicleRegistry.findVehicleByName}
                         onChange={(value) => this.onSearch(value)}
                         readonly={false} disabled={false} password={false} errorMessage={""}/>
          </div>
          <div>
            <Table headers={this.state.table.headers}
                   items={this.state.table.items} onClick={(item) => this.onTableClick(item)}/>
            <Pagination elementsPerPage={this.elementsPerPage}
                        onPageChange={(page) => this.onPaginationChange(page)}
                        totalPages={this.state.registryCount}
                        page={this.state.table.currentSlice}/>
          </div>

          {this.state.isSideBarVisibile ?
            <SideBar
              className={"side"} onOpen={() => console.log("open")}
              onClose={() => this.toggleSidebar()}
              isOpen={this.state.selectedId != null}>
              <HeaderSection onToggleModify={() => this.onModifyClick()}
                             vehicle={this.state.modal.vehicle}/>
            </SideBar> : null}

        </div>
      </div>
    </div>
  }
}
