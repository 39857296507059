import React from "react";
import Icon, {Props} from "./Icon";

export default class IconChevronCircleLeft extends Icon {
  static defaultProps: Partial<Props> = {
    height: 20,
    width: 20,
    color: "#2E3A59"
  };

  render() {
    return (
      <svg className="IconChevronCircleLeft"
           width={this.props.width}
           height={this.props.height}
           viewBox="0 0 20 20">
        <defs>
          <filter id="mlthtbh3aa">
            <feColorMatrix in="SourceGraphic"/>
          </filter>
        </defs>
        <g fill="none" fillRule="evenodd">
          <g filter="url(#mlthtbh3aa)">
            <path d="m11.208 5.833 1.179 1.179-2.947 2.946 2.947 2.946-1.179 1.179-4.125-4.125 4.125-4.125zm7.125 4.167c0 4.602-3.73 8.333-8.333 8.333-4.602 0-8.333-3.73-8.333-8.333 0-4.602 3.73-8.333 8.333-8.333 4.602 0 8.333 3.73 8.333 8.333v0zm-1.666 0c0 3.682-2.985 6.667-6.667 6.667s-6.667-2.985-6.667-6.667 2.985-6.667 6.667-6.667 6.667 2.985 6.667 6.667v0z" fill="#09a7ef" mask="url(#i2kk9kwmbc)"/>
          </g>
        </g>
      </svg>
    );
  }
}
