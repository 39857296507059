import {SkillDto} from "../SkillDto";

export class SkillsToWorkerRequest {

  workerId: number;
  skills: SkillDto[];

  constructor(workerId: number, skills: SkillDto[]) {
    this.workerId = workerId;
    this.skills = skills;
  }
}
