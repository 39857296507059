import React, {Component} from "react";
import {FocusPanel} from "../../FocusPanel/FocusPanel";
import Button from "../../Button/Button";
import I18n from "../../../lib/I18n";
import "./EngineErrorsModal.scss"

interface Props{
  engineErrors:string;
  onCancel:()=>void;
}

interface State{

}

export default class EngineErrorsModal extends Component<Props, State>{
  render(){
    const labels = I18n.get().MonthlyTimeLine;
    return <>
      <FocusPanel show/>
      <div className="Modal EngineErrorsModal">
        <div className="EngineErrorsModal-title">
          {labels.engineErrorTitle}
        </div>
        <div id="EngineErrorsModal-content" className="EngineErrorsModal-message">
          {this.props.engineErrors}
        </div>
        <div className="EngineErrorsModal-buttons">
          <Button secondary onClick={() => this.props.onCancel()}>
            {labels.close}
          </Button>
          <Button onClick={() => this.copyToClipboard()}>
            {labels.copy}
          </Button>
        </div>
      </div>
    </>
  }

  private copyToClipboard() {
    const range_ = document.createRange();
    range_.selectNode(document.getElementById("EngineErrorsModal-content"));
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range_);
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
    window.alert("Messaggio copiato");
  }
}

