import "./MonthlyEmployeeCell.scss"
import React from "react"
import Time from "../../../../lib/utils/Time";
import IconError from "../../../../icons/IconError";

interface Props {
  name: string
  errors: string[]
  totalHours: number
  onClick: (workerName, errors) => void
}

export default function MonthlyEmployeeCell(props: Props) {
  return <div className="MonthlyEmployeeCell">
    {props.errors != null && props.errors.length > 0 ? <span onClick={() => { props.onClick(props.name, props.errors) }}>
    <IconError/>
    </span> : null}
    <span className="MonthlyEmployeeCell-name" title={props.name}>{props.name}</span>
    <span className="MonthlyEmployeeCell-totalHours"
          title={Time.minutesToHourString(props.totalHours)}>
      {Time.getMinutesAndHours(props.totalHours)[0]}h
    </span>
  </div>
}
