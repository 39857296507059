import {WorkerDto} from "../WorkerDto";
import {WorkerResponseNt} from "./nt/WorkerResponseNt";

export class WorkerResponse {
  status: string;
  errors: string[];
  content: WorkerDto[];
  totalPages: number;
  currentPages: number;
  size: number;
  total: number;

  constructor(workerResponseNt: WorkerResponseNt) {
    this.status = workerResponseNt.status;
    this.errors = workerResponseNt.errors;
    this.content = workerResponseNt.content.map((workerDtoNt) => new WorkerDto(workerDtoNt))
    this.totalPages = workerResponseNt.totalPages;
    this.currentPages = workerResponseNt.currentPages;
    this.size = workerResponseNt.size;
    this.total = workerResponseNt.total;
  }

}
