import React, {CSSProperties, PureComponent, ReactNode} from "react";
import "./Ellipsis.scss";

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>


type CSSPropertiesWithoutWidth = Omit<CSSProperties, "width" | "maxWidth">

interface Props {
  className: string,
  style: CSSPropertiesWithoutWidth
  width: number;
  flexibleWidth: boolean;
  children: string | ReactNode;
}

class Ellipsis extends PureComponent<Props> {

  static defaultProps = {
    width: null,
    className: "",
    style: {},
    flexibleWidth: false
  };

  render() {
    if (!this.props.children)
      return null;
    return (
      <div className={"Ellipsis " + this.props.className} style={this.getStyle()}>
        <span title={this.props.children.toString()}>
            {this.props.children}
        </span>
      </div>
    );
  }

  private getStyle() {
    let style: CSSProperties = {...this.props.style};
    if (this.props.width) {
      if (!this.props.flexibleWidth)
        style.width = this.props.width;
      else
        style.maxWidth = this.props.width;
    }
    return style;
  }
}


export default Ellipsis;
