import React, {Component, ReactNode} from "react";
import TableRow from "./TableRow"
import "./Table.scss"
import {PartialRecord} from "../../lib/TypeUtils";

export type Field<T> = keyof T

export type ValueOf<T> = T[keyof T];

export enum CellVisibilityRule{
  FOR_PHONE_ONLY          = "FOR_PHONE_ONLY",
  FOR_TABLET_PORTRAIT_UP  ="FOR_TABLET_PORTRAIT_UP",
  FOR_TABLET_LANDSCAPE_UP = "FOR_TABLET_LANDSCAPE_UP",
  FOR_DESKTOP_UP          = "FOR_DESKTOP_UP",
  FOR_BIG_DESKTOP_UP      = "FOR_BIG_DESKTOP_UP",
}

export interface Header<T> {
  name: ReactNode
  field: Field<T>
  visibility?:CellVisibilityRule
}

interface Props<T> {
  onClick?: (i) => void
  headers: Header<T>[]
  items: T[]
  itemsRender?: PartialRecord<Field<T>, (value: ValueOf<T>, item: T) => ReactNode>
}

export default class Table<T = object> extends Component<Props<T>> {
  render() {
    let {items, headers, itemsRender} = this.props;

    return <div>
      <table className="Table">
        <thead>
        <tr>
          {headers.map((header, i) => <th key={i} className={getHidingClassFor(header.visibility)}>{header.name}</th>)}
        </tr>
        </thead>
        <tbody>
        {items.map((item, i) =>
          <TableRow<T> key={i} itemsRender={itemsRender} headers={headers} item={item}
                       onClick={(i) => this.props.onClick ? this.props.onClick(i) : null}/>
        )}
        </tbody>
      </table>
    </div>
  }
}

export function getHidingClassFor(visibility:CellVisibilityRule){
  switch (visibility){
    case CellVisibilityRule.FOR_PHONE_ONLY:
      return "show-cell-phone-only"
    case CellVisibilityRule.FOR_TABLET_PORTRAIT_UP:
      return "hide-cell-below-tablet-portrait"
    case CellVisibilityRule.FOR_TABLET_LANDSCAPE_UP:
      return "hide-cell-below-tablet-landscape"
    case CellVisibilityRule.FOR_DESKTOP_UP:
      return "hide-cell-below-desktop"
    case CellVisibilityRule.FOR_BIG_DESKTOP_UP:
      return "hide-cell-below-big-desktop"
    default:
      return  ""
  }
}


