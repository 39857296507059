import AuthorizeApi from "../AuthorizeApi";
import {ElapsedNeedResponse} from "../entity/response/ElapsedNeedResponse";
import {ReadTimeRequest} from "../entity/request/ReadTimeRequest";
import {OverloadedNeedResponse} from "../entity/response/OverloadedNeedResponse";

export default class OverloadedNeedApi {

  private base: string = '/overloaded-need';
  private authApi: AuthorizeApi = new AuthorizeApi();

  public async searchByDate(date: string = null, count : number, search: string,
                            skip: number, size: number, circuit: string) {
    const req : ReadTimeRequest = {
      count: count,
      date: date,
      searchKey: search,
      circuit: circuit,
      skip: skip,
      size: size
    }
    return await this.authApi.post <ReadTimeRequest, OverloadedNeedResponse>(`${this.base}`, req);
  }

}
