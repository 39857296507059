import {WorkerDto} from "../../api/entity/WorkerDto";
import {DateTime} from "luxon";
import {WorkerModal, WorkerModalErrors} from "../../components/Modal/WorkerModal/WorkerModal";
import {WorkerContractDto} from "../../api/entity/WorkerContractDto";
import {WorkerContractModalErrors} from "../../components/Modal/WorkerContractModal/WorkerContractModal";
import ShiftUtils from "../../utils/ShiftUtils";
import {Worker} from "../DailyPlanning/DailyWorkersTimeLine/DailyWorkersTimeLine";

export default class WorkersRegistryUtils {

  private shiftUtils = new ShiftUtils();

  public getEmptyWorkerDto(): WorkerDto {
    return {
      id: 0, lastname: "", name: "", height: "", cf: "", birthdate: DateTime.now(), firstContract: null,
      endContract: null, circuit: this.shiftUtils.CIRCUIT_DTO[0], role: this.shiftUtils.ROLE_DTO[0], moss: false,
      skill: this.shiftUtils.SERVICES_DTO[3], active: false,
      company: WorkerModal.companies[0], creationDate: DateTime.now(), tutoring: this.shiftUtils.TUTORING_DTO[0],
      tutoringId: 0, tutoredFullName: "", tutoredId: 0, incomplete: false,
      tutoringFullName: "",
      gender: "F",
      flagNottiConsecutive: 0,
      numNottiConsecutive: 2
    }
  }

  public getEmptyErrorState(): WorkerModalErrors {
    return {cf: "", lastname: "", name: "", birthdate: ""}
  }

  public getEmptyWorkerContractDto(idWorker: number): WorkerContractDto {
    return {
      id: 0, idWorker: idWorker, startDate: DateTime.now(), endDate: DateTime.local(2099,12,31),
      weekHour: 38, contractType: "", oscillation: 8, overrun: 10
    }
  }

  public getEmptyContractErrorState(): WorkerContractModalErrors {
    return {contract: "", startDate: "", endDate: "", weekHour: ""}
  }

  public getEmptyWorker(): Worker {
    return {
      fullName: "",
      id: null,
      role: "",
      skill: "",
      manuallyCreated: true
    }
  }

}
