import React, {PureComponent} from "react";
import "./Toast.scss";
import IconToastSuccess from "../../icons/IconToastSuccess";
import IconToastError from "../../icons/IconToastError";
import I18n from "../../lib/I18n";
import Button from "../Button/Button";

interface State {
  show: boolean;
}

interface Props {
  message: string,
  errors: string[],
  isError: boolean,
  visible: boolean,
  trigger: () => void
}

export class Toast extends PureComponent<Props, State> {

  private readonly MS = 5000;

  private updateTimer;

  /************************************************
   * PROPS
   ************************************************/
  static defaultProps: Partial<Props> = {
    message: "",
    errors: [],
    isError: false,
    trigger: () => {
    }
  };

  /************************************************
   * CONSTRUCTOR
   ************************************************/
  constructor(props: Props) {
    super(props);
    this.state = {show: false};
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (prevProps.visible == false && this.props.visible == true) {
      this.setState({show: true});
      this.notify();
    }
  }

  notify() {
    clearTimeout(this.updateTimer);
    this.updateTimer = setTimeout(() => {
      this.setState({show: false})
      this.props.trigger();
    }, this.MS);
  }

  private isPlural(): boolean {
    return this.props.errors.length > 1;
  }

  private triggerAndHide() {
    this.props.trigger();
    this.setState({show: false})
  }

  /************************************************
   * LIFECYCLE
   ************************************************/
  render() {
    return (
      <div
        className={"Toast " + (this.props.isError ? "error" : "success") + " " + ((this.state.show == false) ? "invisible" : "")}>
        <div className={"inner"}>
          <div>
            {this.props.isError ? <IconToastError/> : <IconToastSuccess/>}
          </div>
          <div>
            {this.props.isError ?
              <span>
                <p>
                  <span
                    className={"count-error"}>{this.props.errors.length} {this.isPlural() ? I18n.get().Toast.errors : I18n.get().Toast.error}</span>
                  <span> </span>
                  <span>{this.props.message}</span>
                </p>

              </span> :
              <span className={"success-text"}>
                {this.props.message}
              </span>
            }
          </div>
          <div>
            {this.props.isError ?
              <span>
                <Button
                  className={"error"} flat={true}>
                  {I18n.get().Toast.show} {this.isPlural() ? I18n.get().Toast.errors : I18n.get().Toast.error}</Button>
              </span> :
              <span onClick={() => {
                this.triggerAndHide();
              }}>
                ✕
              </span>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default Toast;
