import {
  MonthlyUnavailabilityError,
  MonthlyUnavailabilityErrorEntry
} from "../../../components/Modal/MonthlyUnavailabilityPlanningModal/MonthlyUnavailabilityPlanningModal";
import {MonthlyUnavailabilityRowDto} from "../../entity/MonthlyUnavailabilityRowDto";
import GfDateUtils from "../../../components/DatePicker/GfDateUtils";
import {DateTime} from "luxon";

export default class MonthlyPlanningValidator {

  private static regexTime = "^[0-9]{2}:[0-9]{2}$";

  public static validate(targetDate: DateTime, monthlyUnavailability: MonthlyUnavailabilityRowDto): MonthlyUnavailabilityError {
    let errors: MonthlyUnavailabilityError = {entryError: []}

    monthlyUnavailability.entries.forEach((entry, i) => {
        // initialize
        let error: MonthlyUnavailabilityErrorEntry = { date: "", time: ""};


        console.log(entry.date);
        if(entry.date.month != targetDate.month) {
          error.date = "Date issue";
        }

        if (!new RegExp(this.regexTime).test(monthlyUnavailability.entries[i].fromtime) ||
          !new RegExp(this.regexTime).test(monthlyUnavailability.entries[i].totime) ||
          GfDateUtils.convertHHMMToNumber(monthlyUnavailability.entries[i].fromtime) > GfDateUtils.timeBoundaryAsInt ||
          GfDateUtils.convertHHMMToNumber(monthlyUnavailability.entries[i].totime) > GfDateUtils.timeBoundaryAsInt) {
          error.time = "Time issue";
        }
        errors.entryError.push(error);
      }
    );
    return errors;
  }

  public static isValid(mer: MonthlyUnavailabilityError) : boolean {
    let monthlyUnavailabilityErrorEntries = mer.entryError.filter(a => a.time != "" || a.date != "");
    return monthlyUnavailabilityErrorEntries.length == 0;
  }
}
