import React, {Component, PureComponent} from "react";
import "./WeekToggleGroup.scss";
import {ToggleDayButton} from "./ToggleDayButton/ToggleDayButton";
import TimeInputPicker from "../TimeInputPicker/TimeInputPicker";
import GfDateUtils from "../DatePicker/GfDateUtils";


export interface Props {
  /** This defines additional classes for the Button */
  className?: string,
  label?: string,
  /** This dictates what the button will do on click */
  elements: string[],
  indexesOffset?: number,
  selectedIndexes: number[],
  fromTime: string;
  toTime: string;
  index: number
  onToggle: (state) => void;
  onError?: () => void;
  onBlur?: (index, fromTime, toTime, selectedIndexes, error) => void;
}

interface State {
  values: ValuesError[],
  selectedDaysIndexes: number[],
  isTimeError: boolean
}

interface ValuesError {
  value: string,
  error: boolean
}

export class WeekToggleGroup extends Component<Props, State> {

  /************************************************
   * PROPS
   ************************************************/
  static defaultProps: Partial<Props> = {
    onBlur: () => {
    },
    className: "",
    elements: [],
    selectedIndexes: [],
    indexesOffset: 0,
  };

  /************************************************
   * CONSTRUCTOR
   ************************************************/
  constructor(props: Props) {
    super(props);
    this.state = {
      values: [{error: false, value: this.props.fromTime}, {error: false, value: this.props.toTime}],
      isTimeError: false,
      selectedDaysIndexes: this.props.selectedIndexes
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (prevProps != this.props)
      this.setState({
        values: [{error: false, value: this.props.fromTime}, {error: false, value: this.props.toTime}],
        isTimeError: false,
        selectedDaysIndexes: this.props.selectedIndexes
      });
  }

  private setTimeValue(index, value) {
    this.state.values[index].value = value;
    let isTimeRangeError = this.isTimeRangeError();
    this.setState({isTimeError: isTimeRangeError});
    this.signalChange();
  }

  private signalChange() {
    this.props.onBlur(this.props.index, this.state.values[0].value,
      this.state.values[1].value, this.state.selectedDaysIndexes,
      this.isErrorState())
  }

  private isErrorState() {
    return this.state.values.filter(value => value.error).length > 0;
  }

  private onTimeFormatChange(index: number, isError: boolean) {
    this.state.values[index].error = isError;
    this.setState(this.state);
    this.signalChange();
  }

  private isTimeRangeError(): boolean {
    const fromValue = this.state.values[0].value;
    const toValue = this.state.values[1].value;
    // Elect the time, based on the chance of having 24:00 to be equal to 00:00 - simply more readable.
    let toTimeAsInt = GfDateUtils.convertHHMMToNumber(toValue);
    let electedToTimeAsInt = toTimeAsInt == 0 ? GfDateUtils.timeBoundaryAsInt : toTimeAsInt;
    return electedToTimeAsInt - GfDateUtils.convertHHMMToNumber(fromValue) < 0;
  }

  private onToggleDay(index: number) {
    let indexOfToggledDayIndex = this.state.selectedDaysIndexes.indexOf(index);
    if (indexOfToggledDayIndex != -1) {
      this.state.selectedDaysIndexes.splice(indexOfToggledDayIndex, 1);
    } else {
      this.state.selectedDaysIndexes.push(index);
    }
    this.signalChange();
  }

  /************************************************
   * LIFECYCLE
   ************************************************/
  render() {

    return (
      <div
        className={"WeekToggleGroup " + this.props.className}>
        <div className={"column"}>
          {
            this.props.elements.map((dayOfWeek, index) => {
              return <ToggleDayButton className={"row-btn"}
                                      label={dayOfWeek}
                                      isSelected={this.props.selectedIndexes.indexOf(index + this.props.indexesOffset) != -1}
                                      key={index} index={index + this.props.indexesOffset}
                                      onToggle={(index) => this.onToggleDay(index)}/>
            })
          }
        </div>
        <div className={"column-time-range"}>
          <div className={"dist"}>
            <TimeInputPicker defaultValue={this.props.fromTime}
                             error={this.state.isTimeError}
                             onError={() => this.onTimeFormatChange(0, true)}
                             onCorrectChange={() => this.onTimeFormatChange(0, false)}
                             onBlur={(value) => this.setTimeValue(0, value)}/>
          </div>
          <div className={"dist"}>
            <TimeInputPicker defaultValue={this.props.toTime}
                             error={this.state.isTimeError}
                             isToTime={true}
                             onError={() => this.onTimeFormatChange(1, true)}
                             onCorrectChange={() => this.onTimeFormatChange(1, false)}
                             onBlur={(value) => this.setTimeValue(1, value)}/>
          </div>
        </div>
      </div>
    )
  }
}
