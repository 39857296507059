import React from "react";
import Button from "../../../../components/Button/Button";
import I18n from "../../../../lib/I18n";
import "./WizardStepZero.scss"

interface Props {
  goToStepOne: () => void
}

export default function WizardStepZero(props: Props) {
  let labels = I18n.get().MonthlyTimeLine;
  return <div className="WizardStepZero" >
          <div className="WizardStepZero-wrapper">
            <div className="WizardStepZero-title">
              {labels.monthHasNotBeenPlanned}
            </div>
            <div className="WizardStepZero-subtext">
              {labels.clickPlanToPlanTheMonth}
            </div>
            <Button className="WizardStepZero-button" onClick={() => props.goToStepOne()}>
              {labels.plan}
            </Button>
          </div>
    </div>
}
