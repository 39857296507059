import Page from "../Page";
import './RadioCodeRegistry.scss';
import React from "react";
import {FocusPanel} from "../../components/FocusPanel/FocusPanel";
import I18n from "../../lib/I18n";
import Table, {CellVisibilityRule, Header} from "../../components/Table/Table";
import {Pagination} from "../../components/Table/Pagination";
import SearchInput from "../../components/SearchInput/SearchInput";
import Toast from "../../components/Toast/Toast";
import ContainerRadioVehicleDailyApi from "../../api/container-vehicle/ContainerRadioVehicleDailyApi";
import {ContainerVehicleEntryDto} from "../../api/entity/ContainerVehicleEntryDto";
import Select from "../../components/Select/Select";
import ShiftUtils from "../../utils/ShiftUtils";
import IconPencil from "../../icons/IconPencil";
import {RadioRegistryModal} from "../../components/Modal/RadioCodeModal/RadioRegistryModal";
import RadioCodeApi from "../../api/radio/RadioCodeApi";
import {RadioCodeDto} from "../../api/entity/RadioCodeDto";
import Spinner from "../../components/Spinner/Spinner";


interface TableEntry {
  serviceName: string,
  containerVehicle: string,
  category: string,
  from: string,
  to: string,
  circuit: string,
  publishedBy: string,
  radioCode: string
  modifyBtn: any
}

interface TableInt {
  headers: Header<TableEntry>[];
  items: TableEntry[];
  currentSlice: number;
}

interface State {
  filterIndex: number
  circuitFilter: string,
  searchKey: string
  registryCount: number
  isCreateModifyOpen: boolean
  // modal: {
  //   createModify: ModalType,
  //   vehicle: VehicleDto
  // }
  containerVehicleDto: ContainerVehicleEntryDto[],
  modal: {
    selected: {
      title: string,
      radioCodeId: number,
      containerVehicle: string
    },
    radioCodes: RadioCodeDto[]
  }
  toast: {
    isError: boolean;
    errorList: string[];
    message: string;
  },
  isToastOpen: boolean;
  table: TableInt
  selectedId: number
  isSideBarVisible: boolean
  loading: boolean;
}

export default class RadioCodeRegistry extends Page<{}, State> {

  private readonly elementsPerPage = 25;

  private containerVehicleDailyApi = new ContainerRadioVehicleDailyApi();
  private radioCodeApi: RadioCodeApi = new RadioCodeApi();
  private shiftUtils = new ShiftUtils();

  private circuitFilters = [I18n.get().RadioCodeRegistry.circuitFilter.all].concat(this.shiftUtils.CIRCUIT_DTO);

  public constructor(props) {
    super(props);
    this.state = {
      filterIndex: 0,
      isToastOpen: false,
      searchKey: "",
      circuitFilter: I18n.get().RadioCodeRegistry.circuitFilter.all,
      registryCount: 1,
      isCreateModifyOpen: false,
      modal: {
        selected:
          {
            title: "",
            containerVehicle: "",
            radioCodeId: 0
          },
        radioCodes: []
      },
      toast: {
        isError: false,
        errorList: [],
        message: ""
      },
      table: {
        currentSlice: 0,
        items: [],
        headers: []
      },
      selectedId: null,
      isSideBarVisible: false,
      containerVehicleDto: [],
      loading: true
    }
  }

  async componentDidMount() {
    try {
      await this.reloadTable();
      this.setState({loading: false})
    } catch {
      this.setState({loading: false})
    }
  }

  private async reloadTable(slice = 0) {
    const searchKey = this.state.searchKey == null ? "" : this.state.searchKey;
    const skip = this.elementsPerPage * (slice);
    const containerVehicleResponse = await this.containerVehicleDailyApi.getContainerVehicleEntry(
      searchKey,
      this.state.circuitFilter,
      this.elementsPerPage,
      skip);

    this.setState({
        containerVehicleDto: containerVehicleResponse.content,
        registryCount: containerVehicleResponse.total
      },
      () => {
        this.updateTable(containerVehicleResponse.content)
      });
  }

  private toggleCreateModifyModal() {
    let {isCreateModifyOpen} = this.state;
    isCreateModifyOpen = !isCreateModifyOpen;
    this.setState({isCreateModifyOpen});
  }

  private openModify(e: ContainerVehicleEntryDto) {
    this.radioCodeApi.getRadioCodes(0, Number.MAX_SAFE_INTEGER)
      .then((radios) => {
        this.setState({
          modal: {
            selected: {
              title: e.containerVehicle + " - " + e.vehicleCategory +
                " - " + e.from + "-" + e.to + " - " + e.publishing,
              containerVehicle: e.containerVehicle,
              radioCodeId: e.radioCodeId
            },
            // Refresh radio codes
            radioCodes: radios.content
          }
        });
        this.toggleCreateModifyModal();
      })
  }

  private async onPaginationChange(page: number) {
    let {currentSlice} = this.state.table;
    currentSlice = page;
    this.setState({table: {...this.state.table, currentSlice}}, () => {
      this.reloadTable(page);
    });
  }

  private updateTable(containerVehicleEntries: ContainerVehicleEntryDto[]) {
    let openModify = (e) => {
      this.openModify(e)
    }
    const items: TableEntry[] = containerVehicleEntries.map(function (e) {
      return {
        radioCode: e.radioCode,
        circuit: e.circuit,
        from: e.from,
        to: e.to,
        serviceName: e.need,
        category: e.vehicleCategory,
        publishedBy: e.publishing,
        containerVehicle: e.containerVehicle,
        modifyBtn: <div onClick={() =>
          openModify(e)
        }>
          <IconPencil width={24} height={24}/></div>
      };
    });
    this.setState({
      table: {
        ...this.state.table,
        headers: [
          {
            name: I18n.get().RadioCodeRegistry.table.service,
            field: "serviceName"
          },
          {
            name: I18n.get().RadioCodeRegistry.table.containerVehicle,
            field: "containerVehicle"
          },
          {
            name: I18n.get().RadioCodeRegistry.table.typology,
            field: "category",
            visibility: CellVisibilityRule.FOR_TABLET_LANDSCAPE_UP
          },
          {
            name: I18n.get().RadioCodeRegistry.table.from,
            field: "from",
            visibility: CellVisibilityRule.FOR_TABLET_LANDSCAPE_UP
          },
          {
            name: I18n.get().RadioCodeRegistry.table.to,
            field: "to",
            visibility: CellVisibilityRule.FOR_TABLET_LANDSCAPE_UP
          },
          {
            name: I18n.get().RadioCodeRegistry.table.circuit,
            field: "circuit",
            visibility: CellVisibilityRule.FOR_TABLET_PORTRAIT_UP
          }, {
            name: I18n.get().RadioCodeRegistry.table.publishedBy,
            field: "publishedBy",
            visibility: CellVisibilityRule.FOR_DESKTOP_UP
          },
          {
            name: I18n.get().RadioCodeRegistry.table.radioCode,
            field: "radioCode"
          },
          {
            name: "",
            field: "modifyBtn"
          }
        ], items:
        items
      }
    });
  }

  private async onSearch(value: string) {
    let {circuitFilter} = this.state;
    this.setState({searchKey: value, table: {...this.state.table, currentSlice: 0}},
      async () => {
        let [response] = await Promise.all([this.containerVehicleDailyApi.getContainerVehicleEntry(value, circuitFilter,
          this.elementsPerPage, this.elementsPerPage * (this.state.table.currentSlice))]);
        this.setState({registryCount: response.total});
        this.resetTable();
        this.updateTable(response.content);
      })
  }

  private resetTable() {
    this.state.table.currentSlice = 0;
  }

  private closeToast() {
    this.setState({isToastOpen: false});
  }

  private onSetCircuitFilter(index: number) {
    this.setState({
      circuitFilter: this.circuitFilters[index],
      filterIndex: index,
      table: {
        ...this.state.table,
        currentSlice: 0
      }
    }, () => {
      this.reloadTable(0);
    })
  }

  private onChange(containerVehicle: string, radioCodeId: number) {
    this.containerVehicleDailyApi
      .updateRadioIdForContainerVehicle(containerVehicle, radioCodeId)
      .then(() => {
        this.reloadTable(this.state.table.currentSlice);
        this.toggleCreateModifyModal();
      });
  }

  render() {
    if (this.state.loading)
      return <Spinner/>
    return <div className={"RadioCodeRegistry"}>
      <FocusPanel show={this.state.isCreateModifyOpen}/>
      {this.state.isCreateModifyOpen ?
        <RadioRegistryModal
          selectedContainerVehicle={this.state.modal.selected.containerVehicle}
          onSubmit={(containerVehicle: string, radioCode: number) =>
            this.onChange(containerVehicle, radioCode)}
          onCancel={() => this.toggleCreateModifyModal()}
          selectedRadioCodeId={this.state.modal.selected.radioCodeId}
          radioCodes={this.state.modal.radioCodes}
          title={this.state.modal.selected.title}
        /> : null}

      <div className={"inner-content"}>
        <Toast isError={this.state.toast.isError}
               message={this.state.toast.message}
               errors={this.state.toast.errorList}
               visible={this.state.isToastOpen}
               trigger={() => this.closeToast()}/>
        <div className={"top"}>
          <div className={"up-title"}>
            <h1>{I18n.get().RadioCodeRegistry.title}</h1>
          </div>
          <div className={"up-controls"}>
            {/* Here up controls */}
          </div>

          <div className={"search-location"}>
            <Select
              defaultValue={this.state.filterIndex}
              className={"circuit-filter"}
              items={this.circuitFilters}
              onChange={(index) => {
                this.onSetCircuitFilter(index);
              }}/>
            <SearchInput className="search-input" label="" defaultValue=""
                         placeholder={I18n.get().RadioCodeRegistry.searchBy}
                         onChange={(value) => this.onSearch(value)}
                         readonly={false} disabled={false} password={false} errorMessage={""}/>
          </div>
          <div>
            <Table headers={this.state.table.headers}
                   items={this.state.table.items} onClick={(item) => {
            }}/>
            <Pagination
              page={this.state.table.currentSlice}
              onPageChange={(page) => this.onPaginationChange(page)}
              totalPages={this.state.registryCount}
              elementsPerPage={this.elementsPerPage}/>
          </div>

        </div>
      </div>
    </div>
  }
}
