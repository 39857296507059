import React, {Component} from "react";
import "./ViewSelector.scss"
import Button from "../../../components/Button/Button";
import I18n from "../../../lib/I18n";
import {ViewSelection} from "../TotemManagers";

interface Props {
  state: ViewSelection
  onSelect: (MonthlyCompletedView) => void;
}

export default class ViewSelector extends Component<Props, {}> {
  /**************************************************
   * CONSTRUCTOR
   **************************************************/
  constructor(props: Props) {
    super(props);
  }

  /**************************************************
   * LIFECYCLE
   **************************************************/
  public render() {
    return <div className="ViewSelector">
      <div className="ViewSelector-actions">
        <Button
          onClick={() => this.props.onSelect(ViewSelection.MANAGEMENT)}
          secondary={this.props.state != ViewSelection.MANAGEMENT}>
          {I18n.get().TotemManagers.viewSelectors.management}
        </Button>
        <Button
          onClick={() => this.props.onSelect(ViewSelection.HR)}
          secondary={this.props.state != ViewSelection.HR}>
          {I18n.get().TotemManagers.viewSelectors.hr}
        </Button>
        <Button
          onClick={() => this.props.onSelect(ViewSelection.NURSING)}
          secondary={this.props.state != ViewSelection.NURSING}>
          {I18n.get().TotemManagers.viewSelectors.nursing}
        </Button>
      </div>
    </div>;
  }
}
