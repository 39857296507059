import React, {Component, CSSProperties} from "react";
import TimeLineData from "../TimeLineData";
import Interval = TimeLineData.Interval
import "./TimeLineDataRow.scss"

interface Props {
  intervals: Interval[],
  segmentsNum: number
  spacing:boolean
  style:CSSProperties
}

export default class TimeLineDataRow extends Component<Props> {
  render() {
    const {intervals, spacing} = this.props
    const offset = (100 / this.props.segmentsNum);

    return <div className="TimeLineDataRow" style={this.props.style}>
      {intervals.map((interval,i) => {
        let style: CSSProperties = {}
        let start = offset * interval.start;

        let end = offset * (interval.end ? interval.end : interval.start + 1);
        let width = end - start;

        if (spacing) {
          const spacing = 10;
          style = {
            left: `calc(${start}% + ${spacing}px)`,
            width: `calc(${width}% - ${spacing * 2}px)`
          };
        } else {
          style = {
            left: `${start}%`,
            width: `${width}%`
          };
        }

        if (interval.height)
          style.height = interval.height
        if (interval.color)
          style.backgroundColor = interval.color

        if (interval.onClick)
          style.cursor = "pointer"

        // TABLE CELL
        return <div className="TimeLineDataRow-line" style={style} key={i}
                    onClick={interval.onClick ? ()=>interval.onClick(interval) : null} > {interval.content} </div>;
      })}
    </div>
  }
}
