import React, {Component} from "react";
import TimeLineNavigation from "../../../components/TimeLine/TimeLineNavigation/TimeLineNavigation";
import {DateTime} from "luxon";
import "./MonthlyNavigationBar.scss"
import Button from "../../../components/Button/Button";
import IconChevronCircleLeft from "../../../icons/IconChevronCircleLeft";
import I18n from "../../../lib/I18n";
import MonthlyPlanningNavigationLabel from "../MonthlyPlanningNavigationLabel";

interface Props {
  currentDate: DateTime
  onDateChanged: (date: DateTime) => void
}

interface State {
  isCalendarOpen: boolean
}

export default class MonthlyNavigationBar extends Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = {
      isCalendarOpen: false
    }
  }

  render() {
    const {currentDate, onDateChanged} = this.props;
    return <div className="MonthlyNavigationBar">

      <TimeLineNavigation
        navigationLabel={<MonthlyPlanningNavigationLabel
          currentDate={currentDate}/>}
        selectedDate={currentDate}
        onDateChange={date => onDateChanged(date)}
        prevPage={() => this.previousMonth()}
        nextPage={() => this.nextMonth()}
        granularity="month"
        maxDate={null}
      /></div>
  }

  public nextMonth() {
    const {currentDate} = this.props;
    this.props.onDateChanged(currentDate.plus({month: 1}))
  }

  public previousMonth() {
    const {currentDate} = this.props;
    this.props.onDateChanged(currentDate.minus({month: 1}))
  }
}

