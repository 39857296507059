import FixedShiftDtoNt from "./nt/FixedShiftDtoNt";

export class FixedShiftDto {

  id: number;
  userId: number;
  shiftId: number;
  shiftCode: string;
  days: number[];

  constructor(dtoNt: FixedShiftDtoNt) {
    this.id = dtoNt.id;
    this.userId = dtoNt.userId;
    this.shiftId = dtoNt.shiftId;
    this.shiftCode = dtoNt.shiftCode;
    this.days = dtoNt.days;
  }
}
