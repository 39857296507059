import React, {Component, ReactNode} from "react";
import "./TimeLineYAxis.scss"
import TimeLineData from "../TimeLineData";
import TimeLineMatrix = TimeLineData.TimeLineMatrix;

interface Props {
  timeLines:TimeLineMatrix[]
  headerHeight:number
  rowHeight:number
  height:number
  bufferCell:ReactNode
}

export class TimeLineYAxis extends Component<Props> {

  render() {
    const {height, rowHeight, headerHeight, timeLines, bufferCell} = this.props
    return <div className="TimeLineYAxis" style={{minHeight:height}}>
      <div className="TimeLineYAxis-buffer-cell" style={{height:headerHeight}}>
        {bufferCell}
      </div>
      {timeLines.map((timeLine, i) =>
        <div key={i} style={{height: rowHeight}} className="TimeLineYAxis-cell">{timeLine.title}</div>)}
    </div>
  }
}
