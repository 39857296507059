import React, {ReactNode} from "react";
import {CellVisibilityRule, getHidingClassFor} from "./Table";

interface Props{
  value:unknown
  row:unknown
  itemRender?: (value:unknown, row:unknown)=>ReactNode
  visibility:CellVisibilityRule
}

export default function TableCell (props:Props){
  const {itemRender,value,row,visibility} = props;
  return itemRender
    ? <td className={getHidingClassFor(visibility)}>{itemRender(value,row)}</td>
    : <td className={getHidingClassFor(visibility)}>{value}</td>
}
