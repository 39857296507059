import {I18nDefinitions} from "../languages/languages";
import URLUtils from "./utils/URLUtils";
import isEqual from 'lodash.isequal';
import {DateTime} from "luxon";

const i18n = config.i18n;

interface LocalConfig {
    lang?: string
    JSON?: I18nDefinitions,
}

export default class I18n {
    /************************************************
     * PUBLIC FUNCTIONS
     ************************************************/
    public static get(...args:(string|number)[]): I18nDefinitions {
      let config = this.getConfigFromLocalStorage();
      return I18n.safe(config ? config.JSON : {},...args);
    }

    public static setLanguage(lang: ISO639_1): Promise<I18nDefinitions> {
        return this.setConfig({lang}, false);
    }

    public static getLanguage(): "it"|"en" {
        let language:string = i18n.lang;
        const config = this.getConfigFromLocalStorage();
        if (config && config.lang)
            language = config.lang.substring(0, 2);
        else if (navigator.language && !(language == "it" || language == "en"))
            language = navigator.language.substring(0, 2);
        if (language != "it" && language != "en") // only italian and english support
            return "en";
        else
            return language;
    }


    public static setConfig(userConfig, skipRequest?): Promise<I18nDefinitions> {
        return new Promise((resolve, reject) => {
            const oldConfig = this.getConfigFromLocalStorage()? this.getConfigFromLocalStorage() : {};
            const config = {...oldConfig, ...userConfig};
            this.setConfigToLocalStorage(config);

            if (config.lang === oldConfig.lang && config.JSON && config.lang === config.JSON.lang)
                resolve(config.JSON);

            if (skipRequest)
                return;

            this.HTTPGetLanguage(config.lang)
                .then((response: string) => {
                    const newJson: I18nDefinitions = JSON.parse(response);
                    if (!config.JSON || !isEqual(config.JSON, newJson)) {
                        config.JSON = newJson;
                        this.setConfigToLocalStorage(config);
                    }
                    resolve(newJson);
                })
                .catch(error => reject(error));
        });
    }

  public static printDateTime(date: DateTime) {
    return `${this.printDate(date)} - ${this.printTime(date)}`;
  }

  public static printTime(date: DateTime) {
    if (!date)
      return "";
    return date.toLocaleString({hour: "2-digit", minute: "2-digit"});
  }

  public static printDate(date: DateTime, format?: string) {
    if (!date)
      return "";
    return format ?
      date.toFormat(format, {locale: I18n.getConfigFromLocalStorage().lang}) :
      date.toLocaleString( {locale: I18n.getConfigFromLocalStorage().lang});
  }

    /************************************************
     * PRIVATE FUNCTIONS
     ************************************************/
    private static safe(obj,...args:(string|number)[]): I18nDefinitions{
        return new Proxy<I18nDefinitions>(obj, {
            get: (target, name) => {
                 if(target[name] instanceof Object)
                    return I18n.safe(target[name],...args);
                 else if(typeof target[name] === "string")
                    return I18n.replaceVariablesInLabel(target[name],...args);
                else {
                   target[name] = I18n.safe(obj, ...args)
                   return target[name];
                 }
            }
        });
    }

    private static HTTPGetLanguage(lang: ISO639_1): Promise<string> {
        return new Promise((resolve, reject) => {

            const xmlHttp = new XMLHttpRequest();
            xmlHttp.onreadystatechange = event => {
                if (xmlHttp.readyState === 4) {
                    if (xmlHttp.status < 300)
                        resolve(xmlHttp.responseText);
                    else
                        reject(xmlHttp.status)
                }
            };
            // xmlHttp.open('GET', `${this.getConfigFromLocalStorage().url + lang}.json`, true); // true for asynchronous
            let lang:string = i18n.lang;
            let config = this.getConfigFromLocalStorage();
            xmlHttp.open('GET', `${URLUtils.getBaseURL()}/lang/${config ? config.lang : lang}.json`, true); // true for asynchronous
            xmlHttp.send(null);
        });
    }



    private static getConfigFromLocalStorage(): LocalConfig {
        try {
            const config = localStorage.getItem("_I18N_config");
            if (!config)
                return null;
            return JSON.parse(localStorage.getItem("_I18N_config"));
        } catch (e) {
            return null;
        }
    }

    private static setConfigToLocalStorage(config) {
        localStorage.setItem("_I18N_config", JSON.stringify(config));
    }

    private static replaceVariablesInLabel(label:string, ...args:(string|number)[]) {
        let result = label;
        for (let i = 0; i < args.length; i++)
            result = result.replace(`{${i}}`, String(args[i]));
        return result;
    }
}

type ISO639_1 = "aa" | "ab" | "af" | "ak" | "sq" | "am" | "ar" | "an" | "hy" | "as" | "av" | "ae" |
    "ay" | "az" | "ba" | "bm" | "eu" | "be" | "bn" | "bh" | "bi" | "bs" | "br" | "bg" |
    "my" | "ca" | "ch" | "ce" | "zh" | "cu" | "cv" | "kw" | "co" | "cr" | "cs" | "da" |
    "dv" | "nl" | "dz" | "en" | "eo" | "et" | "ee" | "fo" | "fj" | "fi" | "fr" | "fy" |
    "ff" | "ka" | "de" | "gd" | "ga" | "gl" | "gv" | "el" | "gn" | "gu" | "ht" | "ha" |
    "he" | "hz" | "hi" | "ho" | "hr" | "hu" | "ig" | "is" | "io" | "ii" | "iu" | "ie" |
    "ia" | "id" | "ik" | "it" | "jv" | "ja" | "kl" | "kn" | "ks" | "kr" | "kk" | "km" |
    "ki" | "rw" | "ky" | "kv" | "kg" | "ko" | "kj" | "ku" | "lo" | "la" | "lv" | "li" |
    "ln" | "lt" | "lb" | "lu" | "lg" | "mk" | "mh" | "ml" | "mi" | "mr" | "ms" | "mg" |
    "mt" | "mn" | "na" | "nv" | "nr" | "nd" | "ng" | "ne" | "nn" | "nb" | "no" | "ny" |
    "oc" | "oj" | "or" | "om" | "os" | "pa" | "fa" | "pi" | "pl" | "pt" | "ps" | "qu" |
    "rm" | "ro" | "rn" | "ru" | "sg" | "sa" | "si" | "sk" | "sl" | "se" | "sm" | "sn" |
    "sd" | "so" | "st" | "es" | "sc" | "sr" | "ss" | "su" | "sw" | "sv" | "ty" | "ta" |
    "tt" | "te" | "tg" | "tl" | "th" | "bo" | "ti" | "to" | "tn" | "ts" | "tk" | "tr" |
    "tw" | "ug" | "uk" | "ur" | "uz" | "ve" | "vi" | "vo" | "cy" | "wa" | "wo" | "xh" |
    "yi" | "yo" | "za" | "zu";
