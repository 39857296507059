import React, {Component} from "react";
import "./MonthlyPlanningUnavailabilityTable.scss";
import I18n from "../../../lib/I18n";
import Table, {Header} from "../../Table/Table";
import {MonthlyUnavailabilityRowDto} from "../../../api/entity/MonthlyUnavailabilityRowDto";
import IconPencil from "../../../icons/IconPencil";
import {WorkerDto} from "../../../api/entity/WorkerDto";
import {Menu, MenuButton, MenuItem, MenuList} from "@reach/menu-button";
import GfDateUtils from "../../DatePicker/GfDateUtils";

export interface Props {
  /** This defines additional classes for the Button */
  className: string,
  /** Disables onclick
   *  @default false
   **/
  monthlyPlannedUnavailabilities: MonthlyUnavailabilityRowDto[];
  workers: WorkerDto[];
  onEdit: (unavailabilityId: number) => void;
  onDelete: (unavailabilityId: number) => void;
  isVisible: boolean;
}

interface State {
  table: TableData
}

interface TableEntry {
  name: string,
  formattedDate: string,
  startHour: string,
  endHour: string,
  edit: number,
  workerId: number
}

export interface TableData {
  headers: Header<TableEntry>[],
  items: any
}

export class MonthlyPlanningUnavailabilityTable extends Component<Props, State> {

  /************************************************
   * PROPS
   ************************************************/
  static defaultProps: Partial<Props> = {
    className: "",
  };

  // @ts-ignore
  private itemsRender: Partial<Record<TableEntry, (value: any) => React.ReactNode>> = {
    name: (value) => <div>{value}</div>,
    formattedDate: (value) => <div>{value}</div>,
    startHour: (value) => <div>{value}</div>,
    endHour: (value) => <div>{value}</div>,
    edit: (value) =>
      <Menu>
        {({isExpanded}) => (
          <React.Fragment>
            <MenuButton>
              <img src={"assets/menu-vertical-" + (isExpanded ? "open" : "close") + ".svg"} alt={"menu"}/>
            </MenuButton>
            <MenuList className={"slide-down"}>
              <MenuItem className={"itemMenu"}
                        onSelect={() => this.onUnavailabilityEditClick(value)}>{I18n.get().ActionButton.modify}</MenuItem>
              <MenuItem className={"itemMenu"}
                        onSelect={() => this.onUnavailabilityDeleteClick(value)}>{I18n.get().ActionButton.delete}</MenuItem>
            </MenuList>
          </React.Fragment>
        )}
      </Menu>
  };


  /************************************************
   * CONSTRUCTOR
   ************************************************/
  constructor(props: Props) {
    super(props);
    this.state = {
      table: {headers: [], items: []}
    }
  }

  componentDidMount() {
    this.populateTable();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (this.props.monthlyPlannedUnavailabilities != prevProps.monthlyPlannedUnavailabilities) {
      this.populateTable();
    }
  }

  private findNameFromId(id: number){
    return this.props.workers.filter(w=>w.id == id)[0];
  }

  private populateTable() {

    let tableEntries: TableEntry[] = [];

    this.props
      .monthlyPlannedUnavailabilities.forEach(
      mpu => {
        let wId = this.findNameFromId(mpu.workerId);
        return mpu.entries.forEach(
          entry => tableEntries.push(
            {
              name: `${wId.lastname} ${wId.name}`,
              formattedDate: I18n.printDate(entry.date, GfDateUtils.DATE_FORMAT),
              startHour: entry.fromtime,
              endHour: entry.totime == "00:00" ? "24:00" : entry.totime,
              edit: entry.id,
              workerId: mpu.workerId
            }
          )
        );
      }
    );
    this.setState({
        table: {
          headers: [
            {
              name: I18n.get().MonthlyTimeLine.table.name,
              field: "name"
            },
            {
              name: I18n.get().MonthlyTimeLine.table.date,
              field: "formattedDate"
            },
            {
              name: I18n.get().MonthlyTimeLine.table.startHour,
              field: "startHour"
            },
            {
              name: I18n.get().MonthlyTimeLine.table.endHour,
              field: "endHour"
            },
            {
              name: "",
              field: "edit"
            }
          ], items: tableEntries
        }
      }
    )
  }

  onUnavailabilityEditClick(unavailabilityId: number) {
    this.props.onEdit(unavailabilityId);
  }

  onUnavailabilityDeleteClick(unavailabilityId: number) {
    this.props.onDelete(unavailabilityId);
  }

  /************************************************
   * LIFECYCLE
   ************************************************/
  render() {
    return (
      <div className={"MonthlyPlanningUnavailabilityTable " + this.props.className}>
        <div>
          <Table headers={this.state.table.headers}
                 itemsRender={this.itemsRender}
                 items={this.state.table.items} onClick={()=>console.log("")}/>
        </div>
      </div>
    )
  }
}
