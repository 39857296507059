import React, {Component} from "react";
import "./MonthlyEmployeeManualSettingsLine.scss"
import IconSettings from "../../../../icons/IconSettings";
import {TimeOffTypeEnum} from "../../../../api/timeOff/TimeOffApi";

interface Props {
  turn: {value:string, label:string},
  onEdit: () => void
  editMode:boolean
  hideEmpty:boolean
}

export default class MonthlyEmployeeManualSettingsLine extends Component<Props>{

  constructor(props:Props) {
    super(props)
  }

  render() {
    const {onEdit, turn, editMode, hideEmpty} = this.props
    const timeOffClass = this.getTimeOffType()
    if (editMode)
      return <div className={"MonthlyEmployeeManualSettingsLine" + (timeOffClass)}>
        {turn.label}{onEdit && <span className={"actionable"} onClick={() => onEdit()}>
        {/*<IconSettings/>*/}
          &#9881;
        </span>}
      </div>
    else
      return <div onClick={() => onEdit()}
                  className={"MonthlyEmployeeManualSettingsLine onEdit " + (timeOffClass)}>
        {(!hideEmpty && turn.label == "") ? "+" : turn.label}
      </div>
  }

  private getTimeOffType() {
    switch(this.props.turn.value) {
      case TimeOffTypeEnum.F: return "timeOff-f"
      case TimeOffTypeEnum.AR: return "timeOff-ar"
      case TimeOffTypeEnum.GR: return "timeOff-gr"
      default: return ""
    }
  }
}
