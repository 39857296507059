import AuthorizeApi from "../AuthorizeApi";
import {VehicleUnavailabilityResponse} from "../entity/response/VehicleUnavailabilityResponse";
import {VehicleUnavailabilityResponseNt} from "../entity/response/nt/VehicleUnavailabilityResponseNt";
import {DateTime} from "luxon";
import GfDateUtils from "../../components/DatePicker/GfDateUtils";
import {VehicleUnavailabilityDto} from "../entity/VehicleUnavailabilityDto";
import {VehicleUnavailabilityNt} from "../entity/nt/VehicleUnavailabilityNt";

export default class VehicleUnavailabilityApi {

  private base: string = '/vehicle-unavailability';

  private authApi: AuthorizeApi = new AuthorizeApi();

  public async getVehicleById(id: number) {
    let response: VehicleUnavailabilityResponse;
    await this.authApi.get<VehicleUnavailabilityResponseNt>(`${this.base}/` + id).then((resp) => {
      response = new VehicleUnavailabilityResponse(resp)
    });
    return response;
  }

  public async getVehicleInMonth(date: DateTime) {
    let response: VehicleUnavailabilityResponse;
    await this.authApi.get<VehicleUnavailabilityResponseNt>(`${this.base}/month/` + date.toFormat(GfDateUtils.STORED_DATE_FORMAT))
      .then((resp) => {
        response = new VehicleUnavailabilityResponse(resp)
      });
    return response;
  }

  public async getVehicleInDay(date: DateTime) {
    let response: VehicleUnavailabilityResponse;
    await this.authApi.get<VehicleUnavailabilityResponseNt>(`${this.base}/day/` + date.toFormat(GfDateUtils.STORED_DATE_FORMAT))
      .then((resp) => {
        response = new VehicleUnavailabilityResponse(resp)
      });
    return response;
  }

  public async delete(id: number) {
    let response: VehicleUnavailabilityResponse;
    await this.authApi.delete<void, VehicleUnavailabilityResponseNt>(`${this.base}/` + id)
      .then((resp) => {
        response = new VehicleUnavailabilityResponse(resp)
      });
    return response;
  }

  public async create(vehicle: VehicleUnavailabilityDto) {
    let response: VehicleUnavailabilityResponse;
    let vehicleUnNt : VehicleUnavailabilityNt = new VehicleUnavailabilityNt(vehicle);
    this.authApi.post<VehicleUnavailabilityNt, VehicleUnavailabilityResponseNt>(`${this.base}`, vehicleUnNt).then((resp) => {
      response = new VehicleUnavailabilityResponse(resp);
    })
    return response;
  }

  public async update(vehicle: VehicleUnavailabilityDto) {
    let response: VehicleUnavailabilityResponse;
    let vehicleUnNt : VehicleUnavailabilityNt = new VehicleUnavailabilityNt(vehicle);
    this.authApi.put<VehicleUnavailabilityNt, VehicleUnavailabilityResponseNt>(`${this.base}`, vehicleUnNt).then((resp) => {
      response = new VehicleUnavailabilityResponse(resp);
    })
    return response;
  }

}
