import WorkerWeeklyUnDto from "../WorkerWeeklyUnDto";
import {WorkerWeeklyUnResponseNt} from "./nt/WorkerWeeklyUnResponseNt";

export class WorkerWeeklyUnResponse {
  status: string;
  errors: string[];
  content: WorkerWeeklyUnDto[];
  totalPages: number;
  currentPages: number;
  size: number;
  total: number;

  constructor(workerWeeklyUnResponseNt: WorkerWeeklyUnResponseNt) {
    this.status = workerWeeklyUnResponseNt.status;
    this.errors = workerWeeklyUnResponseNt.errors;
    this.content = workerWeeklyUnResponseNt.content.map((workerWeeklyUnDtoNT) =>
      new WorkerWeeklyUnDto(workerWeeklyUnDtoNT))
    this.totalPages = workerWeeklyUnResponseNt.totalPages;
    this.currentPages = workerWeeklyUnResponseNt.currentPages;
    this.size = workerWeeklyUnResponseNt.size;
    this.total = workerWeeklyUnResponseNt.total;
  }

}
