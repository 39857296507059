import React from "react"
import {DateTime} from "luxon";
import {NeedDto} from "../../api/entity/NeedDto";
import {ShiftDto} from "../../api/entity/ShiftDto";
import {NeedHumanShiftDto} from "../../api/entity/NeedHumanShiftDto";
import Time from "../../lib/utils/Time";
import {NeedVehicleDto} from "../../api/entity/NeedVehicleDto";
import DailyServiceLine from "./DailyServicesTimeLineOverview/DailyServiceLine/DailyServiceLine";
import TimeLineData from "../../components/TimeLine/TimeLineData";
import Range from "../../utils/Range";
import {DailyPlanningWorkerDtoNt} from "../../api/entity/response/nt/DailyPlanningWorkerResponseNt";
import {VehicleDailyPlanningDto} from "../../api/entity/VehicleDailyPlanningDto";
import {DailyContainerVehicle} from "../../api/entity/response/nt/ContainerVehicleResponseNt";
import DailyVehicleLine from "./DailyVehiclesTimeLine/DailyVehicleLine/DailyVehicleLine";
import I18n from "../../lib/I18n";
import VehicleManagementUtils from "../VehicleManagement/VehicleManagementUtils";
import Interval = TimeLineData.Interval;


export interface ContainerVehicleMap {
  [service: string]: ContainerVehicle []
}

export interface ContainerVehicle {
  container: string,
  ranges: Range[],
  categories: string[],
  radioCodeName: string
}

export class TimeLineUtils {
  private constructor() {
  }

  /**************************************************
   * PUBLIC FUNCTIONS
   **************************************************/
  public static hasHumanNeedToday(date: DateTime, human: NeedHumanShiftDto): boolean {
    const isHoliday = Time.isHoliday(date);
    const weekDay = date.weekday - 1
    if (isHoliday)
      return parseInt(human.numberVehiclesFestive[weekDay]) > 0
    else
      return parseInt(human.numberVehiclesWorkdays[weekDay]) > 0
  }

  public static hasVehicleNeedToday(date: DateTime, vehicle: NeedVehicleDto): boolean {
    const isHoliday = Time.isHoliday(date);
    const weekDay = date.weekday - 1
    if (isHoliday)
      return parseInt(vehicle.numberVehiclesFestive[weekDay]) > 0
    else
      return parseInt(vehicle.numberVehiclesWorkdays[weekDay]) > 0
  }


  public static getYesterdayNeedIntervals(date: DateTime, need: NeedDto, shifts: ShiftDto[], color: string = "rgba(169,226,252,0.5)"): Interval[] {
    const timelineIntervals: Interval[] = []
    const yesterday = date.minus({day: 1})
    const yesterdayRanges = TimeLineUtils.calculateServiceRangeForNeed(yesterday, need, shifts);
    for (const range of yesterdayRanges) {
      if (range.end > Range.HOURS_PER_DAY) {
        const start = range.start > Range.HOURS_PER_DAY ? range.start % Range.HOURS_PER_DAY : 0
        timelineIntervals.push({
          color,
          start,
          end: range.end % Range.HOURS_PER_DAY,
          content: <DailyServiceLine
            name={need.serviceCode}
            start={range.startString}
            end={range.endString}
          />
        })
      }
    }
    return timelineIntervals;
  }

  public static getTodayNeedIntervals(date: DateTime, need: NeedDto, shifts: ShiftDto[], color: string = "#A9E2FC"): Interval[] {
    const timelineIntervals: Interval[] = []
    const ranges = TimeLineUtils.calculateServiceRangeForNeed(date, need, shifts);
    for (const range of ranges) {
      timelineIntervals.push({
        color,
        start: range.boundStart,
        end: range.boundEnd,
        content: <DailyServiceLine
          name={need.serviceCode}
          start={range.startString}
          end={range.endString}
        />
      })
    }
    return timelineIntervals
  }


  public static getNeedTypes(date: DateTime, need: NeedDto): string[] {
    const types: { [type: string]: boolean } = {}
    for (const human of need.needHumanDtoList)
      if (TimeLineUtils.hasHumanNeedToday(date, human)) {
        types[human.enablement] = true;
        if (human.moss)
          types["MOSS"] = true;
      }
    for (const vehicle of need.needVehicleDtoList)
      if (TimeLineUtils.hasVehicleNeedToday(date, vehicle))
        types[vehicle.category] = true;

    return Object.keys(types);
  }

  public static getContainerVehiclesForServices(dailyWorkers: DailyPlanningWorkerDtoNt[], dailyVehicles: VehicleDailyPlanningDto[], dailyContainers: DailyContainerVehicle[], radioIdsMap: Map<number, string>): ContainerVehicleMap {
    const containerVehiclesMap: ContainerVehicleMap = {}

    for (const worker of dailyWorkers) {
      this.getContainersAndRanges(containerVehiclesMap, worker.service, worker.containervehicle, worker.servicestart, worker.serviceend,
        "-"/*worker.servicecategory*/, (worker.radiocodeid != 0) ? radioIdsMap.get(worker.radiocodeid) : "");
    }

    for (const vehicle of dailyVehicles) {
      this.getContainersAndRanges(containerVehiclesMap, vehicle.service, vehicle.containerVehicle, vehicle.serviceStartTime, vehicle.serviceEndTime,
        "-"/*vehicle.serviceCategory*/, vehicle.radiocodeid != 0 ? radioIdsMap.get(vehicle.radiocodeid) : "");
    }

    for (const container of dailyContainers) {
      this.getContainersAndRanges(containerVehiclesMap, container.service, container.containervehicle, container.startshift, container.endshift,
        container.category,
        container.radiocodeid != 0 ? radioIdsMap.get(container.radiocodeid) : "");
    }

    return containerVehiclesMap;
  }

  private static getContainersAndRanges(containerVehiclesMap: ContainerVehicleMap, service: string, container: string, start: string, end: string, category: string, radioCodeName: string) {
    if (!containerVehiclesMap[service])
      containerVehiclesMap[service] = []
    if (containerVehiclesMap[service].filter(c => c.container == container).length == 0)
      containerVehiclesMap[service].push({
        container, ranges: [new Range(start, end)], categories: [category], radioCodeName
      })
    else {
      containerVehiclesMap[service].filter(c => c.container == container)[0].ranges =
        Range.mergeOverlappingRanges([new Range(start, end), ...containerVehiclesMap[service].filter(c => c.container == container)[0].ranges])
      if (containerVehiclesMap[service].filter(c => c.container == container)[0].categories.indexOf(category) == -1) {
        containerVehiclesMap[service].filter(c => c.container == container)[0].categories.push(category)
      }
    }
  }

  /**************************************************
   * PRIVATE FUNCTIONS
   **************************************************/
  // the service total range is made by the combination of the durations of human turns and vehicles
  private static calculateServiceRangeForNeed(date: DateTime, need: NeedDto, shifts: ShiftDto[]): Range[] {
    /** The service range is calculated ONLY using the vehicle need
     *  IF the vehicle need is empty, then human need*/
    const vehicleRanges = this.calculateServiceRangeForVehiclesNeed(date, need)
    if (vehicleRanges && vehicleRanges.length > 0)
      return Range.mergeOverlappingRanges([...this.calculateServiceRangeForVehiclesNeed(date, need)]);
    else
      return Range.mergeOverlappingRanges([...this.calculateServiceRangeForWorkersNeed(date, need, shifts)]);
  }

  private static calculateServiceRangeForWorkersNeed(date: DateTime, need: NeedDto, shifts: ShiftDto[]): Range[] {
    let ranges: Range[] = []
    for (const human of need.needHumanDtoList) {
      if (TimeLineUtils.hasHumanNeedToday(date, human)) {
        const shift = this.getShift(shifts, human.turnId);
        if (shift)
          ranges.push(new Range(shift.fromTime, shift.toTime/*, shift.fromTime, shift.toTime*/))
      }
    }
    return Range.mergeOverlappingRanges(ranges)
  }

  private static getShift(shifts: ShiftDto[], turnId: number): ShiftDto {
    let result = shifts.filter(turn => turn.id == turnId);
    return result.length == 0 ? null : result[0]
  }

  private static calculateServiceRangeForVehiclesNeed(date: DateTime, need: NeedDto): Range[] {
    let ranges: Range[] = []
    for (const vehicle of need.needVehicleDtoList)
      if (TimeLineUtils.hasVehicleNeedToday(date, vehicle))
        ranges.push(new Range(vehicle.fromTime, vehicle.toTime/*, r.fromTime, r.toTime*/))
    return Range.mergeOverlappingRanges(ranges)
  }


  /**************************************************
   * VEHICLES SPECIFIC STUFF
   **************************************************/
  public static getVehicleInterval(vehicle: VehicleDailyPlanningDto): Interval {

    const range = new Range(vehicle.serviceStartTime, vehicle.vehicleEndTime)

    // const endString = this.removeSeconds(vehicle.vehicleEndTime);
    // const startString = this.removeSeconds(vehicle.vehicleStartTime);

    // const timeRangeForShift = Range.getTimeRangeForShift(vehicle.vehicleStartTime,
    //   vehicle.vehicleEndTime,
    //   this.props.day, DateTime.fromFormat(vehicle.date, GfDateUtils.STORED_DATE_FORMAT));

    const BG_COLOR_DEFAULT = "#c6ecfd"
    const BG_COLOR_OFF = "#09a7ef"
    const BG_COLOR_LAV = "#f7d5d5"

    const TEXT_COLOR_DEFAULT = "#4b5760"
    const TEXT_COLOR_OFF = "#4b5760"
    const TEXT_COLOR_LAV = "#df5858"


    const isTimeOff = VehicleManagementUtils.TIME_OFF_COMBINATION.indexOf(vehicle.containerVehicle) > -1;

    return {
      color: isTimeOff ? TimeLineUtils.determineBgColorForOffTime(vehicle.containerVehicle, BG_COLOR_OFF, BG_COLOR_LAV) : BG_COLOR_DEFAULT,
      textColor: isTimeOff ? TimeLineUtils.determineTextColorForOffTime(vehicle.containerVehicle, TEXT_COLOR_OFF, TEXT_COLOR_LAV) : TEXT_COLOR_DEFAULT,
      start: range.boundStart,
      end: range.boundEnd,
      content: <DailyVehicleLine
        onClick={() => {
        }}
        id={vehicle.id}
        textColor={isTimeOff ? TimeLineUtils.determineTextColorForOffTime(vehicle.containerVehicle, TEXT_COLOR_OFF, TEXT_COLOR_LAV) : TEXT_COLOR_DEFAULT}
        name={TimeLineUtils.getShiftName(vehicle)}
        start={range.startString}
        end={range.endString}
      />
    }
  }

  private static getShiftName(ve: VehicleDailyPlanningDto) {
    switch (ve.containerVehicle) {
      case "OFF":
        return I18n.get().VehicleUnavailabilityModal.reasons.maintenance.toUpperCase();
      case "LAV":
        return I18n.get().VehicleUnavailabilityModal.reasons.washing.toUpperCase();
      default:
        // return ve.containerVehicle.toUpperCase();
        return ve.service.toUpperCase();
    }
  }

  private static determineBgColorForOffTime(containerVehicle: string, bgColorOff, bgColorLav) {
    return containerVehicle.toUpperCase() != "OFF" ? bgColorOff : bgColorLav;
  }

  private static determineTextColorForOffTime(containerVehicle: string, textColorLav, textColorOff) {
    return containerVehicle.toUpperCase() != "OFF" ? textColorLav : textColorOff;
  }
}
