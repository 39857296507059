import {SkillType} from "./SkillType";
import SkillDtoNT from "./nt/SkillDtoNt";

export class SkillDto {
  id: number;
  name: string;
  type: SkillType;

  constructor(skillDtoNt: SkillDtoNT) {
    this.id = skillDtoNt.id;
    this.name = skillDtoNt.name;
    this.type = skillDtoNt.type;
  }
}
