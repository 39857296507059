import React, {Component} from "react";
import {DateTime} from "luxon";
import Button from "../../../../components/Button/Button";
import IconChevronCircleLeft from "../../../../icons/IconChevronCircleLeft";
import MonthlyPlanningNavigationLabel
  from "../../MonthlyNavigationBar/MonthlyPlanningNavigationLabel";
import I18n from "../../../../lib/I18n";

interface Props {
  onClick: () => void,
  currentDate: DateTime
}

export default class WizardStepOneNavigation extends Component<Props> {
  render() {
    const labels = I18n.get().MonthlyTimeLine;
    return <div className="TimeLineNavigation">
      <div className="TimeLineNavigation-goBack">
        <Button flat onClick={this.props.onClick}>
          <IconChevronCircleLeft/>{labels.exit}
        </Button>
      </div>
      <div className="TimeLineNavigation-buttons">
        <MonthlyPlanningNavigationLabel currentDate={this.props.currentDate}/>
      </div>
    </div>;
  }
}
