import I18n from "../../../lib/I18n";
import GfDateUtils from "../../DatePicker/GfDateUtils";
import {VehicleDailyPlanningDto} from "../../../api/entity/VehicleDailyPlanningDto";

export interface VehicleServiceTurnModalError {
  fromTime: string,
  toTime: string
}

export class VehicleServiceTurnUtils {

  public getErrors(elem: VehicleDailyPlanningDto[]) : VehicleServiceTurnModalError[]{
    return elem.map(el => {
      if(GfDateUtils.convertHHMMToNumber(el.vehicleStartTime) == GfDateUtils.convertHHMMToNumber(el.vehicleEndTime)){
        return {fromTime: I18n.get().DailyVehicle.errorMod, toTime: I18n.get().DailyVehicle.errorMod}
      }
      return {
        fromTime: el.vehicleStartTime == "" ? I18n.get().DailyVehicle.errorMod : "",
        toTime: el.vehicleEndTime == "" ? I18n.get().DailyVehicle.errorMod : ""
      }
    })
  }

  public isError(error: VehicleServiceTurnModalError){
    return error.fromTime != "" || error.toTime != "";
  }

  public getEmptyError() : VehicleServiceTurnModalError {
    return {
      fromTime: "",
      toTime: ""
    };
  }


}
