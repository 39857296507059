import EngineApi, {NextPlanning, StatusEnum} from "../api/engine/EngineApi";
import LoginApi from "../api/login/LoginApi";
import {Roles} from "../api/Roles";


export default class StatusPoller {
  /**************************************************
   * DESTRUCTOR
   **************************************************/
  public disposeInstance() {
    this.stop();
    this.statusChangeObserver.disposeAll()
    StatusPoller.instance = null;
  }

  /**************************************************
   * CONSTRUCTOR
   **************************************************/
  private constructor() {
  }

  public static getInstance() {
    if (!this.instance)
      this.instance = new StatusPoller()
    return this.instance
  }

  /**************************************************
   * PROPERTIES
   **************************************************/
  public get currentStatus(): NextPlanning {
    return this._currentStatus;
  }

  /**************************************************
   * PUBLIC FUNCTIONS
   **************************************************/
  public listenToStatusChange(callback:(currentStatus:NextPlanning, previousStatus:NextPlanning)=>void): string {
    return this.statusChangeObserver
      .on((value) => callback(value.currentStatus, value.previousStatus));
  }

  public unregisterToStatusChange(id: string) {
    this.statusChangeObserver.dispose(id);
  }

  public startOrStopStatusPoller() {
    if (LoginApi.isUserRoleHigherThanUser()/* && !StatusPoller.getInstance().isRunning()*/)
      StatusPoller.getInstance().start()
    else if (!LoginApi.isUserRoleHigherThanUser()/*  && StatusPoller.getInstance().isRunning()*/)
      StatusPoller.getInstance().stop()
  }

  public start() {
    if (this.timeout != null) return;
    this.getStatus()
  }

  public stop() {
    if (this.timeout == null) return;
    window.clearTimeout(this.timeout)
    this.timeout = null;
  }

  public engineIsRunning(){
    switch (this._currentStatus?.status){
      case StatusEnum.CONVERGENCE_CHECK_IN_PROGRESS:
      case StatusEnum.MONTHLY_PLANNING_IN_PROGRESS:
      case StatusEnum.CONSTRAINT_CHECK_IN_PROGRESS:
      case StatusEnum.DAILY_PLANNING_IN_PROGRESS:
        return true;
      default:
        return false;
    }
  }

  public async checkStatus(): Promise<NextPlanning> {
    try {
      const status = await this.engineApi.getStatus(() => {
        this.stop();
        LoginApi.logout()
      })
      if (this._currentStatus?.status != status?.status) {
        this.statusChangeObserver.dispatch({currentStatus: {...status}, previousStatus: {...this._currentStatus}})
        this._currentStatus = {...status}
      }
      return status;
    } catch{
        this.stop();
        LoginApi.logout()
    }
  }

  /**************************************************
   * PRIVATE FUNCTIONS
   **************************************************/
  private getStatus() {
    this.timeout = window.setTimeout(async () => {
      await this.checkStatus()
      this.getStatus()
    }, this.THRESHOLD)
  }

  /**************************************************
   * VARIABLES
   **************************************************/
  private _currentStatus: NextPlanning;
  private engineApi = new EngineApi();
  private timeout: number = null;
  private readonly THRESHOLD = 2 * 1000;
  private static instance: StatusPoller;
  private statusChangeObserver = new Observer<{currentStatus:NextPlanning, previousStatus:NextPlanning}>();
}

export class Observer<T = void> {
  /**************************************************
   * DESTRUCTOR
   **************************************************/
  public dispose(id: string) {
    if (!this.callbacks[id]) return console.warn(`Invalid id ${id} passed to observer dispose`)
    this.callbacks[id] = null
    delete this.callbacks[id]
  }

  public disposeAll() {
    this.callbacks = {}
  }

  /**************************************************
   * PUBLIC FUNCTIONS
   **************************************************/
  public on(callback: (arg: T) => void) {
    const id = String(++Observer.counter);
    this.callbacks[id] = (arg: T) => callback(arg)
    return id;
  }

  public dispatch(arg: T) {
    for (const id in this.callbacks)
      setTimeout(() => this.callbacks[id](arg))
  }

  /**************************************************
   * PRIVATE FUNCTIONS
   **************************************************/
  private callbacks: { [id: string]: (arg: T) => void } = {}
  private static counter = 0;
}



