import React, {Component} from "react";
import "./MonthlyElapsedTable.scss";
import Table, {Header} from "../../../components/Table/Table";
import I18n from "../../../lib/I18n";
import {ElapsedEntryDto} from "../../../api/entity/ElapsedEntryDto";
import {DateTime} from "luxon";
import GfDateUtils from "../../../components/DatePicker/GfDateUtils";

interface Props {
  entries: ElapsedEntryDto[]
}

interface TableEntry {
  day: string,
  requiredRole: string,
  requiredEnablement: string,
  circuit: string,
  moss: string,
  humanShifts: string,
  elapsed: string
}

interface State {
  table: {
    headers: Header<TableEntry>[];
    items: TableEntry[];
    currentSlice: number;
  };
}

export default class MonthlyElapsedTable extends Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = {
      table: {
        currentSlice: 1,
        items: [],
        headers: []
      }
    }
  }

  componentDidMount() {
    this.updateTable();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (prevProps.entries !== this.props.entries) {
      this.updateTable();
    }
  }

  updateTable() {
    const entries = this.props.entries;
    this.setState({
      table: {
        ...this.state.table,
        headers: [
          {
            name: I18n.get().MonthlyPlanning.viewElapsed.table.day,
            field: "day"
          },
          {
            name: I18n.get().MonthlyPlanning.viewElapsed.table.circuit,
            field: "circuit"
          },
          {
            name: I18n.get().MonthlyPlanning.viewElapsed.table.role,
            field: "requiredRole"
          },
          {
            name: I18n.get().MonthlyPlanning.viewElapsed.table.enablement,
            field: "requiredEnablement"
          },
          {
            name: I18n.get().MonthlyPlanning.viewElapsed.table.moss,
            field: "moss"
          },
          {
            name: I18n.get().MonthlyPlanning.viewElapsed.table.shift,
            field: "humanShifts"
          },
          {
            name: I18n.get().MonthlyPlanning.viewElapsed.table.quantity,
            field: "elapsed"
          }
        ], items:
          entries.map((e) => {
            return {
              day: DateTime.fromFormat(e.date, GfDateUtils.STORED_DATE_FORMAT).toFormat(GfDateUtils.DATE_FORMAT),
              circuit: e.circuit.toUpperCase(),
              requiredRole: e.role.toUpperCase(),
              requiredEnablement: e.enablement.toUpperCase(),
              moss: e.moss ? I18n.get().VehicleModal.yes : I18n.get().VehicleModal.no,
              humanShifts: e.shift.toUpperCase(),
              elapsed: e.nelapsed.toString()
            };
          })
      }
    });
  }

  render() {
    return <div>
      <Table headers={this.state.table.headers}
             items={this.state.table.items}
             onClick={(i) => console.log(i)}/>
    </div>;
  }
}
