
import { useDroppable } from "@dnd-kit/core";
import "./ContainerVehicleList.scss";
import React, { useEffect, useState } from "react";
import WorkerCard from "../WorkerCard/WorkerCard";
import ContainerVehicleRow from "./ContainerVehicleRow";
import { WorkerDto } from "../../../api/entity/WorkerDto";
import { IWorkers } from "../DailyOverviewUtils";
import { transform } from "lodash";

interface IProps {
  containerVehicles: any[];
  dailyWorkers: any[];
  dailyVehicles: any[];
  vehiclesMap: Map<number, any>,
  radioCodeMap: Map<number, string>,
  workersMap: Map<string, WorkerDto>,
  shiftMap: Map<string, any>,
  workersByService: IWorkers
}




export default function ContainerVehicleList({
    containerVehicles: containerVehicles,
    dailyWorkers,
    dailyVehicles,
    vehiclesMap,
    radioCodeMap,
    workersMap,
    shiftMap,
    workersByService,
 }: IProps) {

  const [maxwidth, setMaxWidth] = useState(0);
  const [headYTranslation, setHeadYTranslation] = useState(0);


  var style = {
    width: maxwidth + "px"
  };

  var extra = containerVehicles.filter((x) => x.containervehicle == "-")[0];
  var other = containerVehicles.filter((x) =>
      x.containervehicle != "-"
     && x.containervehicle != "RIPOSO"
     && x.containervehicle != "REPERIBILE"
      && x.containervehicle != "LAV"
      && x.containervehicle != "OFF"
      && x.containervehicle.startsWith("REP") == false
    );

  //Sorting other by service
  other.sort((a, b) => {
    return a.containervehicle.localeCompare(b.containervehicle);
  });

  const paddingRight = 40;
  //Log table width on resize and load
  useEffect(() => {

    var element = document.querySelector("table#containerVehicleTable");
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      var table = document.querySelector("table#containerVehicleTable");
      if (table) {
        var bodywidth = document.body.clientWidth;
        var tableWidth = table.clientWidth;
        var tableWidthWithPadding = tableWidth + paddingRight;
        var maxWidth = Math.max(bodywidth, tableWidthWithPadding);
        console.log("table width: " + tableWidth + "px -> maxwidth: " + maxWidth + "px");

        setMaxWidth(maxWidth);
      }
    }

    function handleScroll() {
      var elementTop = element.getBoundingClientRect().top;

      if (elementTop > window.scrollY) {
        setHeadYTranslation(0);
        return;
      }

      setHeadYTranslation(-elementTop);
    }

    // Add event listener
    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const thStyle = {
    transform: "translateY(" + headYTranslation + "px)"
  };

  return <div  className="container-vehicle-list" style={ { ...style, }}>
<h1>Container Vehicle List</h1>
    <table  id="containerVehicleTable" style={ { marginRight: paddingRight+"px" }}>
      <thead style={thStyle}>
          <tr>
          <th></th>
          <th>Mattino</th>
          <th>Pomeriggio</th>
          <th>Sera</th>
          <th>Notte</th>
          </tr>
      </thead>
      <tbody>
        <ContainerVehicleRow

          containerVehicle={ { ...extra, id: "LIBERO|SERVICE", containervehicle: "LIBERO|SERVICE",
               "startshift": "00:00", "endshift": "23:59"


           } }
          dailyWorkers={workersByService.LIBERO}
          dailyVehicles={[]}

          shiftMap={shiftMap}
          workersMap={workersMap}

          />
        { other.map((containerVehicle, index) => {
          var vneed = getVehicleNeedForContainer(containerVehicle, vehiclesMap);
          var radioCode = radioCodeMap.get(containerVehicle.radiocodeid);
          return(<ContainerVehicleRow key={index}
              containerVehicle={containerVehicle}
              dailyWorkers={dailyWorkers.filter((x) => x.containervehicle == containerVehicle.containervehicle)}
              dailyVehicles={dailyVehicles.filter((x) => x.containerVehicle == containerVehicle.containervehicle)}
              vehicleNeed={vneed}
              shiftMap={shiftMap}
              workersMap={workersMap}
              radioCode={radioCode}
              />);
        })}
      </tbody>
    </table>
  </div>;

  function getVehicleNeedForContainer(containerVehicle: any, vehiclesMap: Map<number, any>) {
    var cvId = containerVehicle.containervehicle;
    var parts = cvId.split("_");
    if (vehiclesMap == null || vehiclesMap.size == 0) {
      return null;
    }

    if (parts.length != 3) {
      return null;
    }

    var need = vehiclesMap.get(parseInt(parts[1]));
    return need;
  }
}

