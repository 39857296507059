import {VehicleUnavailabilityModal} from "../../components/Modal/VehicleUnavailabilityModal/VehicleUnavailabilityModal";
import {VehicleServiceTurnModal} from "../../components/Modal/VehicleServiceTurnModal/VehicleServiceTurnModal";
import {VehicleDailyPlanningDto} from "../../api/entity/VehicleDailyPlanningDto";

export default class VehicleManagementUtils{
  private constructor() {}
  /**************************************************
   * PUBLIC FUNCTIONS
   **************************************************/
  public static getTimelineBgColorUnavailability(motivation: string) {
    return this.isMaintenanceUnavailability(motivation) ? this.OFF_TIMELINE_COLOR : this.LAV_TIMELINE_COLOR;
  }

  public static getTimelineTextColorUnavailability(motivation: string) {
    return this.isMaintenanceUnavailability(motivation) ? this.OFF_TEXT_COLOR : this.LAV_TEXT_COLOR;
  }

  public static getTimelineBgColorDailyPlanningVehicle(vehicle:VehicleDailyPlanningDto) {
    return this.isTimeOffPlanningVehicle(vehicle.containerVehicle)
      ? this.isMaintenanceDailyEntry(vehicle.containerVehicle)
        ? this.OFF_TIMELINE_COLOR : this.LAV_TIMELINE_COLOR
      : (vehicle.previousDay ? this.BG_PREVIOUS_DAY_COLOR : this.BG_COLOR);
  }

  public static getTimelineTextColorDailyPlanningVehicle(vehicle:VehicleDailyPlanningDto) {
    return this.isTimeOffPlanningVehicle(vehicle.containerVehicle)
      ? this.isMaintenanceDailyEntry(vehicle.containerVehicle)
        ? this.OFF_TEXT_COLOR : this.LAV_TEXT_COLOR
      : this.TEXT_COLOR;
  }

  /**************************************************
   * PRIVATE FUNCTIONS
   **************************************************/
  private static isTimeOffPlanningVehicle(containerVehicle: string) {
      return this.TIME_OFF_COMBINATION.indexOf(containerVehicle) > -1;
  }

  private static isMaintenanceUnavailability(motivation: string) {
    return motivation == VehicleUnavailabilityModal.MOTIVATION_DTO[0];
  }

  private static isMaintenanceDailyEntry(containerVehicle: string) {
    return containerVehicle.toUpperCase() == "OFF";
  }
  /**************************************************
   * VARIABLES
   **************************************************/
  public static readonly TEXT_COLOR = "#4b5760";
  public static readonly BG_COLOR = "#A9E2FC"
  public static readonly BG_PREVIOUS_DAY_COLOR = "rgba(169,226,252,0.5)"

  private static readonly OFF_TEXT_COLOR = "#df5858";
  static readonly OFF_TIMELINE_COLOR = "#f7d5d5"

  // private static readonly LAV_TIMELINE_COLOR = "#c6ecfd";
  // private static readonly LAV_TEXT_COLOR = "#09a7ef";

  private static readonly LAV_TIMELINE_COLOR = "#e6d0fa";
  private static readonly LAV_TEXT_COLOR = "#9a0ef1";

  public static readonly CELL_BG_COLOR = "#f3f6f8";
  public static readonly TIME_OFF_COMBINATION: string[] = ["LAV", "OFF"];
}
