import React, {Component} from "react";
import "./VehicleHeader.scss"
import Time from "../../../../lib/utils/Time";
interface Props{
  day:Date
  hour:string
}

interface State{

}

export default class VehicleHeader extends Component<Props,State>{
  render() {
    const {day,hour} = this.props
    return <div className="VehicleHeader">
      <div className="VehicleHeader-subtext">{Time.getStringDay(day)}</div>
      <div className="VehicleHeader-text">{hour}</div>
    </div>
  }

}
