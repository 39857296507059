import React from 'react';
import "./SearchInput.scss";
import TextInput from "../TextInput/TextInput";

export interface IProps {
  className: string;
  label: string;
  defaultValue: string;
  placeholder: string;
  onChange: (value: string) => void;
  readonly: boolean,
  disabled: boolean,
  password: boolean,
  errorMessage: string,
  value? : string;
  cancelSignal?: number;
}

interface State {
  value: string,
}

export default class SearchInput extends TextInput {

  private readonly MS = 1250;
  private updateTimer : any;


  /************************************************
   * CONSTRUCTOR
   ************************************************/
  protected constructor(props: IProps) {
    super(props);
    this.state = {
      value: this.props.defaultValue,
    };
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<State>, snapshot?: any) {
    super.componentDidUpdate(prevProps, prevState, snapshot);
    if(this.props.value != prevProps.value) {
      this.setState({value: this.props.value}, ()=> { this.props.onChange(this.state.value) });
    }

    if (prevProps.cancelSignal != (this.props as IProps).cancelSignal) {
      this.setState({value: ""}, ()=> { this.props.onChange(this.state.value) });
    }
  }

  handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({value: event.target.value})
    if(this.updateTimer != null) {
      clearTimeout(this.updateTimer);
    }
    this.updateTimer = setTimeout(() => {
      this.props.onChange(this.state.value);
    }, this.MS);
  }

  /************************************************
   * LIFECYCLE
   ************************************************/
  render() {
    return <label className={`SearchInput ${this.props.className}`}>
      {!!this.props.label && <span className="TextInput-label">{this.props.label}</span>}
      <input
        type={this.props.password ? "password" : "text"}
        disabled={this.props.disabled}
        readOnly={this.props.readonly}
        value={this.state.value ? this.state.value : ""}
        onChange={event => this.handleChange(event)}
        placeholder={this.props.placeholder}
        className={!!this.props.errorMessage ? "TextInput-borderError" : ""}
      />
      {!!this.props.errorMessage && <span className="TextInput-error">{this.props.errorMessage}</span>}
    </label>
  }
}
