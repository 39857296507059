import React, {Component} from 'react';
import InputMask from 'react-input-mask';
import "./TextInputMask.scss";
import TooltipWrapper from "../TooltipWrapper/TooltipWrapper";

export interface Props {
  className: string;
  label: string;
  defaultValue: string;
  placeholder: string;
  onChange: (value: string) => void;
  onValidate?: (value: string) => boolean;
  readonly: boolean;
  disabled: boolean;
  password: boolean;
  errorMessage: string;
  mask: string | (string | RegExp)[];
  maskChar: string;
  formatsChars: object;
  tooltipMessage?: string;
}

interface State {
  value: string
}

export default class TextInputMask extends Component<Props, State> {
  /************************************************
   * PROPS
   ************************************************/
  static defaultProps: Partial<Props> = {
    className: "",
    label: "",
    defaultValue: "",
    placeholder: "",
    onChange: () => {
    },
    readonly: false,
    disabled: false,
    errorMessage: ""
  };

  /************************************************
   * CONSTRUCTOR
   ************************************************/
  protected constructor(props: Props) {
    super(props);
    this.state = {
      value: this.props.defaultValue,
    };
  }

  /************************************************
   * LIFECYCLE
   ************************************************/
  render() {

    return <label className={`TextInput ${this.props.className}`}>
      <div className={"title"}>
        {!!this.props.label && <span className="TextInput-label">{this.props.label}</span>}
        {!!this.props.tooltipMessage && <span className={"TextInput-tooltip"}>
          <TooltipWrapper content={this.props.tooltipMessage}>
            <img src={"assets/info.svg"}/>
          </TooltipWrapper>
        </span>}
      </div>
      <InputMask
        mask={this.props.mask}
        maskPlaceholder={this.props.maskChar}
        type={this.props.password ? "password" : "text"}
        disabled={this.props.disabled}
        readOnly={this.props.readonly}
        value={this.state.value ? this.state.value : ""}
        onChange={event => this.handleChange(event)}
        placeholder={this.props.placeholder}
        onBlur={event => this.handleValidate(event)}
        className={!!this.props.errorMessage ? "TextInput-borderError" : ""}
      />
      {!!this.props.errorMessage && <span className="TextInput-error">{this.props.errorMessage}</span>}
    </label>
  }

  handleChange(event: React.ChangeEvent<HTMLInputElement>) {

      this.setState({value: event.target.value})
      this.props.onChange(event.target.value);

  }

  handleValidate(event: React.ChangeEvent<HTMLInputElement>) {
    if (this.props.onValidate) {
      if (this.props.onValidate(event.target.value)) {
        this.setState({value: event.target.value})
        this.props.onChange(event.target.value);
      }
      else {
        this.resetChange();
      }
    }
  }

  resetChange() {
    //I have to wait for the state to be updated before resetting the value
    setTimeout(() => {
      this.setState({value: this.props.defaultValue});
    }, 0);
  }


}
