import React from "react";
import Icon from "./Icon";

interface Props {
  className: string;
  width: number;
  height: number;
  color: string;
}

export default class IconWorker extends Icon<Props> {
  /************************************************
   * PROPS
   ************************************************/
  static defaultProps = {
    className: "",
    width: 12,
    height: 12,
    color: "#919da5"
  };

  /************************************************
   * LIFECYCLE
   ************************************************/
  render() {
    return <svg className="IconWorker" xmlns="http://www.w3.org/2000/svg" width={this.props.width} height={this.props.height}  viewBox={`0 0 ${this.props.width} ${this.props.height}`}>
        <g fill="none" fillRule="evenodd">
            <path d="M3.5 3.5C3.5 4.878 4.622 6 6 6s2.5-1.122 2.5-2.5S7.378 1 6 1 3.5 2.122 3.5 3.5zm1 0C4.5 2.673 5.173 2 6 2s1.5.673 1.5 1.5S6.827 5 6 5s-1.5-.673-1.5-1.5zm6 6.5c0-1.93-1.57-3.5-3.5-3.5H5c-1.93 0-3.5 1.57-3.5 3.5v.5h1V10c0-1.378 1.122-2.5 2.5-2.5h2c1.378 0 2.5 1.122 2.5 2.5v.5h1V10z"
                  fill={this.props.color} />
        </g>
      </svg>
  }
}








