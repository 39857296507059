import {MonthlyUnavailabilityRowDtoNt} from "./response/nt/MonthlyPlannedUnavailabilityResponseNt";
import {DateTime} from "luxon";
import {MonthlyUnavailabilityRowEntry} from "./MonthlyUnavailabilityRowEntry";

export class MonthlyUnavailabilityRowDto {

  id: number;
  workerId: number;
  date: DateTime;
  entries: MonthlyUnavailabilityRowEntry[];

  constructor(mt: MonthlyUnavailabilityRowDtoNt) {
    this.id = mt.id;
    this.workerId = mt.workerId;
    this.date = DateTime.fromISO(mt.date);
    this.entries = mt.entries.map(a => new MonthlyUnavailabilityRowEntry(a));
  }
}
