import React, {Component} from "react";
import MonthlyPlanningApi from "../../../../api/planning/MonthlyPlanningApi";
import {DateTime} from "luxon";
import Button from "../../../../components/Button/Button";
import {MonthlyCompletedView} from "../../MonthlyPlanning";
import I18n from "../../../../lib/I18n";

interface Props {
  month: DateTime,
  view: MonthlyCompletedView
  monthHasData: boolean,
  monthHasElapsed: boolean,
  monthHasOverloaded: boolean
}

interface State {
  isDisabledPlanningDownloadFlag: boolean
  isDisabledElapsedDownloadFlag: boolean
  isDisabledOverloadedDownloadFlag: boolean
}


export default class MonthlyDownloadButtonBar extends Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      isDisabledOverloadedDownloadFlag: false,
      isDisabledElapsedDownloadFlag: false,
      isDisabledPlanningDownloadFlag: false
    }
  }

  public render() {
    let label = I18n.get().MonthlyTimeLine;
    return <div>
      {this.props.view == MonthlyCompletedView.PLANNING &&
      <Button
        className={"downloadExcel-btn"}
        secondary disabled={this.state.isDisabledPlanningDownloadFlag || !this.props.monthHasData}
        onClick={() => this.onPlanningExcelExport()}>
        {label.download_excel}
      </Button>
      }
      {this.props.view == MonthlyCompletedView.ELAPSED &&
      <Button
        className={"downloadExcel-btn"}
        secondary disabled={this.state.isDisabledElapsedDownloadFlag || !this.props.monthHasElapsed}
        onClick={() => this.onElapsedExcelExport()}>
        {label.download_elapsed}
      </Button>
      }
      {this.props.view == MonthlyCompletedView.OVERLOADED &&
      <Button
        className={"downloadExcel-btn"}
        secondary disabled={this.state.isDisabledOverloadedDownloadFlag || !this.props.monthHasOverloaded}
        onClick={() => this.onOverloadedExcelExport()}>
        {label.download_overloaded}
      </Button>}
    </div>
  }

  private async onPlanningExcelExport() {
    this.setState({isDisabledPlanningDownloadFlag: true});
    await new MonthlyPlanningApi()
      .exportExcel(this.props.month.startOf("month"))
      .then(() => this.setState({isDisabledPlanningDownloadFlag: false}));
  }

  private async onElapsedExcelExport() {
    this.setState({isDisabledElapsedDownloadFlag: true});
    await new MonthlyPlanningApi()
      .exportElapsed(this.props.month.startOf("month"))
      .then(() => this.setState({isDisabledElapsedDownloadFlag: false}));
  }

  private async onOverloadedExcelExport() {
    this.setState({isDisabledOverloadedDownloadFlag: true});
    await new MonthlyPlanningApi()
      .exportOverload(this.props.month.startOf("month"))
      .then(() => this.setState({isDisabledOverloadedDownloadFlag: false}));
  }
}
