import React, {Component} from "react";
import "./TimeLineSeparatorsHorizontal.scss"

interface Props{
  totalHeaders:number
}

export default class TimeLineSeparatorsHorizontal extends Component<Props>{
  render(){
    const offset = (100/this.props.totalHeaders);
    return <div className="TimeLineSeparatorsHorizontal">
      {Array.apply(null, {length: this.props.totalHeaders}).map(Number.call, Number).map((segment, i) =>
        <div className="TimeLineSeparatorsHorizontal-line" key={i} style={{left:(i*offset)+"%"}} />)}
    </div>
  }
}
