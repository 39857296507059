import React, { useState } from 'react';
import {DateTime} from "luxon";
import './DailyOverview.scss';
import Page from '../Page';
import I18n from "../../lib/I18n";
import { DndContext,
    DragEndEvent,
     DragMoveEvent,
     DragOverlay,
      DragStartEvent,
       getClientRect,
       MeasuringConfiguration,
       MeasuringStrategy,
       pointerWithin,

      } from '@dnd-kit/core';
import ContainerVehicleApi from '../../api/container-vehicle/ContainerVehicleApi';
import VehiclePlanningApi from '../../api/vehicle/VehiclePlanningApi';
import VehicleApi from '../../api/vehicle/VehicleApi';
import NeedManagementApi from '../../api/need/NeedManagementApi';
import RadioCodeApi from '../../api/radio/RadioCodeApi';
import MonthlyPlanningApi from '../../api/planning/MonthlyPlanningApi';
import WorkersRegistryUtils from '../WorkersRegistry/WorkerUtils';
import DailyPlanningApi from '../../api/planning/DailyPlanningApi';
import ShiftRegistryApi from '../../api/shift/ShiftRegistryApi';
import WorkerRegistryApi from '../../api/worker/WorkerRegistryApi';
import { WorkerDto } from '../../api/entity/WorkerDto';
import GfDateUtils from '../../components/DatePicker/GfDateUtils';
import { NeedDto } from '../../api/entity/NeedDto';
import ContainerVehicleRow from './ContainerVehicle/ContainerVehicleRow';
import ContainerVehicleList from './ContainerVehicle/ContainerVehicleList';
import SideBar from './SideBar/SideBar';
import {
  restrictToVerticalAxis,
  restrictToWindowEdges,
  restrictToFirstScrollableAncestor,
  snapCenterToCursor
} from '@dnd-kit/modifiers';
import { IWorkers, splitWorkersByServices, getWorkerStartEndForShiftInContainerVehicle } from './DailyOverviewUtils';
interface State {
  activeId: string | null,
  day: DateTime,
  dateIndex: number,
  allWorkers: WorkerDto[],
  radioCodeMap: Map<number, string>,
  vehiclesMap: Map<number, any>,
  dailyWorkers: any[],
  activeWorkers: WorkerDto[],
  needs: NeedDto[],
  shifts: any[],
  allVehicles: any[],
  dailyVehicles: any[],
  //monthlyPlanning: any[],
  containerServiceVehicle: any[],
  dailyWorkersStartingOnDate: any[],
  dailyVehiclesStartingOnDate: any[],
  dailyContainerVehicleStartingOnDate: any[],
  radioCodeIds: any[],
  needVehicles: any[]

  workersMap: Map<string, WorkerDto>,
  shiftMap: Map<string, any>,

  // searchFilter: string,
  // circuitFilter: string,
  // dailyContent: UserDailyShiftDto[],
  // reperibleWorkerMap: Map<string, DailyPlanningWorkerDtoNt[]>,
  // reperibleWorkers: DailyPlanningWorkerDtoNt[],
  // coursesWorkerMap: Map<string, DailyPlanningWorkerDtoNt[]>,
  // coursesWorkers: DailyPlanningWorkerDtoNt[],
  // tableContentMapping: WTEntry[],
  // searchValue?: string,
  // cancelSearchSignal?: number,
  // pendingDownload?: boolean,
  // workers: WorkerDto[]

  fullData: boolean,
  workersByService: IWorkers
}


export default class DailyOverview extends Page<{}, State> {
  private containerVehicleApi = new ContainerVehicleApi();
  private dailyPlanningApi = new DailyPlanningApi();
  private workerRegistryUtils = new WorkersRegistryUtils()
  private monthlyPlanningApi = new MonthlyPlanningApi();
  private radioCodeApi = new RadioCodeApi();
  private needApi = new NeedManagementApi();

  private vehicleApi = new VehicleApi();
  // private vehicleApiUnavailability = new VehicleUnavailabilityApi();
  private vehiclePlanningApi = new VehiclePlanningApi();



  public constructor(props: any) {
    super(props);


    const day = DateTime.now();

    this.state = {
      activeId: null,
      day: day, //DateTime.fromFormat("2022-01-28", GfDateUtils.STORED_DATE_FORMAT), //DateTime.now(), //
      dateIndex: 0,
      allWorkers: [],
      radioCodeMap: new Map<number, string>(),
      vehiclesMap: new Map<number, any>(),
      dailyWorkers: [],
      activeWorkers: [],
      needs: [],
      shifts: [],
      allVehicles: [],
      dailyVehicles: [],
      //monthlyPlanning: [],
      containerServiceVehicle: [],
      dailyWorkersStartingOnDate: [],
      dailyVehiclesStartingOnDate: [],
      dailyContainerVehicleStartingOnDate: [],
      radioCodeIds: [],
      needVehicles: [],
      workersMap: new Map<string, WorkerDto>(),
      shiftMap: new Map<string, any>(),
      fullData: false,
      workersByService: {
      }
    }

    this.loadDataForDay(day);
    this.loadAllWorkers();
  }

  measuring: MeasuringConfiguration = {

    // draggable: {
    //   measure: (element) => {
    //     console.log('measure', element);

    //     return {
    //       ...getClientRect(element, { ignoreTransform: false }),
    //       //top: 0 // getClientRect(element, { ignoreTransform: false }).top
    //      //left: element.getClientRects()[0].left
    //     };
    //   }
    // },
    droppable: {
      strategy: MeasuringStrategy.BeforeDragging,
     //frequency: 100000,
    },
  };


  render() {
    return <DndContext
    measuring={this.measuring}
    //collisionDetection={pointerWithin}
    autoScroll={true}
    modifiers={[
      //restrictToWindowEdges,
      snapCenterToCursor,
      ]}
      onDragMove={this.handleDragMove.bind(this)}
      onDragEnd={this.handleDragEnd.bind(this)}
      onDragStart={this.handleDragStart.bind(this)}
      onDragCancel={this.handleDragCancel.bind(this)}
    >
      { this.state.fullData && <div className="canvas">
        <ContainerVehicleList
          containerVehicles={this.state.dailyContainerVehicleStartingOnDate}
          dailyWorkers={this.state.dailyWorkersStartingOnDate}
          dailyVehicles={this.state.dailyVehiclesStartingOnDate}
          vehiclesMap={this.state.vehiclesMap}
          radioCodeMap={this.state.radioCodeMap}
          workersMap={this.state.workersMap}
          shiftMap={this.state.shiftMap}
          workersByService={this.state.workersByService}
          />




      <SideBar
          containerVehicles={this.state.dailyContainerVehicleStartingOnDate}
          dailyWorkers={this.state.dailyWorkersStartingOnDate}
          dailyVehicles={this.state.dailyVehiclesStartingOnDate}
          vehiclesMap={this.state.vehiclesMap}
          radioCodeMap={this.state.radioCodeMap}
          workersMap={this.state.workersMap}
          shiftMap={this.state.shiftMap}
          dragging={this.state.activeId != null}
          workersByService={this.state.workersByService}
          />


      </div>}

    </DndContext>;
  }

  handleDragStart(event: DragStartEvent) {
    console.log('drag start', event);
    this.setActiveId(event.active.id.toString());

  }

  handleDragCancel(event: DragEndEvent) {
    console.log('drag cancel', event);
  }

  setActiveId(id: string | null) {
    this.setState({ activeId: id });
  }

  handleDragMove(event: DragMoveEvent) {

  }

  cloneObject(obj: any) {
    return JSON.parse(JSON.stringify(obj));
  }

  moveWorkerToVehicle(dailyWorker: any, containerVehicle: any) {
    var newWorker = this.cloneObject(dailyWorker);
    newWorker.containervehicle = containerVehicle.containervehicle;
    newWorker.service = containerVehicle.service;

    newWorker = this.fixWorkerStartEnd(newWorker);

    var dailyWorkers = this.state.dailyWorkersStartingOnDate.filter(x => x.id != dailyWorker.id).concat(newWorker);
    this.recalculateWorkers(true, dailyWorkers);
  }

  fixWorkerStartEnd(dailyWorker: any) {
    var [ start, end ] = this.getWorkerStartEnd(dailyWorker.turn, dailyWorker.containervehicle);
    dailyWorker.servicestart = start;
    dailyWorker.serviceend = end;
    return dailyWorker;
  }

  fixDailyWorkersStartEnd(dailyWorkers: any[], shiftMap: Map<string, any> = this.state.shiftMap, dailyContainerVehicleStartingOnDate: any[] = this.state.dailyContainerVehicleStartingOnDate) {
    if (dailyWorkers.length == 0) {
      return dailyWorkers;
    }

    if (dailyContainerVehicleStartingOnDate.length == 0) {
      return dailyWorkers;
    }

    if (shiftMap.size == 0) {
      return dailyWorkers;
    }

    var dailyWorkersFixed = dailyWorkers.map(w => {
      var shiftObj = shiftMap.get(w.turn);
      var containerObj = dailyContainerVehicleStartingOnDate.find(x => x.containervehicle == w.containervehicle);
      if (shiftObj && containerObj) {
        var [ start, end ] = getWorkerStartEndForShiftInContainerVehicle (shiftObj, containerObj, true);
        w.servicestart = start;
        w.serviceend = end;
      }

      return w;
    });


    return dailyWorkersFixed;

  }

  getWorkerStartEnd(shift: string, containerVehicle: string) {
    var shiftObj = this.state.shiftMap.get(shift);
    var containerObj = this.state.dailyContainerVehicleStartingOnDate.find(x => x.containervehicle == containerVehicle);

    var [ start, end ] = getWorkerStartEndForShiftInContainerVehicle (shiftObj, containerObj, true);

    return [ start, end ];
  }



  pushWorkerToService(dailyWorker: any, service: string) {
    switch (service) {
      case "REPERIBILI":
        dailyWorker.containervehicle = "REPERIBILE";
        dailyWorker.service = "REPERIBILE"
        break;
      case "MALATTIE":
        dailyWorker.containervehicle = "-";
        dailyWorker.service = "AR";
        break;
      case "RIPOSO":
        dailyWorker.containervehicle = "RIPOSO";
        dailyWorker.service = "RIPOSO";
        break;
      case "SMONTO":
        dailyWorker.containervehicle = "SMONTO";
        dailyWorker.service = "SMONTO"
        break;
      case "FERIE":
        dailyWorker.containervehicle = "FERIE";
        dailyWorker.service = "FERIE"
        break;
      case "EXTRA":
        dailyWorker.containervehicle = "-";
        dailyWorker.service = "GR";
        break;
      case "GESTIONE":
        dailyWorker.containervehicle = "-";
        dailyWorker.service = "GE";
        break;

      case "LIBERO":
        dailyWorker.containervehicle = "-";
        dailyWorker.service = "LIBERO";
        break;
    }

    var dailyWorkers = this.state.dailyWorkersStartingOnDate.filter(x => x.id != dailyWorker.id).concat(dailyWorker);
    this.recalculateWorkers(true, dailyWorkers);
  }

  recalculateWorkers(update = true, dailyWorkersStartingOnDate = this.state.dailyWorkersStartingOnDate, allWorkers = this.state.allWorkers, shiftMap = this.state.shiftMap) {

    let dailyWorkers =
      this.fixDailyWorkersStartEnd(
        this.fixDailyWorkersNames(dailyWorkersStartingOnDate, allWorkers), shiftMap, this.state.dailyContainerVehicleStartingOnDate
      );

    dailyWorkers.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }

      if (a.name > b.name) {
        return 1;
      }

      return 0;
    });

    let workersByService: IWorkers = {};
    if (dailyWorkers.length > 0) {
      workersByService = splitWorkersByServices(dailyWorkers);
    }

    if (update) {
      this.setState({
        dailyWorkersStartingOnDate: dailyWorkers,
        workersByService
      });
    }

    return { dailyWorkers, workersByService };
  }


  handleDragEnd({ over, active }) {
    this.setActiveId(null);

    if (!(over?.id) || !(active?.id)) {
      return;
    }



    var dailyWorkerId = active.id;
    var destinationId = over.id;
    var destinationType = "?";


    if (dailyWorkerId?.includes('|')) {
      dailyWorkerId = dailyWorkerId.split('|')[0];
    }
    var dailyWorker = this.state.dailyWorkersStartingOnDate.find(x => x.id == dailyWorkerId);

    if (destinationId?.includes('|')) {
      var parts = destinationId.split('|');
      destinationId = parts[0];
      destinationType = parts[1];
    }

    if (destinationType == "SERVICE") {
      this.pushWorkerToService(dailyWorker, destinationId);
      return;
    }

    var containerVehicle = this.state.dailyContainerVehicleStartingOnDate.find(x => x.containervehicle == destinationId);
    this.moveWorkerToVehicle(dailyWorker, containerVehicle);
    //this.setParent(over ? over.id : null);
  }

  private async loadDataForDay(targetDay: DateTime) {
    const [dailyWorkers, activeWorkers, needs, shifts, allVehicles,
      vehicleEntries, /*planning, containerVehiclesYesterday, */ containerVehiclesToday, radioCodeIds, allNeedsVehicles] = await Promise.all<any>([
      new DailyPlanningApi().getWorkersByDate(targetDay, true),
      new WorkerRegistryApi().getAllActiveInDay(targetDay),
      new NeedManagementApi().getAllActiveInDay(targetDay),
      new ShiftRegistryApi().getAll(),
      this.vehicleApi.getOrSearch("", 10000, 0),
      this.vehiclePlanningApi.getDailyPlanningVehicleByDay(targetDay),
      //this.monthlyPlanningApi.getByDate(targetDay.startOf('month')),
      //this.containerVehicleApi.getDistinctContainerVehiclesForDate(targetDay.minus({day: 1}).toFormat(GfDateUtils.STORED_DATE_FORMAT)),
      this.containerVehicleApi.getDistinctContainerVehiclesForDate(targetDay.toFormat(GfDateUtils.STORED_DATE_FORMAT)),
      this.radioCodeApi.getRadioCodes(0, Number.MAX_SAFE_INTEGER),
      this.needApi.getAllNeedVehicles()
    ])
    this.setState({
      day: targetDay,
      dailyWorkers,
      activeWorkers: activeWorkers.content,
      needs: this.getRelevantForDay(needs.content, targetDay),
      shifts: shifts.content,
      allVehicles: allVehicles.content,
      dailyVehicles: vehicleEntries,
      //monthlyPlanning: planning,
      containerServiceVehicle: containerVehiclesToday.content,
      // TODO: not check date but only previousDay bool
      dailyWorkersStartingOnDate: dailyWorkers.filter((it) => it.date == targetDay.toFormat(GfDateUtils.STORED_DATE_FORMAT) && !it.previousDay),
      dailyVehiclesStartingOnDate: vehicleEntries.filter((it) => it.date == targetDay.toFormat(GfDateUtils.STORED_DATE_FORMAT) && !it.previousDay),
      dailyContainerVehicleStartingOnDate: containerVehiclesToday.content.filter((it) => it.date == targetDay.toFormat(GfDateUtils.STORED_DATE_FORMAT)),
      radioCodeIds: radioCodeIds.content,
      needVehicles: allNeedsVehicles.needVehicleDtoList
    }, () => {
      const radioCodesDto = radioCodeIds.content;
      const needVehicles = allNeedsVehicles.needVehicleDtoList
      let radioMap = new Map();
      radioCodesDto.forEach(it => {
        radioMap.set(it.id, it.name);
      });
      let vehicleMap = new Map();
      needVehicles.forEach(it => {
        vehicleMap.set(it.id, it);
      });



      let shiftMap = new Map();
      shifts.content.forEach(it => {
        shiftMap.set(it.shiftCode, it);
      });

      const { dailyWorkers, workersByService } = this.recalculateWorkers(false, this.state.dailyWorkers, this.state.allWorkers, shiftMap);


      this.setState({
          radioCodeMap: radioMap,
          vehiclesMap: vehicleMap,
          shiftMap,
          fullData: this.state.workersMap.size > 0,
          dailyWorkersStartingOnDate: dailyWorkers,
          workersByService
        });
    });
  }

  private getRelevantForDay(content: NeedDto[], targetDay: DateTime) {
    return content.filter(need => {
      const endDate = DateTime.fromFormat(need.endDate, GfDateUtils.STORED_DATE_FORMAT);
      return endDate >= targetDay;
    });
  }

  private async loadAllWorkers() {
    const workers = await new WorkerRegistryApi().getAll()
    let workersMap = new Map();
    workers.content.forEach(it => {
      workersMap.set(it.id, it);
    });

    const { dailyWorkers, workersByService } = this.recalculateWorkers(false, this.state.dailyWorkers, workers.content);

    this.setState({
      allWorkers: workers.content,
      workersMap,
      dailyWorkersStartingOnDate: dailyWorkers,
      fullData: dailyWorkers.length > 0,
      workersByService
    });

  }

  fixDailyWorkersNames(workers: any[], allWorkers: WorkerDto[]) {
    var dailyWorkers = workers.map(x => {
      var worker = allWorkers.find(w => w.id == x.workerid);
      if (worker) {
        x.name = worker.lastname + ' ' + worker.name;
      }
      return x;
    });

    return dailyWorkers;
  }
}
