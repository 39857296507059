import React, {PureComponent} from "react";
import "./FocusPanel.scss";

export interface Props {
  /** This defines additional classes for the Button */
  className: string,
  /** This dictates what the button will do on click */
  show: boolean,
  text: string,
}

export class FocusPanel extends PureComponent<Props> {
  /************************************************
   * PROPS
   ************************************************/
  static defaultProps: Partial<Props> = {
    className: "",
    show: false,
    text: ""
  };

  /************************************************
   * CONSTRUCTOR
   ************************************************/
  constructor(props: Props) {
    super(props);
  }

  /************************************************
   * LIFECYCLE
   ************************************************/
  render() {
    return (
      <div className={"FocusPanel " + (this.props.show ? "show" : "")}>
        {this.props.text != "" ? <span className={"center"}>{this.props.text}</span> : null}
      </div>
    )
  }

}
