import {DateTime} from "luxon";
import I18n from "../../lib/I18n";

export default class GfDateUtils {

  static readonly STORED_DATE_FORMAT = "yyyy-MM-dd";
  static readonly STORED_DATE_TIME_FORMAT = "yyyy-MM-dd hh:mm:ss";

  static readonly FORMAT_MONTH = "MM/yyyy";
  static readonly FORMAT_MONTH_DASH = "MM-yyyy";
  public static DATE_FORMAT: string = 'dd/MM/yyyy';
  public static DAY_NR: string = 'd cccc';
  public static DAY_NR_WITH_MONTH_YEAR: string = 'cccc d LLLL yyyy';
  public static DAY_MONTH_FORMAT: string = 'dd/MM';
  public static HH_MM: string = 'HH:mm';
  public static FULL_READABLE_DATE = "dd LLLL yyyy";

  public static HH_MM_SS_PRIORITY: string = 'HH:mm:ss ' + GfDateUtils.DATE_FORMAT;

  public static timeBoundaryAsInt : number = 2400;

  public static readonly TIME_SEPARATOR = ":";
  public static readonly WEEK_ENTRIES = 3;

  public static readonly WEEKEND_ENTRIES = 2;
  public static hours_time_limit = 24;
  public static minutes_time_limit = 59;


  public static calculateWorkerAge(birthdate: DateTime): number {
    return Math.floor(Math.abs(birthdate.diffNow("year").years));
  }

  public static convertHHMMToNumber(timeOfDay: String): number {
    let split = timeOfDay.replace(GfDateUtils.TIME_SEPARATOR, "");
    return parseInt(split);
  }

  public static getWeekdays(): string[] {
    return this.getWeekDaysShort().concat(this.getWeekendDaysShort());
  }

  public static getWeekendDaysShort(): string[] {
    return [I18n.get().Weekdays.saturday,
      I18n.get().Weekdays.sunday];
  }

  public static getWeekDaysShort(): string[] {
    return [I18n.get().Weekdays.monday, I18n.get().Weekdays.tuesday, I18n.get().Weekdays.wednesday,
      I18n.get().Weekdays.thursday, I18n.get().Weekdays.friday];
  }

  public static isTimeOutOfRange(hhMM: string[]): boolean {
    if (parseInt(hhMM[0]) > GfDateUtils.hours_time_limit) return true;
    return parseInt(hhMM[1]) > GfDateUtils.minutes_time_limit;
  }
}
