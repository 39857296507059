import React, {Component} from "react";
import {WorkerDto} from "../../../api/entity/WorkerDto";
import I18n from "../../../lib/I18n";
import "./HeaderSection.scss"
import ShiftUtils from "../../../utils/ShiftUtils";

export interface Props {
  onToggleModify: () => void;
  onToggleAddTutor: () => void;
  user: WorkerDto
}

interface State {
}

export default class HeaderSection extends Component<Props, State> {

  private shiftUtils = new ShiftUtils();

  constructor(props) {
    super(props);
  }

  render() {
    return <div>
      <div className={"row"}>
        <div className={"circuitRole"}>
          <span className={"decorated"}>{this.shiftUtils.resolveLabelForRole(this.props.user.role)}</span>
        </div>
        {this.props.user.tutoringId == 0 ?
          <div className={"actionButton"}
               onClick={() => this.props.onToggleAddTutor()}>
            + {I18n.get().WorkersRegistry.addTutor}
          </div>
          : null}
        {this.props.user.tutoringId != 0 ?
          <div className={"actionButton"}
               onClick={() => this.props.onToggleAddTutor()}>
            {I18n.get().WorkersRegistry.modifyTutoring} </div> : null
        }
      </div>
      <div className={"row"}>
        <span>{I18n.get().NeedManagement.addNewNeedManagement.circuit}</span>
        <span> </span>
        <span className={"circuit-title"}>{this.props.user.circuit}
        </span>
        <div onClick={() => this.props.onToggleModify()} className={"actionButton"}>
          {I18n.get().ActionButton.modify}
        </div>
      </div>
      {this.props.user.tutoringId != 0 ?

        <div className={"row"}>
          <span>Tutor</span>
          <span> </span>
          <span
            className={"circuit-title"}>{this.props.user.tutoringFullName == "" ? "-" : this.props.user.tutoringFullName}
        </span>
        </div>
        : null
      }
    </div>;
  }
}
