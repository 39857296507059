import URLUtils from '../lib/utils/URLUtils';
import Api from './Api';
import LoginApi from "./login/LoginApi";
import jwt_decode from "jwt-decode";


class AuthorizeApi {
  private api: Api;

  constructor(private baseUrl: string = URLUtils.getBaseURL() + "/api/v1") {
    this.api = new Api(baseUrl);
  }

  /************************************************
   * PUBLIC FUNCTIONS
   ************************************************/
  public get<R>(
    path: string,
    onAuthError: () => void = () => {
      AuthorizeApi.redirectToLogin()
    },
    query?: { [p: string]: string | number | boolean | null },
    additionalHeaders?: { [p: string]: string }
  ): Promise<R> {
    const token = LoginApi.getToken();
    if (token) {
      this.ensureRefreshedToken(token);
      additionalHeaders = AuthorizeApi.addAdditionalHeadersWithToken(additionalHeaders)
      return this.api.get(path, query, additionalHeaders, onAuthError);
    } else {
      onAuthError();
    }
  }

  public post<T, R>(path: string, value: T, additionalHeaders?: { [key: string]: string }): Promise<R> {
    const token = LoginApi.getToken();
    if (token) {
      this.ensureRefreshedToken(token);
      additionalHeaders = AuthorizeApi.addAdditionalHeadersWithToken(additionalHeaders);
      return this.api.post(path, value, additionalHeaders, ()=> AuthorizeApi.redirectToLogin());
    } else {
      AuthorizeApi.redirectToLogin();
    }
  }

  public put<T, R>(path: string, value: T, additionalHeaders?: { [key: string]: string }): Promise<R> {
    const token = LoginApi.getToken();
    if (token) {
      this.ensureRefreshedToken(token);
      if (additionalHeaders) {
        additionalHeaders['authorization'] = AuthorizeApi.composeTokenString();
      } else {
        additionalHeaders = {authorization: AuthorizeApi.composeTokenString()};
      }
      return this.api.put(path, value, additionalHeaders, ()=> AuthorizeApi.redirectToLogin());
    } else {
      AuthorizeApi.redirectToLogin();
    }
  }

  public delete<T, R>(path: string, additionalHeaders?: { [key: string]: string }): Promise<R> {
    const token = LoginApi.getToken();
    if (token) {
      this.ensureRefreshedToken(token);
      if (additionalHeaders) {
        additionalHeaders['authorization'] = AuthorizeApi.composeTokenString();
      } else {
        additionalHeaders = {authorization: AuthorizeApi.composeTokenString()};
      }
      return this.api.delete(path, additionalHeaders, ()=> AuthorizeApi.redirectToLogin());
    } else {
      AuthorizeApi.redirectToLogin();
    }
  }

  public download(path: string, fileName: string, query?: { [field: string]: string | number | boolean | null }, additionalHeaders?: { [key: string]: string }): Promise<void> {
    const token = LoginApi.getToken();
    if (token) {
      this.ensureRefreshedToken(token);
      if (additionalHeaders) {
        additionalHeaders['authorization'] = AuthorizeApi.composeTokenString();
      } else {
        additionalHeaders = {authorization: AuthorizeApi.composeTokenString()};
      }
      return this.api.download(path, fileName, {}, additionalHeaders,  ()=> AuthorizeApi.redirectToLogin());
    } else {
      AuthorizeApi.redirectToLogin();
    }
  }

  public upload(path: string, formaData: FormData, additionalHeaders?: { [key: string]: string }) /*: {controller:AbortController, promise:Promise<void>}*/ {
    const token = LoginApi.getToken();
    if (token) {
      this.ensureRefreshedToken(token);
      if (additionalHeaders) {
        additionalHeaders['authorization'] = AuthorizeApi.composeTokenString();
      } else {
        additionalHeaders = {authorization: AuthorizeApi.composeTokenString()};
      }
      return this.api.upload(path, formaData, additionalHeaders, ()=> AuthorizeApi.redirectToLogin());
    } else {
      AuthorizeApi.redirectToLogin();
    }
  }

  private static addAdditionalHeadersWithToken(additionalHeaders: { [p: string]: string }) {
    if (additionalHeaders) {
      additionalHeaders['authorization'] = AuthorizeApi.composeTokenString();
    } else {
      return {authorization: AuthorizeApi.composeTokenString()};
    }
    return additionalHeaders;
  }

  static redirectToLogin() {
    location.href = "/";
  }

  private static composeTokenString() {
    return 'Bearer ' + LoginApi.getToken();
  }

  /************************************************
   * PRIVATE FUNCTIONS
   ************************************************/
  private getFullUrl(path: string) {
    if (!path) return this.baseUrl;
    if (path.indexOf('/') == 0) return this.baseUrl + path;
    return this.baseUrl + '/' + path;
  }

  private ensureRefreshedToken(token: string) {
    let jwtDecode: any = jwt_decode(token);
    let exp = jwtDecode.exp;
    let expirationDateFromJwt = this.getExpirationDateFromJwt(exp);
    if (Date.now() < expirationDateFromJwt &&
      this.getTimeInSixHrs() >= expirationDateFromJwt) {
      // console.log("Going to refresh token!");
      // If next refresh is happening in half an hour, refresh it now
      // console.log("New token: " + LoginApi.getToken())
      this.refreshToken();
    }
  }

  private async refreshToken() {
    return new LoginApi().refreshToken(LoginApi.getToken());
  }

  private getTimeInSixHrs() {
    return Date.now() + this.getSixHoursInMs();
  }

  private getExpirationDateFromJwt(exp) {
    return exp * 1000;
  }

  private getSixHoursInMs() {
    return 3600000 * 6;
  }
}

export default AuthorizeApi;
