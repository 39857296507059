import React from "react";
import Icon, {Props} from "./Icon";

export default class IconBackBtn extends Icon {
  static defaultProps: Partial<Props> = {
    height: 15,
    width: 15
  };

  render() {
    return (
      <svg className="IconBackBtn"
           width={this.props.width}
           height={this.props.height}
           viewBox="0 0 24 24">
        <defs>
          <filter id="p98yyeauwa">
            <feColorMatrix in="SourceGraphic" values="0 0 0 0 0.035294 0 0 0 0 0.654902 0 0 0 0 0.937255 0 0 0 1.000000 0"/>
          </filter>
          <path id="m1gvjkbvyb" d="M0 0H20V20H0z"/>
        </defs>
        <g fill="none" fillRule="evenodd">
          <g>
            <g filter="url(#p98yyeauwa)" transform="translate(-247 -82) translate(247 82)">
              <mask id="tvi7hrtrkc" fill="#fff">
                <use xlinkHref="#m1gvjkbvyb"/>
              </mask>
              <path fill="#2E3A59" d="M11.208 5.833l1.179 1.179L9.44 9.958l2.947 2.946-1.179 1.179-4.125-4.125 4.125-4.125zM18.333 10c0 4.602-3.73 8.333-8.333 8.333-4.602 0-8.333-3.73-8.333-8.333 0-4.602 3.73-8.333 8.333-8.333 4.602 0 8.333 3.73 8.333 8.333 0 3.068 0 3.068 0 0zm-1.666 0c0 3.682-2.985 6.667-6.667 6.667S3.333 13.682 3.333 10 6.318 3.333 10 3.333 16.667 6.318 16.667 10c0 2.455 0 2.455 0 0z" mask="url(#tvi7hrtrkc)"/>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
