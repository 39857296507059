import React, {PureComponent} from "react";
import "./GfDatePicker.scss";
import {DateTime} from "luxon";
import DatePicker from "react-date-picker";


interface Props {
  /** This defines additional classes for the Button */
  className?: string,
  label?: string,
  onChange: (date: DateTime) => void
  date: DateTime
  errorMessage?: String
  minDate?: DateTime
  maxDate?: DateTime
  disabled? : boolean
}

interface State {
  selectedDate: DateTime
}

export class GfDatePicker extends PureComponent<Props, State> {

  private LOWER_BOUND_DATE = DateTime.fromISO("1899-12-31T23:00:00.000Z");

  constructor(props: Props) {
    super(props);
    this.state = {selectedDate: this.props.date};
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if(prevProps.date != this.props.date) {
      this.setState({selectedDate: this.props.date});
    }
  }

  private onChange(date) {
    if (date instanceof Object) {
      let dateTime = DateTime.fromJSDate(date).endOf("day");
      this.props.onChange(dateTime);
      this.setState({selectedDate: dateTime})
    }
  }

  private readonly FORMAT = "dd-MM-yyyy";

  render() {
    return (
      <div className={"DatePicker " + (this.props.className ? this.props.className : "")}>
        <label className={"TextInput-label"}>{this.props.label}</label>
        <div className={"value-input"}>
          <DatePicker
            disabled={!!this.props.disabled}
            returnValue={"start"}
            onChange={(callback) => this.onChange(callback)}
            value={this.state.selectedDate ? this.state.selectedDate.toJSDate() : null}
            minDate={this.props.minDate ? this.props.minDate.toJSDate() : this.LOWER_BOUND_DATE.toJSDate()}
            className={(!!this.props.errorMessage ? "TextInput-borderError " : " ")}
            maxDate={this.props.maxDate ? this.props.maxDate.toJSDate() : null}
            calendarIcon={null}
            clearIcon={null}
            format={this.FORMAT}
          />
          {/*<IconCalendar className={"icon"} width={25} height={25} />*/}
        </div>
        {!!this.props.errorMessage && <span className="TextInput-error">{this.props.errorMessage}</span>}
      </div>
    )
  }
}
