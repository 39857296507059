import React, {Component} from "react";
import "./MonthlyTimeLineButtonBar.scss"
import {MonthlyCompletedView} from "../MonthlyPlanning";
import Button from "../../../components/Button/Button";
import I18n from "../../../lib/I18n";

interface Props {
  isDataAvailable: boolean,
  isElapsedAvailable: boolean,
  isOverloadedAvailable: boolean,
  state: MonthlyCompletedView
  onSelect: (MonthlyCompletedView) => void;
}

export default class MonthlyPlanningViewSelector extends Component<Props, {}> {
  /**************************************************
   * CONSTRUCTOR
   **************************************************/
  constructor(props: Props) {
    super(props);
  }

  /**************************************************
   * LIFECYCLE
   **************************************************/
  public render() {
    return <div className="MonthlyPlanningViewSelector">
      <div className="MonthlyPlanning-actions">
        <Button
          onClick={() => this.props.onSelect(MonthlyCompletedView.PLANNING)}
          secondary={this.props.state != MonthlyCompletedView.PLANNING}>
          {I18n.get().MonthlyPlanning.viewType.planning}
        </Button>
        <Button
          onClick={() => this.props.onSelect(MonthlyCompletedView.ELAPSED)}
          secondary={!this.props.isElapsedAvailable || this.props.state != MonthlyCompletedView.ELAPSED}
          disabled={!this.props.isElapsedAvailable}>
          {I18n.get().MonthlyPlanning.viewType.elapsed}
        </Button>
        <Button
          onClick={() => this.props.onSelect(MonthlyCompletedView.OVERLOADED)}
          secondary={!this.props.isOverloadedAvailable || this.props.state != MonthlyCompletedView.OVERLOADED}
          disabled={!this.props.isOverloadedAvailable}>
          {I18n.get().MonthlyPlanning.viewType.overloaded}
        </Button>
      </div>
    </div>;
  }
}
