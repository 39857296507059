import React from "react";
import "./DailyEmployeeCell.scss"
import ShiftUtils from "../../../../utils/ShiftUtils";
import IconWorker from "../../../../icons/IconWorker";
import IconTrash from "../../../../icons/IconTrash";
import {SpecialServices} from "../../../../api/need/NeedUtils";

interface Props{
  onClick?:()=>void
  className?:string
  mainService:string
  allServices?: string[]
  name:string
  role?:string
  skill:string
  background?:string;
  manuallyCreated?:boolean
  onDelete?:()=>void
}

export default function DailyEmployeeCell(props:Props){

  function getServiceClass(service: string) {
    if(!service)
      return "free";

    switch (service?.toUpperCase()){
      case SpecialServices.LIBERO.toUpperCase():
      case SpecialServices.REPERIBILE.toUpperCase():
        return "free"
      case SpecialServices.FERIE.toUpperCase():
        return "vacation"
      case SpecialServices.RIPOSO.toUpperCase():
        return "rest"
      case SpecialServices.FUORI_TURNO.toUpperCase():
      case SpecialServices.SMONTO.toUpperCase():
        return "busy";
      default:
        return "other";
    }
  }

  function getServiceShort(service: string) {
    if(service.toLowerCase() == "assenza giornaliera"){
      return "ar+gr".toUpperCase();
    }
    return service;
  }

  let style = {}
  if(props.background)
    style = {background:props.background};

  return <div className={`DailyEmployeeCell ${props.className ? props.className : ""}`} style={style} onClick={()=> props.onClick ? props.onClick():null}>
    <div>
      <span className={`DailyEmployeeCell-service ${getServiceClass(props.mainService)}`}>{props.mainService ? getServiceShort(props.mainService) : SpecialServices.LIBERO}</span>
      {props.allServices && props.allServices.length > 1 ?
        <div className={"otherServices"} title={props.allServices.slice(1, props.allServices.length).join("\n")}>[+{props.allServices.length - 1}]</div>
        : null }
    </div>

    { props.skill != "" ? <div>
      <span className={`DailyEmployeeCell-skill skillLabel ${"EM+" ? "EM_PLUS" : props.skill}`}>{props.skill}</span>
    </div>   : null }
    <span className="DailyEmployeeCell-name .ellipsis" title={props.name}>{props.name}</span>
    {props.manuallyCreated && <span className="DailyEmployeeCell-IconTrash" onClick={()=> props.onDelete()}><IconTrash width={24} height={24}/></span>}
    {props.role && <div className={`DailyEmployeeCell-role ${props.role == "NURSE" ? "nurse" : "driver"}`}>
      <IconWorker />
      {new ShiftUtils().resolveLabelForRole(props.role)}
    </div>}
  </div>
}
