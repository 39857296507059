import React, {Component} from "react";
import "./HeaderSection.scss";
import I18n from "../../../lib/I18n";
import {VehicleDto} from "../../../api/entity/VehicleDto";
import ShiftUtils from "../../../utils/ShiftUtils";
import IconTelephone from "../../../icons/IconTelephone";
import IconCalendarVehicle from "../../../icons/IconCalendarVehicle";
import LineHorizontal from "../../../components/LineHorizontal/LineHorizontal";
import IconToastSuccess from "../../../icons/IconToastSuccess";
import IconStopProcess from "../../../icons/IconStopProcess";
import {DateTime} from "luxon";

export interface Props {
  onToggleModify: () => void;
  vehicle: VehicleDto
}

interface State {
}

export default class HeaderSection extends Component<Props, State> {

  static defaultProps: Partial<Props> = {
    onToggleModify: () => {
    },
    vehicle: new ShiftUtils().getEmptyVehicleDto()
  };

  render() {
    const type = this.props.vehicle.category.toUpperCase();
    return (
      <div className={"HeaderSection"}>
        <div className={"head"}>
          <div className={"skillLabel " + (type == "EM+" ? "EM_PLUS" : type)}>
            {this.props.vehicle.category}
          </div>
          <span className={"actionButton"} onClick={() => this.props.onToggleModify()}>
    {I18n.get().ActionButton.modify}
    </span>
        </div>
        <div className={"details"}>
          <div className={"registrySubtitle"}>
            <span className={"subtitle"} title={this.props.vehicle.name}>{this.props.vehicle.name}</span>
            <span className={"side-subtitle"}>{this.props.vehicle.plate}</span>
          </div>
          <div className={"registryDetails"}>
            <div className={"row"}>
              <div>
                <label><IconTelephone width={16} height={16}/></label>
                <label>{I18n.get().VehicleModal.telephone}</label>
              </div>
              <div>
                <label><IconCalendarVehicle width={16} height={16}/></label>
                <label>{I18n.get().VehicleModal.validityStart}</label>
              </div>
              <div>
                <label>{I18n.get().VehicleModal.validityEnd}</label>
              </div>
            </div>
            <div className={"row row-content"}>
              <div>{this.props.vehicle.telephone}</div>
              <div>{DateTime.fromFormat(this.props.vehicle.validitystart, "yyyy-MM-dd").toFormat("dd-MM-yyyy")}</div>
              <div>{DateTime.fromFormat(this.props.vehicle.validityend, "yyyy-MM-dd").toFormat("dd-MM-yyyy")}</div>
            </div>
            <div className={"row circuit"}>
              <div>{I18n.get().VehicleModal.circuit} <span className={"bold"}>{this.props.vehicle.circuit}</span></div>
            </div>
          </div>
        </div>
        <LineHorizontal/>
        <div className={"vehicleDetails"}>
          <div className={"row"}>
            <h2>{I18n.get().VehicleModal.detail}</h2>
          </div>
          <div className={"row"}>
            <div>
              {I18n.get().VehicleModal.air}
            </div>
            <div>
              {I18n.get().VehicleModal.obese}
            </div>
            <div>
              {I18n.get().VehicleModal.babyCradle}
            </div>
            <div>
              {I18n.get().VehicleModal.pcCar}
            </div>
          </div>
          <div className={"row"}>
            <div>
              {this.props.vehicle.air ? <IconToastSuccess/> : <IconStopProcess width={15} color={"#ff0000"}/>}
            </div>
            <div>
              {this.props.vehicle.obese ? <IconToastSuccess/> : <IconStopProcess width={15} color={"#ff0000"}/>}
            </div>
            <div>
              {this.props.vehicle.babycradle ? <IconToastSuccess/> : <IconStopProcess width={15} color={"#ff0000"}/>}
            </div>
            <div>
              {this.props.vehicle.pccar ? <IconToastSuccess/> : <IconStopProcess width={15} color={"#ff0000"}/>}
            </div>
          </div>
          <div className={"row"}>
            <div>
              {I18n.get().VehicleModal.telepass}
            </div>
            <div>
              {I18n.get().VehicleModal.pedimate}
            </div>
            <div>

            </div>
            <div>

            </div>
          </div>
          <div className={"row"}>
            <div>
              {this.props.vehicle.telepass ? <IconToastSuccess/> : <IconStopProcess width={15} color={"#ff0000"}/>}
            </div>
            <div>
              {this.props.vehicle.pedimate ? <IconToastSuccess/> : <IconStopProcess width={15} color={"#ff0000"}/>}
            </div>
          </div>
        </div>

      </div>
    )
  }
}
