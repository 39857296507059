import React, {Component} from "react";
import Select from "react-select";
import {FixedShiftDto} from "../../../../api/entity/FixedShiftDto";
import {ShiftDto} from "../../../../api/entity/ShiftDto";
import GfDateUtils from "../../../DatePicker/GfDateUtils";
import ShiftUtils from "../../../../utils/ShiftUtils";
import {ToggleDayButton} from "../../../WeekToggleGroup/ToggleDayButton/ToggleDayButton";

export interface Props {
  title: string,
  /** This defines additional classes for the Button */
  className: string,
  index: number,
  /** Disables onclick
   *  @default false
   **/
  daysAlreadySelected: number[]
  availableShifts: ShiftDto[];
  fixedShift: FixedShiftDto;
  onShiftChange: (index: number, fixedShifts: FixedShiftDto,
                  previousShift: FixedShiftDto) => void;
  onCancel: (e: FixedShiftDto) => void;
  disabled: boolean;
}

interface State {
  fixedShift: FixedShiftDto,
  availableShifts: ShiftDto[],
  options: any[]
}

export interface Entry {
  value: ShiftDto,
  label: string
}

export class FixedShiftEntry extends Component<Props, State> {

  private shiftUtils = new ShiftUtils();

  private daysLabel = GfDateUtils.getWeekDaysShort().concat(GfDateUtils.getWeekendDaysShort());
  /************************************************
   * PROPS
   ************************************************/
  static defaultProps: Partial<Props> = {
    title: "",
    className: "",
    onCancel: () => {
    },
    disabled: false,
  };

  /************************************************
   * CONSTRUCTOR
   ************************************************/
  constructor(props: Props) {
    super(props);
    this.state = {
      fixedShift: props.fixedShift,
      availableShifts: props.availableShifts,
      options: props.availableShifts.filter(a => a)
        .map((a, i) => {
          return {value: props.availableShifts[i], label: a.shiftCode}
        })
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (prevProps.availableShifts != this.props.availableShifts) {
      this.setState({
        availableShifts: this.props.availableShifts, options:
          this.props.availableShifts.filter(a => a).map((shift, i) => {
              return {value: this.props.availableShifts[i], label: shift.shiftCode}
          })
      });
    }
  }

  private onToggleDay(dayIndex: number) {
    let {fixedShift} = this.state;
    // Prevent If the day has already been selected and trying to activate
    if (this.props.daysAlreadySelected.indexOf(dayIndex) != -1 && fixedShift.days[dayIndex] == 0) {
      return;
    }
    fixedShift.days[dayIndex] = fixedShift.days[dayIndex] == 0 ? 1 : 0;
    this.props.onShiftChange(this.props.index, this.state.fixedShift, this.state.fixedShift);
  }

  private onShiftChange(e: Entry) {
    let {fixedShift} = this.state;
    let previousShiftCode: FixedShiftDto =
      {
        shiftCode: fixedShift.shiftCode, shiftId: fixedShift.shiftId,
        days: fixedShift.days, userId: fixedShift.userId, id: fixedShift.id
      };
    fixedShift.shiftId = e.value.id;
    fixedShift.shiftCode = e.value.shiftCode;
    this.setState({fixedShift});
    this.props.onShiftChange(this.props.index, fixedShift, previousShiftCode);
  }

  private onDelete(e: FixedShiftDto) {
    this.props.onCancel(e);
  }

  /************************************************
   * LIFECYCLE
   ************************************************/
  render() {
    return (
      <div className="row-inner">
        {this.daysLabel.map((d, i) =>
          <ToggleDayButton className={"row-btn"}
                           label={this.daysLabel[i]}
                           isSelected={this.state.fixedShift.days[i] == 1}
                           key={`${this.props.availableShifts.length + "_" + d + "_" + this.state.fixedShift.shiftId}`}
                           index={i}
                           onToggle={(dayIndex) =>
                             this.onToggleDay(dayIndex)}/>
        )}
        <Select className={"Select"}
                onChange={(e) => this.onShiftChange(e)}
                options={this.state.options}
                key={this.props.index}
                value={{label: this.state.fixedShift.shiftCode, value: this.state.fixedShift.shiftId}}
        />
        <div onClick={() => this.onDelete(this.state.fixedShift)} className={"close"}>&times;</div>
      </div>
    )
  }
}
