import React, {Component} from "react";
import WizardStepZero from "./WizardStepZero/WizardStepZero";
import WizardStepOne from "./WizardStepOne/WizardStepOne";
import WizardStepTwo from "./WizardStepTwo/WizardStepTwo";
import WizardStepThree from "./WizardStepThree/WizardStepThree";
import {WorkerDto} from "../../../api/entity/WorkerDto";
import {ShiftDto} from "../../../api/entity/ShiftDto";
import {DateTime} from "luxon";
import {FixedShiftDto} from "../../../api/entity/FixedShiftDto";

interface Props {
  currentStep: WizardStep
  month: DateTime
  workers: WorkerDto[]
  excludedWorkers: WorkerDto[]
  shifts: ShiftDto[]
  fixedShifts:FixedShiftDto[]
  onConvergenceCheck: () => void;
  onStepChange: (step: WizardStep) => void;
  lastDayMap:{[id:number]:string[]},
  circuitFilter: string,
}

export enum WizardStep {
  ZERO  = "ZERO",
  ONE   = "ONE",
  TWO   = "TWO",
  THREE = "THREE",
}

export default class MonthlyPlanningWizard extends Component<Props> {
  /**************************************************
   * LIFECYCLE
   **************************************************/
  render() {
    const {currentStep, month, workers, shifts, excludedWorkers,
      fixedShifts, lastDayMap, circuitFilter} = this.props;
    switch (currentStep) {
      case WizardStep.ONE:
        return <WizardStepOne
          currentDate={month}
          excludedWorkers={excludedWorkers}
          workers={workers} shifts={shifts}
          fixedShifts={fixedShifts}
          lastDayMap={lastDayMap}
          goToStepTwo={() => this.goToStepTwo()}
          goBack={() => this.goToStepZero()}
          circuitFilter={circuitFilter}
        />
      case WizardStep.TWO:
        return <WizardStepTwo
          circuitSearch={circuitFilter}
          workers={workers}
          goToStepThree={() => this.goToStepThree()}
          currentDate={month.toJSDate()}
          onClickBack={() => this.goToStepOne()}
        />
      case WizardStep.THREE:
        return <WizardStepThree
          onConvergenceCheck={async () => this.props.onConvergenceCheck()}
          goBack={() => this.goToStepTwo()}
        />
      default:
        return <WizardStepZero
          goToStepOne={() => this.goToStepOne()}
        />
    }
  }

  /**************************************************
   * PRIVATE FUNCTIONS
   **************************************************/
  private goToStepZero() {
    this.props.onStepChange(WizardStep.ZERO);
  }

  private goToStepOne() {
    this.props.onStepChange(WizardStep.ONE)
  }

  private goToStepTwo() {
    this.props.onStepChange(WizardStep.TWO)
  }

  private goToStepThree() {
    this.props.onStepChange(WizardStep.THREE)
  }
}
