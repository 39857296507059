import React from "react";
import Page from "../Page";
import './VehicleManagement.scss';
import {FocusPanel} from "../../components/FocusPanel/FocusPanel";
import {Legend} from "../../components/Legend/Legend";
import {DateTime} from "luxon";
import MonthlyNavigationBar from "./MonthlyNavigationBar/MonthlyNavigationBar";
import MonthlyVehicleTimeLine from "./MonthlyVehicleTimeLine/MonthlyVehicleTimeLine";
import {VehicleDto} from "../../api/entity/VehicleDto";
import VehicleApi from "../../api/vehicle/VehicleApi";
import {VehicleUnavailabilityModal} from "../../components/Modal/VehicleUnavailabilityModal/VehicleUnavailabilityModal";
import {VehicleUnavailabilityDto} from "../../api/entity/VehicleUnavailabilityDto";
import VehicleUnavailabilityApi from "../../api/vehicle/VehicleUnavailabilityApi";
import {VehicleResponse} from "../../api/entity/response/VehicleResponse";
import I18n from "../../lib/I18n";
import {ConfirmModal} from "../../components/Modal/ConfirmModal/ConfirmModal";
import GfDateUtils from "../../components/DatePicker/GfDateUtils";
import Button from "../../components/Button/Button";
import EngineApi from "../../api/engine/EngineApi";
import VehiclePlanningApi from "../../api/vehicle/VehiclePlanningApi";
import IconError from "../../icons/IconError";
import {VehiclePlanningResponse} from "../../api/entity/response/VehiclePlanningResponse";
import IconToastSuccess from "../../icons/IconToastSuccess";
import {InformationModal} from "../../components/Modal/InformationModal/InformationModal";
import { VehicleDailyPlanningDto } from "../../api/entity/VehicleDailyPlanningDto";
import VehicleManagementUtils from "./VehicleManagementUtils";
import {CircuitSelect} from "../../components/CircuitSelect/CircuitSelect";

interface State {
  isCreateModalOpen: boolean
  isConfirmModalOpen: boolean
  isVehiclePlanningRunning: boolean
  isInformationModalOpen: boolean
  circuitSelect: string,
  signaledForDeletion: VehicleUnavailabilityDto
  vehicles: VehicleDto[],
  vehicleUnavailabilitiesForMonth: VehicleUnavailabilityDto[],
  dailyPlanningVehicles: VehicleDailyPlanningDto[],
  vehicleUnavailability: VehicleUnavailabilityDto,
  runResponse: VehiclePlanningResponse
  month: DateTime
  today: DateTime
  selectedVehicle: VehicleDto
}

export default class VehicleManagement extends Page<{}, State> {

  private readonly sevenDaysPlanning = 7;

  private vehicleApi = new VehicleApi();
  private vehicleApiUnavailability = new VehicleUnavailabilityApi();
  private vehiclePlanningApi = new VehiclePlanningApi();
  private engineApi = new EngineApi();

  private planningChecker: any = null;

  public constructor(props) {
    super(props);
    this.state = {
      isCreateModalOpen: false,
      isConfirmModalOpen: false,
      isVehiclePlanningRunning: false,
      isInformationModalOpen: false,
      circuitSelect: CircuitSelect.NO_FILTER,
      vehicleUnavailability: null,
      vehicleUnavailabilitiesForMonth: [],
      dailyPlanningVehicles: [],
      runResponse: null,
      selectedVehicle: null,
      signaledForDeletion: null,
      vehicles: [],
      month: DateTime.now().startOf("month"),
      today: DateTime.now()
    }
  }

  componentDidMount() {
    this.onLoadForMonth(this.state.month);
    this.onVehiclePageLoadCheckRun();
    this.checkOnesToSeeStatus();
  }

  private checkOnesToSeeStatus() {
    this.onCheckPlanningStatus(() => {
      this.setState({isVehiclePlanningRunning: true});
      this.startWatchingRunningStatus()
    }, () => {
    });
  }

  private startWatchingRunningStatus() {
    this.planningChecker = setInterval(() => this.onCheckPlanningStatus(() => {
      this.setState({isVehiclePlanningRunning: true});
    }, () => {
      this.setState({isVehiclePlanningRunning: false});
      this.onVehiclePageLoadCheckRun();
      this.onLoadForMonth(this.state.month);
    }), 2000)
  }

  private onCheckPlanningStatus(onProgress: () => void, onOther: () => void) {
    const tomorrowDate = this.state.today.plus({day: 1});
    this.vehiclePlanningApi.get(tomorrowDate).then(
      (value) => {
        if (value.planning.status == "IN_PROGRESS") {
          onProgress();
        } else {
          onOther();
        }
      }
    )
  }

  componentWillUnmount() {
    clearInterval(this.planningChecker);
  }

  private async onVehicleLoad(date: DateTime) {
    const vehiclesResponse = await this.vehicleApi.getOrSearchValid(date,
      "",
      3000, 0, true);
    this.setState({vehicles: vehiclesResponse.content});
  }

  private async onVehicleCreate(id: number) {
    const foundData: VehicleResponse = await this.vehicleApi.getVehicleById(id);
    const date = DateTime.now().startOf("day");
    let vehicleUnavailabilityDto: VehicleUnavailabilityDto = {
      vehicleId: foundData.content[0].id, id: null,
      fromDate: date, toDate: date,
      motivation: VehicleUnavailabilityModal.MOTIVATION_DTO[0], fromTime: "", toTime: "",
      description: ""
    };
    this.setState(
      {
        selectedVehicle: foundData.content[0],
        vehicleUnavailability: vehicleUnavailabilityDto
      },
      () => this.toggleCreateModifyModal());
  }

  private toggleCreateModifyModal() {
    let {isCreateModalOpen} = this.state;
    this.setState({isCreateModalOpen: !isCreateModalOpen});
  }

  private async saveUnavailability(vehicleUnavailabilityDto: VehicleUnavailabilityDto) {
    if (vehicleUnavailabilityDto.id == null) {
      await this.vehicleApiUnavailability.create(vehicleUnavailabilityDto).then(() => {
        this.toggleCreateModifyModal();
        this.onLoadForMonth(this.state.month);
      });
      return;
    }

    await this.vehicleApiUnavailability.update(vehicleUnavailabilityDto).then((r) => {
      this.toggleCreateModifyModal();
      this.onLoadForMonth(this.state.month);
    });
  }

  private async onLoadForMonth(day: DateTime) {
    const ensuredStartOfMonth = day.startOf("month");
    this.setState({month: ensuredStartOfMonth});
    this.onVehicleLoad(ensuredStartOfMonth);
    const vehicleDailyResponse = await this.vehiclePlanningApi.getDailyPlanningVehicleByMonth(ensuredStartOfMonth);
    const vehicleUnavailabilityResponse = await this.vehicleApiUnavailability.getVehicleInMonth(ensuredStartOfMonth);
    this.setState({
      vehicleUnavailabilitiesForMonth: vehicleUnavailabilityResponse.content,
      dailyPlanningVehicles: vehicleDailyResponse.content
    });
  }

  private onSignalDeletion(vehicleUnavailability: VehicleUnavailabilityDto) {
    this.setState({signaledForDeletion: vehicleUnavailability});
    this.toggleConfirmModal();
  }

  private async onDelete(vehicleUnavailability: VehicleUnavailabilityDto) {
    await this.vehicleApiUnavailability.delete(vehicleUnavailability.id)
      .then(() => {
        this.toggleConfirmModal();
        this.onLoadForMonth(this.state.month)
      });
  }

  private toggleConfirmModal() {
    let {isConfirmModalOpen} = this.state;
    isConfirmModalOpen = !isConfirmModalOpen;
    this.setState({isConfirmModalOpen: isConfirmModalOpen});
  }

  private startPlanning() {
    this.startWatchingRunningStatus();
    const tomorrow = this.state.today.plus({day: 1});
    this.engineApi.startVehiclePlanning(tomorrow);
  }

  private startSubmission() {
    this.engineApi.startVehicleSubmission();
  }

  private onVehiclePageLoadCheckRun() {
    this.vehiclePlanningApi.getLast().then((resp) => {
      this.setState({runResponse: resp});
    });
  }

  private toggleInformationErrors() {
    this.setState({isInformationModalOpen: !this.state.isInformationModalOpen})
  }

  private getFilteredVehicles() {
    return this.state.vehicles
      .filter(v => this.state.circuitSelect == CircuitSelect.NO_FILTER ||
        v.circuit.toLowerCase() == this.state.circuitSelect.toLowerCase());
  }

  private onCircuitSelect(circuit: string) {
    this.setState({circuitSelect: circuit});
  }

  render() {

    let {month} = this.state;
    let signaledForDeletion = this.state.signaledForDeletion;

    const todayDate = DateTime.now();
    const tomorrow = todayDate.plus({day: 1});
    const startPlanningBtnLabel = I18n.get().VehicleRegistry.startPlanning + " dal " +
      tomorrow.toFormat(GfDateUtils.DAY_MONTH_FORMAT) + " - " +
      tomorrow.plus({day: this.sevenDaysPlanning}).toFormat(GfDateUtils.DAY_MONTH_FORMAT);

    return <div className={"VehicleManagement"}>

      {this.state.isInformationModalOpen ?
        <InformationModal
          title={"Errori dell'ultima pianificazione"}
          onOk={() => this.toggleInformationErrors()}
          body={<p>{this.state.runResponse.planning.errors}</p>}/> : null
      }

      <div className={"inner-content"}>
        <div>
          <div className={"top"}>
            <div className={"up-title"}>
              <h1>{I18n.get().Menu.vehicleManagement}</h1>
            </div>
          </div>

          <div className={"up-controls"}>

            <Legend legend={[
              {color:MonthlyVehicleTimeLine.SERVICE_ONLY_BUSY_COLOR,label:I18n.get().VehiclePlanning.assigned},
              {color:MonthlyVehicleTimeLine.SERVICE_AND_UNAVAILABILITY_BUSY_COLOR,label:I18n.get().VehiclePlanning.assignedAndUnavailability},
              {color:MonthlyVehicleTimeLine.LAV_ONLY_BUSY_COLOR,label:I18n.get().VehiclePlanning.washing}
            ]} />

            <div className={"execution-details"}>
              {(this.state.runResponse && this.state.runResponse.planning) ?
                <>{I18n.get().VehiclePlanning.lastRun} {I18n.get().VehiclePlanning.completedOn} {DateTime.fromISO(this.state.runResponse.planning.lastupdate).toLocal().toFormat(GfDateUtils.DAY_MONTH_FORMAT)}
                  <div className={"result-icon"}>
                    {this.state.runResponse.planning.errors != "" ?
                      <span className="actionable" onClick={() => {
                        this.toggleInformationErrors();
                      }}><IconError/></span>
                      : <span><IconToastSuccess/></span>}
                  </div>
                </> : null}
            </div>

            {/*<Button*/}
            {/*  onClick={() => {this.startSubmission();}}>Invia dati engineering</Button>*/}
            <Button
              disabled={this.state.isVehiclePlanningRunning}
              onClick={() => {
                this.startPlanning();
              }}>
              {this.state.isVehiclePlanningRunning ? I18n.get().VehiclePlanning.runningPlanning : startPlanningBtnLabel}
            </Button>
          </div>
          <div>
            <CircuitSelect value={this.state.circuitSelect} onChange={(circuit)=> { this.onCircuitSelect(circuit) }} />
          </div>

          {this.state.isConfirmModalOpen ?
            <ConfirmModal title={I18n.get().VehicleModal.deleteTitle} body={I18n.get().VehicleModal.deleteBody
              + " " + this.state.vehicles.filter(v => v.id == signaledForDeletion.vehicleId)[0].name + ", " + signaledForDeletion.motivation.toUpperCase() + "("
              + signaledForDeletion.fromDate.toFormat(GfDateUtils.DATE_FORMAT) + "," + signaledForDeletion.fromTime +
              " - " + signaledForDeletion.toDate.toFormat(GfDateUtils.DATE_FORMAT) + "," + signaledForDeletion.toTime + "), " + I18n.get().VehicleUnavailabilityModal.notes
              + ": '" + signaledForDeletion.description + "' ?"}
                          onClick={() => this.onDelete(signaledForDeletion)}
                          onCancel={() => this.toggleConfirmModal()}/> : null}

          <FocusPanel
            show={this.state.isCreateModalOpen || this.state.isConfirmModalOpen || this.state.isInformationModalOpen}/>
          <div className={"planning-panel"}>
            <FocusPanel show={this.state.isVehiclePlanningRunning} text={I18n.get().VehiclePlanning.runningPlanning}/>
          </div>
          <div className="DailyPlanningTitleBar">
          </div>

          {this.state.isCreateModalOpen ?
            <VehicleUnavailabilityModal
              vehicle={this.state.selectedVehicle}
              vehicleUnDto={this.state.vehicleUnavailability}
              onCancel={() => this.toggleCreateModifyModal()}
              onSubmit={(vehicleUnavailabityDto) => this.saveUnavailability(vehicleUnavailabityDto)}
              month={this.state.month.startOf("month")}
            /> :
            null}

          <MonthlyNavigationBar
            currentDate={month}
            onDateChanged={(day) => {
              this.onLoadForMonth(day)
            }}
          />


          <div>
            <MonthlyVehicleTimeLine
              promptMenu={(x, y) => console.log(x, y)}
              onVehicleIdLoad={(id) => this.onVehicleCreate(id)}
              vehicleUnavailabilities={this.state.vehicleUnavailabilitiesForMonth}
              dailyPlanningVehicles={this.state.dailyPlanningVehicles}
              onToggleDeleteConfirmModal={(vu) => this.onSignalDeletion(vu)}
              day={month} allVehicles={this.getFilteredVehicles()}
            />
          </div>
        </div>
      </div>
    </div>
  }
}
