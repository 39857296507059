import React, {Component} from "react";
import "./WorkerServiceTurnModal.scss";
import I18n from "../../../lib/I18n";
import Select from "react-select";
import {DailyPlanningWorkerDto} from "../../../api/entity/response/nt/DailyPlanningWorkerResponseNt";
import {ShiftDto} from "../../../api/entity/ShiftDto";
import Button from "../../Button/Button";
import DailyPlanningApi from "../../../api/planning/DailyPlanningApi";
import {DateTime} from "luxon";
import {Worker} from "../../../pages/DailyPlanning/DailyWorkersTimeLine/DailyWorkersTimeLine";
import TextInput from "../../TextInput/TextInput";
import {DailyContainerVehicle} from "../../../api/entity/response/nt/ContainerVehicleResponseNt";
import {VehicleServiceTurnModal} from "../VehicleServiceTurnModal/VehicleServiceTurnModal";
import {NeedVehicleDto} from "../../../api/entity/NeedVehicleDto";
import {CircuitSelect} from "../../CircuitSelect/CircuitSelect";
import {NeedDto} from "../../../api/entity/NeedDto";

interface Props {
  date: DateTime,
  worker: Worker
  shifts: ShiftDto[],
  needs: NeedDto[],
  containerVehicles: DailyContainerVehicle[],
  isWorkerCreationModal: boolean
  recordsDto: DailyPlanningWorkerDto[]
  onSave: (records: DailyPlanningWorkerDto[],
           deletedRecords: DailyPlanningWorkerDto[]) => void
  onCancel: () => void
  radioMap: Map<number, string>
  vehicleNeedMap: Map<number, NeedVehicleDto>
}

interface State {
  circuitFilter: string,
  combinations: Combination[],
  fullNameForCreation: string,
  isEditingAllowed: boolean,
  shifts: ShiftDto[],
  records: DailyPlanningWorkerDto[],
  deletedRecords: DailyPlanningWorkerDto[],
  entriesCombination: Combination[], // may be different for each entry
  errors: WorkerServiceTurnModalError,
  containerOptions: SelectOption[][]
}

interface Shift {
  name: string,
  startTime: string,
  endTime: string
}

interface Combination {
  serviceOrContainerVehicle: string;
  radioCodeId: number;
  shifts: Shift[];
  circuit: string,
}

interface WorkerServiceTurnModalError {
  name: string;
  shiftWarning: string;
}

interface SelectOption {
  label: string,
  value: string,
}

interface ContainerToRadio {
  name: string
  radioId: number
  circuit?: string
}

export class WorkerServiceTurnModal extends Component<Props, State> {

  private static START_TIME_OFF = "00:00";
  private static END_TIME_OFF = "23:59";

  private static LIBERO = "LIBERO";

  public static REST_SHIFT = "R";
  public static SMONTO_SHIFT = "S";
  public static REP_SHIFT = "REP";
  public static DAILY_ABSENCE_GR = "GR";
  public static DAILY_ABSENCE_AR = "AR";
  public static HOLIDAYS_ABSENCE = "F";

  public static TIME_OFF_SHIFTS = [
    WorkerServiceTurnModal.REST_SHIFT, WorkerServiceTurnModal.SMONTO_SHIFT,
    WorkerServiceTurnModal.REP_SHIFT, WorkerServiceTurnModal.DAILY_ABSENCE_GR,
    WorkerServiceTurnModal.DAILY_ABSENCE_AR, WorkerServiceTurnModal.HOLIDAYS_ABSENCE]
  public static OTHER_KEYS: Combination[] = [{
    serviceOrContainerVehicle: WorkerServiceTurnModal.LIBERO,
    shifts: [],
    radioCodeId: 0,
    circuit: ""
  }];

  private static REST_SERVICE = "RIPOSO";
  private static SMONTO_SERVICE = "SMONTO";
  private static REPERIBILE_SERVICE = "REPERIBILE";
  private static DAILY_ABS_SERVICE = "ASSENZA GIORNALIERA";
  private static FERIE_SERVICE = "FERIE";

  public static TIME_OFF_COMBINATION: Combination[] = [
    {
      serviceOrContainerVehicle: WorkerServiceTurnModal.REST_SERVICE,
      shifts: [{
        name: WorkerServiceTurnModal.REST_SHIFT,
        startTime: WorkerServiceTurnModal.START_TIME_OFF,
        endTime: WorkerServiceTurnModal.END_TIME_OFF
      }],
      radioCodeId: 0, circuit: ""
    },
    {
      serviceOrContainerVehicle: WorkerServiceTurnModal.SMONTO_SERVICE,
      shifts: [{
        name: WorkerServiceTurnModal.SMONTO_SHIFT,
        startTime: WorkerServiceTurnModal.START_TIME_OFF,
        endTime: WorkerServiceTurnModal.END_TIME_OFF
      }],
      radioCodeId: 0, circuit: ""
    },
    {
      serviceOrContainerVehicle: WorkerServiceTurnModal.REPERIBILE_SERVICE, shifts: [
        {
          name: WorkerServiceTurnModal.REP_SHIFT,
          startTime: WorkerServiceTurnModal.START_TIME_OFF,
          endTime: WorkerServiceTurnModal.END_TIME_OFF
        }],
      radioCodeId: 0, circuit: ""
    },
    {
      serviceOrContainerVehicle: WorkerServiceTurnModal.DAILY_ABS_SERVICE, shifts: [
        {
          name: WorkerServiceTurnModal.DAILY_ABSENCE_AR,
          startTime: WorkerServiceTurnModal.START_TIME_OFF,
          endTime: WorkerServiceTurnModal.END_TIME_OFF
        },
        {
          name: WorkerServiceTurnModal.DAILY_ABSENCE_GR,
          startTime: WorkerServiceTurnModal.START_TIME_OFF,
          endTime: WorkerServiceTurnModal.END_TIME_OFF
        }
      ],
      radioCodeId: 0, circuit: ""
    },
    {
      serviceOrContainerVehicle: WorkerServiceTurnModal.FERIE_SERVICE, shifts: [{
        name: WorkerServiceTurnModal.HOLIDAYS_ABSENCE,
        startTime: WorkerServiceTurnModal.START_TIME_OFF,
        endTime: WorkerServiceTurnModal.END_TIME_OFF
      }],
      radioCodeId: 0, circuit: ""
    },
  ];

  constructor(state) {
    super(state);
    this.state = {
      circuitFilter: CircuitSelect.NO_FILTER,
      fullNameForCreation: "", combinations: [],
      isEditingAllowed: false, entriesCombination: [],
      deletedRecords: [], records: [], shifts: [],
      errors: {name: "", shiftWarning: ""},
      containerOptions: []
    }
  }

  async componentDidMount() {
    // Setup combinations
    let filteredDistinctNeedCodes: ContainerToRadio[] =
      this.props.containerVehicles.map(m => {
        return {
          name: m.containervehicle, radioId: m.radiocodeid,
          circuit: this.getCircuitByContainerVehicle(m.containervehicle)
        }
      }).filter(cv => cv.name != VehicleServiceTurnModal.VEHICLE_LAV_SERVICE &&
        cv.name != VehicleServiceTurnModal.VEHICLE_OFF_SERVICE);
    const specialServices: ContainerToRadio[] = WorkerServiceTurnModal
      .TIME_OFF_COMBINATION
      .map(c => {
        return {name: c.serviceOrContainerVehicle, radioId: c.radioCodeId, circuit: ""}
      })
      .concat(
        WorkerServiceTurnModal.OTHER_KEYS.map(c => {
          return {
            name: c.serviceOrContainerVehicle,
            radioId: c.radioCodeId, circuit: ""
          }
        }))
    const orderFilteredNeedCodes: ContainerToRadio[] = filteredDistinctNeedCodes
      .filter(service => service.name != "-" &&
        specialServices.indexOf(service) == -1).sort()
    const allNeedCodes = orderFilteredNeedCodes.concat(specialServices)
    const shiftSet = this.getDistinctShifts(this.props.shifts);

    const combinations: Combination[] = this.getUniqueCombinations(allNeedCodes, shiftSet);

    this.setState({
      entriesCombination: [],
      combinations: combinations,
      records: this.props.recordsDto,
      shifts: this.props.shifts.concat(WorkerServiceTurnModal.TIME_OFF_SHIFTS.map(shOff => {
        return {
          shiftCode: shOff, fromTime: WorkerServiceTurnModal.START_TIME_OFF,
          toTime: WorkerServiceTurnModal.END_TIME_OFF, smontoH: "", shiftType: "",
          pause: "", time: "", id: 0, typology: [], description: "", autoGenerated: false, archived: false
        }
      })),
    }, () => {
      this.setState({
        containerOptions: this.props.recordsDto.map(_ => this.getContainerOptions())
      });
    });
  }

  private getUniqueCombinations(filteredDistinctNeedCodes: ContainerToRadio[], shiftSet: Shift[]): Combination[] {
    return filteredDistinctNeedCodes.filter(
      comb => WorkerServiceTurnModal.TIME_OFF_COMBINATION.map(
        tf => {
          return {name: tf.serviceOrContainerVehicle, radioId: tf.radioCodeId}
        })
        .filter(tf => tf.name == comb.name).length == 0)
      .map((it: ContainerToRadio) => {
        return {
          serviceOrContainerVehicle: it.name,
          radioCodeId: it.radioId,
          shifts: shiftSet,
          circuit: it.circuit
        }
      }).concat(WorkerServiceTurnModal.TIME_OFF_COMBINATION);
  }

  addEntry() {
    this.hideNoShiftError();
    let {records} = this.state;
    const containerVehicle = this.state.combinations[0].serviceOrContainerVehicle;
    records.push(DailyPlanningApi.getDefaultEmptyEntry(this.props.date,
      this.props.worker.id,
      (this.props.isWorkerCreationModal ? this.state.fullNameForCreation : this.props.worker.fullName),
      containerVehicle));
    const lastSelectionIndex = records.length - 1;
    this.onChangeContainerVehicleList(lastSelectionIndex, {label: containerVehicle, value: containerVehicle})
    this.onCircuitFilteringChange(lastSelectionIndex, CircuitSelect.NO_FILTER);
    this.setState({records});
  }

  deleteAt(index: number) {
    this.hideNoShiftError();
    let {records, deletedRecords} = this.state;
    const deletedElement = records.splice(index, 1);
    deletedElement.forEach(d => deletedRecords.push(d));
    this.setState({records, deletedRecords});
  }

  private onChangeContainerVehicleList(selectionIndex: number, selectOption: SelectOption) {
    this.hideNoShiftError();
    const combinations = this.state.combinations
      .filter((comb) => comb.serviceOrContainerVehicle == selectOption.value);
    if (combinations.length == 0) throw new Error("No combinations");
    const electedCombination = combinations[0];
    if (electedCombination.shifts.length == 0) {
      this.showNoShiftError();
      return;
    }
    let {records} = this.state;
    records[selectionIndex].containervehicle = electedCombination.serviceOrContainerVehicle;
    records[selectionIndex].radiocodeid = electedCombination.radioCodeId;

    let newIndex = electedCombination.shifts.map(shift => shift.name).indexOf(records[selectionIndex].turn)
    if(newIndex < 0) newIndex = 0;
    const electedShift = electedCombination.shifts[newIndex];

    records[selectionIndex].turn = electedShift.name;
    this.setState({records}, () => {
      this.changeShift(selectionIndex, {value: electedShift.name, label: electedShift.name})
    });
  }

  private getServiceToRecord(serviceOrContainerVehicle: string, shift: string) {
    if (!serviceOrContainerVehicle) return "";

    if (shift == WorkerServiceTurnModal.REP_SHIFT) {
      return WorkerServiceTurnModal.REPERIBILE_SERVICE
    }
    if (shift == WorkerServiceTurnModal.REST_SHIFT) {
      return WorkerServiceTurnModal.REST_SERVICE
    }
    if (shift == WorkerServiceTurnModal.SMONTO_SHIFT) {
      return WorkerServiceTurnModal.SMONTO_SERVICE
    }
    if (shift == WorkerServiceTurnModal.DAILY_ABSENCE_GR) {
      return WorkerServiceTurnModal.DAILY_ABS_SERVICE
    }
    if (shift == WorkerServiceTurnModal.DAILY_ABSENCE_AR) {
      return WorkerServiceTurnModal.DAILY_ABS_SERVICE
    }
    if (shift == WorkerServiceTurnModal.HOLIDAYS_ABSENCE) {
      return WorkerServiceTurnModal.FERIE_SERVICE
    }

    const splitContainerVehicle = serviceOrContainerVehicle.split("_");
    const length = splitContainerVehicle.length;
    const isContainerVehicleSelected = length == 3;
    const isSpecialServiceSelected = length == 1;
    if (isContainerVehicleSelected || isSpecialServiceSelected) {
      return splitContainerVehicle[0].toUpperCase();
    }
    return "";
  }

  private changeShift(selectionIndex: number, shiftValue: SelectOption) {
    let {records} = this.state;
    const shiftDtos = this.state.shifts.filter(s => s.shiftCode == shiftValue.value);

    if (shiftDtos.length == 0) {
      throw new Error("No Combinations");
    }

    const electedDto = shiftDtos[0];
    records[selectionIndex].turn = shiftValue.value;
    records[selectionIndex].shiftstart = electedDto.fromTime;
    records[selectionIndex].shiftend = electedDto.toTime;

    records[selectionIndex].service = this.getServiceToRecord(records[selectionIndex].containervehicle, shiftValue.value);


    this.setState({records});
  }

  private showNoShiftError() {
    this.setState({errors: {name: "", shiftWarning: "Nessun turno disponibile per il servizio selezionato."}});
  }

  private hideNoShiftError() {
    this.setState({errors: {name: "", shiftWarning: ""}});
  }

  public static isShiftTimeOff(shiftCode: string) {
    const flattenedTimeOffShiftCodes: Shift[] =
      this.TIME_OFF_COMBINATION.map(a => a.shifts).reduce((acc, val) => acc.concat(val), []);
    let filtered = flattenedTimeOffShiftCodes.filter(a => a.name == shiftCode)
    return filtered.length == 1;
  }

  private triggerClose() {
    this.props.onCancel()
  }

  private modifyName(e: string) {
    let {errors, records, fullNameForCreation} = this.state;
    errors.name = "";
    fullNameForCreation = e;
    records.forEach(r => {
      r.workername = e
    });
    this.setState({errors, records, fullNameForCreation})
  }

  private getOnSave() {
    let {errors} = this.state;

    const workername = this.state.records[0].workername;
    if (this.props.isWorkerCreationModal &&
      this.isNameError(workername)) {
      errors.name = I18n.get().DailyTimeLine.nameError;
      this.setState({errors});
    }

    if (errors.name == "") {
      this.props.onSave(
        this.state.records,
        this.state.deletedRecords);
    }
  }

  private isNameError(workername: string) {
    return workername == "" || workername.split(" ").length < 2;
  }

  private getDistinctShifts(shifts: ShiftDto[]): Shift[] {
    return Array.from(
      new Set(
        shifts
        //Only not archived
          .filter(s => !s.archived)

        .map(
          s => {
            return {name: s.shiftCode, startTime: s.fromTime, endTime: s.toTime}
          }
        )
      )
    );
  }

  private getShiftOptionsForSelectedContainer(record: DailyPlanningWorkerDto) {
    const index = this.state.combinations
      .map(c => c.serviceOrContainerVehicle)
      .indexOf(WorkerServiceTurnModal.getContainerVehicleOrServiceForShiftList(record));
    if (index == -1) {
      console.error("Setting LAST KEY but actually not reading sync data correctly");
      return [this.state.combinations[0].shifts.map(s => {
        return {label: s.name, value: s.name}
      })];
    }
    return this.state.combinations[index].shifts.map(s => {
      return {label: s.name, value: s.name}
    });
  }

  /**
   * Currently, the engine does not specify a
   * container vehicle in case the service was LIBERO or such.
   * Instead, a "-" is present in the field
   */
  private static getContainerVehicleOrServiceForShiftList(r: DailyPlanningWorkerDto) {
    if (r.containervehicle == "-") {
      return r.service;
    }
    return r.containervehicle;
  }

  private getLabel(radioCode: string, containerVehicle: string, service: string) {
    if (containerVehicle == "-") {
      return service
    }
    const splitContainerVehicle = containerVehicle.split("_");
    if (splitContainerVehicle.length > 2) {
      const vehicleId = parseInt(splitContainerVehicle[1]);
      const vehicleNumber = parseInt(splitContainerVehicle[2]);

      if (isNaN(vehicleId)) throw new Error();
      if (isNaN(vehicleNumber)) throw new Error();

      const containerName = radioCode ? radioCode : containerVehicle;
      const electedVehicle = this.props.vehicleNeedMap.get(vehicleId);
      if (electedVehicle) {
        const category = electedVehicle.category;
        const timeLabel = `${electedVehicle.fromTime}-${electedVehicle.toTime}`;
        return `${containerName} ${category} ${timeLabel} (${vehicleNumber})`;
      }
      return containerVehicle;
    }
    return containerVehicle;
  }

  private getContainerOptions(): SelectOption[] {
    return Array.from(
      new Set(this.state.combinations
        .map(s => {
          return {
            label:
              this.getLabel(
                this.getRadioCode(s.radioCodeId),
                s.serviceOrContainerVehicle,
                s.serviceOrContainerVehicle
              ),
            value: s.serviceOrContainerVehicle
          }
        })));
  }

  private pickValue(service: string, containerVehicle: string) {
    return (containerVehicle == "-") ? service : containerVehicle;
  }

  private getRadioCode(radioCodeId: number) {
    if (radioCodeId && radioCodeId != 0)
      return this.props.radioMap.get(radioCodeId);
    return ""
  }

  private onCircuitFilteringChange(elementNr, circuitDto: string) {
    this.setState({
      circuitFilter: circuitDto
    }, () => {
      const selectOption = this.state.combinations.filter(
        comb => circuitDto == CircuitSelect.NO_FILTER ||
          comb.circuit == circuitDto || comb.circuit == "");
      const {containerOptions} = this.state;
      const filteredOptions = selectOption.map(so => {
        const radioCode = this.getRadioCode(so.radioCodeId);
        const containerVehicle = so.serviceOrContainerVehicle;
        return {
          label: this.getLabel(radioCode, containerVehicle, containerVehicle),
          value: this.pickValue(containerVehicle, containerVehicle)
        }
      });
      containerOptions[elementNr] = filteredOptions;
      this.setState({containerOptions}, ()=> {
        this.onChangeContainerVehicleList(elementNr, containerOptions[elementNr][0]);
      })
    });
  }

  private getCircuitByContainerVehicle(containerVehicle: string) {
    if (containerVehicle == "-") {
      return "";
    }
    const splitContainerVehicle = containerVehicle.split("_");
    // S_REP ?
    const isContainerVehicleSpecialForm = splitContainerVehicle.length == 2;
    if (isContainerVehicleSpecialForm) {
      const containerService = containerVehicle;
      return this.getCircuitForService(containerVehicle);
    }
    const isContainerVehicleNormalForm = splitContainerVehicle.length > 0;
    if (isContainerVehicleNormalForm) {
      const containerService = splitContainerVehicle[0];
      return this.getCircuitForService(containerService)
    }
    return "";
  }

  private getCircuitForService(containerVehicle: string) {
    const foundNeed = this.props.needs.filter(n => n.serviceCode == containerVehicle);
    if (foundNeed.length > 0) {
      return foundNeed[0].circuit;
    }
    return "";
  }

  /************************************************
   * LIFECYCLE
   ************************************************/
  render() {
    return (
      <div className="WorkerServiceTurnModal-Overlay">
        <div className="WorkerServiceTurnModal">
          <h2>
            {I18n.get().DailyPlanning.serviceModalTitle} {this.props.worker.fullName != "" ? this.props.worker.fullName :
            I18n.get().DailyTimeLine.newWorker}
          </h2>
          <div className="WorkerServiceTurnModal-Body">
            {
              this.props.isWorkerCreationModal ?
                <div className={"new-worker-form"}>
                  <TextInput errorMessage={this.state.errors.name}
                             onChange={(e) => {
                               this.modifyName(e)
                             }}
                             label={I18n.get().WorkersRegistry.name + " " + I18n.get().WorkersRegistry.surname}/>
                </div> : null
            }
            {this.state.records.map((record, i) => {
              const radioCode = this.getRadioCode(record.radiocodeid);
              return (
                <div key={record.id + "_" + i}>
                  <div className={"row"}>
                    <div className={"w-s"}>
                      <CircuitSelect className={"getCircuitForService-select"} onChange={(circuitDto) => {
                        this.onCircuitFilteringChange(i, circuitDto)
                      }}/>
                    </div>
                    <div className={"w-h container"}>
                      <Select className={"row-w height"}
                              onChange={containerValue => this.onChangeContainerVehicleList(i, containerValue)}
                              options={this.state.containerOptions[i]}
                              value={{
                                label: this.getLabel(radioCode, record.containervehicle, record.service),
                                value: this.pickValue(record.service, record.containervehicle)
                              }}
                      />


                    </div>
                    <div className={"w-h"}>
                      <Select className={"row-w height"}
                              onChange={shiftValue => this.changeShift(i, shiftValue)}
                              options={this.getShiftOptionsForSelectedContainer(record)}
                              value={{value: record.turn, label: record.turn}}
                      />

                    </div>
                    {this.state.records.length > 1 ?
                      <div className="close" onClick={() => {
                        this.deleteAt(i)
                      }}>×
                      </div> : null}
                  </div>
                  <div className={"row margin-top"}>
                    <div className={"w-h container"}>
                    </div>
                    <div className={"w-h"}>
                      <label>{`${record.shiftstart}-${record.shiftend}`}</label>
                    </div>
                  </div>
                </div>
              )
            })}
            {this.state.errors.shiftWarning != "" ?
              <div className={"row shiftWarning"}>{this.state.errors.shiftWarning}</div> : null}
            <div className={"add-btn"} onClick={() => this.addEntry()}>
              <span className={"icon"}>&#43;</span>
              <span>{I18n.get().DailyPlanning.addBtn}</span>
            </div>
          </div>

          <div className="WorkerServiceTurnModal-Buttons">
            <Button disabled={this.state.records.length == 0} onClick={() => this.getOnSave()}
                    className="WorkerServiceTurnModal-OkButton">
              Ok
            </Button>
            <Button onClick={() => this.triggerClose()}
                    secondary={true}
                    className="ConfirmModal-CancelButton">
              {I18n.get().MonthlyPlanning.cancelModal}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
