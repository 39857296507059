import React from "react";
import Icon from "./Icon";

interface Props {
  className: string;
  width: number;
  height: number;
  color: string;
}

export default class IconToastSuccess extends Icon<Props> {
  /************************************************
   * PROPS
   ************************************************/
  static defaultProps = {
    className: "",
    width: 20,
    height: 20,
    color: "#2E3A59"
  };

  /************************************************
   * LIFECYCLE
   ************************************************/
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={this.props.width} height={this.props.height} viewBox="0 0 20 20">
        <defs>
          <filter id="8vj2tzam0a">
            <feColorMatrix in="SourceGraphic"
                           values="0 0 0 0 0.521569 0 0 0 0 0.796078 0 0 0 0 0.000000 0 0 0 1.000000 0"/>
          </filter>
          <path id="1mm77tkmgb" d="M0 0H20V20H0z"/>
        </defs>
        <g fill="none" fillRule="evenodd">
          <g>
            <g filter="url(#8vj2tzam0a)" transform="translate(-502 -44) translate(484 29)">
              <g transform="translate(18 15)">
                <mask id="toc7lp135c" fill="#fff">
                  <use xlinkHref="#1mm77tkmgb"/>
                </mask>
                <path fill={this.props.color}
                      d="M1.667 10C1.667 5.4 5.4 1.667 10 1.667S18.333 5.4 18.333 10 14.6 18.333 10 18.333 1.667 14.6 1.667 10c0-3.067 0-3.067 0 0zm6.666 1.808l5.492-5.491L15 7.5l-6.667 6.667L5 10.833l1.175-1.175 2.158 2.15z"
                      mask="url(#toc7lp135c)"/>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
