import React, {Component} from "react";
import I18n from "../../../lib/I18n";
import "./MonthlyPlanningTitleBar.scss";
import {StatusEnum} from "../../../api/engine/EngineApi";
import {WizardStep} from "../MonthlyPlanningWizard/MonthlyPlanningWizard";

export interface Props {
  step:WizardStep
  status:StatusEnum
}

export default class MonthlyPlanningTitleBar extends Component<Props> {
  /************************************************
   * LIFECYCLE
   ************************************************/
  render() {
    const labels = I18n.get().MonthlyTimeLine;
    const {status} = this.props;
    return (
      <div className="MonthlyPlanningTitleBar">
            {status == StatusEnum.NO_PLANNING
              ? <>{this.getWizardStepLabel()}</>
              : labels.monthlyPlanning}
      </div>
    )
  }

  private getWizardStepLabel() {
    let labels = I18n.get().MonthlyTimeLine;
    switch (this.props.step){
      case WizardStep.ZERO: return labels.monthlyPlanning;
      case WizardStep.ONE: return labels.assignShifts;
      case WizardStep.TWO: return labels.assignUnavailability;
      case WizardStep.THREE: return labels.verify;
      default:
    }
  }
}
