import React from "react";
import Icon, {Props} from "./Icon";

export default class IconKebab extends Icon {
  static defaultProps: Partial<Props> = {
    height: 25,
    width: 25
  };

  render() {
    return (
      <svg className={"KebabIcon " + this.props.className}
           width={this.props.width}
           height={this.props.height}
           viewBox={"0 0 " + this.props.width + " " + this.props.height}>
        <defs>
          <filter id="dattpycsla">
            <feColorMatrix in="SourceGraphic"
                           values="0 0 0 0 0.374657 0 0 0 0 0.438476 0 0 0 0 0.483431 0 0 0 1.000000 0"/>
          </filter>
          <path id="nnd9b1z76b" d="M0 0H24V24H0z"/>
        </defs>
        <g fill="none" fillRule="evenodd">
          <g>
            <g>
              <g>
                <g filter="url(#dattpycsla)"
                   transform="translate(-670 -384) translate(446 -10) translate(44 234) translate(1 127)">
                  <g transform="translate(179 33)">
                    <mask id="tisaxoesyc" fill="#fff">
                      <use xlinkHref="#nnd9b1z76b"/>
                    </mask>
                    <path fill="#2E3A59" d="M14 6c0-1.1-.9-2-2-2s-2 .9-2 2 .9 2 2 2 2-.9 2-2zm-4 6c0 1.1.9 2 2 2s2-.9 2-2-.9-2-2-2-2 .9-2 2zm2 4c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2z" mask="url(#0qmurm9s2c)"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
