import {NeedDto} from "../../entity/NeedDto";
import {NeedHumanErrors, NeedModalErrors, NeedVehicleErrors} from "../../../pages/NeedModal/NeedModal";
import I18n from "../../../lib/I18n";
import EngineConfigValidator from "./EngineConfigValidator";
import NeedManagementApi from "../../need/NeedManagementApi";
import {ModalType} from "../../../components/Modal/ModalType";
import GfDateUtils from "../../../components/DatePicker/GfDateUtils";
import {DateTime} from "luxon";

export default class NeedValidator {

  private static readonly EMPTY_ERROR_STRING = "";
  private static DAY_NUMBER = 7;
  private static regexTime = "^[0-9]{2}:[0-9]{2}$";
  private static regexServiceCode = "^[a-zA-Z0-9\\_\\-\\.]+$";

  public static async validate(need: NeedDto, modalType: ModalType): Promise<NeedModalErrors> {
    const needModalError: NeedModalErrors = this.getEmptyErrors();
    const needApi: NeedManagementApi = new NeedManagementApi();

    if (need.serviceCode.length == 0) {
      needModalError.codeService = I18n.get().NeedManagement.addNewNeedManagement.errorMessage.code;
    }

    if (!new RegExp(NeedValidator.regexServiceCode).test(need.serviceCode)) {
      needModalError.codeService = I18n.get().NeedManagement.addNewNeedManagement.errorMessage.invalidCode;
    }

    if (modalType == ModalType.CREATE && (await needApi.getNotDeletedByCode(need.serviceCode)).content.length == 1) {
      needModalError.codeService = I18n.get().NeedManagement.addNewNeedManagement.errorMessage.existingCode;
    }

    if (need.serviceCode.indexOf("_") > -1) {
      needModalError.codeService = I18n.get().NeedManagement.addNewNeedManagement.errorMessage.errorMessageSlashes;
    }

    if (DateTime.fromFormat(need.startDate, GfDateUtils.STORED_DATE_FORMAT).toMillis() > DateTime.fromFormat(need.endDate, GfDateUtils.STORED_DATE_FORMAT).toMillis()) {
      needModalError.fromDate = I18n.get().NeedManagement.addNewNeedManagement.errorMessage.fromDate;
      needModalError.toDate = I18n.get().NeedManagement.addNewNeedManagement.errorMessage.toDate;
    }

    need.needVehicleDtoList.forEach((vehicle, index) => {
        // Initialize
        needModalError.needVehicleErrors.push({
          fromTimeError: this.EMPTY_ERROR_STRING, toTimeError: this.EMPTY_ERROR_STRING,
          weekEndTimeErrors: [], weekTimeErrors: []
        });


        vehicle.numberVehiclesFestive.forEach(festive => {
          if (!EngineConfigValidator.isPositiveInt(festive)) {
            needModalError.needVehicleErrors[index].weekEndTimeErrors.push(I18n.get().NeedManagement.addNewNeedManagement.errorMessage.empty);
          } else {
            needModalError.needVehicleErrors[index].weekEndTimeErrors.push(this.EMPTY_ERROR_STRING);
          }
        });


        vehicle.numberVehiclesWorkdays.forEach(weekday => {
          if (!EngineConfigValidator.isPositiveInt(weekday)) {
            needModalError.needVehicleErrors[index].weekTimeErrors.push(I18n.get().NeedManagement.addNewNeedManagement.errorMessage.empty);
          } else {
            needModalError.needVehicleErrors[index].weekTimeErrors.push(this.EMPTY_ERROR_STRING);
          }
        });

        if (!new RegExp(this.regexTime).test(vehicle.fromTime)) {
          needModalError.needVehicleErrors[index].fromTimeError = I18n.get().ShiftRegistry.shiftModal.timeError;
        }
        if (!new RegExp(this.regexTime).test(vehicle.toTime)) {
          needModalError.needVehicleErrors[index].toTimeError = I18n.get().ShiftRegistry.shiftModal.timeError;
        }

        // Check that at least one need value is set to positive value
        const anyPositiveValue = vehicle.numberVehiclesWorkdays.concat(vehicle.numberVehiclesFestive)
          .map(t => EngineConfigValidator.isOneOrGreater(t)).filter(t => t);
        if (anyPositiveValue.length == 0) {
          needModalError.emptyVehicleNeed = I18n.get().NeedManagement.addNewNeedManagement.errorMessage.emptyNeed;
        }
      }
    )

    need.needHumanDtoList.forEach((human, index) => {
      // Initialize
      needModalError.needHumanErrors.push({
        weekEndTimeErrors: [], weekTimeErrors: []
      });

      human.numberVehiclesFestive.forEach(festive => {
        if (!EngineConfigValidator.isPositiveInt(festive)) {
          needModalError.needHumanErrors[index].weekEndTimeErrors.push(I18n.get().NeedManagement.addNewNeedManagement.errorMessage.empty);
        } else {
          needModalError.needHumanErrors[index].weekEndTimeErrors.push(this.EMPTY_ERROR_STRING);
        }
      });

      human.numberVehiclesWorkdays.forEach(weekday => {
        if (!EngineConfigValidator.isPositiveInt(weekday)) {
          needModalError.needHumanErrors[index].weekTimeErrors.push(I18n.get().NeedManagement.addNewNeedManagement.errorMessage.empty);
        } else {
          needModalError.needHumanErrors[index].weekTimeErrors.push(this.EMPTY_ERROR_STRING);
        }
      });

      // Check that at least one need value is set to positive value
      const anyPositiveValue = human.numberVehiclesWorkdays.concat(human.numberVehiclesFestive)
        .map(t => EngineConfigValidator.isOneOrGreater(t)).filter(t => t);
      if (anyPositiveValue.length == 0) {
        needModalError.emptyVehicleNeed = I18n.get().NeedManagement.addNewNeedManagement.errorMessage.emptyNeed;
      }

    })
    return needModalError;
  }

  public static isNoError(needModalError: NeedModalErrors) {
    return needModalError.fromDate == this.EMPTY_ERROR_STRING && needModalError.toDate == this.EMPTY_ERROR_STRING
      && needModalError.codeService == this.EMPTY_ERROR_STRING && needModalError.emptyVehicleNeed == this.EMPTY_ERROR_STRING
      && needModalError.codeService.indexOf("_") == -1 &&
      needModalError.needVehicleErrors.filter(vehicleError => !this.isVehicleCorrect(vehicleError)).length == 0
  }

  public static getEmptyErrors() {
    return {
      codeService: this.EMPTY_ERROR_STRING,
      fromDate: this.EMPTY_ERROR_STRING,
      toDate: this.EMPTY_ERROR_STRING,
      emptyVehicleNeed: this.EMPTY_ERROR_STRING,
      needVehicleErrors: [],
      needHumanErrors: []
    }
  };

  public static getEmptyVehicleError(): NeedVehicleErrors {
    return {
      fromTimeError: this.EMPTY_ERROR_STRING,
      toTimeError: this.EMPTY_ERROR_STRING,
      weekTimeErrors: this.getWeekTimeEmptyErrors(),
      weekEndTimeErrors: this.getWeekTimeEmptyErrors()
    }
  };

  public static getEmptyHumanError(): NeedHumanErrors {
    return {
      weekTimeErrors: this.getWeekTimeEmptyErrors(),
      weekEndTimeErrors: this.getWeekTimeEmptyErrors()
    }
  };

  public static getWeekTimeEmptyErrors() {
    return [this.EMPTY_ERROR_STRING, this.EMPTY_ERROR_STRING, this.EMPTY_ERROR_STRING,
      this.EMPTY_ERROR_STRING, this.EMPTY_ERROR_STRING, this.EMPTY_ERROR_STRING, this.EMPTY_ERROR_STRING];
  }

  private static isVehicleCorrect(vehicleError: NeedVehicleErrors) {
    return vehicleError.toTimeError == this.EMPTY_ERROR_STRING && vehicleError.fromTimeError == this.EMPTY_ERROR_STRING
      && vehicleError.weekEndTimeErrors.filter(a => a == this.EMPTY_ERROR_STRING).length == this.DAY_NUMBER
      && vehicleError.weekTimeErrors.filter(a => a == this.EMPTY_ERROR_STRING).length == this.DAY_NUMBER;
  }


}
