import React from "react";
import Icon, {Props} from "./Icon";

export default class IconClock extends Icon {
  static defaultProps: Partial<Props> = {
    height: 18,
    width: 18,
    color: "#2E3A59"
  };

  render() {
    return (
      <svg className="IconClock"
           width={this.props.width}
           height={this.props.height}
           viewBox="0 0 18 18">
        <path fill={this.props.color}
              d="M1.5 9c0 4.136 3.365 7.5 7.5 7.5 4.136 0 7.5-3.364 7.5-7.5 0-4.135-3.364-7.5-7.5-7.5-4.135 0-7.5 3.365-7.5 7.5zM3 9c0-3.308 2.692-6 6-6s6 2.692 6 6-2.692 6-6 6-6-2.692-6-6zm5.25-3.75v4.5h4.5v-1.5h-3v-3h-1.5z"
              mask="url(#h21tn11cvc)"/>
      </svg>
    );
  }
}
