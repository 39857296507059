import AuthorizeApi from "../AuthorizeApi";
 import {MonthlyUnavailabilityRequest} from "../entity/request/MonthlyUnavailabilityRequest";
import {DateTime} from "luxon";
import {MonthlyPlannedUnavailabilityRequest} from "../entity/request/MonthlyPlannedUnavailabilityRequest";
import {
  MonthlyPlannedUnavailabilityEntryResponse, MonthlyPlannedUnavailabilityEntryResponseNt,
  MonthlyPlannedUnavailabilityResponse,
  MonthlyPlannedUnavailabilityResponseNt
} from "../entity/response/nt/MonthlyPlannedUnavailabilityResponseNt";


export default class MonthlyUnavailabilityPlanningApi {

  private base: string = '/planned-unavailability';

  private authApi: AuthorizeApi = new AuthorizeApi();

  public async getUnavailabilityEntryById(id: number): Promise<MonthlyPlannedUnavailabilityEntryResponse> {
    const response = await this.authApi.get<MonthlyPlannedUnavailabilityEntryResponseNt>(`${this.base}/` + id);
    return MonthlyUnavailabilityPlanningApi.mapToUnavailabilityEntryResponse(response);
  }

  public async getUnavailabilityDate(date: DateTime, filter: string): Promise<MonthlyPlannedUnavailabilityResponse> {
    const response = await this.authApi.post<MonthlyUnavailabilityRequest, MonthlyPlannedUnavailabilityResponseNt>(`${this.base}`,
      {date: date.toISO(), filter: filter})
    return MonthlyUnavailabilityPlanningApi.mapToUnavailabilityResponse(response)
  }

  public async upsert(planningRequest: MonthlyPlannedUnavailabilityRequest): Promise<MonthlyPlannedUnavailabilityResponse> {
    const response = await this.authApi.put<MonthlyPlannedUnavailabilityRequest,
      MonthlyPlannedUnavailabilityResponseNt>(this.base + "/entry", planningRequest)
    return MonthlyUnavailabilityPlanningApi.mapToUnavailabilityResponse(response);
  }

  public async deleteUnavailability(id: number) {
    await this.authApi.delete(this.base + "/entry/" + id);
  }

  private static mapToUnavailabilityResponse(monthlyPlannedRowNt: MonthlyPlannedUnavailabilityResponseNt)
    : MonthlyPlannedUnavailabilityResponse {
    return new MonthlyPlannedUnavailabilityResponse(monthlyPlannedRowNt);
  }

  private static mapToUnavailabilityEntryResponse(monthlyPlannedRowNt: MonthlyPlannedUnavailabilityEntryResponseNt)
    : MonthlyPlannedUnavailabilityEntryResponse {
    return new MonthlyPlannedUnavailabilityEntryResponse(monthlyPlannedRowNt);
  }
}
