import React from "react";
import Icon, {Props} from "./Icon";

export default class IconCalendarUn extends Icon {
  static defaultProps: Partial<Props> = {
    height: 18,
    width: 18,
    color: "#2E3A59"
  };

  render() {
    return (
      <svg className="IconCalendar"
           width={this.props.width}
           height={this.props.height}
           viewBox="0 0 18 18">
        <path fill={this.props.color}
              d="M6.22 12.97l1.72-1.72-1.72-1.72 1.06-1.06L9 10.19l1.72-1.72 1.06 1.06-1.72 1.72 1.72 1.72-1.06 1.06L9 12.31l-1.72 1.72-1.06-1.06zM12.75 3h1.5c.827 0 1.5.673 1.5 1.5V15c0 .827-.673 1.5-1.5 1.5H3.75c-.827 0-1.5-.673-1.5-1.5V4.5c0-.827.673-1.5 1.5-1.5h1.5V1.5h1.5V3h4.5V1.5h1.5V3zm-9 12h10.502l-.002-7.5H3.75V15zm0-9h10.5V4.5H3.75V6z"
              mask="url(#wr2jk7cvxc)"/>
      </svg>
    );
  }
}
