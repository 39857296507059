import FixedShiftDtoNt from "../nt/FixedShiftDtoNt";
import {FixedShiftDto} from "../FixedShiftDto";
import {FixedShiftResponseNt} from "./nt/FixedShiftResponseNt";

export class FixedShiftResponse {
  status: string;
  errors: string[];
  content: FixedShiftDtoNt[];
  totalPages: number;
  currentPages: number;
  size: number;
  total: number;

  constructor(shiftResponseNt: FixedShiftResponseNt) {
    this.status = shiftResponseNt.status;
    this.errors = shiftResponseNt.errors;
    this.content = shiftResponseNt.content.map((shiftDtoNt) => new FixedShiftDto(shiftDtoNt))
    this.totalPages = shiftResponseNt.totalPages;
    this.currentPages = shiftResponseNt.currentPages;
    this.size = shiftResponseNt.size;
    this.total = shiftResponseNt.total;
  }

}
